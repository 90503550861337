import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryButton } from "./button";

export default function EChartExportButton({
  label,
  selectedEchart,
  isLoading,
  handleExport,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNonADCClick = () => {
    handleExport(selectedEchart, "full");
  };

  const handleOnExport = (item) => {
    handleClose();
    handleExport(selectedEchart, item);
  };

  return (
    <>
      {selectedEchart !== "ADC" && (
        <PrimaryButton
          label={label}
          isLoading={isLoading}
          disabled={isLoading || !selectedEchart || selectedEchart === "ATOCA"}
          onClick={handleNonADCClick}
        />
      )}
      {selectedEchart === "ADC" && (
        <PrimaryButton
          label={label}
          id="export-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          isLoading={isLoading}
          disabled={isLoading || !selectedEchart}
          onClick={handleClick}
        />
      )}
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "export-button",
        }}
      >
        <MenuItem onClick={() => handleOnExport("full")}>Full Chart</MenuItem>
        <MenuItem onClick={() => handleOnExport("grids")}>Chose Grid</MenuItem>
      </Menu>
    </>
  );
}
