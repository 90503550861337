import React, { Fragment, useEffect, useState } from "react";
import { chain, flatten, split } from "lodash";
import { useSelector } from "react-redux";
import { Polygon, Popup } from "react-leaflet";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { areaHighLightColor } from "../../constants/colors";
import { modifyCategories } from "../../constants/amdbData";
import { roles } from "../../constants/constant";
import PopupBox from "../map/PopupBox";
import { LightButton } from "../button";

export default function RenderTwyShape({
  selectedFeatures,
  twyElementList,
  handleEditShap,
}) {
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [twyShapes, setTwyShapes] = useState([]);
  const [areaColor, setAreaColor] = useState({});
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const twyList = chain(twyElementList)
      .filter((twyObj) => {
        const twyKey = `twy_${twyObj.twy}`;
        return selectedFeatures.includes(`${twyKey}__${areaColorT[twyKey]}`);
      })
      .value();

    setAreaColor(areaColorT);
    setTwyShapes(twyList);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = (e) => {
    e.target.bringToBack();
    setSelectedOverE(null);
  };

  const handlePolygonEdit = (data) => {
    handleEditShap(data, modifyCategories.taxiway);
  };

  return (
    <Fragment>
      {twyShapes.map((twyGeometry) => {
        const twyCoordinates = twyGeometry.coordinates.coordinates;
        const twyCoordinateMap = flatten(twyCoordinates).map((twyCoords) => {
          return twyCoords.map((twy) => {
            return {
              lng: twy[0],
              lat: twy[1],
            };
          });
        });

        return twyCoordinateMap.map((twyPolygonPath, i) => {
          const twyKey = `twy_${twyGeometry.twy}`;

          return (
            <Polygon
              key={i}
              positions={twyPolygonPath}
              pathOptions={{
                fillColor: areaColor[twyKey],
                color:
                  selectedOverE?.twy === twyGeometry.twy
                    ? areaHighLightColor
                    : areaColor[twyKey],
                weight: selectedOverE?.twy === twyGeometry.twy ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, twyGeometry),
                mouseout: (e) => handlePolygonOut(e),
              }}
            >
              <Popup>
                <PopupBox>
                  <Box>Taxiway:</Box>
                  <Box>{twyGeometry?.twy}</Box>
                </PopupBox>
                {isAdmin && (
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <LightButton
                      label="Modify"
                      onClick={() => handlePolygonEdit(twyGeometry)}
                    />
                  </Stack>
                )}
              </Popup>
            </Polygon>
          );
        });
      })}
    </Fragment>
  );
}
