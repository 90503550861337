import SvgIcon from '@mui/material/SvgIcon';

export default function PoleIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }} {...props} >
      <svg fill="none">
        <circle cx="12" cy="12" r="7.00001" stroke="black" strokeWidth="2" />
        <circle cx="12" cy="12" r="2.18182" fill="black" />
      </svg>
    </SvgIcon>
  )
};