import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import generator from "generate-password";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {
  OutlinedButton,
  PrimaryButton,
  LightButton,
} from "../../components/button";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";
import { ACCESS_TOKEN, AES_KEY, roles } from "../../constants/constant";
import { masterResetPasswordValidationSchema } from "../../constants/surveyorData";
import {
  getAllRequestPassword,
  selectAllReqPasswordList,
  masterResetPassword,
} from "../../models/survey";
import { decryptStatic, encryptStatic } from "../../utils/decodeEncodeData";
import {
  getAsyncStorageValue,
  getStorageValue,
} from "../../utils/localStorage";

export default function NewPasswordRequest() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;
  const isLoadingGetallReqPassword = useSelector(
    (state) => state.survey.isLoadingGetallReqPassword
  );
  const isResetingMasterPassword = useSelector(
    (state) => state.survey.isResetingMasterPassword
  );
  const allPasswordRequestList = useSelector((state) =>
    selectAllReqPasswordList(state)
  );
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const aesKey = getStorageValue(AES_KEY);
  const passwordResetForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
    },
    validationSchema: masterResetPasswordValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          masterResetPassword(
            {
              user_id: values.user_id,
              new_password: encryptStatic(values.new_password, aesKey),
            },
            token,
            handleCloseResetPasswordDialog
          )
        );
      });
    },
  });

  useEffect(() => {
   if (isAdmin) {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllRequestPassword(token));
    })
   }
  }, []); // eslint-disable-line

  const handleResetPassword = (payload) => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    passwordResetForm.setValues({
      user_id: payload.userid,
      new_password: password,
    });
    setOpenResetPasswordDialog(true);
  };

  const handleCloseResetPasswordDialog = () => {
    setOpenResetPasswordDialog(false);
    passwordResetForm.resetForm();
    passwordResetForm.setSubmitting(false);
  };

  return (
    <div className="surveyor-category-table white-bg">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Surveyor Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell align="center">ACTION</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingGetallReqPassword && (
              <TableRow>
                <StyledTableCell align="center" colSpan={5}>
                  Loading...
                </StyledTableCell>
              </TableRow>
            )}
            {!isLoadingGetallReqPassword &&
              allPasswordRequestList.length === 0 && (
                <TableRow>
                  <StyledTableCell align="center" colSpan={5}>
                    No record found.
                  </StyledTableCell>
                </TableRow>
              )}
            {allPasswordRequestList.map((request) => {
              return (
                <TableRow key={request.emp_id}>
                  <StyledTableCell>{request.emp_id}</StyledTableCell>
                  <StyledTableCell>
                    {decryptStatic(request.name, aesKey)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {decryptStatic(request.email, aesKey) || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <LightButton
                      label="RESET PASSWORD"
                      onClick={() => handleResetPassword(request)}
                      disabled={isResetingMasterPassword}
                      isLoading={isResetingMasterPassword}
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullWidth open={openResetPasswordDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              id="new_password"
              name="new_password"
              label="New Password"
              value={passwordResetForm.values.new_password}
              onChange={passwordResetForm.handleChange}
              error={
                passwordResetForm.touched.new_password &&
                Boolean(passwordResetForm.errors.new_password)
              }
              helperText={
                passwordResetForm.touched.new_password &&
                passwordResetForm.errors.new_password
              }
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={passwordResetForm.values.new_password}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton
                        edge="end"
                        color={isCopied ? "primary" : "default"}
                      >
                        <FaCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseResetPasswordDialog}
            sx={{ width: 120 }}
          />
          <PrimaryButton
            label="Reset Password"
            onClick={() => passwordResetForm.handleSubmit()}
            disabled={isResetingMasterPassword}
            isLoading={isResetingMasterPassword}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
