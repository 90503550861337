import React, { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { FaArrowLeft } from "react-icons/fa";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { roles } from "../constants/constant";
import { airportDataCategories } from "../constants/ad2Data";
import { setSelctedAirportFormCategories } from "../models/airport";
import DropDownLabel from "./DropDownLabel";
import PageTitle from "./PageTitle";
import BrandLogo from "./BrandLogo";

export default function AD2DataEntrySideBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.userSession.user);
  const isAAI = user?.role === roles.aai;
  const isSurveyor = user?.role === roles.surveyor;
  const isAddAirportDataPath =
    location.pathname === "/ad-2-data-entry/add-airport-data";
  const selctedAirportFormCategories = useSelector(
    (state) => state.airport.selctedAirportFormCategories
  );
  const isLoadingAddAirportDetail = useSelector(
    (state) => state.airport.isLoadingAddAirportDetail
  );

  const handleAirportFromCategory = (option) => {
    dispatch(setSelctedAirportFormCategories(option));
  };

  return (
    <div className="sidebar">
      <BrandLogo />
      <Button
        variant="text"
        size="small"
        startIcon={<FaArrowLeft />}
        sx={{ mt: -5 }}
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      {(isAAI || isSurveyor) && (
        <Fragment>
          <PageTitle title="Add data to new airport" />
          {isAddAirportDataPath && (
            <Box>
              <DropDownLabel label="Category" />
              <Select
                isClearable
                isMulti
                value={selctedAirportFormCategories}
                placeholder="Select..."
                disabled={isLoadingAddAirportDetail}
                onChange={handleAirportFromCategory}
                options={airportDataCategories}
              />
            </Box>
          )}
        </Fragment>
      )}
    </div>
  );
}
