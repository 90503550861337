// Async variable names.

export const ACCESS_TOKEN = "AAI_ACCESS_TOKEN";
export const REFRESH_TOKEN = "AAI_REFRESH_TOKEN";
export const AES_KEY = "AAI_AES_KEY";

export const defaultLocationCenter = {
  lat: 20.5937,
  lng: 78.9629,
};

// fe version
export const feVersion = {
  aai: "AAI",
  aero: "AERO",
};

// Google Map]
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const mapStyleWithoutLabel = [
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const roles = {
  admin: "ADMIN",
  surveyor: "SURVEYOR",
  aai: "AAI_USER",
};

export const userType = {
  surveyor: "SURVEYOR",
  aai: "AAI_USER",
};

export const userLabel = {
  SURVEYOR: "Surveyor",
  AAI_USER: "AAI User",
};

export const eTodAirportList = [
  "CHENNAI INTERNATIONAL AIRPORT",
  "NETAJI SUBHASH CHANDRA BOSE INTERNATIONAL AIRPORT, KOLKATA",
  "IMPHAL AIRPORT",
  "CALICUT AIRPORT",
  "TIRUCHIRAPPALLI AIRPORT",
];

export const amdbAirportList = [
  "CHENNAI INTERNATIONAL AIRPORT",
  "NETAJI SUBHASH CHANDRA BOSE INTERNATIONAL AIRPORT, KOLKATA",
  "TIRUCHIRAPPALLI AIRPORT",
  "IMPHAL AIRPORT",
  "CALICUT AIRPORT",
];

export const eChartAirportList = [
  "CHENNAI INTERNATIONAL AIRPORT",
  "NETAJI SUBHASH CHANDRA BOSE INTERNATIONAL AIRPORT, KOLKATA",
  "TIRUCHIRAPPALLI AIRPORT",
  "IMPHAL AIRPORT",
  "CALICUT AIRPORT",
];

export const mapDrawType = {
  polygon: "Polygon",
  lineString: "LineString",
  point: "Point",
  polyline: "Polyline",
};
