import SvgIcon from '@mui/material/SvgIcon';

export default function TreeIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }} {...props} >
      <svg fill="none">
        <path d="M3.47519 12.031H21" stroke="black" strokeWidth="1.2" strokeLinecap="round" />
        <path d="M8.30084 4.41156L17.0632 19.5885" stroke="black" strokeWidth="1.2" strokeLinecap="round" />
        <path d="M16.5553 4.41156L7.79289 19.5885" stroke="black" strokeWidth="1.2" strokeLinecap="round" />
      </svg>
    </SvgIcon>
  )
};