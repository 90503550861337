import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { FaArrowLeft } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import {
  getFIRData,
  getAllTRA_TSA_ADIZ,
  getRestrictiveAirspaceDetails,
  setRestrictiveAirspaceDetails,
  findADIZDataList,
  findFIRData,
} from "../../models/airspace";
import { airspaceType } from "../../constants/airspaceData";
import MapLayers from "../map/MapLayers";
import MeasurementControl from "../map/MeasurementControl";
import FullScreenControl from "../map/FullScreenControl";
import RouteTable from "../airspace/RouteTable";
import RenderPDRAirspace from "./RenderPDRAirspace";
import RenderTraTsaAirspace from "./RenderTraTsaAirspace";
import RenderControlledAirspace from "./RenderControlledAirspace";
import RenderFIRAirspace from "./RenderFIRAirspace";
import RenderADIZAirspace from "./RenderADIZAirspace";
import RestrictedAirspaceDetail from "./RestrictedAirspaceDetail";
import ADIZDetail from "./ADIZDetail";
import FIRUIRDetail from "./FIRUIRDetail";
import RenderNavigationalRouteAirspace from "./RenderNavigationalRouteAirspace";
import RenderConventionalRouteAirspace from "./RenderConventionalRouteAirspace";
import RenderNavidsAirspace from "./RenderNavidsAirspace";
import RenderWayPointsAirspace from "./RenderWayPointsAirspace";
import RenderWayPointsRouteAirspace from "./RenderWayPointsRouteAirspace";
import NavidDetail from "./NavidDetail";

export default function OSMMapContainer({
  airportLocation,
  zoomLevel,
  colorTray,
  pdrAirspaceGeometry,
  traTsaAirspaceGeometry,
  controlAirspaceGeometry,
  FIRAirspaceGeometry,
  ADIZAirspaceGeometry,
  areaNavigationalRouteData,
  conventionalRouteData,
  navidAirspaceGeometry,
  wayPointsAirspaceGeometry,
  waypointRouteGeometry,
}) {
  const dispatch = useDispatch();
  const isLoadingRestrictiveAirspaceDetails = useSelector(
    (state) => state.airspace.isLoadingRestrictiveAirspaceDetails
  );
  const isLoadingFIRData = useSelector(
    (state) => state.airspace.isLoadingFIRData
  );
  const isLoadingGetAllTRA_TSA_ADIZ = useSelector(
    (state) => state.airspace.isLoadingGetAllTRA_TSA_ADIZ
  );
  const restrictiveAirspaceDetails = useSelector(
    (state) => state.airspace.restrictiveAirspaceDetails || []
  );
  const [selectedFIRUIR, setSelectedFIRUIR] = useState(null); // eslint-disable-line
  const FIRData = useSelector((state) => findFIRData(state, selectedFIRUIR));
  const [selectedADIZ, setSelectedADIZ] = useState(null); // eslint-disable-line
  const ADIZData = useSelector((state) =>
    findADIZDataList(state, selectedADIZ)
  );
  const [selectedNavid, setSelectedNavid] = useState(null);
  const [isShowMoreView, setShowMoreView] = useState(false);
  const [slectedFeatureType, setSlectedFeatureType] = useState(null);
  const [slectedFeatureData, setSlectedFeatureData] = useState(null);

  useEffect(() => {
    dispatch(getFIRData());
    dispatch(getAllTRA_TSA_ADIZ());
  }, []); // eslint-disable-line

  const handleViewMore = (selectedPolygon, type, selectedData) => {
    setSlectedFeatureType(type);

    if (selectedData) {
      setSlectedFeatureData(selectedData);
    }

    if (type === airspaceType.restrictedAirspace) {
      dispatch(getRestrictiveAirspaceDetails(selectedPolygon.airspace_id));
    } else if (type === airspaceType.firuir) {
      setSelectedFIRUIR(selectedPolygon.name);
    } else if (type === airspaceType.adiz) {
      setSelectedADIZ(selectedPolygon.designation);
    } else if (type === airspaceType.navaids) {
      setSelectedNavid(selectedPolygon);
    }

    setShowMoreView(true);
  };

  const handleCloseViewMore = () => {
    setShowMoreView(false);
    dispatch(setRestrictiveAirspaceDetails(null));
  };

  const airspaceMapE = useMemo(() => {
    return (
      <MapContainer
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        <RenderPDRAirspace
          pdrAirspaceGeometry={pdrAirspaceGeometry}
          colorTray={colorTray}
          handleViewMore={handleViewMore}
        />
        <RenderTraTsaAirspace
          traTsaAirspaceGeometry={traTsaAirspaceGeometry}
          colorTray={colorTray}
          handleViewMore={handleViewMore}
        />
        <RenderControlledAirspace
          controlAirspaceGeometry={controlAirspaceGeometry}
          colorTray={colorTray}
        />
        <RenderFIRAirspace
          FIRAirspaceGeometry={FIRAirspaceGeometry}
          handleViewMore={handleViewMore}
        />
        <RenderADIZAirspace
          ADIZAirspaceGeometry={ADIZAirspaceGeometry}
          handleViewMore={handleViewMore}
        />
        <RenderNavigationalRouteAirspace
          routeData={areaNavigationalRouteData}
          handleViewMore={handleViewMore}
        />
        <RenderConventionalRouteAirspace
          routeData={conventionalRouteData}
          handleViewMore={handleViewMore}
        />
        <RenderNavidsAirspace
          navidAirspaceGeometry={navidAirspaceGeometry}
          handleViewMore={handleViewMore}
        />
        <RenderWayPointsAirspace
          wayPointsAirspaceGeometry={wayPointsAirspaceGeometry}
        />
        <RenderWayPointsRouteAirspace
          routeData={waypointRouteGeometry}
          handleViewMore={handleViewMore}
        />
        {/* <PrintMap /> */}
        <FullScreenControl />
        <MeasurementControl />
        <ZoomControl position="bottomright" />
        <MapLayers />
        <ScaleControl position="topleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pdrAirspaceGeometry,
    traTsaAirspaceGeometry,
    controlAirspaceGeometry,
    FIRAirspaceGeometry,
    ADIZAirspaceGeometry,
    areaNavigationalRouteData,
    conventionalRouteData,
    navidAirspaceGeometry,
    wayPointsAirspaceGeometry,
    waypointRouteGeometry,
  ]);

  return (
    <Fragment>
      {airspaceMapE}
      {isShowMoreView && (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "#FFFFFF",
            zIndex: 1001,
          }}
        >
          <Fragment>
            <IconButton
              onClick={handleCloseViewMore}
              sx={{ ml: 1, mt: 1, mb: 1 }}
            >
              <FaArrowLeft />
            </IconButton>
            {slectedFeatureType === airspaceType.restrictedAirspace && (
              <RestrictedAirspaceDetail
                isLoadingRestrictiveAirspaceDetails={
                  isLoadingRestrictiveAirspaceDetails
                }
                restrictiveAirspaceDetails={restrictiveAirspaceDetails}
              />
            )}
            {slectedFeatureType === airspaceType.adiz && (
              <ADIZDetail
                isLoadingGetAllTRA_TSA_ADIZ={isLoadingGetAllTRA_TSA_ADIZ}
                ADIZData={ADIZData}
              />
            )}
            {slectedFeatureType === airspaceType.firuir && (
              <FIRUIRDetail
                isLoadingFIRData={isLoadingFIRData}
                FIRData={FIRData}
              />
            )}
            {slectedFeatureType === airspaceType.rnav && (
              <RouteTable
                isLoading={false}
                routeData={areaNavigationalRouteData}
              />
            )}
            {slectedFeatureType === airspaceType.cnav && (
              <RouteTable isLoading={false} routeData={conventionalRouteData} />
            )}
            {slectedFeatureType === airspaceType.wayPointRoutes &&
              slectedFeatureData && (
                <RouteTable isLoading={false} routeData={slectedFeatureData} />
              )}
            {slectedFeatureType === airspaceType.navaids && (
              <NavidDetail selectedNavid={selectedNavid} />
            )}
          </Fragment>
        </div>
      )}
    </Fragment>
  );
}
