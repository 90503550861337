import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {
  GOOGLE_MAP_API_KEY,
  mapStyleWithoutLabel,
} from "../../constants/constant";

export default function GoogleMapLayer({ maptype = "roadmap" }) {
  return (
    <ReactLeafletGoogleLayer
      apiKey={GOOGLE_MAP_API_KEY}
      styles={mapStyleWithoutLabel}
      type={maptype}
      googleMapsLoaderConf={{
        region: "IN",
      }}
    />
  );
}

export function GoogleMapLayerWithLabel({ maptype = "roadmap" }) {
  return (
    <ReactLeafletGoogleLayer
      apiKey={GOOGLE_MAP_API_KEY}
      type={maptype}
      googleMapsLoaderConf={{
        region: "IN",
      }}
    />
  );
}
