import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';
import { aixmFeatureKeyMap } from '../../constants/aixmData';

export default function RescueList({
  isLoading,
  category,
  airportDetails,
  selctedAirport
}) {
  const rescueList = airportDetails[category];
  const keys = Object.keys(rescueList);

  return (
    <Fragment key={category}>
      {(isLoading || keys.length === 0) && (
        <TableLoader isLoading={isLoading} data={keys} />
      )}
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {aixmFeatureKeyMap[category]}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell sx={{ width: '20%' }}>
                Aerodrome category for fire fighting
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Rescue equipment
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {rescueList.RESCUE_EQPT_DESC || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Capability for removal of disabled aircraft
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {rescueList.REMOVAL_AIRCRAFT || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {rescueList.RESCUE_REMARK || 'NIL'}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
