import React, { Fragment, useMemo, useState, useEffect } from "react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { split } from "lodash";
import MapLayers from "../map/MapLayers";
import ControlMapCenter from "../map/ControlMapCenter";
import MeasurementControl from "../map/MeasurementControl";
import FullScreenControl from "../map/FullScreenControl";
import RenderGroundOverlay from "./RenderGroundOverlay";
import RenderLegends from "./RenderLegends";
import RenderArea2D from "./RenderArea2D";
import RenderObstacles from "./RenderObstacles";
import RenderArea3Obstacles from "./RenderArea3Obstacles";
import RenderArea1Obstacles from "./RenderArea1Obstacles";
import RenderArea1 from "./RenderArea1";
import RenderArea from "./RenderArea";
import RenderOLSTakeOffClimb from "./RenderOLSTakeOffClimb";
import RenderOLSApproach from "./RenderOLSApproach";
import RenderOLSHorizontalSurface from "./RenderOLSHorizontalSurface";
import RenderOLSTransitionalSurface from "./RenderOLSTransitionalSurface";
import RenderOLSRwyStrip from "./RenderOLSRwyStrip";
import RenderOLSObstacleFreeZone from "./RenderOLSObstacleFreeZone";
import RenderOLSObstacles from "./RenderOLSObstacles";

export default function EtodMap({
  airportLocation,
  zoomLevel,
  selectedAreas,
  etodAreaList,
  selectedObstacles,
  obstaclesList,
  selctedAirport,
  selectedOls,
  OLSApproachList,
  OLSHorizontalSurfaceList,
  OLSTransitionalSurfaceList,
  OLSObstacleFreeZoneList,
  OLSRwyStripList,
  OLSTakeOffClimbList,
  selectedArea1,
  area1Data,
  rasterAreaList,
  selectedRasterArea,
  isShowPenetrationObstacle,
  olsObstaclesList,
  area1Obstacles,
  areaThreeObstacles,
}) {
  const [isAbovePermissibleClick, setAbovePermissibleClick] = useState(false);
  const [isBelowPermissibleClick, setBelowPermissibleClick] = useState(false);

  useEffect(() => {
    if (!isShowPenetrationObstacle) {
      setAbovePermissibleClick(false);
      setBelowPermissibleClick(false);
    }
  }, [isShowPenetrationObstacle]);

  const getAreaColors = () => {
    const areaColor = {};

    selectedAreas.forEach((a) => {
      const splitArray = split(a, "__");
      areaColor[splitArray[0]] = splitArray[1];
    });

    return areaColor;
  };

  const getOLSColors = () => {
    const olsColor = {};

    selectedOls.forEach((a) => {
      const splitArray = split(a, "__");
      olsColor[splitArray[0]] = splitArray[1];
    });

    return olsColor;
  };

  const handleAbovePermissible = () => {
    setAbovePermissibleClick(true);
    setBelowPermissibleClick(false);
  };

  const handleBelowPermissible = () => {
    setAbovePermissibleClick(false);
    setBelowPermissibleClick(true);
  };

  const eTodMapE = useMemo(() => {
    const selectedAreasT = selectedAreas.map((a) => a.split("__")[0]);
    const isSelectedA2D = Boolean(selectedAreasT.includes("area_2d"));
    const isSelectedArea3 = Boolean(selectedAreasT.includes("Area 3"));
    const isSelectedRasterArea3 = Boolean(
      selectedRasterArea.includes("AREA 3")
    );

    return (
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        <RenderGroundOverlay
          rasterAreaList={rasterAreaList}
          selectedRasterArea={selectedRasterArea}
        />
        {Boolean(selectedArea1) && (
          <RenderArea1 selectedArea1={selectedArea1} area1Data={area1Data} />
        )}
        {isSelectedA2D && (
          <RenderArea2D
            selctedAirport={selctedAirport}
            getAreaColors={getAreaColors}
          />
        )}
        <RenderArea
          etodAreaList={etodAreaList}
          selectedAreas={selectedAreas}
          getAreaColors={getAreaColors}
        />
        <RenderOLSTakeOffClimb
          OLSTakeOffClimbList={OLSTakeOffClimbList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSApproach
          OLSApproachList={OLSApproachList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSHorizontalSurface
          OLSHorizontalSurfaceList={OLSHorizontalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSTransitionalSurface
          OLSTransitionalSurfaceList={OLSTransitionalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSRwyStrip
          OLSRwyStripList={OLSRwyStripList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSObstacleFreeZone
          OLSObstacleFreeZoneList={OLSObstacleFreeZoneList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        {Boolean(selectedArea1) && (
          <RenderArea1Obstacles
            isShowPenetrationObstacle={isShowPenetrationObstacle}
            area1Obstacles={area1Obstacles}
            isAbovePermissibleClick={isAbovePermissibleClick}
            isBelowPermissibleClick={isBelowPermissibleClick}
          />
        )}
        {Boolean(selectedObstacles) && (
          <RenderObstacles
            selctedAirport={selctedAirport}
            selectedAreas={selectedAreas}
            selectedRasterArea={selectedRasterArea}
            obstaclesList={obstaclesList}
            selectedObstacles={selectedObstacles}
            isShowPenetrationObstacle={isShowPenetrationObstacle}
            getAreaColors={getAreaColors}
            isAbovePermissibleClick={isAbovePermissibleClick}
            isBelowPermissibleClick={isBelowPermissibleClick}
          />
        )}
        {(isSelectedArea3 || isSelectedRasterArea3) && (
          <RenderArea3Obstacles
            selctedAirport={selctedAirport}
            areaThreeObstacles={areaThreeObstacles}
            isShowPenetrationObstacle={isShowPenetrationObstacle}
            isAbovePermissibleClick={isAbovePermissibleClick}
            isBelowPermissibleClick={isBelowPermissibleClick}
          />
        )}
        <RenderOLSObstacles
          selctedAirport={selctedAirport}
          olsObstaclesList={olsObstaclesList}
          selectedOls={selectedOls}
          isShowPenetrationObstacle={isShowPenetrationObstacle}
          getOLSColors={getOLSColors}
          isAbovePermissibleClick={isAbovePermissibleClick}
          isBelowPermissibleClick={isBelowPermissibleClick}
        />
        <RenderLegends
          isShowPenetrationObstacle={isShowPenetrationObstacle}
          selectedRasterArea={selectedRasterArea}
          rasterAreaList={rasterAreaList}
          handleAbovePermissible={handleAbovePermissible}
          handleBelowPermissible={handleBelowPermissible}
        />
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        {/* <PrintMap
          pageTitle={selctedAirport ? `${selctedAirport.name} (${selctedAirport.location_indicator})` : undefined}
        /> */}
        <FullScreenControl />
        <MeasurementControl />
        <ZoomControl position="bottomright" />
        <MapLayers />
        <ScaleControl position="topleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airportLocation,
    zoomLevel,
    selectedOls,
    selectedAreas,
    selectedRasterArea,
    rasterAreaList,
    selectedArea1,
    area1Obstacles,
    isShowPenetrationObstacle,
    olsObstaclesList,
    obstaclesList,
    isAbovePermissibleClick,
    isBelowPermissibleClick,
  ]);

  return <Fragment>{eTodMapE}</Fragment>;
}
