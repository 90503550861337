import {
  DMEImgIcon,
  NDBImgIcon,
  VORImgIcon,
  VOR_DMEImgIcon
} from './mapIcon';

export const getNavaidIcon = (name) => {
  let icon = VORImgIcon;

  switch (name) {
    case 'NDB':
      icon = NDBImgIcon;
      break;
    case 'VOR_DME':
      icon = VOR_DMEImgIcon;
      break;
    case 'VOR':
      icon = VORImgIcon;
      break;
    case 'DME':
      icon = DMEImgIcon;
      break;
    default:
      icon = VORImgIcon;
      break;
  };

  return icon;
};