import React, { Fragment } from 'react';
import { keysIn } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';

export default function NavaidItem({
  isLoading,
  category,
  airportDetails,
  aixmFeatureMapData,
  selctedAirport
}) {
  const navaidList = airportDetails[category];
  const NavaidListMap = aixmFeatureMapData['Navaid_list'];

  return (
    <Fragment>
      {(isLoading || navaidList.length === 0) && (
        <TableLoader isLoading={isLoading} data={navaidList} />
      )}
      {
        navaidList.map((navaid, i) => {
          return (
            <Paper sx={{ mb: 5 }} key={`${category}_${i}`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell colSpan={2}>
                      {selctedAirport.name} {'>'} Navaid {navaid.Identification}
                    </HeaderTableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(NavaidListMap).map((key, j) => {
                    const navidKey = NavaidListMap[key];

                    return (
                      <TableRow
                        key={j}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell sx={{ width: '20%' }}>{key}</StyledTableCell>
                        <StyledTableCell sx={{ width: '80%' }}>{navaid[navidKey] || '-'}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          );
        })
      }
    </Fragment>
  );
}
