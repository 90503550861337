import React from "react";
import { FaFileDownload } from "react-icons/fa";
import { RiLoader3Fill } from "react-icons/ri";
import { Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

export default function AirportExportButton({
  items,
  selectedAirport,
  airport,
  isExportXMLAD2Loading,
  handleExportXML,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnExport = (item) => {
    handleClose();
    handleExportXML(item.value);
  };

  return (
    <>
      <Tooltip title="Export">
        <IconButton
          aria-label="more"
          id="airport-download-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          color="primary"
          disabled={selectedAirport?.id === airport.id && isExportXMLAD2Loading}
          onClick={handleClick}
        >
          {selectedAirport?.id === airport.id && isExportXMLAD2Loading ? (
            <RiLoader3Fill size={22} />
          ) : (
            <FaFileDownload size={22} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "airport-download-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem key={item.id} onClick={() => handleOnExport(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
