import React, { useEffect, useState } from "react";
import { chain, flatten, split } from "lodash";
import { Polygon, Polyline, Popup, FeatureGroup } from "react-leaflet";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { mapDrawType, roles } from "../../constants/constant";
import { areaHighLightColor } from "../../constants/colors";
import { modifyCategories } from "../../constants/amdbData";
import PopupBox from "../map/PopupBox";
import { LightButton } from "../button";

export default function RenderRunWayShape({
  selectedFeatures,
  rwyElementList,
  handleEditShap,
}) {
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [rwyShapes, setRwyShapes] = useState([]);
  const [areaColor, setAreaColor] = useState({});
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const rwyList = chain(rwyElementList)
      .filter((rwy) => {
        return selectedFeatures.includes(
          `${rwy.type}__${areaColorT[rwy.type]}`
        );
      })
      .value();

    setAreaColor(areaColorT);
    setRwyShapes(rwyList);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = (e) => {
    e.target.bringToBack();
    setSelectedOverE(null);
  };

  const handlePolygonEdit = (data) => {
    handleEditShap(data, modifyCategories.runway);
  };

  return (
    <FeatureGroup>
      {rwyShapes.map((rwyGeometry) => {
        const rwyCoordinates = rwyGeometry.coordinates.coordinates;
        const coordinateType = rwyGeometry.coordinates.type;
        const rwyCoordinateMap = flatten(rwyCoordinates).map((rwyCoords) => {
          return rwyCoords.map((rwyel) => {
            return {
              lng: rwyel[0],
              lat: rwyel[1],
            };
          });
        });

        if (coordinateType === mapDrawType.polyline) {
          return rwyCoordinateMap.map((rwyPolygonPath, i) => (
            <Polyline
              key={i}
              positions={rwyPolygonPath}
              pathOptions={{
                color: areaColor[rwyGeometry.type],
                weight: selectedOverE?.type === rwyGeometry.type ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, rwyGeometry),
                mouseout: (e) => handlePolygonOut(e),
              }}
            />
          ));
        }

        return rwyCoordinateMap.map((rwyPolygonPath, i) => (
          <Polygon
            key={i}
            positions={rwyPolygonPath}
            pathOptions={{
              fillColor: areaColor[rwyGeometry.type],
              color:
                selectedOverE?.type === rwyGeometry.type
                  ? areaHighLightColor
                  : areaColor[rwyGeometry.type],
              weight: selectedOverE?.type === rwyGeometry.type ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, rwyGeometry),
              mouseout: (e) => handlePolygonOut(e),
            }}
          >
            <Popup>
              <PopupBox>
                <Box>RunWay:</Box>
                <Box>{rwyGeometry?.type}</Box>
              </PopupBox>
              {isAdmin && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <LightButton
                    label="Modify"
                    onClick={() => handlePolygonEdit(rwyGeometry)}
                  />
                </Stack>
              )}
            </Popup>
          </Polygon>
        ));
      })}
    </FeatureGroup>
  );
}
