import { createSlice, createSelector } from "@reduxjs/toolkit";
import { chain, remove, orderBy } from "lodash";
import { toast } from "react-toastify";
import { apiCall } from "../utils/connect";
import { eChartAirportList } from "../constants/constant";
import {
  GET_AMDB_AIRPORT_URL,
  GET_ECHART_PDF_DATA_URL,
  GET_ECHART_DATA_URL,
  ECHART_EDIT_DATA_URL,
  ECHART_EXPORT_URL,
  ECHART_ATOCA_URL,
  ECHART_TABLE_DETAIL_URL,
} from "../constants/urls";

const initialState = {
  isLoadingAlleChartAirport: false,
  alleChartAirports: [],
  isLoadingechartPDFData: false,
  echartPDFData: [],
  isLoadingechartData: false,
  echartData: null,
  isLoadingEditPolyline: false,
  isLoadingAlleChartExportUrl: false,
  alleChartExportUrls: [],
  isLoadingATOCAData: false,
  atocaData: null,
  isLoadingEchartTableData: false,
  chartTableData: null,
};

export const eChart = createSlice({
  name: "eChart",
  initialState,
  reducers: {
    setLoadingAlleChartAirport: (state, action) => {
      state.isLoadingAlleChartAirport = action.payload;
    },
    setAlleChartAirports: (state, action) => {
      state.alleChartAirports = action.payload;
    },
    setLoadingechartPDFData: (state, action) => {
      state.isLoadingechartPDFData = action.payload;
    },
    setechartPDFData: (state, action) => {
      state.echartPDFData = action.payload;
    },
    setLoadingechartData: (state, action) => {
      state.isLoadingechartData = action.payload;
    },
    setechartData: (state, action) => {
      state.echartData = action.payload;
    },
    setLoadingEditPolyline: (state, action) => {
      state.isLoadingEditPolyline = action.payload;
    },
    setLoadingAlleChartExportUrl: (state, action) => {
      state.isLoadingAlleChartExportUrl = action.payload;
    },
    setAlleChartExportUrls: (state, action) => {
      state.alleChartExportUrls = action.payload;
    },
    setLoadingATOCAData: (state, action) => {
      state.isLoadingATOCAData = action.payload;
    },
    setATOCAData: (state, action) => {
      state.atocaData = action.payload;
    },
    setLoadingEchartTableData: (state, action) => {
      state.isLoadingEchartTableData = action.payload;
    },
    setTableData: (state, action) => {
      state.chartTableData = action.payload;
    },
  },
});

export const {
  setLoadingAlleChartAirport,
  setAlleChartAirports,
  setLoadingechartPDFData,
  setechartPDFData,
  setLoadingechartData,
  setechartData,
  setLoadingEditPolyline,
  setLoadingAlleChartExportUrl,
  setAlleChartExportUrls,
  setLoadingATOCAData,
  setATOCAData,
  setLoadingEchartTableData,
  setTableData,
} = eChart.actions;

export default eChart.reducer;

export const selecteChartAirports = (state) => {
  const eChartAirports = state.eChart.alleChartAirports;

  if (eChartAirports && eChartAirports.length > 0) {
    return chain(eChartAirports)
      .filter((airport) => {
        return eChartAirportList.includes(airport.name);
      })
      .orderBy(["name"], ["asc"])
      .value();
  }

  return [];
};

export const selecteChartPDFData = (state) => {
  const echartPDFData = state.eChart.echartPDFData;

  if (echartPDFData && echartPDFData.length > 0) {
    return echartPDFData;
  }

  return [];
};

export const selecteChartData = (state) => {
  const echartData = state.eChart.echartData;

  if (!echartData) {
    return null;
  }

  return echartData;
};

export const selecteChartOptions = (state) => {
  const echartData = state.eChart.echartData;

  if (!echartData) {
    return [];
  }

  const echartOptions = [];

  Object.keys(echartData).forEach((echart) => {
    echartOptions.push({
      label: echart,
      value: echart,
    });
  });

  remove(echartOptions, (o) => o.value === "grids");

  return echartOptions;
};

const selectChartType = (state, type) => type;
const selecteAllChartTableData = (state, type) => state.eChart.chartTableData;
export const selecteChartTableData = createSelector(
  [selecteAllChartTableData, selectChartType],
  (tableData, type) => {
    if (tableData && tableData[type]) {
      const data = tableData[type];
      return orderBy(data, ["id"], ["asc"]);
    }

    return [];
  }
);

export const geteChartAirports = () => (dispatch) => {
  try {
    dispatch(setLoadingAlleChartAirport(true));

    const onSuccess = (response) => {
      dispatch(setAlleChartAirports(response.data));
      dispatch(setLoadingAlleChartAirport(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAlleChartAirport(false));
    };

    apiCall("GET", GET_AMDB_AIRPORT_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAlleChartAirport(false));
    toast.error(error.message);
  }
};

export const geteChartExportUrls = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingAlleChartExportUrl(true));

    const onSuccess = (response) => {
      dispatch(setAlleChartExportUrls(response.data));
      dispatch(setLoadingAlleChartExportUrl(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAlleChartExportUrl(false));
    };

    apiCall("GET", ECHART_EXPORT_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAlleChartExportUrl(false));
    toast.error(error.message);
  }
};

export const geteChartPDFData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingechartPDFData(true));

    const onSuccess = (response) => {
      dispatch(setechartPDFData(response.data));
      dispatch(setLoadingechartPDFData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingechartPDFData(false));
    };

    apiCall(
      "GET",
      GET_ECHART_PDF_DATA_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingechartPDFData(false));
    toast.error(error.message);
  }
};

export const geteChartData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingechartData(true));

    const onSuccess = (response) => {
      dispatch(setechartData(response.data));
      dispatch(setLoadingechartData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingechartData(false));
    };

    apiCall("GET", GET_ECHART_DATA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingechartData(false));
    toast.error(error.message);
  }
};

export const editEchartPolyline =
  (payload, airportId, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingEditPolyline(true));
      const onSuccess = () => {
        toast.success("Polyline updated successfully");

        const onSuccessEchart = (response) => {
          dispatch(setechartData(response.data));
          dispatch(setLoadingechartData(false));
          callback();
        };
        const onFailureEcgart = (error) => {
          toast.error(error.message);
          dispatch(setLoadingechartData(false));
          callback();
        };

        apiCall(
          "GET",
          GET_ECHART_DATA_URL(airportId),
          "",
          onSuccessEchart,
          onFailureEcgart
        );
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingEditPolyline(false));
      };

      apiCall(
        "POST",
        ECHART_EDIT_DATA_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingEditPolyline(false));
      toast.error(error.message);
    }
  };

export const getATOCAData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingATOCAData(true));

    const onSuccess = (response) => {
      dispatch(setATOCAData(response.data));
      dispatch(setLoadingATOCAData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingATOCAData(false));
    };

    apiCall("GET", ECHART_ATOCA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingATOCAData(false));
    toast.error(error.message);
  }
};

export const getEchartTableData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingEchartTableData(true));

    const onSuccess = (response) => {
      dispatch(setTableData(response.data));
      dispatch(setLoadingEchartTableData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEchartTableData(false));
    };

    apiCall(
      "GET",
      ECHART_TABLE_DETAIL_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingEchartTableData(false));
    toast.error(error.message);
  }
};
