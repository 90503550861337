import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import RowStack from "../../components/tabel-component/RowStack";
import { decryptValue } from "../../utils/decodeEncodeData";

export default function TopTableHeaderRow() {
  const currentUser = useSelector((state) => state.userSession.user);

  return (
    <RowStack direction="row" justifyContent="space-between">
      <Typography variant="body1" gutterBottom>
        SURVEYOR NAME:{" "}
        {currentUser && currentUser?.userName
          ? decryptValue(currentUser.userName)
          : "-"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        UNIQUE ID:{" "}
        {currentUser && currentUser?.userEmpId
          ? currentUser && currentUser?.userEmpId
          : "-"}
      </Typography>
    </RowStack>
  );
}
