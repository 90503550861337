import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MuiSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { ACCESS_TOKEN } from "../../constants/constant";
import {
  createAd212tValidationSchema,
  Ad212SurfaceTypes,
  Ad212PavementTypes,
  Ad212EveluationTypes,
  Ad212MaxTyrePressureTypes,
  Ad212PavementSubgradeTypes,
  rwyDesignationDir,
} from "../../constants/ad2Data";
import {
  removeSelctedAirportFormCategories,
  runwayDetailUpload,
} from "../../models/airport";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { PrimaryButton } from "../button";

const runwayDetail = {
  rwy_dsg1: "",
  rwy_dsg_dir1: "",
  rwy_dsg2: "",
  rwy_dsg_dir2: "",
  nominalLength: "",
  nominalWidth: "",
  lengthStrip: "",
  widthStrip: "",
  surface: "",
  classPCN: false,
  pavementTypePCN: "",
  pavementSubgradePCN: "",
  maxTyrePressurePCN: "",
  evaluationMethodPCN: "",
};

export default function Ad212RunWayDetailForm({
  selectedAirport,
  selectedCategory,
}) {
  const dispatch = useDispatch();
  const isLoadingRunwayDetailUpload = useSelector(
    (state) => state.airport.isLoadingRunwayDetailUpload
  );

  const createAd212RunwayDetailForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      runways: [runwayDetail],
    },
    validationSchema: createAd212tValidationSchema,
    onSubmit: (values) => {
      const runwayvalues = values.runways.map((runway) => {
        return {
          ...runway,
          rwy_dsg: `RWY-${runway.rwy_dsg1}${runway.rwy_dsg_dir1}${runway.rwy_dsg2}${runway.rwy_dsg_dir2}`,
        };
      });

      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          runwayDetailUpload(
            {
              airport_id: selectedAirport.id,
              form: runwayvalues,
            },
            selectedAirport?.version_id,
            token,
            handleUploadSuccessCallback
          )
        );
      });
    },
  });

  const handleUploadSuccessCallback = () => {
    createAd212RunwayDetailForm.resetForm();
    createAd212RunwayDetailForm.setSubmitting(false);
    dispatch(removeSelctedAirportFormCategories(selectedCategory));
  };

  return (
    <Paper sx={{ pb: 2, pt: 2 }}>
      <FormikProvider value={createAd212RunwayDetailForm}>
        <form onSubmit={createAd212RunwayDetailForm.handleSubmit}>
          <FieldArray
            name="runways"
            render={(arrayHelpers) => (
              <div>
                {createAd212RunwayDetailForm.values.runways.map((_, index) => {
                  const isTouched = createAd212RunwayDetailForm.touched?.runways
                    ? createAd212RunwayDetailForm.touched?.runways[index]
                    : false;
                  const isErrors = createAd212RunwayDetailForm.errors?.runways
                    ? createAd212RunwayDetailForm.errors?.runways[index]
                    : false;

                  return (
                    <Fragment key={index}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Runway Designations</Box>
                        <Box sx={{ pl: 2 }} display="flex" alignItems="center">
                          RWY-
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name={`runways[${index}].rwy_dsg1`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .rwy_dsg1
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].rwy_dsg1 &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].rwy_dsg1
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].rwy_dsg1
                                : ""
                            }
                            sx={{ mb: 2, mt: 2, width: 80 }}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 2);
                            }}
                          />
                          <FormControl
                            size="small"
                            sx={{ width: 60, mb: 1.5, mt: 2 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].rwy_dsg_dir1 &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].rwy_dsg_dir1
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].rwy_dsg_dir1`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].rwy_dsg_dir1
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {rwyDesignationDir.map((d) => {
                                return (
                                  <MenuItem
                                    key={d.id}
                                    value={d.value}
                                    disabled={
                                      d.value &&
                                      createAd212RunwayDetailForm.values
                                        .runways[index].rwy_dsg_dir2 === d.value
                                    }
                                  >
                                    {d.label}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].rwy_dsg_dir1
                                : ""}
                            </FormHelperText>
                          </FormControl>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name={`runways[${index}].rwy_dsg2`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .rwy_dsg2
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].rwy_dsg2 &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].rwy_dsg2
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].rwy_dsg2
                                : ""
                            }
                            sx={{ mb: 2, mt: 2, width: 80 }}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 2);
                            }}
                          />
                          <FormControl
                            size="small"
                            sx={{ width: 60, mb: 1.5, mt: 2 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].rwy_dsg_dir2 &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].rwy_dsg_dir2
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].rwy_dsg_dir2`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].rwy_dsg_dir2
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {rwyDesignationDir.map((d) => {
                                return (
                                  <MenuItem
                                    key={d.id}
                                    value={d.value}
                                    disabled={
                                      d.value &&
                                      createAd212RunwayDetailForm.values
                                        .runways[index].rwy_dsg_dir1 === d.value
                                    }
                                  >
                                    {d.label}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].rwy_dsg_dir2
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Length</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="number"
                            name={`runways[${index}].nominalLength`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .nominalLength
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].nominalLength &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].nominalLength
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].nominalLength
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  M
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Width</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="number"
                            name={`runways[${index}].nominalWidth`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .nominalWidth
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].nominalWidth &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].nominalWidth
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].nominalWidth
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  M
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Length Strip</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="number"
                            name={`runways[${index}].lengthStrip`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .lengthStrip
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].lengthStrip &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].lengthStrip
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].lengthStrip
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  M
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Width Strip</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="number"
                            name={`runways[${index}].widthStrip`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .widthStrip
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].widthStrip &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].widthStrip
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].widthStrip
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  M
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Surface</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].surface &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].surface
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].surface`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].surface
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {Ad212SurfaceTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].surface
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>PCN</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="number"
                            name={`runways[${index}].classPCN`}
                            value={
                              createAd212RunwayDetailForm.values.runways[index]
                                .classPCN
                            }
                            onChange={createAd212RunwayDetailForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].classPCN &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].classPCN
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].classPCN
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  NUM
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Pavement Type</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].pavementTypePCN &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].pavementTypePCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].pavementTypePCN`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].pavementTypePCN
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {Ad212PavementTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].pavementTypePCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Pavement Subgrade PCN</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].pavementSubgradePCN &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].pavementSubgradePCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].pavementSubgradePCN`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].pavementSubgradePCN
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {Ad212PavementSubgradeTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].pavementSubgradePCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Max Tyre Pressure</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].maxTyrePressurePCN &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].maxTyrePressurePCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].maxTyrePressurePCN`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].maxTyrePressurePCN
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {Ad212MaxTyrePressureTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].maxTyrePressurePCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Evaluation Method</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd212RunwayDetailForm.touched?.runways[
                                index
                              ].evaluationMethodPCN &&
                              Boolean(
                                createAd212RunwayDetailForm.errors?.runways[
                                  index
                                ].evaluationMethodPCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`runways[${index}].evaluationMethodPCN`}
                              value={
                                createAd212RunwayDetailForm.values.runways[
                                  index
                                ].evaluationMethodPCN
                              }
                              onChange={
                                createAd212RunwayDetailForm.handleChange
                              }
                            >
                              {Ad212EveluationTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd212RunwayDetailForm.errors?.runways[
                                    index
                                  ].evaluationMethodPCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack alignItems="flex-end">
                        <PrimaryButton
                          label="Remove"
                          color="error"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </Stack>
                      <Divider />
                    </Fragment>
                  );
                })}
                <PrimaryButton
                  label="Add More"
                  onClick={() => arrayHelpers.push(runwayDetail)}
                />
              </div>
            )}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ pr: 2 }}
          >
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingRunwayDetailUpload}
              disabled={isLoadingRunwayDetailUpload}
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
    </Paper>
  );
}
