import React from "react";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import StyledTableCell from "../tabel-component/StyledTableCell";

export default function InReviewTableHeaderRow({ isShowBaseElev }) {
  return (
    <TableRow>
      <StyledTableCell align="center" sx={{ width: "1vw" }}>
        UID
      </StyledTableCell>
      <StyledTableCell align="center" sx={{ width: "1vw" }}>
        POINT NAME
      </StyledTableCell>
      <StyledTableCell align="center" sx={{ width: "2vw" }}>
        <Box>LATITUDE</Box>
        <Stack
          direction="row"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <Box sx={{ typography: "caption" }}>NEW</Box>
          <Box sx={{ typography: "caption" }}>OLD</Box>
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="center" sx={{ width: "2vw" }}>
        <Box>LONGITUDE</Box>
        <Stack
          direction="row"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <Box sx={{ typography: "caption" }}>NEW</Box>
          <Box sx={{ typography: "caption" }}>OLD</Box>
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="center" sx={{ width: "1vw" }}>
        <Stack>
          <Box>TOP ELEV. IN m. (EGM 08)</Box>
          <Stack
            direction="row"
            justifyContent="center"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <Box sx={{ typography: "caption" }}>NEW</Box>
            <Box sx={{ typography: "caption" }}>OLD</Box>
            <Box sx={{ typography: "caption" }}>DIFF</Box>
          </Stack>
        </Stack>
      </StyledTableCell>
      {isShowBaseElev && (
        <StyledTableCell align="center" sx={{ width: "1vw" }}>
          <Stack>
            <Box>BASE ELEV. IN m. (EGM 08)</Box>
            <Stack
              direction="row"
              justifyContent="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <Box sx={{ typography: "caption" }}>NEW</Box>
              <Box sx={{ typography: "caption" }}>OLD</Box>
              <Box sx={{ typography: "caption" }}>DIFF</Box>
            </Stack>
          </Stack>
        </StyledTableCell>
      )}
      {!isShowBaseElev && (
        <StyledTableCell align="center" sx={{ width: "1vw" }}>
          <Stack>
            <Box>ELLIPSOIDAL ELEV. IN m.</Box>
            <Stack
              direction="row"
              justifyContent="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              <Box sx={{ typography: "caption" }}>NEW</Box>
              <Box sx={{ typography: "caption" }}>OLD</Box>
              <Box sx={{ typography: "caption" }}>DIFF</Box>
            </Stack>
          </Stack>
        </StyledTableCell>
      )}
    </TableRow>
  );
}
