import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, useNavigationType } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { useFormik } from "formik";
import Select from "react-select";
import { FaBell, FaPlus } from "react-icons/fa";
import { MapContainer, ZoomControl, Marker } from "react-leaflet";
import CoordinateInput from "react-coordinate-input";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MuiSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { ACCESS_TOKEN, roles } from "../constants/constant";
import { surveyorCategories } from "../constants/surveyorData";
import { defaultLocationCenter } from "../constants/constant";
import {
  createAirportValidationSchema,
  airportVersionDate,
  icaoPrefixTypes,
} from "../constants/ad2Data";
import { setCompareData } from "../models/survey";
import { LocationImgIcon } from "../utils/mapIcon";
import { postCreateNewAirport } from "../models/airport";
import { getAsyncStorageValue } from "../utils/localStorage";
import ControlMapCenter from "./map/ControlMapCenter";
import { GoogleMapLayerWithLabel } from "./map/GoogleMapLayer";
import FullScreenControl from "./map/FullScreenControl";
import DropDownLabel from "./DropDownLabel";
import PageTitle from "./PageTitle";
import BrandLogo from "./BrandLogo";
import {
  OutlinedButton,
  PrimaryButton,
  UploadButton,
  LightButton,
} from "./button";

export default function DataManagementSideBar({
  isDownloadTemplate,
  isUploadSurveyFile,
  isLoadingCompareData,
  isLoadingReviewFeaturesdata,
  isActiveAirport,
  selctedCategory,
  isActiveCategory,
  reviewBackFeaturesData,
  handleCategory,
  handleTemplateDownload,
  handleFileUpload,
  handleDateReValidation,
  isLoadingGetallReqPassword,
  allReqPasswordData,
  handlePasswordRequest,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;
  const isAAI = user?.role === roles.aai;
  const isSurveyor = user?.role === roles.surveyor;
  const isHomePath = location.pathname === "/data-management";
  const isAirportReviewListPath =
    location.pathname === "/data-management/airport-review-list";
  const isSurveyorReportListPath =
    location.pathname === "/data-management/surveyor-report-list";
  const isLoadingPostCreateNewAirport = useSelector(
    (state) => state.airport.isLoadingPostCreateNewAirport
  );
  const [isOpenAddAirportDialog, setIsOpenAddAirportDialog] = useState(false);
  const [isOpenMap, setOpenMap] = useState(false);
  const isDisabled = isActiveAirport;

  useEffect(() => {
    if (navigationType === "POP") {
      setIsOpenAddAirportDialog(false);
    }
  }, [navigationType]); // eslint-disable-line

  const createAirportForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      icaoPrefix: "",
      icao: "",
      name: "",
      latitude: "",
      longitude: "",
      effective_date: "",
      published_date: "",
    },
    validationSchema: createAirportValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          postCreateNewAirport(
            { ...values, icao: `${values.icaoPrefix}${values.icao}` },
            token,
            handleCloseCreateAirportDialog
          )
        );
      });
    },
  });
  const formValues = createAirportForm.values;

  const handleCreateAirport = () => {
    setIsOpenAddAirportDialog(true);
  };

  const handleCloseCreateAirportDialog = () => {
    createAirportForm.resetForm();
    createAirportForm.setSubmitting(false);
    setIsOpenAddAirportDialog(false);
  };

  const handleEffectiveDateChange = (event) => {
    const option = airportVersionDate.find(
      (d) => event.target.value === d.effective_date
    );
    createAirportForm.handleChange(event);
    createAirportForm.setFieldValue("published_date", option.published_date);
  };

  const handlePublishedDateChange = (event) => {
    const option = airportVersionDate.find(
      (d) => event.target.value === d.published_date
    );
    createAirportForm.handleChange(event);
    createAirportForm.setFieldValue("effective_date", option.effective_date);
  };

  const handleCRC = () => {
    navigate("/data-management/crc-check");
  };

  const handleSeeMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  return (
    <div className="sidebar">
      <BrandLogo />
      {!isHomePath && (
        <Button
          variant="text"
          size="small"
          startIcon={<FaArrowLeft />}
          sx={{ mt: -5 }}
          onClick={() => {
            navigate(-1);
            dispatch(setCompareData(null));
          }}
        >
          Back
        </Button>
      )}
      {isAdmin && (
        <Fragment>
          <div className="admin-sidebar-footer">
            <Box sx={{ width: "100%" }}>
              {isAirportReviewListPath && (
                <PageTitle title="Review Airport Datasets" />
              )}
              {isSurveyorReportListPath && (
                <PageTitle title="Review Surveyor Datasets" />
              )}
            </Box>
            <Box>
              {isAirportReviewListPath && (
                <PrimaryButton
                  label="Create New Airport"
                  onClick={() => handleCreateAirport()}
                  startIcon={<FaPlus />}
                  sx={{ width: 250, mb: 2 }}
                />
              )}
              <Badge badgeContent={allReqPasswordData.length} color="secondary">
                <PrimaryButton
                  label="Password Requests"
                  onClick={handlePasswordRequest}
                  startIcon={<FaBell />}
                  disabled={
                    isLoadingGetallReqPassword ||
                    allReqPasswordData.length === 0
                  }
                  isLoading={isLoadingGetallReqPassword}
                  sx={{ width: 250 }}
                />
              </Badge>
              <PrimaryButton
                label="Check CRC32Q"
                onClick={handleCRC}
                sx={{ width: 250, mt: 2 }}
              />
            </Box>
          </div>
        </Fragment>
      )}
      {(isSurveyor || isAAI) && (
        <Fragment>
          <Box sx={{ mb: 1 }}>
            <DropDownLabel label="Category" />
            <Select
              isClearable
              isDisabled={isDisabled}
              value={selctedCategory || null}
              placeholder="Select..."
              onChange={handleCategory}
              options={surveyorCategories}
              styles={{
                menuList: (provided) => ({ ...provided, overflow: "hidden" }),
              }}
            />
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <div className="surveyor-sidebar-footer">
            <Box>
              <UploadButton
                label="Upload"
                accept=".xlsx"
                loadingIndicator={
                  isLoadingCompareData ? "Comparing..." : "Uploading..."
                }
                disabled={
                  isActiveCategory || isUploadSurveyFile || isLoadingCompareData
                }
                isLoading={isUploadSurveyFile || isLoadingCompareData}
                handleUpload={handleFileUpload}
                sx={{ width: 200 }}
              />
              <Typography
                variant="caption"
                display="block"
                align="center"
                gutterBottom
              >
                File should be less than 10 MB.
              </Typography>
              <PrimaryButton
                label="AD 2 Data Entry"
                onClick={() => navigate("/ad-2-data-entry")}
                sx={{ width: 200 }}
                disabled={!isAAI}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Badge
                badgeContent={reviewBackFeaturesData.length}
                color="secondary"
              >
                <PrimaryButton
                  label="Dataset Re-Validation"
                  onClick={handleDateReValidation}
                  disabled={
                    isLoadingReviewFeaturesdata ||
                    reviewBackFeaturesData.length === 0
                  }
                  isLoading={isLoadingReviewFeaturesdata}
                  sx={{ width: 200, mb: 2 }}
                />
              </Badge>
              <PrimaryButton
                label="Download Template"
                onClick={() => handleTemplateDownload(selctedCategory.template)}
                disabled={isActiveCategory || isDownloadTemplate}
                isLoading={isDownloadTemplate}
                sx={{ width: 200 }}
              />
              <PrimaryButton
                label="Check CRC32Q"
                onClick={handleCRC}
                sx={{ width: 200, mt: 2 }}
              />
            </Box>
          </div>
        </Fragment>
      )}
      <Dialog
        fullWidth
        open={isOpenAddAirportDialog && isAirportReviewListPath}
      >
        <DialogTitle>Create New Airport</DialogTitle>
        <DialogContent>
          <form>
            <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
              <FormControl
                fullWidth
                sx={{ width: 200 }}
                error={
                  createAirportForm.touched.icao &&
                  Boolean(createAirportForm.errors.icao)
                }
              >
                <InputLabel id="icaoPrefix">ICAO Prefix</InputLabel>
                <MuiSelect
                  id="icaoPrefix"
                  name="icaoPrefix"
                  label="icaoPrefix"
                  value={createAirportForm.values.icaoPrefix}
                  onChange={createAirportForm.handleChange}
                >
                  {icaoPrefixTypes.map((types) => (
                    <MenuItem key={types.id} value={types.value}>
                      {types.label}
                    </MenuItem>
                  ))}
                </MuiSelect>
                <FormHelperText>
                  {createAirportForm.touched.icaoPrefix &&
                    createAirportForm.errors.icaoPrefix}
                </FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                variant="outlined"
                id="icao"
                name="icao"
                label="ICAO Code"
                inputProps={{ maxLength: 2 }}
                value={createAirportForm.values.icao}
                onChange={createAirportForm.handleChange}
                error={
                  createAirportForm.touched.icao &&
                  Boolean(createAirportForm.errors.icao)
                }
                helperText={
                  createAirportForm.touched.icao &&
                  createAirportForm.errors.icao
                }
              />
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label="Airport Name"
              value={createAirportForm.values.name}
              onChange={createAirportForm.handleChange}
              error={
                createAirportForm.touched.name &&
                Boolean(createAirportForm.errors.name)
              }
              helperText={
                createAirportForm.touched.name && createAirportForm.errors.name
              }
              sx={{ mb: 2 }}
            />
            <CoordinateInput
              style={{
                width: "94%",
                padding: 14,
                fontSize: 20,
              }}
              onChange={(_, { dd }) => {
                createAirportForm.setFieldValue("latitude", dd[0]);
                createAirportForm.setFieldValue("longitude", dd[1]);
              }}
            />
            <FormHelperText sx={{ mb: 1 }}>
              Add latitude longitude format ddmmss N/S dddmmss W/E Ex: 04° 08′
              15″ N 162° 03′ 42″ E
            </FormHelperText>
            <FormHelperText
              sx={{ mb: 2 }}
              error={
                createAirportForm.touched.latitude &&
                Boolean(createAirportForm.errors.latitude)
              }
            >
              {createAirportForm.touched.latitude &&
                createAirportForm.errors.latitude}
            </FormHelperText>
            <LightButton
              label="See on map"
              onClick={handleSeeMap}
              sx={{ p: 0 }}
            />
            <FormControl
              fullWidth
              sx={{ mb: 2, mt: 2 }}
              error={
                createAirportForm.touched.effective_date &&
                Boolean(createAirportForm.errors.effective_date)
              }
            >
              <InputLabel id="effective_date">Effective Date</InputLabel>
              <MuiSelect
                labelId="effective_date"
                id="effective_date"
                name="effective_date"
                label="effective_date"
                value={createAirportForm.values.effective_date}
                onChange={handleEffectiveDateChange}
              >
                {airportVersionDate.map((date) => (
                  <MenuItem key={date.id} value={date.effective_date}>
                    {date.effective_date}
                  </MenuItem>
                ))}
              </MuiSelect>
              <FormHelperText>
                {createAirportForm.touched.effective_date &&
                  createAirportForm.errors.effective_date}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              sx={{ mb: 2 }}
              error={
                createAirportForm.touched.published_date &&
                Boolean(createAirportForm.errors.published_date)
              }
            >
              <InputLabel id="published_date">Published Date</InputLabel>
              <MuiSelect
                labelId="published_date"
                id="published_date"
                name="published_date"
                label="published_date"
                value={createAirportForm.values.published_date}
                onChange={handlePublishedDateChange}
              >
                {airportVersionDate.map((date) => (
                  <MenuItem key={date.id} value={date.published_date}>
                    {date.published_date}
                  </MenuItem>
                ))}
              </MuiSelect>
              <FormHelperText>
                {createAirportForm.touched.published_date &&
                  createAirportForm.errors.published_date}
              </FormHelperText>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseCreateAirportDialog}
            sx={{ width: 120 }}
          />
          <PrimaryButton
            label="Submit"
            onClick={() => createAirportForm.handleSubmit()}
            isLoading={isLoadingPostCreateNewAirport}
            disabled={isLoadingPostCreateNewAirport}
            sx={{ width: 120 }}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={isOpenMap}
        onClose={handleCloseMap}
      >
        <DialogTitle>
          Airport Name: {formValues.name ? formValues.name : "-"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Latitude: {formValues.latitude}, Longitude: {formValues.longitude}
          </DialogContentText>
          <MapContainer
            center={defaultLocationCenter}
            zoom={4}
            style={{ height: "500px", width: "100%" }}
            zoomControl={false}
          >
            <GoogleMapLayerWithLabel />
            <ControlMapCenter
              airportLocation={{
                lat: formValues.latitude
                  ? Number(formValues.latitude)
                  : defaultLocationCenter.lat,
                lng: formValues.longitude
                  ? Number(formValues.longitude)
                  : defaultLocationCenter.lng,
              }}
              zoomLevel={Number(formValues.latitude) ? 16 : 4}
            />
            <Marker
              icon={LocationImgIcon}
              position={{
                lat: formValues.latitude
                  ? Number(formValues.latitude)
                  : defaultLocationCenter.lat,
                lng: formValues.longitude
                  ? Number(formValues.longitude)
                  : defaultLocationCenter.lng,
              }}
            />
            <FullScreenControl />
            <ZoomControl position="bottomright" />
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMap}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
