import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';
import { aixmFeatureKeyMap } from '../../constants/aixmData';

export default function PassengerFacilitiesList({
  isLoading,
  category,
  airportDetails,
  selctedAirport
}) {
  const passengerFacilitiesList = airportDetails[category];
  const keys = Object.keys(passengerFacilitiesList);

  return (
    <Fragment key={category}>
      {(isLoading || keys.length === 0) && (
        <TableLoader isLoading={isLoading} data={keys} />
      )}
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {aixmFeatureKeyMap[category]}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell sx={{ width: '20%' }}>
                Hotel(s) at or in the vicinity of aerodrome
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {passengerFacilitiesList.HOTEL || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Restaurant(s) at or in the vicinity of aerodrome
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {passengerFacilitiesList.REST || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Transportation possibilities
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {passengerFacilitiesList.TRANSPORT || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Medical Facilities
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {passengerFacilitiesList.MEDIC || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Bank and post office at or in the vicinity of aerodrome
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                <Box>Banks: {passengerFacilitiesList.BANK || 'NIL'}</Box>
                <Box>Post office: {passengerFacilitiesList.POST || 'NIL'}</Box>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Tourist office
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {passengerFacilitiesList.INFO || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '20%' }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell sx={{ width: '80%' }}>
                {passengerFacilitiesList.PASSENGER_FACILITIES_REMARK || 'NIL'}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
