import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uniq } from "lodash";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import EtodMap from "../components/etodOSMMap/MapContainer";
import EtodSideBar from "../components/EtodSideBar";
import { defaultLocationCenter } from "../constants/constant";
import { colorMap, staticColorMap } from "../constants/colors";
import {
  getEtodAllAirport,
  getEtodDetails,
  getEtodObstaclesData,
  getEtodOLSData,
  getEtodArea1,
  getRasterAreas,
  getEtodOLSobstaclesData,
  getEtodArea1Obstacles,
  getEtodAreaThreeObstacles,
  setEtodDetails,
  setAllObstaclesList,
  selectEtodAirports,
  selectEtodAreaListData,
  setOLSDetails,
  setRasterAreaList,
  selectOLSApproachList,
  selectOLSHorizontalSurfaceList,
  selectOLSTransitionalSurfaceList,
  selectOLSObstacleFreeZoneList,
  selectOLSRwyStripList,
  selectOLSTakeOffClimbList,
  selectArea1List,
  selectRasterAreaList,
  selectOLSOptions,
  setOLSobstaclesList,
  setAreaThreeObstacles,
  selectAreaOptions,
  exportObstacleData,
} from "../models/etod";

export default function Etod() {
  const dispatch = useDispatch();
  const isLoadingEtodAirport = useSelector(
    (state) => state.etod.isLoadingEtodAirport
  );
  const isLoadingEtodDetails = useSelector(
    (state) => state.etod.isLoadingEtodDetails
  );
  const isLoadingEtodObstacles = useSelector(
    (state) => state.etod.isLoadingEtodObstacles
  );
  const isLoadingEtodOLS = useSelector((state) => state.etod.isLoadingEtodOLS);
  const isLoadingArea1 = useSelector((state) => state.etod.isLoadingArea1);
  const isLoadingRasterAreas = useSelector(
    (state) => state.etod.isLoadingRasterAreas
  );
  const isLoadingEtodOLSObstacles = useSelector(
    (state) => state.etod.isLoadingEtodOLSObstacles
  );
  const isLoadingArea1Obstacles = useSelector(
    (state) => state.etod.isLoadingArea1Obstacles
  );
  const isLoadingAreaThreeObstacles = useSelector(
    (state) => state.etod.isLoadingAreaThreeObstacles
  );
  const isLoadingExportObstacle = useSelector(
    (state) => state.etod.isLoadingExportObstacle
  );
  const obstaclesList = useSelector((state) => state.etod.obstaclesList || []);
  const olsObstaclesList = useSelector(
    (state) => state.etod.OLSobstaclesList || []
  );
  const area1Obstacles = useSelector(
    (state) => state.etod.area1Obstacles || []
  );
  const areaThreeObstacles = useSelector(
    (state) => state.etod.areaThreeObstacles || []
  );
  const allAirports = useSelector(selectEtodAirports);
  const etodAreaListData = useSelector(selectEtodAreaListData);
  const OLSApproachList = useSelector(selectOLSApproachList);
  const OLSHorizontalSurfaceList = useSelector(selectOLSHorizontalSurfaceList);
  const OLSTransitionalSurfaceList = useSelector(
    selectOLSTransitionalSurfaceList
  );
  const OLSObstacleFreeZoneList = useSelector(selectOLSObstacleFreeZoneList);
  const OLSRwyStripList = useSelector(selectOLSRwyStripList);
  const OLSTakeOffClimbList = useSelector(selectOLSTakeOffClimbList);
  const area1Data = useSelector(selectArea1List);
  const rasterAreaList = useSelector(selectRasterAreaList);
  const [selctedAirport, setAirport] = useState(null);
  const areaOptions = useSelector((state) =>
    selectAreaOptions(state, selctedAirport)
  );
  const [selctedFeature, setFeature] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const OLSOptions = useSelector((state) => selectOLSOptions(state));
  const [selectedAreas, setSelectedArea] = useState([]);
  const [selectedObstacles, setSelectedObstacles] = useState(null);
  const [selectedA2D, setSelectedA2D] = useState(null);
  const [selectedArea1, setSelectedArea1] = useState(null);
  const [selectedOls, setSelectedOls] = useState([]);
  const [selectedRasterArea, setSelectedRasterArea] = useState([]);
  const [isShowPenetrationObstacle, setShowPenetrationObstacle] =
    useState(false);

  useEffect(() => {
    dispatch(getEtodArea1());
    dispatch(getEtodArea1Obstacles());
    dispatch(getEtodAllAirport());
    dispatch(setEtodDetails(null));
    dispatch(setRasterAreaList(null));
    setSelectedObstacles({ color: staticColorMap["Obstacles"] });
  }, []); // eslint-disable-line

  const handleAirport = (option) => {
    handleClearAllArea();

    if (option === null) {
      dispatch(setEtodDetails(null));
      dispatch(setAllObstaclesList({ Obstacles_Data_List: null }));
      dispatch(setOLSobstaclesList([]));
      dispatch(setAreaThreeObstacles([]));
      dispatch(setOLSDetails(null));
      dispatch(setRasterAreaList(null));
      setFeature(null);
      setAirport(null);
      setAirportLocation(defaultLocationCenter);
      setZoomLevel(5);
    } else {
      dispatch(getEtodDetails(option.id));
      dispatch(getEtodObstaclesData(option.id));
      dispatch(getEtodOLSData(option.id));
      dispatch(getRasterAreas(option.id));
      dispatch(getEtodOLSobstaclesData(option.id));
      dispatch(getEtodAreaThreeObstacles(option.id));
      setAirport(option);
      setAirportLocation(option.coordinates);
      setZoomLevel(14);
    }
  };

  const handlePenetrationObstacleToggle = (event) => {
    setShowPenetrationObstacle(event.target.checked);
  };

  const handleFeature = (option) => {
    handleClearAllArea();

    if (option === null) {
      setFeature(null);
    } else {
      setFeature(option);
    }
  };

  const updateSelectedOptions = (selectedValues, value) => {
    if (!selectedValues.includes(value)) {
      selectedValues.push(value);
    } else {
      selectedValues.splice(selectedValues.indexOf(value), 1);
    }
  };

  const handleAreaCheck = (area, color) => {
    updateSelectedOptions(selectedAreas, `${area.value}__${color}`);
    setSelectedArea([...uniq(selectedAreas)]);
  };

  const handleParentAreaCheck = (event, area, color) => {
    const subAreas = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      subAreas.push(`${area.value}__${color}`);
      area.sub?.forEach((f1) => {
        subAreas.push(`${f1.value}__${f1.color}`);
      });
    } else {
      selectedAreas.splice(selectedAreas.indexOf(`${area.value}__${color}`), 1);
      area.sub?.forEach((f1) => {
        selectedAreas.splice(
          selectedAreas.indexOf(`${f1.value}__${f1.color}`),
          1
        );
      });
    }

    setSelectedArea(uniq([...selectedAreas, ...subAreas]));
  };

  const handleParentRasterAreaCheck = (event, demArea) => {
    const subDEM = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      subDEM.push(demArea.value);
      demArea.sub?.forEach((f1) => {
        subDEM.push(f1.value);
      });
    } else {
      selectedRasterArea.splice(selectedRasterArea.indexOf(demArea.value), 1);
      demArea.sub?.forEach((f1) => {
        selectedRasterArea.splice(selectedRasterArea.indexOf(f1.value), 1);
      });
    }

    setSelectedRasterArea(uniq([...selectedRasterArea, ...subDEM]));
  };

  const handleArea1Check = (color) => {
    if (Boolean(selectedArea1)) {
      setSelectedArea1(null);
    } else {
      setSelectedArea1({ color });
    }
  };

  const handleSelectAllArea = () => {
    if (selctedFeature?.value === "OLS") {
      const allOls = [];
      OLSOptions.forEach((f0) => {
        allOls.push(`${f0.value}__${f0.color}`);

        f0.sub?.forEach((f1) => {
          allOls.push(`${f1.value}__${f1.color}`);
        });
      });

      setSelectedOls(uniq([...allOls]));
    } else {
      const allArea = [];
      const rasterMap = [];
      areaOptions.forEach((f0) => {
        if (f0.type === "dem_area") {
          rasterMap.push(f0.value);
        } else {
          allArea.push(`${f0.value}__${f0.color}`);
        }

        f0.sub?.forEach((f1) => {
          if (f0.type === "dem_area") {
            rasterMap.push(f1.value);
          } else {
            allArea.push(`${f1.value}__${f1.color}`);
          }
        });
      });

      setSelectedArea(uniq([...allArea]));
      setSelectedRasterArea(uniq([...rasterMap]));

      if (area1Data.length > 0) {
        setSelectedArea1({ color: staticColorMap["Area1"] });
      }
    }
  };

  const handleParentOlsCheck = (event, ols, color) => {
    const subOls = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      subOls.push(`${ols.value}__${color}`);
      ols.sub?.forEach((f1) => {
        subOls.push(`${f1.value}__${f1.color}`);
      });
    } else {
      selectedOls.splice(selectedOls.indexOf(`${ols.value}__${color}`), 1);
      ols.sub?.forEach((f1) => {
        selectedOls.splice(selectedOls.indexOf(`${f1.value}__${f1.color}`), 1);
      });
    }

    setSelectedOls(uniq([...selectedOls, ...subOls]));
  };

  const handleOlsCheck = (ols, color) => {
    updateSelectedOptions(selectedOls, `${ols.value}__${color}`);

    ols.sub?.forEach((f1) => {
      updateSelectedOptions(selectedOls, `${f1.value}__${f1.color}`);
    });

    setSelectedOls(uniq([...selectedOls]));
  };

  const handleRasterAreaCheck = (area) => {
    updateSelectedOptions(selectedRasterArea, area.value);
    setSelectedRasterArea([...uniq(selectedRasterArea)]);
  };

  const handleClearAllArea = () => {
    setSelectedArea([]);
    setSelectedA2D(null);
    setSelectedArea1(null);
    setSelectedOls([]);
    setSelectedRasterArea([]);
    setShowPenetrationObstacle(false);
  };

  const handleExportObstacle = (type) => {
    dispatch(exportObstacleData(type, selctedAirport));
  };

  return (
    <div className="wrapper">
      <EtodSideBar
        isLoadingEtodAirport={isLoadingEtodAirport}
        isLoadingEtodDetails={isLoadingEtodDetails}
        isLoadingEtodObstacles={isLoadingEtodObstacles}
        isLoadingEtodOLS={isLoadingEtodOLS}
        isLoadingArea1={isLoadingArea1}
        isLoadingRasterAreas={isLoadingRasterAreas}
        isLoadingEtodOLSObstacles={isLoadingEtodOLSObstacles}
        isLoadingArea1Obstacles={isLoadingArea1Obstacles}
        isLoadingAreaThreeObstacles={isLoadingAreaThreeObstacles}
        isLoadingExportObstacle={isLoadingExportObstacle}
        allAirports={allAirports}
        selectedAreas={selectedAreas}
        selctedAirport={selctedAirport}
        isActiveAirport={!selctedAirport}
        selctedFeature={selctedFeature}
        selectedOls={selectedOls}
        olsList={OLSOptions}
        selectedArea1={selectedArea1}
        handleAirport={handleAirport}
        handleFeature={handleFeature}
        handleAreaCheck={handleAreaCheck}
        handleSelectAllArea={handleSelectAllArea}
        handleClearAllArea={handleClearAllArea}
        handleParentOlsCheck={handleParentOlsCheck}
        handleOlsCheck={handleOlsCheck}
        handleArea1Check={handleArea1Check}
        handleRasterAreaCheck={handleRasterAreaCheck}
        selectedRasterArea={selectedRasterArea}
        isShowPenetrationObstacle={isShowPenetrationObstacle}
        handlePenetrationObstacleToggle={handlePenetrationObstacleToggle}
        areaOptions={areaOptions}
        handleParentAreaCheck={handleParentAreaCheck}
        handleParentRasterAreaCheck={handleParentRasterAreaCheck}
        handleExportObstacle={handleExportObstacle}
      />
      <div className="main">
        <Header active="etod" />
        <div className="content-area linear-bg">
          {!selctedAirport && !selectedArea1 && (
            <div className="welcome-etod">
              <Typography variant="h3" component="div">
                WELCOME
              </Typography>
              <Typography variant="subtitle2" component="div">
                Data defining eTOD will be displayed here
              </Typography>
            </div>
          )}
          {(selctedAirport || selectedArea1) && (
            <EtodMap
              airportLocation={airportLocation}
              zoomLevel={zoomLevel}
              selctedAirport={selctedAirport}
              etodAreaList={etodAreaListData}
              selectedAreas={selectedAreas}
              obstaclesList={obstaclesList}
              selectedObstacles={selectedObstacles}
              selectedA2D={selectedA2D}
              colors={colorMap}
              selectedOls={selectedOls}
              OLSApproachList={OLSApproachList}
              OLSHorizontalSurfaceList={OLSHorizontalSurfaceList}
              OLSTransitionalSurfaceList={OLSTransitionalSurfaceList}
              OLSObstacleFreeZoneList={OLSObstacleFreeZoneList}
              OLSRwyStripList={OLSRwyStripList}
              OLSTakeOffClimbList={OLSTakeOffClimbList}
              selectedArea1={selectedArea1}
              area1Data={area1Data}
              selectedRasterArea={selectedRasterArea}
              rasterAreaList={rasterAreaList}
              isShowPenetrationObstacle={isShowPenetrationObstacle}
              olsObstaclesList={olsObstaclesList}
              area1Obstacles={area1Obstacles}
              areaThreeObstacles={areaThreeObstacles}
            />
          )}
        </div>
      </div>
    </div>
  );
}
