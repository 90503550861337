import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import { dataChangedRowColorMap } from "../../constants/colors";

const DiffTableCell = styled(TableCell)(
  ({ isdiff = undefined, isselected = "false" }) => {
    const diffColor = isdiff
      ? dataChangedRowColorMap.deviation_detected
      : dataChangedRowColorMap.transparent;
    const selectColor =
      isselected === "true"
        ? "rgb(0, 114, 229, 0.5)"
        : dataChangedRowColorMap.transparent;
    const color = isselected === "true" ? selectColor : diffColor;
    return {
      backgroundColor: color,
      padding: "6px 10px",
    };
  }
);

export default DiffTableCell;
