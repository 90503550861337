import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MuiSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import { ACCESS_TOKEN } from "../../constants/constant";
import { createAd22tValidationSchema } from "../../constants/ad2Data";
import {
  removeSelctedAirportFormCategories,
  addAirportDetail,
} from "../../models/airport";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { PrimaryButton } from "../button";

export default function Ad22Form({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const isLoadingAddAirportDetail = useSelector(
    (state) => state.airport.isLoadingAddAirportDetail
  );

  const createAd22Form = useFormik({
    enableReinitialize: true,
    initialValues: {
      site: "",
      dir_distance_city: "",
      elevation: "",
      temp: "",
      dateMagneticVariation: "",
      magneticVariation: "",
      magneticVariationChange: "",
      traffic_type: "",
      authority_name: "",
      address: "",
      telephone: "",
      fax: "",
      afs: "",
      email: "",
      remark: "",
    },
    validationSchema: createAd22tValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addAirportDetail(
            {
              airport_id: selectedAirport.id,
              form: {
                ...values,
                elevation: values.elevation + " FT",
                temp: values.temp + " DEG C",
                dateMagneticVariation: values.dateMagneticVariation + " DEG W",
                magneticVariationChange:
                  values.magneticVariationChange + " DEG E",
              },
            },
            token,
            handleAddSuccessCallback
          )
        );
      });
    },
  });

  const handleAddSuccessCallback = () => {
    createAd22Form.resetForm();
    createAd22Form.setSubmitting(false);
    dispatch(removeSelctedAirportFormCategories(selectedCategory));
  };

  return (
    <Paper sx={{ pb: 2, pt: 2 }}>
      <form>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Aerodrome reference point coordinates</Box>
          <Box sx={{ pl: 2 }}>
            {selectedAirport.latitude}, {selectedAirport.longitude}
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Aerodrome reference point site</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="site"
              name="site"
              value={createAd22Form.values.site}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.site &&
                Boolean(createAd22Form.errors.site)
              }
              helperText={
                createAd22Form.touched.site && createAd22Form.errors.site
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>
            Direction and distance of aerodrome reference point from the center
            of the city or town which the aerodrome serves
          </Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="dir_distance_city"
              name="dir_distance_city"
              value={createAd22Form.values.dir_distance_city}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.dir_distance_city &&
                Boolean(createAd22Form.errors.dir_distance_city)
              }
              helperText={
                createAd22Form.touched.dir_distance_city &&
                createAd22Form.errors.dir_distance_city
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Aerodrome elevation</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="elevation"
              name="elevation"
              type="number"
              value={createAd22Form.values.elevation}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.elevation &&
                Boolean(createAd22Form.errors.elevation)
              }
              helperText={
                createAd22Form.touched.elevation &&
                createAd22Form.errors.elevation
              }
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">FT</InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Aerodrome reference temperature</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="temp"
              name="temp"
              type="number"
              value={createAd22Form.values.temp}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.temp &&
                Boolean(createAd22Form.errors.temp)
              }
              helperText={
                createAd22Form.touched.temp && createAd22Form.errors.temp
              }
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">DEG C</InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Magnetic variation</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="dateMagneticVariation"
              name="dateMagneticVariation"
              type="number"
              value={createAd22Form.values.dateMagneticVariation}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.dateMagneticVariation &&
                Boolean(createAd22Form.errors.dateMagneticVariation)
              }
              helperText={
                createAd22Form.touched.dateMagneticVariation &&
                createAd22Form.errors.dateMagneticVariation
              }
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">DEG W</InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Date of information</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="magneticVariation"
              name="magneticVariation"
              value={createAd22Form.values.magneticVariation}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.magneticVariation &&
                Boolean(createAd22Form.errors.magneticVariation)
              }
              helperText={
                createAd22Form.touched.magneticVariation &&
                createAd22Form.errors.magneticVariation
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Annual change</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="magneticVariationChange"
              name="magneticVariationChange"
              type="number"
              value={createAd22Form.values.magneticVariationChange}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.magneticVariationChange &&
                Boolean(createAd22Form.errors.magneticVariationChange)
              }
              helperText={
                createAd22Form.touched.magneticVariationChange &&
                createAd22Form.errors.magneticVariationChange
              }
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">DEG E</InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Types of traffic permitted (IFR/VFR)</Box>
          <Box sx={{ pl: 2 }}>
            <FormControl
              fullWidth
              size="small"
              sx={{ mb: 2, width: 200 }}
              error={
                createAd22Form.touched.traffic_type &&
                Boolean(createAd22Form.errors.traffic_type)
              }
            >
              <MuiSelect
                id="traffic_type"
                name="traffic_type"
                value={createAd22Form.values.traffic_type}
                onChange={createAd22Form.handleChange}
              >
                <MenuItem value="IFR/VFR">IFR/VFR</MenuItem>
                <MenuItem value="IFR">IFR</MenuItem>
                <MenuItem value="VFR">VFR</MenuItem>
              </MuiSelect>
              <FormHelperText>
                {createAd22Form.touched.traffic_type &&
                  createAd22Form.errors.traffic_type}
              </FormHelperText>
            </FormControl>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Name of aerodrome operator</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="authority_name"
              name="authority_name"
              value={createAd22Form.values.authority_name}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.authority_name &&
                Boolean(createAd22Form.errors.authority_name)
              }
              helperText={
                createAd22Form.touched.authority_name &&
                createAd22Form.errors.authority_name
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Address</Box>
          <Box sx={{ pl: 2 }}>
            <FormControl
              variant="outlined"
              sx={{ mb: 2, mt: 2, width: 250 }}
              error={
                createAd22Form.touched.address &&
                Boolean(createAd22Form.errors.address)
              }
            >
              <TextareaAutosize
                minRows={2}
                id="address"
                name="address"
                value={createAd22Form.values.address}
                onChange={createAd22Form.handleChange}
                aria-describedby="aerodrome-address"
              />
              <FormHelperText id="aerodrome-address">
                {createAd22Form.touched.address &&
                  createAd22Form.errors.address}
              </FormHelperText>
            </FormControl>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Telephone</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="telephone"
              name="telephone"
              type="number"
              value={createAd22Form.values.telephone}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.telephone &&
                Boolean(createAd22Form.errors.telephone)
              }
              helperText={
                createAd22Form.touched.telephone &&
                createAd22Form.errors.telephone
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Telefax</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="fax"
              name="fax"
              value={createAd22Form.values.fax}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.fax && Boolean(createAd22Form.errors.fax)
              }
              helperText={
                createAd22Form.touched.fax && createAd22Form.errors.fax
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>AFS address</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="afs"
              name="afs"
              value={createAd22Form.values.afs}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.afs && Boolean(createAd22Form.errors.afs)
              }
              helperText={
                createAd22Form.touched.afs && createAd22Form.errors.afs
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>e-mail address</Box>
          <Box sx={{ pl: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="email"
              name="email"
              value={createAd22Form.values.email}
              onChange={createAd22Form.handleChange}
              error={
                createAd22Form.touched.email &&
                Boolean(createAd22Form.errors.email)
              }
              helperText={
                createAd22Form.touched.email && createAd22Form.errors.email
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ width: "30%" }}>Remarks</Box>
          <Box sx={{ pl: 2 }}>
            <FormControl
              variant="outlined"
              sx={{ mb: 2, mt: 2, width: 250 }}
              error={
                createAd22Form.touched.remark &&
                Boolean(createAd22Form.errors.remark)
              }
            >
              <TextareaAutosize
                minRows={2}
                id="remark"
                name="remark"
                value={createAd22Form.values.remark}
                onChange={createAd22Form.handleChange}
                aria-describedby="aerodrome-remark"
              />
              <FormHelperText id="aerodrome-remark">
                {createAd22Form.touched.remark && createAd22Form.errors.remark}
              </FormHelperText>
            </FormControl>
          </Box>
        </Stack>
      </form>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ pr: 2 }}
      >
        <PrimaryButton
          label="Submit"
          onClick={() => createAd22Form.handleSubmit()}
          isLoading={isLoadingAddAirportDetail}
          disabled={isLoadingAddAirportDetail}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}
