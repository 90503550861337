import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { isPlainObject } from "lodash";
import { apiCall, fileUploadApicall } from "../utils/connect";
import {
  RSA_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REQUEST_NEW_PASSWORD_URL,
  CRC32Q_GENERATE,
  REQUEST_FEEDBACK_URL,
} from "../constants/urls";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  AES_KEY,
} from "../constants/constant";
import {
  setStorageValue,
  getStorageValue,
  removeAllCookies,
} from "../utils/localStorage";
import { decryptRsa } from "../utils/decodeEncodeData";

const initialState = {
  isRSALoading: false,
  isLoginLoading: false,
  isLogoutLoading: false,
  rsaKey: null,
  user: null,
  isRequestNewPassowrdLoading: false,
  isCRC32QGenerateRequesting: false,
  isRequestFeedbackLoading: false,
};

export const userSession = createSlice({
  name: "userSession",
  initialState,
  reducers: {
    setRsaRequest: (state, action) => {
      state.isRSALoading = action.payload;
    },
    setLoginRequest: (state, action) => {
      state.isLoginLoading = action.payload;
    },
    setLogoutRequest: (state, action) => {
      state.isLogoutLoading = action.payload;
    },
    setRSA: (state, action) => {
      state.rsaKey = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRequestNewPassowrdLoading: (state, action) => {
      state.isRequestNewPassowrdLoading = action.payload;
    },
    setCRC32QGenerateRequest: (state, action) => {
      state.isCRC32QGenerateRequesting = action.payload;
    },
    setRequestFeedbackLoading: (state, action) => {
      state.isRequestFeedbackLoading = action.payload;
    },
  },
});

export const {
  setRsaRequest,
  setLoginRequest,
  setLogoutRequest,
  setRSA,
  setUser,
  setRequestNewPassowrdLoading,
  setCRC32QGenerateRequest,
  setRequestFeedbackLoading,
} = userSession.actions;

export default userSession.reducer;

export const getRSA = () => (dispatch) => {
  try {
    dispatch(setRsaRequest(true));

    const onSuccess = (response) => {
      dispatch(setRSA(response.data.rsa));
      dispatch(setRsaRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setRsaRequest(false));
    };

    apiCall("GET", RSA_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setRsaRequest(false));
    toast.error(error.message);
  }
};

export const userLogin = (payload, callback) => (dispatch) => {
  try {
    dispatch(setLoginRequest(true));

    const onSuccess = (response) => {
      decryptRsa(response.data.accessToken).then((aesKey) => {
        dispatch(setUser(response.data));
        setStorageValue(AES_KEY, aesKey);
        setStorageValue(ACCESS_TOKEN, response.data.accessToken);
        setStorageValue(REFRESH_TOKEN, response.data.refreshToken);
        dispatch(setLoginRequest(false));
        callback();
        toast.success("User logged in successfully");
      });
    };
    const onFailure = (error) => {
      dispatch(setLoginRequest(false));
      toast.error(error.message);
    };

    apiCall(
      "POST",
      LOGIN_URL,
      { ...payload, encryptionDetails: "PKCS1_OAEP" },
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoginRequest(false));
    toast.error(error.message);
  }
};

const clearTokens = (callback) => (dispatch) => {
  removeAllCookies().then(() => {
    callback();
  });
  dispatch(setRSA(null));
  dispatch(setUser(null));
};

export const userLogout = (callback) => (dispatch) => {
  try {
    dispatch(setLogoutRequest(true));

    const onSuccess = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };
    const onFailure = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };

    const refreshToken = getStorageValue(REFRESH_TOKEN);
    if (refreshToken !== undefined && refreshToken !== null) {
      apiCall("DELETE", LOGOUT_URL, "", onSuccess, onFailure, refreshToken);
    }
  } catch (error) {
    dispatch(clearTokens(callback));
    dispatch(setLogoutRequest(false));
  }
};

export const requestNewPassword = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setRequestNewPassowrdLoading(true));

    const onSuccess = () => {
      toast.success("New Password requested successfully");
      dispatch(setRequestNewPassowrdLoading(false));
      callback();
    };
    const onFailure = (error) => {
      if (isPlainObject(error.message)) {
        toast.error(error.message?.message);
      } else {
        toast.error(error.message);
      }

      dispatch(setRequestNewPassowrdLoading(false));
    };

    apiCall(
      "POST",
      REQUEST_NEW_PASSWORD_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setRequestNewPassowrdLoading(false));
    toast.error(error.message);
  }
};

export const generateCRC32Q = (file, successCallback) => (dispatch) => {
  try {
    dispatch(setCRC32QGenerateRequest(true));

    const onSuccess = (response) => {
      successCallback(response.message);
      dispatch(setCRC32QGenerateRequest(false));
    };
    const onFailure = () => {
      toast.error("CRC32Q code not generated");
      dispatch(setCRC32QGenerateRequest(false));
    };

    fileUploadApicall(CRC32Q_GENERATE, file, onSuccess, onFailure, null);
  } catch (error) {
    dispatch(setCRC32QGenerateRequest(false));
    toast.error("CRC32Q code not generated");
  }
};

export const requestFeedback = (payload, callback) => (dispatch) => {
  try {
    dispatch(setRequestFeedbackLoading(true));

    const onSuccess = () => {
      toast.success("Feedback sent successfully");
      dispatch(setRequestFeedbackLoading(false));
      callback();
    };
    const onFailure = (error) => {
      if (isPlainObject(error.message)) {
        toast.error(error.message?.message);
      } else {
        toast.error(error.message);
      }

      dispatch(setRequestFeedbackLoading(false));
    };

    apiCall("POST", REQUEST_FEEDBACK_URL, payload, onSuccess, onFailure);
  } catch (error) {
    dispatch(setRequestFeedbackLoading(false));
    toast.error(error.message);
  }
};
