export const airspaceType = {
  restrictedAirspace: "Restricted Airspace",
  controlledAirspace: "Controlled Airspace",
  firuir: "FIR / UIR",
  adiz: "ADIZ",
  rnav: "Area Navigational Routes",
  cnav: "Conventional Routes",
  navaids: "Navaids",
  wayPoints: "Way Points",
  wayPointRoutes: "Way Point Routes",
};

export const airspaceFilterType = {
  firuir: "FIR / UIR",
  tma: "TMA",
  controlArea: "Control Area",
  otherRegulatedAirspace: "Other Regulated Airspace",
  routes: "Routes",
  radioNavigation: "Radio Navigation",
  significantPoints: "Significant Points",
  prohibitedRestrictedandDangerAreas: "Prohibited, Restricted and Danger Areas",
  militaryExecriseandTrainingAreas: "Military Execrise and Training Areas",
};

export const airspaceFilterSortType = {
  "FIR / UIR": "FIR",
  TMA: "TMA",
  "Control Area": "CTA",
  "Other Regulated Airspace": "ATZ_CTR",
  "Radio Navigation": "Navaids",
  "Significant Points": "SignificantPoints",
};

export const FIRUIRKeyMap = {
  "MUMBAI FIR": "Mumbai FIR",
  "KOLKATA FIR": "Kolkata FIR",
  "DELHI FIR": "Delhi FIR",
  "CHENNAI FIR": "Chennai Flight Information Region",
};

export const ADIZKeyMap = {
  South: "ADIZ South",
  Subsoutheast: "Sub ADIZ South-East",
  Central: "ADIZ Central",
  West: "ADIZ West",
  East: "ADIZ East",
  North: "ADIZ North",
};

export const airspaceFilterOptions = [
  {
    id: 1,
    label: "FIR / UIR",
    value: "FIR / UIR",
  },
  {
    id: 2,
    label: "TMA",
    value: "TMA",
  },
  {
    id: 3,
    label: "Control Area",
    value: "Control Area",
  },
  {
    id: 4,
    label: "Other Regulated Airspace",
    value: "Other Regulated Airspace",
  },
  {
    id: 5,
    label: "Routes",
    value: "Routes",
  },
  {
    id: 6,
    label: "Radio Navigation",
    value: "Radio Navigation",
  },
  {
    id: 7,
    label: "Significant Points",
    value: "Significant Points",
  },
  {
    id: 8,
    label: "Prohibited, Restricted and Danger Areas",
    value: "Prohibited, Restricted and Danger Areas",
  },
  {
    id: 9,
    label: "Military Execrise and Training Areas",
    value: "Military Execrise and Training Areas",
  },
];

export const airspaceOptions = [
  {
    id: 1,
    label: "Restricted Airspace",
    value: "Restricted Airspace",
  },
  {
    id: 2,
    label: "Controlled Airspace",
    value: "Controlled Airspace",
  },
  {
    id: 3,
    label: "FIR / UIR",
    value: "FIR / UIR",
  },
  {
    id: 4,
    label: "ADIZ",
    value: "ADIZ",
  },
  {
    id: 5,
    label: "Area Navigational Routes",
    value: "Area Navigational Routes",
  },
  {
    id: 6,
    label: "Conventional Routes",
    value: "Conventional Routes",
  },
  {
    id: 7,
    label: "Navaids",
    value: "Navaids",
  },
  {
    id: 8,
    label: "Way Points",
    value: "Way Points",
  },
];

export const DPRTypeFilterOptions = [
  {
    id: 1,
    label: "D",
    value: "D",
  },
  {
    id: 2,
    label: "P",
    value: "P",
  },
  {
    id: 3,
    label: "R",
    value: "R",
  },
];
