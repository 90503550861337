import React from "react";

export const LayerIcon = (props) => (
  <svg
    width={50}
    height={51}
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.079 0.960048C26.2894 0.025996 23.8643 0.0115836 22.0604 0.94323C15.1458 4.51428 8.87415 8.60876 3.37822 13.1501C2.52833 13.8524 1.92518 14.8729 1.93547 16.0741C1.94572 17.2703 2.56114 18.2772 3.40587 18.9679C8.85614 23.4244 15.133 27.5225 21.9209 31.0653C23.7106 31.9993 26.1356 32.0138 27.9396 31.0821C34.8541 27.5111 41.1258 23.4166 46.6217 18.8752C47.4716 18.173 48.0748 17.1525 48.0645 15.9513C48.0542 14.755 47.4388 13.7482 46.5941 13.0575C41.1438 8.60099 34.867 4.50282 28.079 0.960048ZM23.8959 4.49724C24.535 4.16718 25.5848 4.1703 26.2282 4.50613C32.7065 7.88723 38.6756 11.7771 43.8468 15.9786C38.6447 20.2477 32.6934 24.1251 26.1041 27.5281C25.465 27.8582 24.4151 27.855 23.7717 27.5192C17.2935 24.1381 11.3244 20.2482 6.15312 16.0467C11.3553 11.7776 17.3066 7.90029 23.8959 4.49724Z"
      fill="#F2F3F6"
    />
    <path
      d="M49.5254 25.8608C50.1104 26.7977 49.825 28.0315 48.8881 28.6164L32.6021 38.7841C30.3921 40.1639 27.6502 40.8018 24.9997 40.8019C22.3492 40.8021 19.6072 40.1645 17.3969 38.7851L1.2894 28.7324C0.352343 28.1476 0.0667944 26.9139 0.651606 25.9768C1.23642 25.0398 2.47013 24.7542 3.40719 25.339L19.5147 35.3917C20.9658 36.2973 22.937 36.8021 24.9995 36.8019C27.062 36.8018 29.033 36.2968 30.4838 35.3911L46.7697 25.2234C47.7067 24.6384 48.9404 24.9238 49.5254 25.8608Z"
      fill="#F2F3F6"
    />
    <path
      d="M49.5253 35.8731C50.1103 36.81 49.8251 38.0438 48.8883 38.6289L34.7845 47.4365C31.9642 49.1978 28.4362 50.0265 24.9996 50.0267C21.5631 50.0269 18.0349 49.1986 15.2142 47.4377L1.28919 38.7449C0.352209 38.16 0.0668088 36.9262 0.651733 35.9892C1.23666 35.0522 2.47041 34.7668 3.40739 35.3518L17.3324 44.0446C19.3937 45.3315 22.1509 46.0268 24.9994 46.0267C27.8479 46.0265 30.6048 45.3308 32.6658 44.0438L46.7695 35.2361C47.7064 34.6511 48.9402 34.9363 49.5253 35.8731Z"
      fill="#F2F3F6"
    />
  </svg>
);
