import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PrimaryButton } from './button';

export default function EtodObstacleExportButton({
  label,
  items = [],
  isLoadingExportObstacle,
  handleExport
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnExport = (item) => {
    handleClose();
    handleExport(item.value);
  };

  return (
    <>
      <PrimaryButton
        label={label}
        id="export-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        isLoading={isLoadingExportObstacle}
        disabled={isLoadingExportObstacle}
        onClick={handleClick}
      />
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleOnExport(item)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
