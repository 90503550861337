import React, { Fragment } from 'react';
import { keysIn } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StyledTableCell from '../tabel-component/StyledTableCell';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import TableLoader from '../tabel-component/TableLoader';

export default function RunWayItem({
  isLoading,
  category,
  runwayDetails,
  aixmFeatureMapData,
  selctedAirport
}) {
  const runWayList = runwayDetails[category];
  const runWayMap = aixmFeatureMapData['Runway_List'];
  const surfaceCharacteristicsMap = aixmFeatureMapData['SurfaceCharacteristics_List'];

  return (
    <Fragment key={category}>
      {(isLoading || runWayList.length === 0) && (
        <TableLoader isLoading={isLoading} data={runWayList} />
      )}
      {
        runWayList.map((runway, i) => {
          return (
            <Paper sx={{ mb: 5 }} key={`${category}_${i}`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell colSpan={2}>
                      {selctedAirport.name} {">"} {runway.designator}
                    </HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(runWayMap).map((key, j) => {
                    const runWaylKey = runWayMap[key];
                    return (
                      <TableRow
                        key={`${category}_${i}_${j}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell style={{ width: 250 }}>{key}</StyledTableCell>
                        <StyledTableCell>{runway[runWaylKey] || "-"}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={2}>
                      {runway.designator} {">"} Surface Characteristics
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(surfaceCharacteristicsMap).map((key, j) => {
                    const surfaceCharacteristicsKey = surfaceCharacteristicsMap[key];
                    return (
                      <TableRow
                        key={`${i}_${j}_${category}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell style={{ width: 250 }}>{key}</StyledTableCell>
                        <StyledTableCell>{runway[surfaceCharacteristicsKey] || "-"}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          );
        })
      }
    </Fragment>
  );
}
