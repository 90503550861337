import React, { Fragment } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { uniqBy } from "lodash";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {
  aixmFilterOptions,
  aixmFilterType,
  aixmAirportExportItems,
} from "../constants/aixmData";
import {
  airspaceFilterOptions,
  airspaceFilterType,
} from "../constants/airspaceData";
import { exportXMLAD2Data } from "../models/airport";
import AixmAirportExportButton from "./AixmAirportExportButton";
import DropDownLabel from "./DropDownLabel";
import BrandLogo from "./BrandLogo";
import { LightButton } from "./button";

export default function AixmSideBar({
  isLoadingAirport,
  isLoadingAirportDetail,
  isLoadingGetAixmDataVersion,
  isLoadingFIRData,
  isLoadingTMAData,
  isLoadingCTAData,
  isLoadingRegulatedAirspaceData,
  isLoadingGetAllRoute,
  isLoadingGetAllNavaid,
  isLoadingGetAllsignificantPoint,
  isLoadingGetAllDPR,
  isLoadingGetAllTRA_TSA_ADIZ,
  isLoadingGetRouteData,
  allAirports = [],
  categories = [],
  selctedAirport,
  isActiveAirport,
  selectedCategories,
  selctedFeature,
  selctedAirspaceFilter,
  firOptions,
  selectedAirspaceRows,
  TMAOptions,
  CTAOptions,
  regulatedAirspaceOptions,
  allRoutesOptions,
  selctedRouteFilter,
  routesNameList,
  selectedRoute,
  navaidOptions,
  significantPointOptions,
  allDPROptions,
  selctedDPRFilter,
  DPRTypeFilterOptions,
  dprNameList,
  hasSectedAirspace,
  handleCategoryFilter,
  handleAirport,
  handleFeature,
  handleAirspaceFilter,
  handleAirspaceSearch,
  handleAirspaceNameCheck,
  handleAirspaceDesignatorCheck,
  handleAiespaceAerodromeCheck,
  handleDPRMetaCheck,
  handleAirspaceAllSelect,
  handleRouteFilter,
  handleRoute,
  handleAirspaceClear,
  handleDPRFilter,
  selctedDPRTypeFilter,
  handleDPRTypeFilter,
  allTRA_TSA_ADIZOptions,
  selctedMETAFilter,
  handleMETAFilter,
  METANameList,
}) {
  const dispatch = useDispatch();
  const isExportAixmXMLLoading = useSelector(
    (state) => state.airport.isExportXMLAD2Loading
  );

  const isDisabled =
    isActiveAirport || isLoadingAirportDetail || isLoadingGetAixmDataVersion;
  const isAirspaceDisabled =
    (isLoadingFIRData &&
      selctedAirspaceFilter?.value === airspaceFilterType.firuir) ||
    (isLoadingTMAData &&
      selctedAirspaceFilter?.value === airspaceFilterType.tma) ||
    (isLoadingCTAData &&
      selctedAirspaceFilter?.value === airspaceFilterType.controlArea) ||
    (isLoadingRegulatedAirspaceData &&
      selctedAirspaceFilter?.value ===
        airspaceFilterType.otherRegulatedAirspace) ||
    isLoadingGetAllRoute ||
    isLoadingGetRouteData ||
    (isLoadingGetAllNavaid &&
      selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation) ||
    (isLoadingGetAllsignificantPoint &&
      selctedAirspaceFilter?.value === airspaceFilterType.significantPoints) ||
    (isLoadingGetAllDPR &&
      selctedAirspaceFilter?.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas) ||
    (isLoadingGetAllTRA_TSA_ADIZ &&
      selctedAirspaceFilter?.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas);
  let listClass = "check-list airspace-check-list";
  if (selctedAirspaceFilter?.value === airspaceFilterType.routes) {
    listClass = "check-list airspace-check-list-2";
  } else if (
    selctedAirspaceFilter?.value ===
    airspaceFilterType.militaryExecriseandTrainingAreas
  ) {
    listClass = "check-list airspace-check-list-3";
  } else if (
    selctedAirspaceFilter?.value ===
    airspaceFilterType.prohibitedRestrictedandDangerAreas
  ) {
    if (selctedDPRFilter) {
      listClass = "check-list airspace-check-list-4";
    } else {
      listClass = "check-list airspace-check-list-3";
    }
  }

  const handleExportAixmXML = (type, airport) => {
    dispatch(exportXMLAD2Data(type, airport));
  };

  return (
    <div className="sidebar">
      <BrandLogo />
      <Box sx={{ mb: 1 }}>
        <DropDownLabel label="Feature" />
        <Select
          isClearable
          placeholder="Select..."
          value={selctedFeature || null}
          onChange={handleFeature}
          options={aixmFilterOptions}
        />
      </Box>
      {selctedFeature?.value === aixmFilterType.airport && (
        <Fragment>
          <Box>
            <DropDownLabel label="Airport" />
            <Select
              isClearable
              isLoading={isLoadingAirport}
              value={selctedAirport || null}
              placeholder="Select..."
              onChange={handleAirport}
              options={allAirports}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <DropDownLabel label="Category" />
            <Select
              isClearable
              isMulti
              isDisabled={isDisabled}
              isLoading={isLoadingAirportDetail}
              value={selectedCategories}
              placeholder="Select..."
              onChange={handleCategoryFilter}
              options={categories}
            />
          </Box>
          {isLoadingAirportDetail && selectedCategories.length === 0 ? (
            <LinearProgress sx={{ mt: 2, mb: 1 }} />
          ) : (
            <Divider sx={{ mt: 2, mb: 1 }} />
          )}
          <div
            style={{
              position: "absolute",
              bottom: "1.5rem",
              width: "inherit",
            }}
          >
            <div className="aixm-airport-sidebar-footer">
              <AixmAirportExportButton
                label="Export XML"
                items={aixmAirportExportItems}
                handleExportXML={(type) =>
                  handleExportAixmXML(type, selctedAirport)
                }
                isExportXMLLoading={isExportAixmXMLLoading}
                isExportXMLDisabled={!selctedAirport}
              />
            </div>
          </div>
        </Fragment>
      )}
      {/* airspace */}
      {selctedFeature?.value === aixmFilterType.airspace && (
        <Fragment>
          <Box>
            <DropDownLabel label="Airspace" />
            <Select
              isClearable
              placeholder="Select..."
              value={selctedAirspaceFilter || null}
              onChange={handleAirspaceFilter}
              options={airspaceFilterOptions}
            />
          </Box>
          {selctedAirspaceFilter?.value === airspaceFilterType.routes && (
            <Box sx={{ mt: 1 }}>
              <DropDownLabel label="Route" />
              <Select
                isClearable
                isDisabled={isAirspaceDisabled}
                placeholder="Select..."
                value={selctedRouteFilter || null}
                onChange={handleRouteFilter}
                options={allRoutesOptions}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value ===
            airspaceFilterType.militaryExecriseandTrainingAreas && (
            <Box sx={{ mt: 1 }}>
              <DropDownLabel label="Area" />
              <Select
                isClearable
                isDisabled={isAirspaceDisabled}
                placeholder="Select..."
                value={selctedMETAFilter || null}
                onChange={handleMETAFilter}
                options={allTRA_TSA_ADIZOptions}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value ===
            airspaceFilterType.prohibitedRestrictedandDangerAreas && (
            <Box sx={{ mt: 1 }}>
              <DropDownLabel label="DPR" />
              <Select
                isClearable
                isDisabled={isAirspaceDisabled}
                placeholder="Select..."
                value={selctedDPRFilter || null}
                onChange={handleDPRFilter}
                options={allDPROptions}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value ===
            airspaceFilterType.prohibitedRestrictedandDangerAreas &&
            selctedDPRFilter && (
              <Box sx={{ mt: 1 }}>
                <DropDownLabel label="Type" />
                <Select
                  isClearable
                  isDisabled={isAirspaceDisabled}
                  placeholder="Select..."
                  value={selctedDPRTypeFilter || null}
                  onChange={handleDPRTypeFilter}
                  options={DPRTypeFilterOptions}
                />
              </Box>
            )}
          <TextField
            fullWidth
            size="small"
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch />
                </InputAdornment>
              ),
              placeholder: "Type here...",
            }}
            onChange={handleAirspaceSearch}
            sx={{ mt: 2, zIndex: 0 }}
          />
          {isAirspaceDisabled && !hasSectedAirspace ? (
            <LinearProgress sx={{ mt: 2, mb: 1 }} />
          ) : (
            <Divider sx={{ mt: 2, mb: 1 }} />
          )}
          {selctedAirspaceFilter &&
            selctedAirspaceFilter?.value !== airspaceFilterType.routes && (
              <div className="check-list-header">
                <div className="check-list-action">
                  <LightButton
                    label="SELECT ALL"
                    onClick={handleAirspaceAllSelect}
                    disabled={isAirspaceDisabled}
                    sx={{ p: 0 }}
                  />
                  <LightButton
                    label="CLEAR ALL"
                    onClick={handleAirspaceClear}
                    disabled={isAirspaceDisabled}
                    sx={{ p: 0 }}
                  />
                </div>
              </div>
            )}
          <div className={listClass}>
            {selctedAirspaceFilter?.value === airspaceFilterType.firuir &&
              firOptions.map((fir) => (
                <ListItem key={fir.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingFIRData}
                    onClick={() => handleAirspaceNameCheck(fir)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(fir.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": fir.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={fir.id} primary={fir.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value === airspaceFilterType.tma &&
              TMAOptions.map((tma) => (
                <ListItem key={tma.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingTMAData}
                    onClick={() => handleAirspaceNameCheck(tma)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(tma.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": tma.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={tma.id} primary={tma.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value === airspaceFilterType.controlArea &&
              uniqBy(CTAOptions, "name").map((cta) => (
                <ListItem key={cta.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingCTAData}
                    onClick={() => handleAirspaceNameCheck(cta)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(cta.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": cta.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={cta.id} primary={cta.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.otherRegulatedAirspace &&
              regulatedAirspaceOptions.map((ra) => (
                <ListItem key={ra.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingRegulatedAirspaceData}
                    onClick={() => handleAiespaceAerodromeCheck(ra)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(ra.Aerodrome)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": ra.Aerodrome }}
                      />
                    </ListItemIcon>
                    <ListItemText id={ra.id} primary={ra.Aerodrome} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value === airspaceFilterType.routes &&
              routesNameList.map((route) => (
                <ListItem key={route.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingGetAllRoute}
                    selected={selectedRoute?.id === route.id}
                    onClick={() => handleRoute(route)}
                    dense
                  >
                    <ListItemText
                      id={route.id}
                      primary={`${route.designatorSecondLetter}${
                        route.designatorNumber
                      }${
                        route.multipleIdentifier ? route.multipleIdentifier : ""
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.radioNavigation &&
              navaidOptions.map((rn) => (
                <ListItem key={rn.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingGetAllNavaid}
                    onClick={() => handleAirspaceDesignatorCheck(rn)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(rn.designator)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": rn.designator }}
                      />
                    </ListItemIcon>
                    <ListItemText id={rn.id} primary={rn.designator} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.significantPoints &&
              significantPointOptions.map((sp) => (
                <ListItem key={sp.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingGetAllsignificantPoint}
                    onClick={() => handleAirspaceNameCheck(sp)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(sp.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": sp.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={sp.id} primary={sp.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.prohibitedRestrictedandDangerAreas &&
              dprNameList.map((dpr) => {
                const dprName = `${dpr.Airspace_name} ${dpr.designator}`;
                return (
                  <ListItem key={dpr.id} disablePadding>
                    <ListItemButton
                      disabled={isLoadingGetAllDPR}
                      onClick={() => handleDPRMetaCheck(dpr)}
                      dense
                    >
                      <ListItemIcon sx={{ mr: -3 }}>
                        <Checkbox
                          edge="start"
                          checked={selectedAirspaceRows.includes(dprName)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": dprName }}
                        />
                      </ListItemIcon>
                      <ListItemText id={dpr.id} primary={dprName} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.militaryExecriseandTrainingAreas &&
              METANameList.map((meta) => {
                const metaName = `${meta.Airspace_name} ${meta.designator}`;
                return (
                  <ListItem key={meta.id} disablePadding>
                    <ListItemButton
                      disabled={isLoadingGetAllTRA_TSA_ADIZ}
                      onClick={() => handleDPRMetaCheck(meta)}
                      dense
                    >
                      <ListItemIcon sx={{ mr: -3 }}>
                        <Checkbox
                          edge="start"
                          checked={selectedAirspaceRows.includes(metaName)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": metaName }}
                        />
                      </ListItemIcon>
                      <ListItemText id={meta.id} primary={metaName} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </div>
        </Fragment>
      )}
    </div>
  );
}
