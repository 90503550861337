export const layerWiseColor = {
  LOC: "#0d0dff",
  BUILDING: "#03ffff",
  TWY: "#ff0000",
  "RWY MARKING": "",
  "WATER BODIES": "",
  "VOR CHECK POINT": "",
  "RWY MARKING 1": "",
  "FUEL TANK": "",
  "OVER HEAD WATER TANK": "",
  Follow_Line: "#03ffff",
  "TWY CENTER LINE": "#03ffff",
  "LEAD IN AND LEAD OUT LINE": "#03ffff",
  "COMPOUND WALL": "#1111ff",
  "HOLDING POSITION": "",
  SHOULDER: "#ff0000",
  HOTSPOT: "#ff00ff",
  "KATCHA ROAD": "",
  ROAD: "#ffbf7e",
  "SERVICE ROAD": "",
  RESA: "",
  "STATIC TANK": "",
  "ELECRIC LINE": "",
  DRAIN: "",
  "SAFETY LINE": "",
  APRON: "#ff0805",
  "METRO BRIDGE": "",
  DIVIDER: "",
  AIRBRIDGE: "",
  "FIRE APRON": "",
  RAILWAY_LINE: "",
  CULVERT: "",
  FENCE: "",
  "FIRE STATIC TANK": "",
  "LANDING T": "",
  RWY_STRIP: "#ff0000",
  "AIRPORT BOUNDARY WALL": "#0d0dff",
  GATE: "",
  "APPROACH LIGHT": "#5d9b14",
  "ELECTRICAL POLE": "",
  "LAMP POST": "",
  "RUNWAY EDGE LIGHT": "",
  "ROAD ASPHALT": "",
  TAXIWAY: "#ff0805",
  "RUNWAY CENTRELINE": "#ff0000",
  RUNWAY_EDGE_MARKING: "",
  RUNWAY_TRANSFER_STRIP: "",
  RUNWAY_PAVEMENT: "#ff0000",
  "YELLOW LINE": "#0000ff",
  HANGER: "",
  "FIRE TOWER": "",
  SHED: "",
  "GSE AREA": "",
  Canal: "",
  DRAINAGE: "",
  "WATER BODIES(TANKS)": "",
  "WATER TANK": "",
  RAILWAY: "",
  "EDGE LIGHTS": "",
  "DVOR MAIN ANTENNA": "",
  LOCALIZER: "",
  "LOCALIZER BUILDING": "",
  FOOTPATH: "",
  GENERATOR: "",
  MOSQUE: "",
  PAPI_LIGHT: "",
  "OVER HEAD TANK": "",
  "SECURITY HUT": "",
  FIREPIT: "",
  "PUMP HOUSE": "",
  WDI: "",
  "STACIC TANK": "",
  "SMR BUILDING": "",
  RADAR: "",
  "ATC TOWER": "",
  "ISOLATION BAY": "#ff0000",
  "TIN BOUNDARY WALL": "",
  "SOLAR PANEL": "",
  "NIGHT SOIL PIT": "",
  "PAVED AREA": "",
  "RWY HOLDING POINT": "",
  "INVERTER ROOM": "",
  "C.I.S.F SECURITY CABIN": "",
  "FIRE SUB STATION": "",
  "COOLING PIT": "",
  PARKING: "#03ffff",
  "INTERMEDIATE TAXI HOLDINGS": "",
  "DVOR CHECK POINT": "",
  "C.I.S.F. BARRACK": "",
  ARP: "#ff0000",
  "BUILDING ABANDONED": "",
  "SOLAR PLANT BUILDING": "",
  "ARROW MARKING": "",
  BARRACK: "",
  "GP HUT": "",
  "GP BUILDING": "",
  TERMINAL_BUILDING: "",
  HUT: "",
  "DVOR HUT": "",
  "RWY STRIP": "",
  ul: "",
};
