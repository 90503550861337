import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BrandLogo from '../components/BrandLogo';
import { PrimaryButton } from '../components/button';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <Box sx={{ width: '25%', m: 2 }}>
        <BrandLogo />
      </Box>
      <div className="not-found-sec">
        <div className="center-sec">
          <Typography variant="h4">
            Sorry, page not found!
          </Typography>
          <Typography variant="body1">
            We couldn’t find the page you’re looking for.
          </Typography>
          <PrimaryButton
            label="Go To Home"
            onClick={() => navigate('/')}
            sx={{ mt: 5 }}
          />
        </div>
      </div>
    </div>
  );
}
