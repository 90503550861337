import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { roles } from "../../constants/constant";
import Header from "../../components/Header";
import AD2DataEntrySideBar from "../../components/AD2DataEntrySideBar";
import AirportReviewListTable from "../../components/AirportReviewListTable";

export default function AD2DataEntry() {
  const location = useLocation();
  const user = useSelector((state) => state.userSession.user);
  const isParentSorveyorPath = location.pathname === "/ad-2-data-entry";
  const isAAI = user?.role === roles.aai;
  const isSurveyor = user?.role === roles.surveyor;

  return (
    <div className="wrapper">
      <AD2DataEntrySideBar />
      <div className="main">
        <Header active="data-management" />
        {(isAAI || isSurveyor) && (
          <div className="content-area white-bg">
            {isParentSorveyorPath && <AirportReviewListTable />}
            {!isParentSorveyorPath && <Outlet />}
          </div>
        )}
      </div>
    </div>
  );
}
