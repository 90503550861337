import { flatten } from 'lodash';

export const convertToDms = (dd, isLng = false) => {
  const dir = dd < 0
    ? isLng ? 'W' : 'S'
    : isLng ? 'E' : 'N';

  const absDd = Math.abs(dd);
  let deg = absDd | 0;
  const frac = absDd - deg;
  let min = (frac * 60) | 0;
  let sec = (frac * 3600 - min * 60).toFixed(2);
  deg = String(deg).padStart(2, '0');
  min = String(min).padStart(2, '0');
  sec = String(sec).padStart(5, '0');

  if (isLng) {
    return "0" + deg + "° " + min + "' " + sec + '" ' + dir;
  };

  return deg + "° " + min + "' " + sec + '" ' + dir;
};

export const getCenterPosition = (coordinates) => {
  const bounds = new window.google.maps.LatLngBounds();
  const polygonCoords = flatten(coordinates).map((apnCoords) => {
    return apnCoords.map((reml) => ({ lng: reml[0], lat: reml[1] }));
  });

  flatten(polygonCoords).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng()
  };
};

export const getCenterAirspacePosition = (coordinates) => {
  const bounds = new window.google.maps.LatLngBounds();
  const polygonCoords = flatten(coordinates).map((coords) => {
    return { lng: coords.lng, lat: coords.lat };
  });

  flatten(polygonCoords).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng()
  };
};

export const getCenterPositionFromArray = (coordinates) => {
  const bounds = new window.google.maps.LatLngBounds();

  flatten(coordinates).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng()
  };
};