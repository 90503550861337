import React, { Fragment, useEffect, useState, useMemo } from "react";
import { FeatureGroup } from "react-leaflet";
import { chain, flatten, split } from "lodash";
import { Polygon, Popup } from "react-leaflet";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { areaHighLightColor } from "../../constants/colors";
import { modifyCategories } from "../../constants/amdbData";
import { roles } from "../../constants/constant";
import PopupBox from "../map/PopupBox";
import { LightButton } from "../button";
import { useSelector } from "react-redux";

export default function RenderApnShape({
  selectedFeatures,
  apnElementList,
  handleEditShap,
}) {
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [apnShapes, setApnShapes] = useState([]);
  const [areaColor, setAreaColor] = useState({});
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const apnList = chain(apnElementList)
      .filter((apnObj) => {
        const apnKey = `apn_${apnObj.apn}`;
        const fsdfd = selectedFeatures.includes(
          `${apnKey}__${areaColorT[apnKey]}`
        );
        return fsdfd;
      })
      .value();

    setAreaColor(areaColorT);
    setApnShapes(apnList);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonEdit = (data) => {
    handleEditShap(data, modifyCategories.apron);
  };

  const apronShaps = useMemo(() => {
    return (
      <FeatureGroup pane="mapPane">
        {apnShapes.map((apnGeometry) => {
          const apnCoordinates = flatten(apnGeometry.coordinates.coordinates);
          const apnCoordinateMap = apnCoordinates.map((apnCoords) => {
            return apnCoords.map((reml) => {
              return {
                lng: reml[0],
                lat: reml[1],
              };
            });
          });

          return apnCoordinateMap.map((apnPolygonPath, i) => {
            const apnKey = `apn_${apnGeometry.apn}`;

            return (
              <Polygon
                key={apnKey}
                positions={apnPolygonPath}
                pathOptions={{
                  fillColor: areaColor[apnKey],
                  color:
                    selectedOverE?.apn === apnGeometry.apn
                      ? areaHighLightColor
                      : areaColor[apnKey],
                  weight: selectedOverE?.apn === apnGeometry.apn ? 3 : 2,
                }}
                eventHandlers={{
                  mouseover: () => handlePolygonOver(apnGeometry),
                  mouseout: () => handlePolygonOut(),
                }}
              >
                <Popup>
                  <PopupBox>
                    <Box>Apron:</Box>
                    <Box>{apnGeometry?.apn}</Box>
                  </PopupBox>
                  {isAdmin && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <LightButton
                        label="Modify"
                        onClick={() => handlePolygonEdit(apnGeometry)}
                      />
                    </Stack>
                  )}
                </Popup>
              </Polygon>
            );
          });
        })}
      </FeatureGroup>
    );
  }, [selectedFeatures, apnShapes, selectedOverE]); // eslint-disable-line

  return <Fragment>{apronShaps}</Fragment>;
}
