import * as yup from "yup";

export const surveyReportStatus = {
  notVerified: "Not Verified",
  accepted: "Accepted",
  inReview: "In Review",
};

export const categoryKeyMap = {
  Approach: "Approach",
  IHP: "IHP",
  LTP_FPAP_GARP: "LTP FPAP GARP",
  Navaids: "Navaids",
  Objects: "Objects",
  PACS_SACS: "PACS SACS",
  Parking_Bay: "Parking Bay",
  RWY_POINTS: "RWY Points",
  RWY_TWY_INTERSECTION: "RWY TWY Intersection",
  Vertical_Significance: "Vertical Significance",
};

export const surveyCategory = {
  approach: "Approach",
  ihp: "IHP",
  ltp_fpap_garp: "LTP_FPAP_GARP",
  navaids: "Navaids",
  objects: "Objects",
  pacs_sacs: "PACS_SACS",
  parking_bay: "Parking_Bay",
  rwy_points: "RWY_POINTS",
  rwy_twy_intersection: "RWY_TWY_INTERSECTION",
  vertical_significance: "Vertical_Significance",
};

export const statusColorMap = {
  "Not Started": "rgba(233, 20, 20, 0.42)",
  "In Review": "rgba(240, 218, 15, 0.53)",
  "In Process": "rgba(240, 218, 15, 0.53)",
  Completed: "rgba(97, 210, 109, 0.58)",
  Closed: "rgba(97, 210, 109, 0.58)",
};

export const surveyComapareTableHeaderName = {
  IHP: "INTERMEDIATE HOLDING POSITION",
  LTP_FPAP_GARP: "LTP, FPAP & GARP",
  Navaids: "NAVIGATIONAL AIDS",
  PACS_SACS: "PACS & SACS",
  Parking_Bay: "PARKING BAY DETAILS",
  RWY_POINTS: "RWY POINTS",
  RWY_TWY_INTERSECTION: "TWY POINTS",
};

export const surveyorCategories = [
  {
    id: 1,
    label: "Approach",
    value: "Approach",
    template: "Approach",
  },
  {
    id: 2,
    label: "IHP",
    value: "IHP",
    template: "IHP",
  },
  {
    id: 3,
    label: "LTP FPAP GARP",
    value: "LTP_FPAP_GARP",
    template: "LTP_FPAP_GARP",
  },
  {
    id: 4,
    label: "Navaids",
    value: "Navaids",
    template: "Navaids",
  },
  {
    id: 5,
    label: "Objects",
    value: "Objects",
    template: "Objects",
  },
  {
    id: 6,
    label: "PACS SACS",
    value: "PACS_SACS",
    template: "PACS_SACS",
  },
  {
    id: 7,
    label: "Parking Bay",
    value: "Parking_Bay",
    template: "Parking_Bay",
  },
  {
    id: 8,
    label: "RWY Points",
    value: "RWY_POINTS",
    template: "RWY_POINTS",
  },
  {
    id: 9,
    label: "RWY TWY Intersection",
    value: "RWY_TWY_INTERSECTION",
    template: "RWY_TWY_INTERSECTION",
  },
  {
    id: 10,
    label: "Vertical Significance",
    value: "Vertical_Significance",
    template: "Vertical_Significance",
  },
];

const phoneRegEx =
  /^((\\+[1-9]{1,4}[\\-]*)|(\\([0-9]{2,3}\\)[\\-]*)|([0-9]{2,4})[\\-]*)*?[0-9]{3,4}?[\\-]*[0-9]{3,4}?$/;
const emailRefEx =
  /^[a-z0-9.]+@(satsure|gmail|yahoo|ymail|hotmail|outlook)\.[a-z]{2,3}$/;
export const createValidationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .matches(emailRefEx, "Enter a valid email")
    .required("Required"),
  phone: yup
    .string("Enter your Phone Number")
    .min(10, "Phone Number min 10 characters")
    .max(10, "Phone Number max 10 characters")
    .matches(phoneRegEx, "Phone Number is not valid")
    .required("Required"),
  password: yup.string("Enter your password").required("Required"),
  surveyairport_id: yup.string().required("Required"),
});

export const createAaiUserValidationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .matches(emailRefEx, "Enter a valid email")
    .required("Required"),
  phone: yup
    .string("Enter your Phone Number")
    .min(10, "Phone Number min 10 characters")
    .max(10, "Phone Number max 10 characters")
    .matches(phoneRegEx, "Phone Number is not valid")
    .required("Required"),
  password: yup.string("Enter your password").required("Required"),
});

export const editAaiUserValidationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Required"),
  phone: yup
    .string("Enter your Phone Number")
    .min(10, "Phone Number min 10 characters")
    .max(10, "Phone Number max 10 characters")
    .matches(phoneRegEx, "Phone Number is not valid")
    .required("Required"),
});

export const editValidationSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter your Phone Number")
    .min(10, "Phone Number min 10 characters")
    .max(10, "Phone Number max 10 characters")
    .matches(phoneRegEx, "Phone Number is not valid")
    .required("Required"),
  surveyairport_id: yup.string().required("Required"),
});

export const masterResetPasswordValidationSchema = yup.object().shape({
  new_password: yup.string("Enter your password").required("Required"),
});

export const requestNewPasswordValidationSchema = yup.object().shape({
  user_email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Required"),
});

export const editReviewSurveyValidationSchema = yup.object().shape({
  top_el: yup.string().required("Required"),
  latitude: yup.string().required("Required"),
  longitude: yup.string().required("Required"),
});
