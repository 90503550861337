import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormHelperText from "@mui/material/FormHelperText";
import { ACCESS_TOKEN } from "../../constants/constant";
import { createAd228ValidationSchema } from "../../constants/ad2Data";
import { uploadAirportChartFile } from "../../models/airport";
import { getAsyncStorageValue } from "../../utils/localStorage";
import StyledTableCell from "../tabel-component/StyledTableCell";
import { PrimaryButton, UploadButton } from "../button";

export default function Ad224ChartsForm({
  selectedAirport,
  allAirportsCharts,
}) {
  const dispatch = useDispatch();
  const isUplodeChartFileRequest = useSelector(
    (state) => state.airport.isUplodeChartFileRequest
  );
  const isLoadingAirportChartList = useSelector(
    (state) => state.survey.isLoadingAirportChartList
  );
  const [chartFile, setChartFile] = useState(null);

  const createAd228Form = useFormik({
    enableReinitialize: true,
    initialValues: {
      file_name: "",
      file: "",
    },
    validationSchema: createAd228ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          uploadAirportChartFile(
            selectedAirport.id,
            chartFile[0],
            { filename: values.file_name },
            token,
            handleSuccessFileUploadCallback
          )
        );
      });
    },
  });

  const handleChartFileUpload = (event) => {
    event.preventDefault();

    const files = event.target.files;
    setChartFile(files);
    createAd228Form.setFieldValue("file", "uploded");
  };

  const handleSuccessFileUploadCallback = () => {
    createAd228Form.resetForm();
    createAd228Form.setSubmitting(false);
    setChartFile(null);
  };

  return (
    <Fragment>
      <form>
        <Stack alignItems="center" sx={{ pl: 2, pr: 2, mt: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="file_name"
            name="file_name"
            label="File Name"
            value={createAd228Form.values.file_name}
            onChange={createAd228Form.handleChange}
            error={
              createAd228Form.touched.file_name &&
              Boolean(createAd228Form.errors.file_name)
            }
            helperText={
              createAd228Form.touched.file_name &&
              createAd228Form.errors.file_name
            }
            sx={{ mb: 2, mt: 2 }}
          />
        </Stack>
      </form>
      <Stack sx={{ pl: 2, pr: 2 }}>
        <UploadButton
          label="Select File"
          accept=".pdf"
          disabled={isUplodeChartFileRequest}
          handleUpload={handleChartFileUpload}
          sx={{ width: 200, mb: 2 }}
        />
        <FormHelperText>
          {chartFile ? chartFile[0].name : ""}
          {createAd228Form.touched.file && createAd228Form.errors.file}
        </FormHelperText>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ pr: 2, mb: 5 }}
      >
        <PrimaryButton
          label="Submit"
          onClick={() => createAd228Form.handleSubmit()}
          isLoading={isUplodeChartFileRequest}
          disabled={isUplodeChartFileRequest}
          sx={{ width: 200 }}
        />
      </Stack>
      {isLoadingAirportChartList && (
        <Stack alignItems="center">Loading...</Stack>
      )}
      {allAirportsCharts.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Chart Name</StyledTableCell>
                <StyledTableCell>URL</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAirportsCharts.map((row, i) => {
                return (
                  <TableRow key={`${i}_${row?.id}`}>
                    <StyledTableCell>{row?.chart_name}</StyledTableCell>
                    <StyledTableCell>
                      <a
                        href={row?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {row?.chart_name}
                      </a>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
}
