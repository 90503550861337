import React, { Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { flatten } from "lodash";
import { ACCESS_TOKEN } from "../../constants/constant";
import { addEditAMDBpolygon, deleteAMDBpolygon } from "../../models/amdb";
import { getAsyncStorageValue } from "../../utils/localStorage";
import ConfirmationDialog from "../ConfirmationDialog";
import AddAmdbShapModal from "./AddAmdbShapModal";

export default function AMDBDrawControl({
  selctedAirport,
  editableLayer,
  editabletype,
  handleCallback,
}) {
  const dispatch = useDispatch();
  const featureGroupRef = useRef();
  const isLoadingAddEditPolygon = useSelector(
    (state) => state.amdb.isLoadingAddEditPolygon
  );
  const isLoadingDeletePolygon = useSelector(
    (state) => state.amdb.isLoadingDeletePolygon
  );
  const [isOpenAddShapDialog, setOpenAddShapDialog] = useState(false);
  const [isOpenEditConfirmDialog, setOpenEditConfirmDialog] = useState(false);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);
  const [newCoords, setNewCoords] = useState([]);
  const [editedCoords, setEditedCoords] = useState([]);
  const coordinates = editableLayer
    ? flatten(editableLayer.coordinates.coordinates)
    : [];
  const coordinateMap = coordinates.map((coords) => {
    return coords.map((coord) => {
      return {
        lng: coord[0],
        lat: coord[1],
      };
    });
  });

  const onCreated = (e) => {
    const geoJOSN = e.layer.toGeoJSON();
    setNewCoords(geoJOSN.geometry.coordinates[0]);
    setOpenAddShapDialog(true);
  };

  const handleCloseAddShapDialog = () => {
    featureGroupRef.current.clearLayers();
    setOpenAddShapDialog(false);
  };

  const onEdited = (e) => {
    let coords = [];
    e.layers.eachLayer(function (layer) {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates;
    });

    setEditedCoords(coords[0]);
    setOpenEditConfirmDialog(true);
  };

  const handleSaveEditDialog = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        addEditAMDBpolygon(
          {
            geo: editedCoords,
            polytype: "MULTIPOLYGON",
            poly_id: editableLayer?.id,
            airport_id: selctedAirport?.id,
            feature: editabletype,
          },
          selctedAirport?.id,
          token,
          handleCloseEditDialog
        )
      );
    });
  };

  const handleCloseEditDialog = () => {
    featureGroupRef.current.clearLayers();
    handleCallback();
    setOpenEditConfirmDialog(false);
  };

  const onDeleted = () => {
    setOpenDeleteConfirmDialog(true);
  };

  const handleSaveDeleteDialog = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAMDBpolygon(
          editableLayer.id,
          editabletype,
          selctedAirport?.id,
          token,
          handleCloseDeleteDialog
        )
      );
    });
  };

  const handleCloseDeleteDialog = () => {
    featureGroupRef.current.clearLayers();
    handleCallback();
    setOpenDeleteConfirmDialog(false);
  };

  return (
    <Fragment>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="bottomright"
          onCreated={onCreated}
          onEdited={onEdited}
          onDeleted={onDeleted}
          draw={{
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: true,
          }}
          edit={{
            edit: true,
            remove: true,
          }}
        />
        {coordinateMap.map((apnPolygonPath) => {
          return (
            <Polygon
              key={`${editabletype}_${editableLayer?.id}`}
              positions={apnPolygonPath}
              pathOptions={{
                fillColor: "#84b3fc",
                color: "#84b3fc",
                weight: 2,
              }}
            />
          );
        })}
      </FeatureGroup>
      {isOpenAddShapDialog && (
        <AddAmdbShapModal
          isOpenAddShapDialog={isOpenAddShapDialog}
          selctedAirport={selctedAirport}
          coords={newCoords}
          handleClose={handleCloseAddShapDialog}
        />
      )}
      {isOpenEditConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenEditConfirmDialog}
          title="Edit"
          content="Are you sure you want to edit this polygon?"
          isLoading={isLoadingAddEditPolygon}
          isDisabled={isLoadingAddEditPolygon}
          handleSave={handleSaveEditDialog}
          handleCancel={handleCloseEditDialog}
        />
      )}
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this polygon?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeletePolygon}
          isDisabled={isLoadingDeletePolygon}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Fragment>
  );
}
