import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import CoordinateInput from "react-coordinate-input";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ACCESS_TOKEN } from "../../constants/constant";
import { editReviewSurveyValidationSchema } from "../../constants/surveyorData";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";
import ReviewDiffTableCell from "../../components/tabel-component/ReviewDiffTableCell";
import { OutlinedButton, PrimaryButton } from "../../components/button";
import {
  getReviewSurveyData,
  selectReviewBackSurveyData,
  postEditReviewData,
} from "../../models/survey";
import { convertToDms } from "../../utils/geocoords";
import { getAsyncStorageValue } from "../../utils/localStorage";

export default function SurveyorReviewList({ reviewBackFeaturesData }) {
  return (
    <div className="surveyor-category-table white-bg">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>AIRPORT NAME</StyledTableCell>
              <StyledTableCell>DATASET TYPE</StyledTableCell>
              <StyledTableCell>DATE AND TIME</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviewBackFeaturesData?.map((row) => {
              return <RowE key={row.id} row={row} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function RowE({ row }) {
  const dispatch = useDispatch();
  const isLoadingReviewBackSurveyData = useSelector(
    (state) => state.survey.isLoadingReviewBackSurveyData
  );
  const isLoadingPostEditReviewData = useSelector(
    (state) => state.survey.isLoadingPostEditReviewData
  );
  const reviewBackSurveyData = useSelector((state) =>
    selectReviewBackSurveyData(state, row.id)
  );
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditDialog, setEditDialog] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const editReviewDataFrom = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      top_el: "",
      latitude: "",
      longitude: "",
    },
    validationSchema: editReviewSurveyValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          postEditReviewData(
            values,
            selectedRow?.id,
            selectedRow?.feature_name,
            token,
            handleCloseEditReviewDialog
          )
        );
      });
    },
  });

  const handleRowExpand = (row) => {
    setOpen(!open);
    setSelectedRow(row);
    if (!open === true) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(getReviewSurveyData(row.id, row.feature_name, token));
      });
    }
  };

  const handleEditReviewData = (payload) => {
    setEditDialog(true);
    setSelectedReview(payload);
    editReviewDataFrom.setValues({
      id: payload.id,
      top_el: payload.top_elev,
      latitude: payload.latitude,
      longitude: payload.longitude,
    });
  };

  const handleCloseEditReviewDialog = () => {
    setSelectedReview(null);
    setEditDialog(false);
    editReviewDataFrom.resetForm();
    editReviewDataFrom.setSubmitting(false);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => handleRowExpand(row)}
      >
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowExpand(row)}
          >
            {open ? <FaAngleUp /> : <FaAngleDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{row.airport_name}</StyledTableCell>
        <StyledTableCell>{row.feature_name}</StyledTableCell>
        <StyledTableCell>{row.created_datetime}</StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>SNO</StyledTableCell>
                    <StyledTableCell>UID</StyledTableCell>
                    <StyledTableCell>POINT NAME</StyledTableCell>
                    <StyledTableCell>LATITUDE</StyledTableCell>
                    <StyledTableCell>LONGITUDE</StyledTableCell>
                    <StyledTableCell>TOP ELEV. IN m. (EGM 08)</StyledTableCell>
                    <StyledTableCell>BASE ELEV. IN m. (EGM 08)</StyledTableCell>
                    <StyledTableCell>ACTION</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRow?.id === row.id && isLoadingReviewBackSurveyData && (
                    <TableRow>
                      <StyledTableCell align="center" colSpan={8}>
                        Loading...
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {reviewBackSurveyData?.map((rRow) => {
                    return (
                      <TableRow key={rRow.id}>
                        <StyledTableCell>{rRow.id}</StyledTableCell>
                        <StyledTableCell>{rRow.uid}</StyledTableCell>
                        <StyledTableCell>{rRow.point_name}</StyledTableCell>
                        <ReviewDiffTableCell
                          isdiff={
                            rRow.issue_columns && rRow.issue_columns.latitude
                              ? rRow.issue_columns.latitude.toString()
                              : ""
                          }
                        >
                          {rRow.latitude}
                        </ReviewDiffTableCell>
                        <ReviewDiffTableCell
                          isdiff={
                            rRow.issue_columns && rRow.issue_columns.longitude
                              ? rRow.issue_columns.longitude.toString()
                              : ""
                          }
                        >
                          {rRow.longitude}
                        </ReviewDiffTableCell>
                        <ReviewDiffTableCell
                          isdiff={
                            rRow.issue_columns && rRow.issue_columns.top_elve
                              ? rRow.issue_columns.top_elve.toString()
                              : ""
                          }
                        >
                          {rRow.top_elev || "-"}
                        </ReviewDiffTableCell>
                        <StyledTableCell>
                          {rRow.base_elev || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            color="primary"
                            onClick={() => handleEditReviewData(rRow)}
                          >
                            <MdEdit />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog fullWidth open={isEditDialog}>
        <DialogTitle>EDIT {selectedReview?.id}</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              id="top_el"
              name="top_el"
              label="TOP ELEV. IN m. (EGM 08)"
              value={editReviewDataFrom.values.top_el}
              onChange={editReviewDataFrom.handleChange}
              error={
                editReviewDataFrom.touched.top_el &&
                Boolean(editReviewDataFrom.errors.top_el)
              }
              helperText={
                editReviewDataFrom.touched.top_el &&
                editReviewDataFrom.errors.top_el
              }
              sx={{ mb: 2, mt: 2 }}
            />
            <CoordinateInput
              style={{
                width: "94%",
                padding: 14,
                fontSize: 20,
              }}
              value={
                selectedReview
                  ? `${convertToDms(
                      selectedReview.dd_latitude
                    )}, ${convertToDms(selectedReview.dd_longitude, true)}`
                  : ""
              }
              onChange={(_, { dd }) => {
                if (dd.length > 0) {
                  editReviewDataFrom.setFieldValue(
                    "latitude",
                    convertToDms(dd[0])
                  );
                  editReviewDataFrom.setFieldValue(
                    "longitude",
                    convertToDms(dd[1], true)
                  );
                } else {
                  editReviewDataFrom.setFieldValue("latitude", "");
                  editReviewDataFrom.setFieldValue("longitude", "");
                }
              }}
            />
            <FormHelperText sx={{ mb: 1 }}>
              Add latitude longitude format ddmmss N/S dddmmss W/E Ex: 04° 08′
              15″ N 162° 03′ 42″ E
            </FormHelperText>
            <FormHelperText
              sx={{ mb: 2 }}
              error={
                editReviewDataFrom.touched.latitude &&
                Boolean(editReviewDataFrom.errors.latitude)
              }
            >
              {editReviewDataFrom.touched.latitude &&
                editReviewDataFrom.errors.latitude}
            </FormHelperText>
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseEditReviewDialog}
            sx={{ width: 120 }}
          />
          <PrimaryButton
            label="Submit"
            onClick={() => editReviewDataFrom.handleSubmit()}
            disabled={isLoadingPostEditReviewData}
            isLoading={isLoadingPostEditReviewData}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
