import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

const Input = styled("input")({
  display: "none",
});

export const PrimaryButton = ({ label, isLoading, ...props }) => (
  <LoadingButton
    variant="contained"
    loading={isLoading}
    sx={{ width: 150 }}
    {...props}
  >
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const LightButton = ({ label, isLoading, ...props }) => (
  <LoadingButton variant="text" loading={isLoading} {...props}>
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const OutlinedButton = ({ label, isLoading, ...props }) => (
  <LoadingButton variant="outlined" loading={isLoading} {...props}>
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const UploadButton = ({
  label,
  isLoading,
  accept,
  handleUpload,
  ...props
}) => {
  const uploadInputRef = useRef(null);

  return (
    <label htmlFor="contained-button-file">
      <Input
        ref={uploadInputRef}
        accept={accept}
        id="contained-button-file"
        type="file"
        onChange={handleUpload}
        disabled={props.disabled}
      />
      <LoadingButton
        variant="contained"
        component="span"
        loading={isLoading}
        {...props}
      >
        <Typography variant="button" sx={{ textTransform: "none" }}>
          {label}
        </Typography>
      </LoadingButton>
    </label>
  );
};
