import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Button from "@mui/material/Button";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<FaArrowLeft />}
      sx={{ mt: -5 }}
      onClick={() => {
        navigate(-1);
      }}
    >
      Back
    </Button>
  );
}
