import React from "react";
import {
  Link,
  useMatch,
  useResolvedPath
} from "react-router-dom";
import styled from "styled-components";

export default function NavLink({ children, to, isActive, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLinkE
      className={match || isActive ? "active-menu" : "in-active-menu"}
      to={to}
      {...props}
    >
      {children}
    </NavLinkE>
  );
}

const NavLinkE = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
`;