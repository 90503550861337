import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { ACCESS_TOKEN } from "../../constants/constant";
import {
  uploadObstacelFile,
  removeSelctedAirportFormCategories,
} from "../../models/airport";
import { generateCRC32Q } from "../../models/userSession";
import { downloadCategoryTemplate } from "../../models/survey";
import { sheetValidation } from "../../utils/sheetValidation";
import StyledTableCell from "../tabel-component/StyledTableCell";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { PrimaryButton, UploadButton } from "../button";

export default function Ad210Form({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const isCRC32QGenerateRequesting = useSelector(
    (state) => state.userSession.isCRC32QGenerateRequesting
  );
  const isUplodeObstacleFileRequest = useSelector(
    (state) => state.airport.isUplodeObstacleFileRequest
  );
  const isDownloadTemplate = useSelector(
    (state) => state.survey.isDownloadTemplate
  );
  const [isObstacleUploading, setObstacleUploading] = useState(false);
  const [obstacleData, setObstacleData] = useState([]);
  const [obstacleFile, setObstacleFile] = useState(null);
  const [CRCcode, setCRCcode] = useState(null);
  const [isOpenCrcPopup, setOpenCrcPopup] = useState(false);

  useEffect(() => {
    if (!CRCcode) return;
    setOpenCrcPopup(true);
  }, [CRCcode]);

  const handleObstacleFileUpload = (event) => {
    event.preventDefault();
    setObstacleUploading(true);

    const files = event.target.files;
    const size = (files[0].size / 1024 / 1024).toFixed(2);
    setObstacleFile(files);

    if (size > 10) {
      toast.error("File should be less than 10 MB.");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws);
      const sheetCoulmns = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const validation = sheetValidation(selectedCategory, sheetCoulmns);
      setObstacleUploading(false);

      if (validation.isValid) {
        setObstacleData(dataParse);
        dispatch(
          generateCRC32Q(files[0], (crcCode) => {
            setCRCcode(crcCode);
          })
        );
      } else {
        setObstacleFile(null);
        toast.error(validation.message);
      }
    };

    reader.readAsBinaryString(files[0]);
  };

  const handleSubmitObstacleFile = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        uploadObstacelFile(
          selectedAirport.id,
          CRCcode,
          obstacleFile[0],
          token,
          handleSuccessFileUploadCallback
        )
      );
    });
  };

  const handleSuccessFileUploadCallback = () => {
    dispatch(removeSelctedAirportFormCategories(selectedCategory));
  };

  const handleDownloadObstacleTemplate = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(downloadCategoryTemplate("Obstacle_template", token));
    });
  };

  return (
    <Fragment>
      {obstacleData.length === 0 && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          mt={5}
        >
          <UploadButton
            label="Upload"
            accept=".xlsx"
            loadingIndicator="Uploading..."
            isLoading={isObstacleUploading || isCRC32QGenerateRequesting}
            disabled={isObstacleUploading || isCRC32QGenerateRequesting}
            handleUpload={handleObstacleFileUpload}
            sx={{ width: 200, mb: 2 }}
          />
          <PrimaryButton
            label="Download Template"
            isLoading={isDownloadTemplate}
            disabled={isDownloadTemplate}
            onClick={handleDownloadObstacleTemplate}
            sx={{ width: 200 }}
          />
        </Stack>
      )}
      {obstacleData && obstacleData.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: 200 }}>
                  RWY /AREA AFFECTED
                </StyledTableCell>
                <StyledTableCell>OBSTACLE TYPE</StyledTableCell>
                <StyledTableCell>Latitude</StyledTableCell>
                <StyledTableCell>Longitude</StyledTableCell>
                <StyledTableCell>Elevation</StyledTableCell>
                <StyledTableCell>MARKING /LGT</StyledTableCell>
                <StyledTableCell sx={{ width: 250 }}>REMARKS</StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  IDENTIFIER
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  Horizontal Datum
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  Vertical Datum
                </StyledTableCell>
                <StyledTableCell sx={{ width: 150 }}>
                  UOM Elevation
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  RWY Direction
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>Airport</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {obstacleData.map((row, i) => {
                return (
                  <TableRow key={`${i}_${row?.IDENTIFIER}`}>
                    <StyledTableCell>
                      {row["RWY /AREA AFFECTED"]}
                    </StyledTableCell>
                    <StyledTableCell>{row["OBSTACLE TYPE"]}</StyledTableCell>
                    <StyledTableCell>{row["Latitude"]}</StyledTableCell>
                    <StyledTableCell>{row["Longitude"]}</StyledTableCell>
                    <StyledTableCell>{row["Elevation"]}</StyledTableCell>
                    <StyledTableCell>{row["MARKING /LGT"]}</StyledTableCell>
                    <StyledTableCell>{row["REMARKS"]}</StyledTableCell>
                    <StyledTableCell>{row["IDENTIFIER"]}</StyledTableCell>
                    <StyledTableCell>{row["Horizontal Datum"]}</StyledTableCell>
                    <StyledTableCell>{row["Vertical Datum"]}</StyledTableCell>
                    <StyledTableCell>{row["UOM Elevation"]}</StyledTableCell>
                    <StyledTableCell>{row["RWY Direction"]}</StyledTableCell>
                    <StyledTableCell>{row["Airport"]}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {obstacleData && obstacleData.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ pr: 2, pt: 2 }}
        >
          <PrimaryButton
            label="Submit"
            isLoading={isUplodeObstacleFileRequest}
            disabled={isUplodeObstacleFileRequest}
            onClick={() => handleSubmitObstacleFile()}
            sx={{ width: 200 }}
          />
        </Stack>
      )}
      <Snackbar
        open={isOpenCrcPopup}
        autoHideDuration={6000}
        message={`CRC32Q code is ${CRCcode}`}
        onClose={() => setOpenCrcPopup(false)}
        action={
          <CopyToClipboard text={CRCcode}>
            <Button color="inherit" size="small">
              Copy
            </Button>
          </CopyToClipboard>
        }
      />
    </Fragment>
  );
}
