import React from 'react';
import Box from '@mui/material/Box';

const ValueItem = ({ value }) => {
  if (!value || value === ' ' || value === '-') {
    value = 'NIL';
  };

  return (
    <Box>{value}</Box>
  );
};

export default ValueItem;