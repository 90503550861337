import React from 'react';
import { Popup } from 'react-leaflet';
import { getObstacleSymbol } from '../../utils/obstacleSymbols';
import PopupBox from '../map/PopupBox';
import PopupItem from '../map/PopupItem';

export default function Area3ObstacleInfoPopup({ info, selctedAirport }) {
  return (
    <Popup>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 1,
          paddingBottom: 1
        }}
      >
        {getObstacleSymbol(info.object_type)}
      </div>
      <PopupBox>
        <PopupItem>AIRPORT</PopupItem>
        <PopupItem>{selctedAirport.name || '-'}</PopupItem>
        <PopupItem>OBJECT</PopupItem>
        <PopupItem>{info.point_name || '-'}</PopupItem>
        <PopupItem>LATITIUDE</PopupItem>
        <PopupItem>{info.latitude || '-'}</PopupItem>
        <PopupItem>LONGITUDE</PopupItem>
        <PopupItem>{info.longitude || '-'}</PopupItem>
        <PopupItem>PENETRATION</PopupItem>
        <PopupItem>{info.penetration || '-'}</PopupItem>
        <PopupItem>PENETRATION ELEVATION</PopupItem>
        <PopupItem>{info.permissible_elevation || '-'}</PopupItem>
        <PopupItem>DIFFRENCE</PopupItem>
        <PopupItem>{info.difference || '-'}</PopupItem>
        <PopupItem>TOP ELEVETION (ft)</PopupItem>
        <PopupItem>{info.top_elev || '-'}</PopupItem>
      </PopupBox>
    </Popup>
  );
};