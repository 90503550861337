import SvgIcon from '@mui/material/SvgIcon';

export default function ChruchIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }} {...props} >
      <svg fill="none">
        <circle cx="11.8947" cy="17.421" r="3.57895" stroke="black" strokeWidth="2" />
        <path d="M11.7368 13.6316V4" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M6.99998 8.42102L16.6316 8.42102" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      </svg>
    </SvgIcon>
  )
};