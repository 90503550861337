import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdPreview } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { IoSendSharp } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { ACCESS_TOKEN, roles } from "../constants/constant";
import { statusColorMap } from "../constants/surveyorData";
import {
  getAllNewAirports,
  changeStatusAirport,
  exportXMLAD2Data,
  deleteReviewAirport,
} from "../models/airport";
import { AirportStatus, ad2ExportCategoryTypes } from "../constants/ad2Data";
import { getAsyncStorageValue } from "../utils/localStorage";
import StyledTableCell from "./tabel-component/StyledTableCell";
import TableRowLoader from "./tabel-component/TableRowLoader";
import AirportExportButton from "./AirportExportButton";
import ConfirmationDialog from "./ConfirmationDialog";

export default function AirportReviewListTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoadingAllAirports,
    isLoadingAirportStatusChange,
    isLoadingDeleteReviewAirport,
    isExportXMLAD2Loading,
    allAirports: allNewAirports,
  } = useSelector((state) => state.airport);
  const [isOpenFinishDialog, setOpenFinishDialog] = useState(false);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const user = useSelector((state) => state.userSession.user);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const isAdmin = user?.role === roles.admin;
  const isAAIUser = user?.role === roles.aai;

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllNewAirports(token));
    });
  }, []); // eslint-disable-line

  const handleActionEdit = (airport) => {
    if (!isAAIUser || airport.status === AirportStatus.closed) return;

    if (airport.status === AirportStatus.notStarted) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          changeStatusAirport(
            {
              airport_id: airport.id,
              status: AirportStatus.inProcess,
            },
            token,
            handleCloseConfirmDialog
          )
        );
      });
    }

    navigate(`add-airport-data?airportId=${airport.id}`);
  };

  const handleActionReview = (airport) => {
    if (!isAdmin) return;

    navigate(`/review-airport-data/${airport.id}`);
  };

  const handleActionDelete = (airport) => {
    if (!isAdmin) return;

    setSelectedAirport(airport);
    setOpenDeleteDialog(true);
  };

  const handleActionFinished = (airport) => {
    setSelectedAirport(airport);
    setOpenFinishDialog(true);
  };

  const handleSaveConfirmDialog = () => {
    if (selectedAirport.status === AirportStatus.inProcess) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          changeStatusAirport(
            {
              airport_id: selectedAirport.id,
              status: AirportStatus.closed,
            },
            token,
            handleCloseConfirmDialog
          )
        );
      });
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenFinishDialog(false);
  };

  const handleExportXML = (type, airport) => {
    setSelectedAirport(airport);
    dispatch(exportXMLAD2Data(type, airport));
  };

  const handleCloseReviewAirportDialog = () => {
    setSelectedAirport(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteReviewAirport = () => {
    dispatch(
      deleteReviewAirport(
        selectedAirport.id,
        selectedAirport.version_id,
        handleCloseReviewAirportDialog
      )
    );
  };

  return (
    <Fragment>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>AIRPORT NAME</StyledTableCell>
              <StyledTableCell align="center">AIRPORT CODE</StyledTableCell>
              <StyledTableCell>EFFECTIVE DATE</StyledTableCell>
              <StyledTableCell>PUBLISHED DATE</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell align="center">
                {isAdmin ? "REVIEW DATA" : "ENTER DATA"}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowLoader
              isLoading={isLoadingAllAirports}
              data={allNewAirports}
              colSpan={6}
            />
            {allNewAirports?.map((airport) => {
              return (
                <TableRow key={airport.id} onClick={() => {}} tabIndex={-1}>
                  <TableCell>{airport.name}</TableCell>
                  <TableCell align="center">{airport.designator}</TableCell>
                  <TableCell>{airport.effective_date}</TableCell>
                  <TableCell>{airport.published_date}</TableCell>
                  <TableCell>
                    <Box
                      component="span"
                      sx={{
                        p: 1,
                        backgroundColor: statusColorMap[airport.status],
                        color: "#ffffff",
                        fontWeight: "bold",
                        borderRadius: 2,
                      }}
                    >
                      {airport.status}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {isAAIUser && (
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          disabled={airport.status === AirportStatus.closed}
                          onClick={() => handleActionEdit(airport)}
                        >
                          <AiFillEdit size={22} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isAdmin && (
                      <Tooltip title="Review">
                        <IconButton
                          color="primary"
                          disabled={airport.status === AirportStatus.closed}
                          onClick={() => handleActionReview(airport)}
                        >
                          <MdPreview size={22} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isAAIUser && (
                      <Tooltip title="Finish">
                        <IconButton
                          color="primary"
                          disabled={airport.status === AirportStatus.closed}
                          onClick={() => handleActionFinished(airport)}
                        >
                          <IoSendSharp size={22} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <AirportExportButton
                      items={ad2ExportCategoryTypes}
                      selectedAirport={selectedAirport}
                      airport={airport}
                      handleExportXML={(type) => handleExportXML(type, airport)}
                      isExportXMLAD2Loading={isExportXMLAD2Loading}
                    />
                    {isAdmin && (
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleActionDelete(airport)}
                        >
                          <MdDelete color="#d60000" size={22} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        fullWidth
        open={isOpenFinishDialog}
        title="Review Request"
        content={`Are you sure you want to finish airport "${selectedAirport?.name}"?`}
        saveButtonLabel="Finish"
        isLoading={isLoadingAirportStatusChange}
        isDisabled={isLoadingAirportStatusChange}
        handleSave={handleSaveConfirmDialog}
        handleCancel={handleCloseConfirmDialog}
      />
      <ConfirmationDialog
        fullWidth
        open={isOpenDeleteDialog}
        title="Delete Request"
        saveButtonColor="error"
        content={`Are you sure you want to delete airport "${selectedAirport?.name}" ?`}
        saveButtonLabel="Delete"
        isLoading={isLoadingDeleteReviewAirport}
        isDisabled={isLoadingDeleteReviewAirport}
        handleSave={handleDeleteReviewAirport}
        handleCancel={handleCloseReviewAirportDialog}
      />
    </Fragment>
  );
}
