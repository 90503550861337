import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export default function RestrictedAirspaceDetail({
  isLoadingRestrictiveAirspaceDetails,
  restrictiveAirspaceDetails
}) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {
            isLoadingRestrictiveAirspaceDetails && (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  Loading...
                </TableCell>
              </TableRow>
            )
          }
          {!isLoadingRestrictiveAirspaceDetails && Object.keys(restrictiveAirspaceDetails).length === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={2}>
                No Record Found.
              </TableCell>
            </TableRow>
          )}
          {
            Object.keys(restrictiveAirspaceDetails).map((key, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{restrictiveAirspaceDetails[key]}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};