import { TileLayer, LayersControl } from "react-leaflet";
import GoogleMapLayer from "./GoogleMapLayer";

const { BaseLayer } = LayersControl;

export default function MapLayers() {
  return (
    <LayersControl position="topleft">
      <BaseLayer checked name="Google Maps Roads">
        <GoogleMapLayer />
      </BaseLayer>
      <BaseLayer name="Google Maps Satellite">
        <GoogleMapLayer maptype="satellite" />
      </BaseLayer>
      <BaseLayer name="Google Maps Terrain">
        <GoogleMapLayer maptype="terrain" />
      </BaseLayer>
      <BaseLayer name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
    </LayersControl>
  );
}
