import { createSlice, createSelector } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { chain, orderBy, isEmpty, uniqBy, remove } from "lodash";
import { apiCall, downloadReadableStreamFile } from "../utils/connect";
import { eTodAirportList } from "../constants/constant";
import { OLSOptionList, ofzOptionLabel } from "../constants/etodData";
import {
  GET_ALL_ETOD_AIRPORT_URL,
  GET_ETOD_DETAIL_URL,
  GET_ETOD_OBSTACLES_DATA_URL,
  GET_ETOD_OLS_DATA_URL,
  GET_ETOD_AREA_1_URL,
  GET_RASTER_AREA_URL,
  GET_ETOD_OLS_OBSTACLES_DATA_URL,
  GET_ETOD_AREA_1_OBSTACLES_URL,
  GET_AREA_THREE_OBSTACLES_URL,
  EXPORT_OBSTACLES_URL,
} from "../constants/urls";
import { colorMap, darkColor } from "../constants/colors";

const initialState = {
  isLoadingEtodAirport: false,
  isLoadingEtodDetails: false,
  isLoadingEtodObstacles: false,
  isLoadingEtodOLS: false,
  isLoadingArea1: false,
  etodAllAirports: [],
  obstaclesList: [],
  etodDetails: null,
  olsDetails: null,
  area1: null,
  isLoadingRasterAreas: false,
  rasterAreaList: null,
  isLoadingEtodOLSObstacles: false,
  OLSobstaclesList: [],
  isLoadingArea1Obstacles: false,
  area1Obstacles: [],
  isLoadingAreaThreeObstacles: false,
  areaThreeObstacles: [],
  isLoadingExportObstacle: false,
};

export const etod = createSlice({
  name: "etod",
  initialState,
  reducers: {
    setEtodAirportLoadingRequest: (state, action) => {
      state.isLoadingEtodAirport = action.payload;
    },
    setEtodDetailLoadingRequest: (state, action) => {
      state.isLoadingEtodDetails = action.payload;
    },
    setLoadingEtodObstaclesLoadingRequest: (state, action) => {
      state.isLoadingEtodObstacles = action.payload;
    },
    setEtodOLSLoadingRequest: (state, action) => {
      state.isLoadingEtodOLS = action.payload;
    },
    setEtodArea1LoadingRequest: (state, action) => {
      state.isLoadingArea1 = action.payload;
    },
    setAllEtodAirport: (state, action) => {
      state.etodAllAirports = action.payload;
    },
    setEtodDetails: (state, action) => {
      state.etodDetails = action.payload;
    },
    setAllObstaclesList: (state, action) => {
      state.obstaclesList = action.payload.Obstacles_Data_List;
    },
    setOLSDetails: (state, action) => {
      state.olsDetails = action.payload;
    },
    setArea1Details: (state, action) => {
      state.area1 = action.payload;
    },
    setLoadingRasterAreas: (state, action) => {
      state.isLoadingRasterAreas = action.payload;
    },
    setRasterAreaList: (state, action) => {
      state.rasterAreaList = isEmpty(action.payload) ? null : action.payload;
    },
    setLoadingEtodOLSObstacles: (state, action) => {
      state.isLoadingEtodOLSObstacles = action.payload;
    },
    setOLSobstaclesList: (state, action) => {
      state.OLSobstaclesList = action.payload;
    },
    setLoadingArea1Obstacles: (state, action) => {
      state.isLoadingArea1Obstacles = action.payload;
    },
    setArea1Obstacles: (state, action) => {
      state.area1Obstacles = action.payload;
    },
    setLoadingAreaThreeObstacles: (state, action) => {
      state.isLoadingAreaThreeObstacles = action.payload;
    },
    setAreaThreeObstacles: (state, action) => {
      state.areaThreeObstacles = action.payload;
    },
    setLoadingExportObstacle: (state, action) => {
      state.isLoadingExportObstacle = action.payload;
    },
  },
});

export const {
  setEtodAirportLoadingRequest,
  setEtodDetailLoadingRequest,
  setLoadingEtodObstaclesLoadingRequest,
  setEtodOLSLoadingRequest,
  setEtodArea1LoadingRequest,
  setAllEtodAirport,
  setEtodDetails,
  setAllObstaclesList,
  setOLSDetails,
  setArea1Details,
  setLoadingRasterAreas,
  setRasterAreaList,
  setLoadingEtodOLSObstacles,
  setOLSobstaclesList,
  setLoadingArea1Obstacles,
  setArea1Obstacles,
  setLoadingAreaThreeObstacles,
  setAreaThreeObstacles,
  setLoadingExportObstacle,
} = etod.actions;

export default etod.reducer;

export const selectEtodAirports = (state) => {
  const etodAirports = state.etod.etodAllAirports;

  if (etodAirports && etodAirports.length > 0) {
    return chain(etodAirports)
      .filter((airport) => {
        return eTodAirportList.includes(airport.name);
      })
      .orderBy(["name"], ["asc"])
      .value();
  }

  return [];
};

export const selectEtodAreaList = (state) => {
  const etodDetails = state.etod.etodDetails;

  if (
    etodDetails &&
    etodDetails.Merged_area_List &&
    etodDetails.Merged_area_List.length > 0
  ) {
    return orderBy(etodDetails.Merged_area_List, ["rwy_area"], ["asc"]);
  }

  return [];
};

export const selectEtodAreaListData = (state) => {
  const etodDetails = state.etod.etodDetails;

  if (
    etodDetails &&
    etodDetails.Area_List &&
    etodDetails.Area_List.length > 0
  ) {
    return orderBy(etodDetails.Area_List, ["rwy_area"], ["asc"]);
  }

  return [];
};

export const selectOLSApproachList = (state) => {
  const olsDetail = state.etod.olsDetails;

  if (olsDetail?.OLS_Approach_List && olsDetail.OLS_Approach_List.length > 0) {
    return olsDetail.OLS_Approach_List;
  }

  return [];
};

export const selectOLSHorizontalSurfaceList = (state) => {
  const olsDetail = state.etod.olsDetails;

  if (
    olsDetail?.OLS_HorizontalSurface_List &&
    olsDetail.OLS_HorizontalSurface_List.length > 0
  ) {
    return olsDetail.OLS_HorizontalSurface_List;
  }

  return [];
};

export const selectOLSTransitionalSurfaceList = (state) => {
  const olsDetail = state.etod.olsDetails;

  if (
    olsDetail?.OLS_TransitionalSurface_List &&
    olsDetail.OLS_TransitionalSurface_List.length > 0
  ) {
    return olsDetail.OLS_TransitionalSurface_List;
  }

  return [];
};

export const selectOLSObstacleFreeZoneList = (state) => {
  const olsDetail = state.etod.olsDetails;

  if (
    olsDetail?.OLS_ObstacleFreeZone_List &&
    olsDetail.OLS_ObstacleFreeZone_List.length > 0
  ) {
    return olsDetail.OLS_ObstacleFreeZone_List;
  }

  return [];
};

export const selectOLSRwyStripList = (state) => {
  const olsDetail = state.etod.olsDetails;

  if (olsDetail?.OLS_RwyStrip_List && olsDetail.OLS_RwyStrip_List.length > 0) {
    return olsDetail.OLS_RwyStrip_List;
  }

  return [];
};

export const selectOLSTakeOffClimbList = (state) => {
  const olsDetail = state.etod.olsDetails;

  if (
    olsDetail?.OLS_TakeOffClimb_List &&
    olsDetail.OLS_TakeOffClimb_List.length > 0
  ) {
    return olsDetail.OLS_TakeOffClimb_List;
  }

  return [];
};

export const selectArea1List = (state) => {
  const area1Detail = state.etod.area1;

  if (area1Detail?.Areas_List && area1Detail.Areas_List.length > 0) {
    return area1Detail.Areas_List;
  }

  return [];
};

export const selectRasterAreaOption = (state) => {
  const rasterAreaList = state.etod.rasterAreaList;

  if (rasterAreaList) {
    const rasterOptions = uniqBy(rasterAreaList, "area_type").map((area, i) => {
      return {
        id: area.id,
        label: `${area.area_type} DEM`,
        value: area.area_type,
        color: "#262261",
      };
    });

    return orderBy(rasterOptions, ["label"], ["asc"]);
  }

  return null;
};

export const selectRasterAreaList = (state) => {
  const rasterAreaList = state.etod.rasterAreaList;

  if (rasterAreaList) {
    return rasterAreaList;
  }

  return null;
};

const selectedAirport = (state, selectedAirport) => selectedAirport;
export const selectAreaOptions = createSelector(
  [selectEtodAreaList, selectRasterAreaList, selectedAirport],
  (areaList, demAreaList, airport) => {
    const parentNode = [];

    if (areaList.length > 0) {
      parentNode.push({
        id: 1,
        name: "Areas",
        value: "areas",
        color: "#27265f",
        type: "area",
        sub: [],
      });
    }

    if (demAreaList) {
      parentNode.push({
        id: 2,
        name: "DEM Areas",
        value: "dem_areas",
        color: "#27265f",
        type: "dem_area",
        sub: [],
      });
    }

    if (airport && airport.A2D_coordinates?.coordinates) {
      parentNode.push({
        id: 3,
        name: "Area 2D",
        value: "area_2d",
        color: "#e5c614",
        type: "area2d",
        sub: [],
      });
    }

    const areaOptions = areaList.map((area, i) => {
      return {
        id: "area" + i,
        name: area.rwy_area,
        value: area.rwy_area,
        color: colorMap[i],
      };
    });

    const demAreasOptions = uniqBy(demAreaList, "area_type")?.map((dem, i) => {
      return {
        id: "dem" + i,
        name: `${dem.area_type} DEM`,
        value: dem.area_type,
        color: "#27265f",
      };
    });

    const areaFeatures = parentNode.map((af) => {
      if (af.value === "areas") {
        af.sub = orderBy(areaOptions, ["name"], ["asc"]);
      }
      if (af.value === "dem_areas") {
        af.sub = orderBy(demAreasOptions, ["name"], ["asc"]);
      }

      return af;
    });

    return orderBy(areaFeatures, ["id"], ["asc"]);
  }
);

const olsDetails = (state) => state.etod.olsDetails;
export const selectOLSOptions = createSelector([olsDetails], (olsDetails) => {
  const approachlist = olsDetails?.OLS_Approach_List || [];
  const horizontalSurfaceList = olsDetails?.OLS_HorizontalSurface_List || [];
  const transitionalSurfacelist =
    olsDetails?.OLS_TransitionalSurface_List || [];
  const rwyStriplist = olsDetails?.OLS_RwyStrip_List || [];
  const takeOffClimblist = olsDetails?.OLS_TakeOffClimb_List || [];
  const obstacleFreeZonelist = olsDetails?.OLS_ObstacleFreeZone_List || [];

  const approachOptions = uniqBy(approachlist, "rwy_designator").map(
    (approach, i) => {
      return {
        id: "approach" + i,
        name: approach.rwy_designator,
        value: `Approach_${approach.rwy_designator}`,
        color: darkColor[i],
      };
    }
  );
  const hslOptions = horizontalSurfaceList.map((hsl, i) => {
    return {
      id: "hsl" + i,
      name: hsl.type,
      value: hsl.type,
      color: darkColor[i + approachOptions.length],
    };
  });
  const tslOptions = uniqBy(transitionalSurfacelist, "rwy_designator").map(
    (tsl, i) => {
      return {
        id: "tsl" + i,
        name: tsl.rwy_designator,
        value: `${tsl.type}_${tsl.rwy_designator}`,
        color: darkColor[i + approachOptions.length + hslOptions.length],
      };
    }
  );
  const rwyOptions = uniqBy(rwyStriplist, "rwy_designator").map((rwy, i) => {
    return {
      id: "rwy" + i,
      name: rwy.rwy_designator,
      value: `RWY STRIP_${rwy.rwy_designator}`,
      color:
        darkColor[
          i + approachOptions.length + hslOptions.length + tslOptions.length
        ],
    };
  });
  const takeOffOptions = uniqBy(takeOffClimblist, "rwy_designator").map(
    (takeOff, i) => {
      return {
        id: "takeOff" + i,
        name: takeOff.rwy_designator,
        value: `TAKE OFF_${takeOff.rwy_designator}`,
        color:
          darkColor[
            i +
              approachOptions.length +
              hslOptions.length +
              tslOptions.length +
              rwyOptions.length
          ],
      };
    }
  );
  const ofzOptions = uniqBy(obstacleFreeZonelist, "type").map((ofz, i) => {
    return {
      id: "ofz" + i,
      name: ofzOptionLabel[ofz.type],
      value: `OFZ_${ofz.type}_${ofz.rwy_designator}`,
      color:
        darkColor[
          i +
            approachOptions.length +
            hslOptions.length +
            tslOptions.length +
            rwyOptions.length +
            takeOffOptions.length
        ],
    };
  });

  const OLSFeatures = OLSOptionList.map((ols) => {
    if (ols.value === "OLS_Approach_List") {
      ols.sub = orderBy(approachOptions, ["name"], ["asc"]);
    }
    if (ols.value === "OLS_HorizontalSurface_List") {
      ols.sub = orderBy(hslOptions, ["name"], ["asc"]);
    }
    if (ols.value === "Transitional_Surface") {
      ols.sub = orderBy(tslOptions, ["name"], ["asc"]);
    }
    if (ols.value === "OLS_RwyStrip_List") {
      ols.sub = orderBy(rwyOptions, ["name"], ["asc"]);
    }
    if (ols.value === "OLS_TakeOffClimb_List") {
      ols.sub = orderBy(takeOffOptions, ["name"], ["asc"]);
    }
    if (ols.value === "OLS_ObstacleFreeZone_List") {
      ols.sub = orderBy(ofzOptions, ["name"], ["asc"]);
    }

    return ols;
  });

  if (obstacleFreeZonelist.length === 0) {
    remove(OLSFeatures, (f) => {
      return f.value === "OLS_ObstacleFreeZone_List";
    });
  }

  return OLSFeatures;
});

export const getEtodAllAirport = () => (dispatch) => {
  try {
    dispatch(setEtodAirportLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllEtodAirport(response.data));
      dispatch(setEtodAirportLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setEtodAirportLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_ETOD_AIRPORT_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setEtodAirportLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodDetails = (airportId) => (dispatch) => {
  try {
    dispatch(setEtodDetailLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setEtodDetails(response.data));
      dispatch(setEtodDetailLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setEtodDetailLoadingRequest(false));
    };

    apiCall("GET", GET_ETOD_DETAIL_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setEtodDetailLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodObstaclesData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingEtodObstaclesLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllObstaclesList(response.data));
      dispatch(setLoadingEtodObstaclesLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEtodObstaclesLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_ETOD_OBSTACLES_DATA_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingEtodObstaclesLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodOLSData = (airportId) => (dispatch) => {
  try {
    dispatch(setEtodOLSLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setOLSDetails(response.data));
      dispatch(setEtodOLSLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setEtodOLSLoadingRequest(false));
    };

    apiCall("GET", GET_ETOD_OLS_DATA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setEtodOLSLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodArea1 = () => (dispatch) => {
  try {
    dispatch(setEtodArea1LoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setArea1Details(response.data));
      dispatch(setEtodArea1LoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setEtodArea1LoadingRequest(false));
    };

    apiCall("GET", GET_ETOD_AREA_1_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setEtodArea1LoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodArea1Obstacles = () => (dispatch) => {
  try {
    dispatch(setLoadingArea1Obstacles(true));

    const onSuccess = (response) => {
      dispatch(setArea1Obstacles(response.data));
      dispatch(setLoadingArea1Obstacles(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingArea1Obstacles(false));
    };

    apiCall("GET", GET_ETOD_AREA_1_OBSTACLES_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingArea1Obstacles(false));
    toast.error(error.message);
  }
};

export const getRasterAreas = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingRasterAreas(true));

    const onSuccess = (response) => {
      dispatch(setRasterAreaList(response.data));
      dispatch(setLoadingRasterAreas(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingRasterAreas(false));
    };

    apiCall("GET", GET_RASTER_AREA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingRasterAreas(false));
    toast.error(error.message);
  }
};

export const getEtodOLSobstaclesData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingEtodOLSObstacles(true));

    const onSuccess = (response) => {
      dispatch(setOLSobstaclesList(response.data));
      dispatch(setLoadingEtodOLSObstacles(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEtodOLSObstacles(false));
    };

    apiCall(
      "GET",
      GET_ETOD_OLS_OBSTACLES_DATA_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingEtodOLSObstacles(false));
    toast.error(error.message);
  }
};

export const getEtodAreaThreeObstacles = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingAreaThreeObstacles(true));

    const onSuccess = (response) => {
      dispatch(setAreaThreeObstacles(response.data));
      dispatch(setLoadingAreaThreeObstacles(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAreaThreeObstacles(false));
    };

    apiCall(
      "GET",
      GET_AREA_THREE_OBSTACLES_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAreaThreeObstacles(false));
    toast.error(error.message);
  }
};

export const exportObstacleData = (type, airport) => (dispatch) => {
  try {
    dispatch(setLoadingExportObstacle(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename ? filename : `${type}.xls`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingExportObstacle(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingExportObstacle(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_OBSTACLES_URL(airport?.id, type),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingExportObstacle(false));
    toast.error(error.message);
  }
};
