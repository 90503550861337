import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { ACCESS_TOKEN, AES_KEY } from "../../constants/constant";
import { categoryKeyMap, statusColorMap } from "../../constants/surveyorData";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";
import TableRowLoader from "../../components/tabel-component/TableRowLoader";
import { LightButton } from "../../components/button";
import {
  getAllSurveyReport,
  setSelectedSurveyReport,
  selectAllSurveyReports,
  exportReviewReport,
} from "../../models/survey";
import { decryptStatic } from "../../utils/decodeEncodeData";
import {
  getAsyncStorageValue,
  getStorageValue,
} from "../../utils/localStorage";

export default function SurveyorReportList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoadingAllSurveyReport = useSelector(
    (state) => state.survey.isLoadingAllSurveyReport
  );
  const isExportReviewReportLoading = useSelector(
    (state) => state.survey.isExportReviewReportLoading
  );
  const allSurveyReports = useSelector(selectAllSurveyReports);
  const aesKey = getStorageValue(AES_KEY);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllSurveyReport(token));
    });
  }, []); // eslint-disable-line

  const handleReport = (report) => {
    dispatch(setSelectedSurveyReport(report));
    navigate(`/data-management/surveyor-report-list/${report.id}`, {
      state: report,
    });
  };

  const handleExportDataset = (id, type) => {
    dispatch(exportReviewReport(id, type));
  };

  return (
    <div className="surveyor-category-table white-bg">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>SURVEYOR NAME</StyledTableCell>
              <StyledTableCell>AIRPORT NAME</StyledTableCell>
              <StyledTableCell>DATASET TYPE</StyledTableCell>
              <StyledTableCell>DATE AND TIME</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>EXPORT</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowLoader
              isLoading={isLoadingAllSurveyReport}
              data={allSurveyReports}
              colSpan={5}
            />
            {allSurveyReports?.map((report) => {
              return (
                <TableRow key={report.id} tabIndex={-1}>
                  <TableCell onClick={() => handleReport(report)}>
                    {decryptStatic(report.user_name, aesKey)}
                  </TableCell>
                  <TableCell onClick={() => handleReport(report)}>
                    {report.airport_name}
                  </TableCell>
                  <TableCell onClick={() => handleReport(report)}>
                    {categoryKeyMap[report.feature_name]}
                  </TableCell>
                  <TableCell onClick={() => handleReport(report)}>
                    {report.created_datetime}
                  </TableCell>
                  <TableCell onClick={() => handleReport(report)}>
                    <Box
                      component="span"
                      sx={{
                        p: 1,
                        backgroundColor: statusColorMap[report.status],
                        color: "#ffffff",
                        fontWeight: "bold",
                        borderRadius: 2,
                      }}
                    >
                      {report.status}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {categoryKeyMap[report.feature_name] ===
                      categoryKeyMap.Objects &&
                      report.status === "Completed" && (
                        <LightButton
                          style={{ zIndex: 99 }}
                          label="Download"
                          onClick={() =>
                            handleExportDataset(
                              report.id,
                              categoryKeyMap[report.feature_name]
                            )
                          }
                          isLoading={isExportReviewReportLoading}
                          disabled={isExportReviewReportLoading}
                          sx={{ p: 0 }}
                        />
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
