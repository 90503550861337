import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  surveyCategory,
  surveyComapareTableHeaderName,
  surveyReportStatus,
} from "../../constants/surveyorData";
import { ACCESS_TOKEN } from "../../constants/constant";
import CompareReviewDataTable from "../../components/surveyor-review-table/CompareReviewDataTable";
import ApproachReviewTable from "../../components/surveyor-review-table/ApproachReviewTable";
import VerticalSignificanceReviewTable from "../../components/surveyor-review-table/VerticalSignificanceReviewTable";
import {
  comparedata,
  postSurveyVerification,
  setSelectedSurveyReport,
} from "../../models/survey";
import { getAsyncStorageValue } from "../../utils/localStorage";

export default function ReviewSurveyData() {
  const dispatch = useDispatch();
  const routeState = useLocation();
  const isLoadingVerification = useSelector(
    (state) => state.survey.isLoadingVerification
  );
  const selectedReport = useSelector((state) => state.survey.selectedReport);
  const isLoadingCompareData = useSelector(
    (state) => state.survey.isLoadingCompareData
  );
  const surveyReportDetail = useSelector((state) =>
    state.survey.comparedata ? state.survey.comparedata : []
  );
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const { state } = routeState;
    dispatch(setSelectedSurveyReport(state));
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        comparedata(
          state.airport_id,
          state.feature_name,
          state.user_id,
          state.id,
          token
        )
      );
    });
  }, [routeState]); // eslint-disable-line

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filter(surveyReportDetail, [
        "verification1",
        surveyReportStatus.notVerified,
      ]).map((n) => n.id1);

      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleAccept = () => {
    const acceptedPayload = selected.map((id) => ({
      id,
      latitude: false,
      longitude: false,
      top_elve: false,
    }));

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        postSurveyVerification(
          {
            status: surveyReportStatus.accepted,
            issue_data: acceptedPayload,
          },
          selectedReport,
          token,
          handleCallback
        )
      );
    });
  };

  const handleReview = (payload) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        postSurveyVerification(
          {
            status: surveyReportStatus.inReview,
            issue_data: payload,
          },
          selectedReport,
          token,
          handleCallback
        )
      );
    });
  };

  const handleCallback = () => {
    setSelected([]);
  };

  return (
    <div className="surveyor-category-table white-bg">
      {selectedReport?.feature_name === surveyCategory.approach && (
        <ApproachReviewTable
          isLoadingCompareData={isLoadingCompareData}
          isLoadingVerification={isLoadingVerification}
          selectedReport={selectedReport}
          surveyReportDetail={surveyReportDetail}
          selected={selected}
          handleSelectAllClick={handleSelectAllClick}
          handleClick={handleClick}
          handleAccept={handleAccept}
          handleReview={handleReview}
        />
      )}
      {selectedReport?.feature_name !== surveyCategory.vertical_significance &&
        selectedReport?.feature_name !== surveyCategory.approach && (
          <CompareReviewDataTable
            isLoadingCompareData={isLoadingCompareData}
            isLoadingVerification={isLoadingVerification}
            selectedReport={selectedReport}
            surveyReportDetail={surveyReportDetail}
            selected={selected}
            headerName={
              surveyComapareTableHeaderName[selectedReport?.feature_name]
            }
            handleSelectAllClick={handleSelectAllClick}
            handleClick={handleClick}
            handleAccept={handleAccept}
            handleReview={handleReview}
          />
        )}
      {selectedReport?.feature_name ===
        surveyCategory.vertical_significance && (
        <VerticalSignificanceReviewTable
          isLoadingCompareData={isLoadingCompareData}
          isLoadingVerification={isLoadingVerification}
          selectedReport={selectedReport}
          surveyReportDetail={surveyReportDetail}
          selected={selected}
          handleSelectAllClick={handleSelectAllClick}
          handleClick={handleClick}
          handleAccept={handleAccept}
          handleReview={handleReview}
        />
      )}
    </div>
  );
}
