import React, { Fragment, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { areaHighLightColor } from '../../constants/colors';

export default function RenderArea2D({
  selctedAirport,
  getAreaColors
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const a2dcoordinates = selctedAirport.A2D_coordinates?.coordinates || [];
  const areaColor = getAreaColors();

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {
        a2dcoordinates.map((coordinate, i) => {
          const areaCoordinateMap = coordinate.map((a2dCoords) => {
            return {
              lng: a2dCoords[0],
              lat: a2dCoords[1]
            };
          });

          return (
            <Polygon
              key={i}
              positions={areaCoordinateMap}
              pathOptions={{
                fillColor: areaColor['area_2d'],
                color: selectedOverE?.areaA2D ? areaHighLightColor : areaColor['area_2d'],
                weight: selectedOverE?.areaA2D ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: () => handlePolygonOver({ areaA2D: true }),
                mouseout: () => handlePolygonOut()
              }}
            />
          )
        })
      }
    </Fragment>
  );
};