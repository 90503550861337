import React, { Fragment, useMemo, useState } from "react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { useSelector } from "react-redux";
import MapLayers from "../map/MapLayers";
import ControlMapCenter from "../map/ControlMapCenter";
import MeasurementControl from "../map/MeasurementControl";
import FullScreenControl from "../map/FullScreenControl";
import { roles } from "../../constants/constant";
import RenderApnShape from "./RenderApnShape";
import RenderRunWayShape from "./RenderRunWayShape";
import RenderTwyShape from "./RenderTwyShape";
import RenderParkingBayLine from "./RenderParkingBayLine";
import RenderParkingBayPoints from "./RenderParkingBayPoints";
import RenderRwyElementMarking from "./RenderRwyElementMarking";
import AMDBDrawControl from "./AMDBDrawControl";

export default function OSMMapContainer({
  airportLocation,
  zoomLevel,
  selctedAirport,
  selectedFeatures,
  apnElementList,
  rwyElementList,
  twyElementList,
  parkingBayLineList,
  parkingBayPointsList,
  rwyElementMarkingList,
  handleDrawCallback,
}) {
  const [editableLayer, setEditableLayer] = useState(null);
  const [editabletype, setEditableType] = useState(null);
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;

  const handleEditShap = (data, type) => {
    setEditableType(type);
    setEditableLayer(data);
  };

  const handleDrawControlCallback = () => {
    setEditableType(null);
    setEditableLayer(null);
    handleDrawCallback();
  };

  const amdbMapE = useMemo(() => {
    return (
      <MapContainer
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        <RenderApnShape
          selectedFeatures={selectedFeatures}
          apnElementList={apnElementList}
          handleEditShap={handleEditShap}
        />
        <RenderRunWayShape
          selectedFeatures={selectedFeatures}
          rwyElementList={rwyElementList}
          handleEditShap={handleEditShap}
        />
        <RenderTwyShape
          selectedFeatures={selectedFeatures}
          twyElementList={twyElementList}
          handleEditShap={handleEditShap}
        />
        <RenderParkingBayLine
          selectedFeatures={selectedFeatures}
          parkingBayLineList={parkingBayLineList}
        />
        <RenderParkingBayPoints
          selectedFeatures={selectedFeatures}
          parkingBayPointsList={parkingBayPointsList}
        />
        <RenderRwyElementMarking
          selectedFeatures={selectedFeatures}
          rwyElementMarkingList={rwyElementMarkingList}
        />
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        <FullScreenControl />
        <MeasurementControl />
        <ZoomControl position="bottomright" />
        {isAdmin && (
          <AMDBDrawControl
            selctedAirport={selctedAirport}
            editableLayer={editableLayer}
            editabletype={editabletype}
            handleCallback={handleDrawControlCallback}
          />
        )}
        <MapLayers />
        <ScaleControl position="topleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airportLocation,
    zoomLevel,
    selctedAirport,
    selectedFeatures,
    editableLayer,
  ]);

  return <Fragment>{amdbMapE}</Fragment>;
}
