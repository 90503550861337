import React, { Fragment } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { dataChangedRowColorMap } from "../../constants/colors";
import StyledTableCell from "../tabel-component/StyledTableCell";
import DiffTableCell from "../tabel-component/DiffTableCell";
import DiffStack from "../tabel-component/DiffStack";
import ValueItem from "../tabel-component/ValueItem";
import TopTableHeaderRow from "./TopTableHeaderRow";
import TableSheetHeaderRow from "./TableSheetHeaderRow";
import TableFooterRow from "./TableFooterRow";

export default function ApproachTable({
  rows,
  selctedAirport,
  selctedCategory,
}) {
  return (
    <Fragment>
      <TopTableHeaderRow />
      <TableSheetHeaderRow
        selctedAirport={selctedAirport}
        selctedCategory={selctedCategory}
      />
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={6}></StyledTableCell>
              <StyledTableCell colSpan={4} align="center">
                280M RUNWAY STRIP
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="center" rowSpan={2}>
                UID
              </StyledTableCell>
              <StyledTableCell align="center" rowSpan={2}>
                OBJECT
              </StyledTableCell>
              <StyledTableCell align="center" rowSpan={2}>
                <Box>LATITUDE</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <Box sx={{ typography: "caption" }}>NEW</Box>
                  <Box sx={{ typography: "caption" }}>OLD</Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" rowSpan={2}>
                <Box>LONGITUDE</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <Box sx={{ typography: "caption" }}>NEW</Box>
                  <Box sx={{ typography: "caption" }}>OLD</Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" rowSpan={2}>
                <Stack>
                  <Box>TOP ELEV. IN m. (EGM 08)</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                    <Box sx={{ typography: "caption" }}>DIFF</Box>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" rowSpan={2}>
                <Stack>
                  <Box>BASE ELEV. IN m. (EGM 08)</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                    <Box sx={{ typography: "caption" }}>DIFF</Box>
                  </Stack>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Stack alignItems="center">
                  <Box sx={{ typography: "caption" }}>DISTANCE (IN M)</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>X</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>Y</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>YY</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Stack alignItems="center">
                  <Box sx={{ typography: "caption" }}>
                    W.R.T 1:50 IN APP FUNNEL {"&"} 1:7 IN T.S. (IN M.)
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>
                        PERMISSIBLE TOP ELEV. IN M.
                      </Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>OBSTACLE IN M.</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Stack alignItems="center">
                  <Box sx={{ typography: "caption" }}>
                    W.R.T. I.H.S./C.S./O.H.S. (IN M.)
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>
                        PERMISSIBLE TOP ELEV. IN M.
                      </Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                    <Stack alignItems="center">
                      <Box sx={{ typography: "caption" }}>OBSTACLE IN M.</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" rowSpan={2}>
                <Box sx={{ typography: "caption" }}>REMARKS</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <Box sx={{ typography: "caption" }}>NEW</Box>
                  <Box sx={{ typography: "caption" }}>OLD</Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  background: dataChangedRowColorMap[row.result] ?? "#FFFFFF",
                }}
              >
                <DiffTableCell>{row["uid1"]}</DiffTableCell>
                <DiffTableCell>{row["object1"] || "-"}</DiffTableCell>
                <DiffTableCell isdiff={row["latdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["latitude1"]} />
                    <ValueItem value={row["latitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell isdiff={row["longdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["longitude1"]} />
                    <ValueItem value={row["longitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["top_elevdiff"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["top_elev1"]} />
                    <ValueItem value={row["top_elev2"]} />
                    <ValueItem value={row["top_elevdiff"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell isdiff={Boolean(row["base_elevdiff"])}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["base_elev1"]} />
                    <ValueItem value={row["base_elev2"]} />
                    <ValueItem value={row["base_elevdiff"]} />
                  </Stack>
                </DiffTableCell>
                <StyledTableCell>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <DiffStack isdiff={Boolean(row["xdiff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["x1"]} />
                          <ValueItem value={row["x2"]} />
                          <ValueItem value={row["xdiff"]} />
                        </Stack>
                      </DiffStack>
                      <DiffStack isdiff={Boolean(row["ydiff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["y1"]} />
                          <ValueItem value={row["y2"]} />
                          <ValueItem value={row["ydiff"]} />
                        </Stack>
                      </DiffStack>
                      <DiffStack isdiff={Boolean(row["yydiff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["yy1"]} />
                          <ValueItem value={row["yy2"]} />
                          <ValueItem value={row["yydiff"]} />
                        </Stack>
                      </DiffStack>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <DiffStack
                        isdiff={Boolean(row["APPpermissible_elevdiff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["APPpermissible_elev1"]} />
                          <ValueItem value={row["APPpermissible_elev2"]} />
                          <ValueItem value={row["APPpermissible_elevdiff"]} />
                        </Stack>
                      </DiffStack>
                      <DiffStack isdiff={Boolean(row["APPobstaclediff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["APPobstacle1"]} />
                          <ValueItem value={row["APPobstacle2"]} />
                          <ValueItem value={row["APPobstaclediff"]} />
                        </Stack>
                      </DiffStack>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <DiffStack
                        isdiff={Boolean(row["IHSpermissible_elevdiff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["IHSpermissible_elev1"]} />
                          <ValueItem value={row["IHSpermissible_elev2"]} />
                          <ValueItem value={row["IHSpermissible_elevdiff"]} />
                        </Stack>
                      </DiffStack>
                      <DiffStack isdiff={Boolean(row["IHSobstaclediff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["IHSobstacle1"]} />
                          <ValueItem value={row["IHSobstacle2"]} />
                          <ValueItem value={row["IHSobstaclediff"]} />
                        </Stack>
                      </DiffStack>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <DiffTableCell>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["remarks1"]} />
                    <ValueItem value={row["remarks2"]} />
                  </Stack>
                </DiffTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterRow
              colspan={7}
              selctedAirport={selctedAirport}
              selctedCategory={selctedCategory}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
