import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import MuiSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ACCESS_TOKEN } from "../../constants/constant";
import {
  createAd219NavidValidationSchema,
  navidTypes,
  hoursOfOperation,
} from "../../constants/ad2Data";
import {
  removeSelctedAirportFormCategories,
  navidUpload,
} from "../../models/airport";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { PrimaryButton } from "../button";

const navidDetail = {
  identification: "",
  type: "",
  rwy_dir: "",
  LOC: {
    frequency: "",
    latitude: "",
    longitude: "",
    hours_of_operation: "",
    elevation: "",
    signalperformance: "",
    remarks: "",
  },
  GP: {
    frequency: "",
    latitude: "",
    longitude: "",
    hours_of_operation: "",
    elevation: "",
    remarks: "",
  },
  DME: {
    channel: "",
    latitude: "",
    longitude: "",
    hours_of_operation: "",
    elevation: "",
    remarks: "",
  },
  frequency: "",
  channel: "",
  latitude: "",
  longitude: "",
  hours_of_operation: "",
  elevation: "",
  remarks: "",
};

export default function Ad219NavidForm({
  selectedAirport,
  selectedCategory,
  adRunwayInfo,
}) {
  const dispatch = useDispatch();
  const isLoadingTaxiwayUpload = useSelector(
    (state) => state.airport.isLoadingTaxiwayUpload
  );
  const runwayDirection = adRunwayInfo
    ? adRunwayInfo.Runway_Direction_List
    : [];

  const createAd219NavidForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      navids: [navidDetail],
    },
    validationSchema: createAd219NavidValidationSchema,
    onSubmit: (values) => {
      const navidValues = values.navids.map((navid) => {
        if (navid.type === "ILS") {
          return {
            identification: navid.identification,
            type: navid.type,
            rwy_dir: navid.rwy_dir,
            details: {
              LOC: {
                frequency: `${navid.LOC.frequency} hz`,
                hours_of_operation: navid.LOC.hours_of_operation,
                latitude: navid.LOC.latitude,
                longitude: navid.LOC.longitude,
                elevation: `${navid.LOC.elevation} FT`,
                remarks: navid.LOC.remarks,
                signalperformance: navid.LOC.signalperformance,
              },
              GP: {
                frequency: `${navid.GP.frequency} hz`,
                hours_of_operation: navid.GP.hours_of_operation,
                latitude: navid.GP.latitude,
                longitude: navid.GP.longitude,
                elevation: `${navid.GP.elevation} FT`,
                remarks: navid.GP.remarks,
              },
            },
          };
        }
        if (navid.type === "ILS_DME") {
          return {
            identification: navid.identification,
            type: navid.type,
            rwy_dir: navid.rwy_dir,
            details: {
              LOC: {
                frequency: `${navid.LOC.frequency} hz`,
                hours_of_operation: navid.LOC.hours_of_operation,
                latitude: navid.LOC.latitude,
                longitude: navid.LOC.longitude,
                elevation: `${navid.LOC.elevation} FT`,
                remarks: navid.LOC.remarks,
                signalperformance: navid.LOC.signalperformance,
              },
              GP: {
                frequency: `${navid.GP.frequency} hz`,
                hours_of_operation: navid.GP.hours_of_operation,
                latitude: navid.GP.latitude,
                longitude: navid.GP.longitude,
                elevation: `${navid.GP.elevation} FT`,
                remarks: navid.GP.remarks,
              },
              DME: {
                channel: `${navid.DME.channel} WGE`,
                hours_of_operation: navid.DME.hours_of_operation,
                latitude: navid.DME.latitude,
                longitude: navid.DME.longitude,
                elevation: `${navid.DME.elevation} FT`,
                remarks: navid.DME.remarks,
              },
            },
          };
        }

        const payload = {
          identification: navid.identification,
          type: navid.type,
          rwy_dir: navid.rwy_dir,
          hours_of_operation: navid.hours_of_operation,
          latitude: navid.latitude,
          longitude: navid.longitude,
          elevation: `${navid.elevation} FT`,
          remarks: navid.remarks,
        };

        if (navid.type !== "DME") {
          payload.frequency = `${navid.frequency} hz`;
        }

        if (navid.type === "VOR_DME" || navid.type === "DME") {
          payload.channel = `${navid.channel} WGE`;
        }

        return payload;
      });

      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          navidUpload(
            {
              airport_id: selectedAirport.id,
              form: navidValues,
            },
            token,
            handleUploadSuccessCallback
          )
        );
      });
    },
  });

  const handleUploadSuccessCallback = () => {
    createAd219NavidForm.resetForm();
    createAd219NavidForm.setSubmitting(false);
    dispatch(removeSelctedAirportFormCategories(selectedCategory));
  };

  return (
    <Paper sx={{ pb: 2, pt: 2 }}>
      <FormikProvider value={createAd219NavidForm}>
        <form onSubmit={createAd219NavidForm.handleSubmit}>
          <FieldArray
            name="navids"
            render={(arrayHelpers) => (
              <div>
                {createAd219NavidForm.values.navids.map((_, index) => {
                  const isTouched = createAd219NavidForm.touched?.navids
                    ? createAd219NavidForm.touched?.navids[index]
                    : false;
                  const isErrors = createAd219NavidForm.errors?.navids
                    ? createAd219NavidForm.errors?.navids[index]
                    : false;

                  return (
                    <Fragment key={index}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Identification</Box>
                        <Box sx={{ pl: 2 }} display="flex" alignItems="center">
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name={`navids[${index}].identification`}
                            value={
                              createAd219NavidForm.values.navids[index]
                                .identification
                            }
                            onChange={createAd219NavidForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd219NavidForm.touched?.navids[index]
                                .identification &&
                              Boolean(
                                createAd219NavidForm.errors?.navids[index]
                                  .identification
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd219NavidForm.errors?.navids[index]
                                    .identification
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Type</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd219NavidForm.touched?.navids[index]
                                .type &&
                              Boolean(
                                createAd219NavidForm.errors?.navids[index].type
                              )
                            }
                          >
                            <MuiSelect
                              name={`navids[${index}].type`}
                              value={
                                createAd219NavidForm.values.navids[index].type
                              }
                              onChange={createAd219NavidForm.handleChange}
                            >
                              {navidTypes.map((type) => (
                                <MenuItem key={type.id} value={type.value}>
                                  {type.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd219NavidForm.errors?.navids[index]
                                    .type
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Runway Direction</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            disabled={runwayDirection.length === 0}
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd219NavidForm.touched?.navids[index]
                                .rwy_dir &&
                              Boolean(
                                createAd219NavidForm.errors?.navids[index]
                                  .rwy_dir
                              )
                            }
                          >
                            <MuiSelect
                              name={`navids[${index}].rwy_dir`}
                              value={
                                createAd219NavidForm.values.navids[index]
                                  .rwy_dir
                              }
                              onChange={createAd219NavidForm.handleChange}
                            >
                              {runwayDirection.map((type) => (
                                <MenuItem
                                  key={type.id}
                                  value={type.rwy_dir_designator}
                                >
                                  {type.rwy_dir_designator}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {runwayDirection.length === 0 &&
                                "No runway direction found. Please upload runway direction first."}
                              {isErrors && isTouched
                                ? createAd219NavidForm.errors?.navids[index]
                                    .rwy_dir
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      {(createAd219NavidForm.values.navids[index].type ===
                        "ILS" ||
                        createAd219NavidForm.values.navids[index].type ===
                          "ILS_DME") && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<MdOutlineKeyboardArrowDown />}
                            aria-controls="loc-content"
                            id="loc-header"
                          >
                            <Typography>Localizer</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Frequency</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].LOC.frequency`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.LOC?.frequency
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.frequency &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.frequency
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.frequency
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        HZ
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Latitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].LOC.latitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.LOC?.latitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.latitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.latitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.latitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Longitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].LOC.longitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.LOC?.longitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.longitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.longitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.longitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Hours of Operation
                              </Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  sx={{ mb: 2, width: 200 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.hours_of_operation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.hours_of_operation
                                    )
                                  }
                                >
                                  <MuiSelect
                                    name={`navids[${index}].LOC.hours_of_operation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.LOC?.hours_of_operation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                  >
                                    {hoursOfOperation.map((type) => (
                                      <MenuItem
                                        key={type.id}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </MenuItem>
                                    ))}
                                  </MuiSelect>
                                  <FormHelperText>
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.hours_of_operation
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Elevation</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].LOC.elevation`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.LOC?.elevation
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.elevation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.elevation
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.elevation
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Signal Performance
                              </Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].LOC.signalperformance`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.LOC?.signalperformance
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.signalperformance &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.signalperformance
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.signalperformance
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, mt: 2, width: 250 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.LOC?.remarks &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.LOC?.remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    name={`navids[${index}].LOC.remarks`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.LOC?.remarks
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    aria-describedby="aerodrome-loc-remarks"
                                  />
                                  <FormHelperText id="aerodrome-loc-remarks">
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {(createAd219NavidForm.values.navids[index].type ===
                        "ILS" ||
                        createAd219NavidForm.values.navids[index].type ===
                          "ILS_DME") && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<MdOutlineKeyboardArrowDown />}
                            aria-controls="loc-content"
                            id="loc-header"
                          >
                            <Typography>Glide Path</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Frequency</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].GP.frequency`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.GP?.frequency
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.GP?.frequency &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.GP?.frequency
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.frequency
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        HZ
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Latitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].GP.latitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.GP?.latitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.GP?.latitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.GP?.latitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.latitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Longitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].GP.longitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.GP?.longitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.GP?.longitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.GP?.longitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.longitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Hours of Operation
                              </Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  sx={{ mb: 2, width: 200 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.GP?.hours_of_operation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.GP?.hours_of_operation
                                    )
                                  }
                                >
                                  <MuiSelect
                                    name={`navids[${index}].GP.hours_of_operation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.GP?.hours_of_operation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                  >
                                    {hoursOfOperation.map((type) => (
                                      <MenuItem
                                        key={type.id}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </MenuItem>
                                    ))}
                                  </MuiSelect>
                                  <FormHelperText>
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.hours_of_operation
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Elevation</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].GP.elevation`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.GP?.elevation
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.GP?.elevation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.GP?.elevation
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.elevation
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, mt: 2, width: 250 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.GP?.remarks &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.GP?.remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    name={`navids[${index}].GP.remarks`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.GP?.remarks
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    aria-describedby="aerodrome-loc-remarks"
                                  />
                                  <FormHelperText id="aerodrome-loc-remarks">
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {createAd219NavidForm.values.navids[index].type ===
                        "ILS_DME" && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<MdOutlineKeyboardArrowDown />}
                            aria-controls="loc-content"
                            id="loc-header"
                          >
                            <Typography>DME</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Channel</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].DME.channel`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.DME?.channel
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.DME?.channel &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.DME?.channel
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.channel
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        WGE
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Latitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].DME.latitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.DME?.latitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.DME?.latitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.DME?.latitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.latitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Longitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].DME.longitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.DME?.longitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.DME?.longitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.DME?.longitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.longitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Hours of Operation
                              </Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  sx={{ mb: 2, width: 200 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.DME?.hours_of_operation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.DME?.hours_of_operation
                                    )
                                  }
                                >
                                  <MuiSelect
                                    name={`navids[${index}].DME.hours_of_operation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.DME?.hours_of_operation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                  >
                                    {hoursOfOperation.map((type) => (
                                      <MenuItem
                                        key={type.id}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </MenuItem>
                                    ))}
                                  </MuiSelect>
                                  <FormHelperText>
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.hours_of_operation
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Elevation</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].DME.elevation`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.DME?.elevation
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.DME?.elevation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.DME?.elevation
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.elevation
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, mt: 2, width: 250 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.DME?.remarks &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.DME?.remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    name={`navids[${index}].DME.remarks`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.DME?.remarks
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    aria-describedby="aerodrome-loc-remarks"
                                  />
                                  <FormHelperText id="aerodrome-loc-remarks">
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {createAd219NavidForm.values.navids[index].type &&
                        createAd219NavidForm.values.navids[index].type !==
                          "ILS" &&
                        createAd219NavidForm.values.navids[index].type !==
                          "ILS_DME" && (
                          <Fragment>
                            {createAd219NavidForm.values.navids[index].type !==
                              "DME" && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Frequency</Box>
                                <Box
                                  sx={{ pl: 2 }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].frequency`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.frequency
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.frequency &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.frequency
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.frequency
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          HZ
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                            )}
                            {(createAd219NavidForm.values.navids[index].type ===
                              "DME" ||
                              createAd219NavidForm.values.navids[index].type ===
                                "VOR_DME") && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Channel</Box>
                                <Box
                                  sx={{ pl: 2 }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].channel`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.channel
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.channel &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.channel
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.channel
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          WGE
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                            )}
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Latitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].latitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.latitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.latitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.latitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.latitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Longitude</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`navids[${index}].longitude`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.longitude
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.longitude &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.longitude
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.longitude
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Hours of Operation
                              </Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  sx={{ mb: 2, width: 200 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.hours_of_operation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.hours_of_operation
                                    )
                                  }
                                >
                                  <MuiSelect
                                    name={`navids[${index}].hours_of_operation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.hours_of_operation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                  >
                                    {hoursOfOperation.map((type) => (
                                      <MenuItem
                                        key={type.id}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </MenuItem>
                                    ))}
                                  </MuiSelect>
                                  <FormHelperText>
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.hours_of_operation
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Elevation</Box>
                              <Box
                                sx={{ pl: 2 }}
                                display="flex"
                                alignItems="center"
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`navids[${index}].elevation`}
                                  value={
                                    createAd219NavidForm.values.navids[index]
                                      ?.elevation
                                  }
                                  onChange={createAd219NavidForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.elevation &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.elevation
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.elevation
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={{ pl: 2 }}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, mt: 2, width: 250 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd219NavidForm.touched?.navids[index]
                                      ?.remarks &&
                                    Boolean(
                                      createAd219NavidForm.errors?.navids[index]
                                        ?.remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    name={`navids[${index}].remarks`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.remarks
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    aria-describedby="aerodrome-loc-remarks"
                                  />
                                  <FormHelperText id="aerodrome-loc-remarks">
                                    {isErrors && isTouched
                                      ? createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </Fragment>
                        )}
                      <Stack alignItems="flex-end">
                        <PrimaryButton
                          label="Remove Navid"
                          color="error"
                          disabled={runwayDirection.length === 0}
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </Stack>
                      <Divider />
                    </Fragment>
                  );
                })}
                <PrimaryButton
                  label="Add Navid"
                  disabled={runwayDirection.length === 0}
                  onClick={() => arrayHelpers.push(navidDetail)}
                />
              </div>
            )}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ pr: 2 }}
          >
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingTaxiwayUpload}
              disabled={isLoadingTaxiwayUpload}
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
    </Paper>
  );
}
