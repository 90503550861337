import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { GridIcon } from "../assets/svg/grid";
import { FileIcon } from "../assets/svg/file";
import { LayerIcon } from "../assets/svg/layer";
import { MapMarkerIcon } from "../assets/svg/map-marker";
import { PieChartIcon } from "../assets/svg/pie-chart";
import AirportLogo from "../assets/images/airports-authority.png";
import SatsureiNetraLogo from "../assets/images/satsure-iNetra.png";
import {
  feedbackFormValidationSchema,
  loginFormValidationSchema,
} from "../constants/userData";
import NavButton from "../components/NavButton";
import { OutlinedButton, PrimaryButton } from "../components/button";
import { getRSA, requestFeedback, userLogin } from "../models/userSession";
import { rsaEnc } from "../utils/decodeEncodeData";
import "../styles/aerohome.css";

export default function AeroHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSession.user);
  const { isRequestFeedbackLoading, isLoginLoading, rsaKey } = useSelector(
    (state) => state.userSession
  );
  const [isOpenFeedbackform, setIsOpenFeedbackform] = useState(false);
  const [isOpenLoginform, setIsOpenLoginform] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(getRSA());
  }, []); // eslint-disable-line

  const feedbackForm = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      feedback: "",
    },
    validationSchema: feedbackFormValidationSchema,
    onSubmit: (values) => {
      dispatch(requestFeedback(values, handleCloseForm));
    },
  });

  const loginForm = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginFormValidationSchema,
    onSubmit: (values) => {
      dispatch(
        userLogin(
          {
            username: rsaEnc(values.username, rsaKey),
            password: rsaEnc(values.password, rsaKey),
          },
          handleCloseForm
        )
      );
    },
  });

  const handleCloseForm = () => {
    setIsOpenFeedbackform(false);
    setIsOpenLoginform(false);
    feedbackForm.resetForm();
    feedbackForm.setSubmitting(false);
    loginForm.resetForm();
    loginForm.setSubmitting(false);
  };

  return (
    <div className="aero-home-container">
      <div className="aero-brand-container">
        <div className="aero-copyright-div">
          <Typography
            variant="subtitle1"
            component="div"
            className="aero-copyright-text"
          >
            {moment().format("YYYY")} AIRPORTS AUTHORITY OF INDIA All Rights
            Reserved
          </Typography>
        </div>
        <div className="aero-brand-image">
          <div>
            <img
              src={AirportLogo}
              alt="airports-authority"
              className="aero-airport-logo"
            />
          </div>
          <div>
            <img
              src={SatsureiNetraLogo}
              alt="satsure-iNetra"
              className="aero-inetra-logo"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div className="aero-user-feedback-disclaimer">
          <div>
            Disclaimer: The data provided through this page related to eTOD,
            AMDB and Electronic Charts is for the evaluation/feedback purpose.
          </div>
          <div>
            Under{" "}
            <b>
              no circumstances should its contents be used for operational or
              navigational purposes.
            </b>{" "}
          </div>
        </div>
        <div className="aero-menu-container">
          <div className="aero-menu-row">
            <div>
              <NavButton
                icon={<MapMarkerIcon />}
                label="eTOD"
                onClick={() => navigate("/etod")}
              />
            </div>
            <div className="aero-left-margin">
              <NavButton
                icon={<LayerIcon />}
                label="AMDB"
                onClick={() => navigate("/amdb")}
              />
            </div>
            <div className="aero-left-margin">
              <NavButton
                icon={<GridIcon />}
                label="AIXM"
                onClick={() => navigate("/aixm")}
              />
            </div>
            <div className="aero-left-margin">
              <NavButton
                icon={<LayerIcon />}
                label="ELECTRONIC CHART"
                onClick={() => navigate("/electronic-chart")}
              />
            </div>
          </div>
          {currentUser?.userId && (
            <div className="aero-menu-row aero-row-margin">
              <div>
                <NavButton
                  icon={<PieChartIcon />}
                  label="AIRSPACE"
                  onClick={() => navigate("/airspace")}
                />
              </div>
              <div className="aero-left-margin">
                <NavButton
                  isShowLeftIndicator
                  topRightIcon={<FaLock size={18} color="#FFFFFF" />}
                  icon={<FileIcon />}
                  label="DATA MANAGEMENT"
                  onClick={() => navigate("/data-management")}
                />
              </div>
            </div>
          )}
        </div>
        {isEmpty(currentUser?.userId) && (
          <div className="aero-user-feedback-btndiv">
            <OutlinedButton
              label="Internal User Login"
              onClick={() => setIsOpenLoginform(true)}
              color="secondary"
              sx={{ color: "#ffffff" }}
            />
          </div>
        )}
        <div className="aero-user-feedback-text">
          Feedback: In order to continually improve the quality of the
          information and services, users are requested to send their
          feedback/suggestion/comments about the new services/products to
          aim.india@aai.aero or the use link provided below.
        </div>
        <div className="aero-user-feedback-btndiv">
          <OutlinedButton
            label="User Feedback"
            onClick={() => setIsOpenFeedbackform(true)}
            color="secondary"
            sx={{ color: "#ffffff" }}
          />
        </div>
      </div>
      {/* Feedback Modal */}
      <Dialog fullWidth open={isOpenFeedbackform}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <div style={{ width: "100%" }}>
                <Box sx={{ mb: 1 }}>First Name (optional)</Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="first_name"
                  name="first_name"
                  value={feedbackForm.values.first_name}
                  onChange={feedbackForm.handleChange}
                  error={
                    feedbackForm.touched.first_name &&
                    Boolean(feedbackForm.errors.first_name)
                  }
                  helperText={
                    feedbackForm.touched.first_name &&
                    feedbackForm.errors.first_name
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <Box sx={{ mb: 1 }}>Last Name (optional)</Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="last_name"
                  name="last_name"
                  value={feedbackForm.values.last_name}
                  onChange={feedbackForm.handleChange}
                  error={
                    feedbackForm.touched.last_name &&
                    Boolean(feedbackForm.errors.last_name)
                  }
                  helperText={
                    feedbackForm.touched.last_name &&
                    feedbackForm.errors.last_name
                  }
                />
              </div>
            </Stack>
            <Box sx={{ mb: 1, mt: 2 }}>Email (optional)</Box>
            <TextField
              fullWidth
              variant="outlined"
              id="email"
              name="email"
              value={feedbackForm.values.email}
              onChange={feedbackForm.handleChange}
              error={
                feedbackForm.touched.email && Boolean(feedbackForm.errors.email)
              }
              helperText={
                feedbackForm.touched.email && feedbackForm.errors.email
              }
              sx={{ mb: 2 }}
            />
            <Box sx={{ mb: 1 }}>Feedback (Required)</Box>
            <FormControl
              fullWidth
              variant="outlined"
              error={
                feedbackForm.touched.feedback &&
                Boolean(feedbackForm.errors.feedback)
              }
            >
              <TextareaAutosize
                minRows={3}
                name="feedback"
                value={feedbackForm.values.feedback}
                onChange={feedbackForm.handleChange}
                aria-describedby="feedback-feild"
              />
              <FormHelperText id="feedback-feild">
                {feedbackForm.touched.feedback && feedbackForm.errors.feedback}
              </FormHelperText>
            </FormControl>
            <DialogActions>
              <OutlinedButton
                label="Cancel"
                color="secondary"
                onClick={handleCloseForm}
                disabled={isRequestFeedbackLoading}
              />
              <PrimaryButton
                label="Submit"
                type="submit"
                onClick={() => feedbackForm.handleSubmit()}
                disabled={isRequestFeedbackLoading}
                isLoading={isRequestFeedbackLoading}
                sx={{ width: 100 }}
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {/* Login Modal */}
      <Dialog fullWidth open={isOpenLoginform}>
        <DialogTitle>Log In</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="p-2">
              <TextField
                fullWidth
                label="USER ID"
                id="username"
                name="username"
                aria-describedby="username-feild"
                // value={username}
                value={loginForm.values.username}
                onChange={loginForm.handleChange}
                // onChange={(e) => setUsername(e.target.value)}
                error={
                  loginForm.touched.username &&
                  Boolean(loginForm.errors.username)
                }
              />
              <FormHelperText id="username-feild" sx={{ color: "#d60000" }}>
                {loginForm.touched.username && loginForm.errors.username}
              </FormHelperText>
              <FormControl required fullWidth sx={{ mt: 2 }} variant="outlined">
                <InputLabel htmlFor="password">PASSWORD</InputLabel>
                <OutlinedInput
                  id="password"
                  label="Password"
                  name="password"
                  type={isShowPassword ? "text" : "password"}
                  // value={password}
                  value={loginForm.values.password}
                  onChange={loginForm.handleChange}
                  aria-describedby="password-feild"
                  // onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="password"
                        onClick={() => setShowPassword(!isShowPassword)}
                        onMouseDown={() => setShowPassword(!isShowPassword)}
                        edge="end"
                      >
                        {isShowPassword ? <FaEyeSlash /> : <FaEye />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={
                    loginForm.touched.password &&
                    Boolean(loginForm.errors.password)
                  }
                />
                <FormHelperText
                  id="password-feild"
                  sx={{
                    color: "#d60000",
                    ml: 0,
                  }}
                >
                  {loginForm.touched.password && loginForm.errors.password}
                </FormHelperText>
              </FormControl>
            </div>
            <DialogActions>
              <OutlinedButton
                label="Cancel"
                color="secondary"
                onClick={handleCloseForm}
                disabled={isLoginLoading}
              />
              <PrimaryButton
                label="Login"
                type="submit"
                onClick={() => loginForm.handleSubmit()}
                disabled={isLoginLoading}
                isLoading={isLoginLoading}
                sx={{ width: 100 }}
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
