import React, { useState, useEffect, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { staticColorMap } from '../../constants/colors';

export default function RenderLegends({
  isShowPenetrationObstacle,
  selectedRasterArea,
  rasterAreaList,
  handleAbovePermissible,
  handleBelowPermissible,
}) {
  const [rasterLegend, setRasterLegend] = useState(null);

  useEffect(() => {
    if (!rasterLegend && selectedRasterArea?.length > 0) {
      setRasterLegend(rasterAreaList[0]?.legend_url);
    } else if (selectedRasterArea?.length === 0) {
      setRasterLegend(null);
    };
  }, [selectedRasterArea]); // eslint-disable-line

  return (
    <Fragment>
      {isShowPenetrationObstacle && (
        <Paper
          sx={{
            position: 'absolute',
            right: 54,
            top: 10,
            p: 0.5,
            borderRadius: 1,
            zIndex: 1001,
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() => handleAbovePermissible()}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: staticColorMap['RedObstacle'],
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">Above Permissible</Typography>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={() => handleBelowPermissible()}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: staticColorMap['GreenObstacle'],
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">Below Permissible</Typography>
          </Box>
        </Paper>
      )}
      {Boolean(rasterLegend) && (
        <img
          src={rasterLegend}
          srcSet={rasterLegend}
          alt="Legend"
          loading="lazy"
          style={{
            position: 'absolute',
            right: 10,
            top: 100,
            width: 70,
            zIndex: 1001,
          }}
        />
      )}
    </Fragment>
  );
};