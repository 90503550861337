import React, { useState } from "react";
import { FeatureGroup, CircleMarker } from "react-leaflet";
import { chain, isNaN } from "lodash";
import { staticColorMap } from "../../constants/colors";
import { areaHighLightColor } from "../../constants/colors";
import AreaObstacleInfoPopup from "./AreaObstacleInfoPopup";

export default function RenderOLSObstacles({
  selctedAirport,
  selectedOls,
  olsObstaclesList,
  isShowPenetrationObstacle,
  getOLSColors,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const areaColor = getOLSColors();
  let visibleolsObstaclesList = chain(olsObstaclesList)
    .filter((obstacle) => {
      const obstacleKey = obstacle.ols_surface;
      return selectedOls.includes(`${obstacleKey}__${areaColor[obstacleKey]}`);
    })
    .value();

  if (isAbovePermissibleClick) {
    visibleolsObstaclesList = visibleolsObstaclesList.filter((obstacle) => {
      if (!isNaN(Number(obstacle.penetration))) {
        return Number(obstacle.penetration) >= 0;
      } else {
        return obstacle.penetration !== "Clear";
      }
    });
  } else if (isBelowPermissibleClick) {
    visibleolsObstaclesList = visibleolsObstaclesList.filter((obstacle) => {
      if (!isNaN(Number(obstacle.penetration))) {
        return Number(obstacle.penetration) <= 0;
      } else {
        return obstacle.penetration === "Clear";
      }
    });
  }

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  if (selectedOls.length === 0) {
    return null;
  }

  return (
    <FeatureGroup pane="popupPane">
      {visibleolsObstaclesList.map((obstacles, i) => {
        if (!obstacles.latitude || !obstacles.longitude) {
          return null;
        }

        let obstacleColor = staticColorMap["Obstacles"];

        if (isShowPenetrationObstacle && obstacles.penetration) {
          if (!isNaN(Number(obstacles.penetration))) {
            obstacleColor =
              Number(obstacles.penetration) >= 0
                ? staticColorMap["RedObstacle"]
                : staticColorMap["GreenObstacle"];
          } else {
            obstacleColor =
              obstacles.penetration !== "Clear"
                ? staticColorMap["RedObstacle"]
                : staticColorMap["GreenObstacle"];
          }
        }

        return (
          <CircleMarker
            key={i}
            pane="tooltipPane"
            center={{
              lat: Number(obstacles.latitude),
              lng: Number(obstacles.longitude),
            }}
            radius={6}
            pathOptions={{
              fillColor: obstacleColor,
              color:
                selectedOverE?.uid === obstacles.uid
                  ? areaHighLightColor
                  : obstacleColor,
              weight: selectedOverE?.uid === obstacles.uid ? 3 : 1,
              fillOpacity: 1,
            }}
            eventHandlers={{
              mouseover: () => handlePolygonOver(obstacles),
              mouseout: () => handlePolygonOut(),
            }}
          >
            <AreaObstacleInfoPopup
              info={obstacles}
              selctedAirport={selctedAirport}
              selctedFeature="OLS"
            />
          </CircleMarker>
        );
      })}
    </FeatureGroup>
  );
}
