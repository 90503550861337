import React from "react";

export const ResetPasswordIcon = (props) => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.63 0C18.16 0 22.64 4.5 22.64 10C22.64 15.5 18.16 20 12.63 20C9.12 20 6.05 18.18 4.26 15.43L5.84 14.18C7.25 16.47 9.76 18 12.64 18C14.7617 18 16.7966 17.1571 18.2969 15.6569C19.7971 14.1566 20.64 12.1217 20.64 10C20.64 7.87827 19.7971 5.84344 18.2969 4.34315C16.7966 2.84285 14.7617 2 12.64 2C8.56 2 5.2 5.06 4.71 9H7.47L3.73 12.73L0 9H2.69C3.19 3.95 7.45 0 12.63 0ZM15.59 8.24C16.09 8.25 16.5 8.65 16.5 9.16V13.77C16.5 14.27 16.09 14.69 15.58 14.69H10.05C9.54 14.69 9.13 14.27 9.13 13.77V9.16C9.13 8.65 9.54 8.25 10.04 8.24V7.23C10.04 5.7 11.29 4.46 12.81 4.46C14.34 4.46 15.59 5.7 15.59 7.23V8.24ZM12.81 5.86C12.06 5.86 11.44 6.47 11.44 7.23V8.24H14.19V7.23C14.19 6.47 13.57 5.86 12.81 5.86Z"
      fill="#262261"
    />
  </svg>
);
