import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaUserCheck, FaUserTimes, FaCopy } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useFormik } from "formik";
import generator from "generate-password";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { ResetPasswordIcon } from "../../assets/svg/ResetPasswordIcon";
import {
  OutlinedButton,
  PrimaryButton,
  LightButton,
} from "../../components/button";
import CreateUserButton from "../../components/CreateUserButton";
import TableRowLoader from "../../components/tabel-component/TableRowLoader";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";
import {
  ACCESS_TOKEN,
  AES_KEY,
  userLabel,
  userType,
} from "../../constants/constant";
import {
  createValidationSchema,
  editValidationSchema,
  createAaiUserValidationSchema,
  editAaiUserValidationSchema,
  masterResetPasswordValidationSchema,
} from "../../constants/surveyorData";
import {
  getAllSurveyorData,
  getAllSurveyAirport,
  selectAllSurveyorList,
  postSurveyorRegistration,
  editSurveyorRegistration,
  disabledSurveyor,
  enableSurveyor,
  masterResetPassword,
} from "../../models/survey";
import { decryptStatic, encryptStatic } from "../../utils/decodeEncodeData";
import {
  getAsyncStorageValue,
  getStorageValue,
} from "../../utils/localStorage";
import { LightTooltip } from "../../components/LightTooltip";

export default function ManageUsers() {
  const dispatch = useDispatch();
  const isLoadingGetAllSurveyor = useSelector(
    (state) => state.survey.isLoadingGetAllSurveyor
  );
  const isLoadingPostSurveyorRegistration = useSelector(
    (state) => state.survey.isLoadingPostSurveyorRegistration
  );
  const isLoadingEditSurveyorRegistration = useSelector(
    (state) => state.survey.isLoadingEditSurveyorRegistration
  );
  const isLoadingDisableSurveyor = useSelector(
    (state) => state.survey.isLoadingDisableSurveyor
  );
  const isLoadingEnableSurveyor = useSelector(
    (state) => state.survey.isLoadingEnableSurveyor
  );
  const isResetingMasterPassword = useSelector(
    (state) => state.survey.isResetingMasterPassword
  );
  const allSurveyorAirports = useSelector(
    (state) => state.survey.surveyAllAirports
  );
  const [selectedSurveyor, setSelectedSurveyor] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isEditDialog, setEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [filterUserType, setFilterUserType] = useState(userType.surveyor);
  const allSurveyorList = useSelector((state) =>
    selectAllSurveyorList(state, filterUserType)
  );
  const aesKey = getStorageValue(AES_KEY);
  const userTypeName = selectedUserType ? userLabel[selectedUserType] : "";

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllSurveyAirport(token));
    });
  }, []); // eslint-disable-line

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      surveyairport_id: "",
    },
    validationSchema: isEditDialog
      ? selectedUserType === userType.surveyor
        ? editValidationSchema
        : editAaiUserValidationSchema
      : selectedUserType === userType.surveyor
      ? createValidationSchema
      : createAaiUserValidationSchema,
    onSubmit: (values) => {
      const userPayload = {
        name: encryptStatic(values.name, aesKey),
        email: encryptStatic(values.email, aesKey),
        phone: encryptStatic(values.phone, aesKey),
        surveyairport_id: null,
      };

      if (
        selectedUserType === userType.surveyor ||
        (selectedSurveyor && selectedSurveyor.role === userType.surveyor)
      ) {
        userPayload.surveyairport_id = values.surveyairport_id;
      }

      if (values.user_id) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            editSurveyorRegistration(
              {
                user_id: values.user_id,
                ...userPayload,
              },
              token,
              handleCloseAddDialog
            )
          );
        });
      } else {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            postSurveyorRegistration(
              {
                ...userPayload,
                password: encryptStatic(values.password, aesKey),
                role: selectedUserType,
              },
              token,
              handleCloseAddDialog
            )
          );
        });
      }
    },
  });
  const passwordResetFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
    },
    validationSchema: masterResetPasswordValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          masterResetPassword(
            {
              user_id: values.user_id,
              new_password: encryptStatic(values.new_password, aesKey),
            },
            token,
            handleCloseResetPasswordDialog
          )
        );
      });
    },
  });

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllSurveyorData(token));
    });
  }, []); // eslint-disable-line

  const handleUserFilter = (event) => {
    setFilterUserType(event.target.value);
  };

  const handleOpenAddDialog = (userType) => {
    setSelectedUserType(userType);
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    formik.setFieldValue("password", password);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    // add
    setOpenAddDialog(false);
    setCopied(false);
    formik.resetForm();
    formik.setSubmitting(false);

    // edit
    setEditDialog(false);
    setSelectedSurveyor(null);
    setSelectedUserType(null);
  };

  const handleEditSurveyor = (payload) => {
    setEditDialog(true);
    setSelectedSurveyor(payload);
    setOpenAddDialog(true);
    formik.setValues({
      user_id: payload.userid,
      name: decryptStatic(payload.name, aesKey),
      email: decryptStatic(payload.email, aesKey),
      phone: decryptStatic(payload.phone_no, aesKey),
    });

    if (payload.role === userType.surveyor && payload.airport_id) {
      formik.setFieldValue("surveyairport_id", payload.airport_id);
    }
  };

  const handleOpenDeleteDialog = (srveyor) => {
    setSelectedSurveyor(srveyor);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSurveyor(null);
  };

  const handleDisableSurveyor = () => {
    if (selectedSurveyor?.enable) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          disabledSurveyor(
            selectedSurveyor.userid,
            token,
            handleCloseDeleteDialog
          )
        );
      });
    } else {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          enableSurveyor(
            { user_id: selectedSurveyor.userid },
            token,
            handleCloseDeleteDialog
          )
        );
      });
    }
  };

  const handleResetPassword = (payload) => {
    setSelectedSurveyor(payload);
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    passwordResetFormik.setValues({
      user_id: payload.userid,
      new_password: password,
    });
    setOpenResetPasswordDialog(true);
  };

  const handleCloseResetPasswordDialog = () => {
    setCopied(false);
    setSelectedSurveyor(null);
    setOpenResetPasswordDialog(false);
    passwordResetFormik.resetForm();
    passwordResetFormik.setSubmitting(false);
  };

  return (
    <div className="surveyor-category-table white-bg">
      <Toolbar sx={{ justifyContent: "flex-end" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="user-select">User Type</InputLabel>
          <Select
            labelId="user-select"
            id="user-select"
            value={filterUserType}
            label="User Type"
            onChange={handleUserFilter}
          >
            <MenuItem value={userType.surveyor}>{userLabel.SURVEYOR}</MenuItem>
            <MenuItem value={userType.aai}>{userLabel.AAI_USER}</MenuItem>
          </Select>
        </FormControl>
        <CreateUserButton handleOpenAddDialog={handleOpenAddDialog} />
      </Toolbar>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Surveyor Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              {filterUserType === userType.surveyor && (
                <StyledTableCell>Airport</StyledTableCell>
              )}
              <StyledTableCell align="center">ACTION</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(isLoadingGetAllSurveyor || allSurveyorList?.length === 0) && (
              <TableRowLoader
                isLoading={isLoadingGetAllSurveyor}
                data={allSurveyorList || []}
                colSpan={5}
              />
            )}
            {allSurveyorList?.map((surveyor) => {
              return (
                <TableRow key={surveyor.emp_id}>
                  <StyledTableCell>{surveyor.emp_id}</StyledTableCell>
                  <StyledTableCell>
                    {decryptStatic(surveyor.name, aesKey)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {decryptStatic(surveyor.email, aesKey) || "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {decryptStatic(surveyor.phone_no, aesKey) || "-"}
                  </StyledTableCell>
                  {filterUserType === userType.surveyor && (
                    <StyledTableCell>
                      {surveyor.airport_name
                        ? `${surveyor.airport_name} (${surveyor.location_indicator})`
                        : "-"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    <LightTooltip title="Enable / Disable">
                      <IconButton
                        color={surveyor?.enable ? "secondary" : "error"}
                        onClick={() => handleOpenDeleteDialog(surveyor)}
                      >
                        {surveyor?.enable ? <FaUserCheck /> : <FaUserTimes />}
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => handleEditSurveyor(surveyor)}
                      >
                        <MdEdit />
                      </IconButton>
                    </LightTooltip>
                    <LightTooltip title="Reset Password">
                      <IconButton
                        id="long-button"
                        onClick={() => handleResetPassword(surveyor)}
                      >
                        <ResetPasswordIcon />
                      </IconButton>
                    </LightTooltip>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullWidth open={openAddDialog}>
        <DialogTitle>
          {isEditDialog
            ? `Edit ${userLabel[filterUserType]}`
            : `New ${userTypeName}`}
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ mb: 2, mt: 2 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="phone"
              name="phone"
              label="Phone Number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              sx={{ mb: 2 }}
            />
            {!isEditDialog && (
              <TextField
                fullWidth
                variant="outlined"
                id="password"
                name="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                sx={{ mb: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={formik.values.password}
                        onCopy={() => setCopied(true)}
                      >
                        <IconButton
                          edge="end"
                          color={isCopied ? "primary" : "default"}
                        >
                          <FaCopy />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {(selectedSurveyor?.role === userType.surveyor ||
              selectedUserType === userType.surveyor) && (
              <Autocomplete
                id="surveyairport_id"
                name="surveyairport_id"
                options={allSurveyorAirports || []}
                getOptionLabel={(option) => option.name}
                defaultValue={allSurveyorAirports.find(
                  (a) => a.id === formik.values?.surveyairport_id
                )}
                onChange={(_, value) => {
                  formik.setFieldValue("surveyairport_id", value.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="Airport Id"
                    value={formik.values.surveyairport_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.surveyairport_id &&
                      Boolean(formik.errors.surveyairport_id)
                    }
                    helperText={
                      formik.touched.surveyairport_id &&
                      formik.errors.surveyairport_id
                    }
                    sx={{ mb: 2 }}
                  />
                )}
              />
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseAddDialog}
            sx={{ width: 120 }}
          />
          {!isEditDialog && (
            <PrimaryButton
              label="Create"
              onClick={() => formik.handleSubmit()}
              disabled={isLoadingPostSurveyorRegistration}
              isLoading={isLoadingPostSurveyorRegistration}
              sx={{ width: 120 }}
            />
          )}
          {isEditDialog && (
            <PrimaryButton
              label="Update"
              onClick={() => formik.handleSubmit()}
              disabled={isLoadingEditSurveyorRegistration}
              isLoading={isLoadingEditSurveyorRegistration}
              sx={{ width: 120 }}
            />
          )}
        </DialogActions>
      </Dialog>
      <Dialog fullWidth open={openResetPasswordDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              id="new_password"
              name="new_password"
              label="New Password"
              value={passwordResetFormik.values.new_password}
              onChange={passwordResetFormik.handleChange}
              error={
                passwordResetFormik.touched.new_password &&
                Boolean(passwordResetFormik.errors.new_password)
              }
              helperText={
                passwordResetFormik.touched.new_password &&
                passwordResetFormik.errors.new_password
              }
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={passwordResetFormik.values.new_password}
                      onCopy={() => setCopied(true)}
                    >
                      <IconButton
                        edge="end"
                        color={isCopied ? "primary" : "default"}
                      >
                        <FaCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseResetPasswordDialog}
            sx={{ width: 120 }}
          />
          <PrimaryButton
            label="Reset Password"
            onClick={() => passwordResetFormik.handleSubmit()}
            disabled={isResetingMasterPassword}
            isLoading={isResetingMasterPassword}
          />
        </DialogActions>
      </Dialog>
      {openDeleteDialog && (
        <Dialog fullWidth open={openDeleteDialog}>
          <DialogTitle>
            {selectedSurveyor?.enable ? "Disable" : "Enable"}{" "}
            {userLabel[filterUserType]}
          </DialogTitle>
          <DialogContent>
            {`Are you sure you want to ${
              selectedSurveyor?.enable ? "disable" : "enable"
            } ${userLabel[filterUserType]} "${decryptStatic(
              selectedSurveyor?.name,
              aesKey
            )}" ?`}
          </DialogContent>
          <DialogActions>
            <LightButton label="Cancel" onClick={handleCloseDeleteDialog} />
            <LightButton
              label={selectedSurveyor?.enable ? "Disable" : "Enable"}
              color="error"
              onClick={handleDisableSurveyor}
              disabled={isLoadingDisableSurveyor || isLoadingEnableSurveyor}
              isLoading={isLoadingDisableSurveyor || isLoadingEnableSurveyor}
            />
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
