import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { chain, orderBy } from "lodash";
import { apiCall, downloadReadableStreamFile } from "../utils/connect";
import { amdbAirportList } from "../constants/constant";
import { darkColor } from "../constants/colors";
import { airportFeatures } from "../constants/amdbData";
import {
  GET_AMDB_DATA_URL,
  GET_AMDB_AIRPORT_URL,
  EXPORT_GEO_JSON_DATA_URL,
  ADD_EDIT_AMDB_POLYGON_URL,
  DELETE_AMDB_POLYGON_URL,
} from "../constants/urls";

const initialState = {
  isLoadingAMDBData: false,
  amdbDetails: null,
  isLoadingAllAirportamdb: false,
  allamdbAirports: [],
  isExportGeoJSONLoading: false,
  isLoadingAddEditPolygon: false,
  isLoadingDeletePolygon: false,
};

export const amdb = createSlice({
  name: "amdb",
  initialState,
  reducers: {
    setAMDBDataLoadingRequest: (state, action) => {
      state.isLoadingAMDBData = action.payload;
    },
    setAMDBDetails: (state, action) => {
      state.amdbDetails = action.payload;
    },
    setLoadingAllAirportamdb: (state, action) => {
      state.isLoadingAllAirportamdb = action.payload;
    },
    setAllamdbAirports: (state, action) => {
      state.allamdbAirports = action.payload;
    },
    setExportGeoJSONLoading: (state, action) => {
      state.isExportGeoJSONLoading = action.payload;
    },
    setLoadingAddEditPolygon: (state, action) => {
      state.isLoadingAddEditPolygon = action.payload;
    },
    setLoadingDeletePolygon: (state, action) => {
      state.isLoadingDeletePolygon = action.payload;
    },
  },
});

export const {
  setAMDBDataLoadingRequest,
  setAMDBDetails,
  setLoadingAllAirportamdb,
  setAllamdbAirports,
  setExportGeoJSONLoading,
  setLoadingAddEditPolygon,
  setLoadingDeletePolygon,
} = amdb.actions;

export default amdb.reducer;

export const selectAmdbAirports = (state) => {
  const amdbAirports = state.amdb.allamdbAirports;

  if (amdbAirports && amdbAirports.length > 0) {
    return chain(amdbAirports)
      .filter((airport) => {
        return amdbAirportList.includes(airport.name);
      })
      .orderBy(["name"], ["asc"])
      .value();
  }

  return [];
};

export const selectAmdbFeature = (state) => {
  const amdbDetail = state.amdb.amdbDetails;
  const rwylist = amdbDetail?.RWY_ELEMENT_List || [];
  const apnList = amdbDetail?.APN_ELEMENT_List || [];
  const twylist = amdbDetail?.TWY_ELEMENT_List || [];
  const rwyOptions = rwylist.map((rwy, i) => {
    return {
      id: "rwy" + i,
      name: rwy.type,
      value: rwy.type,
      color: darkColor[i],
    };
  });
  const apnOptions = apnList.map((apn, i) => {
    return {
      id: "apn" + i,
      name: apn.apn,
      value: "apn_" + apn.apn,
      color: darkColor[i + rwyOptions.length],
    };
  });
  const twyOptions = twylist.map((twy, i) => {
    return {
      id: "twy" + i,
      name: twy.twy,
      value: "twy_" + twy.twy,
      color: darkColor[i + rwyOptions.length + apnOptions.length],
    };
  });

  const amdbFeatures = airportFeatures.map((af) => {
    if (af.value === "RWY_ELEMENT_List") {
      af.sub = orderBy(rwyOptions, ["name"], ["asc"]);
    }
    if (af.value === "APN_ELEMENT_List") {
      af.sub = orderBy(apnOptions, ["name"], ["asc"]);
    }
    if (af.value === "TWY_ELEMENT_List") {
      af.sub = orderBy(twyOptions, ["name"], ["asc"]);
    }

    return af;
  });

  return amdbFeatures;
};

export const selectParkingBayLineList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (
    amdbDetail?.PARKING_BAYLINE_List &&
    amdbDetail.PARKING_BAYLINE_List.length > 0
  ) {
    return amdbDetail.PARKING_BAYLINE_List;
  }

  return [];
};

export const selectParkingBayPointsList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (
    amdbDetail?.PARKING_BAY_POINTS_List &&
    amdbDetail.PARKING_BAY_POINTS_List.length > 0
  ) {
    return amdbDetail.PARKING_BAY_POINTS_List;
  }

  return [];
};

export const selectRwyElementMarkingList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (
    amdbDetail?.RWY_ELE_MARKING_List &&
    amdbDetail.RWY_ELE_MARKING_List.length > 0
  ) {
    return amdbDetail.RWY_ELE_MARKING_List;
  }

  return [];
};

export const selectApnElementList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (amdbDetail?.APN_ELEMENT_List && amdbDetail.APN_ELEMENT_List.length > 0) {
    return amdbDetail.APN_ELEMENT_List;
  }

  return [];
};

export const selectRwyElementList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (amdbDetail?.RWY_ELEMENT_List && amdbDetail.RWY_ELEMENT_List.length > 0) {
    return amdbDetail.RWY_ELEMENT_List;
  }

  return [];
};

export const selectTwyElementList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (amdbDetail?.TWY_ELEMENT_List && amdbDetail.TWY_ELEMENT_List.length > 0) {
    return amdbDetail.TWY_ELEMENT_List;
  }

  return [];
};

export const getAMDBAirports = () => (dispatch) => {
  try {
    dispatch(setLoadingAllAirportamdb(true));

    const onSuccess = (response) => {
      dispatch(setAllamdbAirports(response.data));
      dispatch(setLoadingAllAirportamdb(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAllAirportamdb(false));
    };

    apiCall("GET", GET_AMDB_AIRPORT_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAllAirportamdb(false));
    toast.error(error.message);
  }
};

export const getAMDBDetails = (airportId) => (dispatch) => {
  try {
    dispatch(setAMDBDataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAMDBDetails(response.data));
      dispatch(setAMDBDataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAMDBDataLoadingRequest(false));
    };

    apiCall("GET", GET_AMDB_DATA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setAMDBDataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const exportGeoJSONData = (type, airport) => (dispatch) => {
  try {
    dispatch(setExportGeoJSONLoading(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename ? filename : `$${airport.location_indicator}_${type}.json`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportGeoJSONLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportGeoJSONLoading(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_GEO_JSON_DATA_URL(type, airport?.id),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setExportGeoJSONLoading(false));
    toast.error(error.message);
  }
};

export const addEditAMDBpolygon =
  (payload, airportId, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingAddEditPolygon(true));

      const onSuccess = () => {
        toast.success("Polygon added/updated successfully");
        const onSuccessAmdb = (response) => {
          dispatch(setAMDBDetails(response.data));
          dispatch(setLoadingAddEditPolygon(false));
          callback();
        };
        const onFailureAmdb = (error) => {
          toast.error(error.message);
          dispatch(setLoadingAddEditPolygon(false));
          callback();
        };

        apiCall(
          "GET",
          GET_AMDB_DATA_URL(airportId),
          "",
          onSuccessAmdb,
          onFailureAmdb
        );
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAddEditPolygon(false));
      };

      apiCall(
        "POST",
        ADD_EDIT_AMDB_POLYGON_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingAddEditPolygon(false));
      toast.error(error.message);
    }
  };

export const deleteAMDBpolygon =
  (poly_id, feature, airportId, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingDeletePolygon(true));

      const onSuccess = () => {
        toast.success("Polygon deleted successfully");
        const onSuccessAmdb = (response) => {
          dispatch(setAMDBDetails(response.data));
          dispatch(setLoadingAddEditPolygon(false));
          callback();
        };
        const onFailureAmdb = (error) => {
          toast.error(error.message);
          dispatch(setLoadingAddEditPolygon(false));
          callback();
        };

        apiCall(
          "GET",
          GET_AMDB_DATA_URL(airportId),
          "",
          onSuccessAmdb,
          onFailureAmdb
        );
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingDeletePolygon(false));
      };

      apiCall(
        "DELETE",
        DELETE_AMDB_POLYGON_URL(poly_id, feature),
        "",
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingDeletePolygon(false));
      toast.error(error.message);
    }
  };
