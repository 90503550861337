import React from "react";

export const FileIcon = (props) => (
  <svg
    width={42}
    height={52}
    viewBox="0 0 42 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31 28.6667C31 27.5621 30.1045 26.6667 29 26.6667H13C11.8954 26.6667 11 27.5621 11 28.6667C11 29.7712 11.8954 30.6667 13 30.6667H29C30.1045 30.6667 31 29.7712 31 28.6667Z"
      fill="#F2F3F6"
    />
    <path
      d="M31 39.3333C31 38.2288 30.1045 37.3333 29 37.3333H13C11.8954 37.3333 11 38.2288 11 39.3333C11 40.4379 11.8954 41.3333 13 41.3333H29C30.1045 41.3333 31 40.4379 31 39.3333Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66665 0C3.61656 0 0.333313 3.28324 0.333313 7.33333V44.6667C0.333313 48.7168 3.61656 52 7.66665 52H34.3333C38.3834 52 41.6666 48.7168 41.6666 44.6667V15.2471C41.6666 14.2317 41.3355 13.244 40.7234 12.4339L32.7293 1.85344C31.8473 0.686128 30.4689 0 29.0059 0H7.66665ZM4.33331 7.33333C4.33331 5.49238 5.8257 4 7.66665 4H27V15.7255C27 16.83 27.8954 17.7255 29 17.7255H37.6666V44.6667C37.6666 46.5076 36.1743 48 34.3333 48H7.66665C5.8257 48 4.33331 46.5076 4.33331 44.6667V7.33333Z"
      fill="#F2F3F6"
    />
  </svg>
);
