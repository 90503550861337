import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableRowLoader from '../tabel-component/TableRowLoader';

export default function FIRTable({
  isLoading,
  FIRData,
  selectedFIR
}) {
  const rows = FIRData.filter((fir) => selectedFIR.includes(fir.name));

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>
              <Box>Lateral Limits</Box>
              <Box>Class of airspace</Box>
            </StyledTableCell>
            <StyledTableCell>Vertical Limits</StyledTableCell>
            <StyledTableCell>Unit providing service</StyledTableCell>
            <StyledTableCell>Call sign, Languages, Hours of service</StyledTableCell>
            <StyledTableCell>Frequency/Purpose</StyledTableCell>
            <StyledTableCell>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader isLoading={isLoading} data={FIRData} colSpan={7} />
          {rows.map((row, i) => {
            const rowSpan = row?.extracol.length === 0 ? 2 : row?.extracol.length + 1;

            return (
              <Fragment key={`${i}_${row?.name}`}>
                <TableRow>
                  <StyledTableCell rowSpan={rowSpan}>{row?.name || '-'}</StyledTableCell>
                  <StyledTableCell rowSpan={rowSpan}>
                    <Box>{row?.description || '-'}</Box>
                    <Box>{row?.Airspace_class}</Box>
                    <Box>{row?.Airspace_class_note}</Box>
                  </StyledTableCell>
                  <StyledTableCell rowSpan={rowSpan}>
                    {row?.AV_upperLimit || '-'} / {row?.AV_lowerLimit || '-'}
                  </StyledTableCell>
                </TableRow>
                {
                  row?.extracol.length > 0 && row?.extracol.map((col, j) => {
                    return (
                      <TableRow key={`${i}_${j}_${row?.name}`}>
                        <StyledTableCell>{col.unit}</StyledTableCell>
                        <StyledTableCell>{`${col.call_sign}, ${col.languages}, ${col.hour}`}</StyledTableCell>
                        <StyledTableCell>{col.frequency}</StyledTableCell>
                        {j === 0 && <StyledTableCell rowSpan={row?.extracol.length}>{row?.remarks || '-'}</StyledTableCell>}
                      </TableRow>
                    )
                  })
                }
                {
                  row?.extracol.length === 0 && [1].map((col, j) => {
                    return (
                      <TableRow key={`${i}_${j}_${row?.name}`}>
                        <StyledTableCell>-</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                        {j === 0 && <StyledTableCell rowSpan={rowSpan}>-</StyledTableCell>}
                      </TableRow>
                    )
                  })
                }
              </Fragment>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};