import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import parseQueryParameters from 'parse-url-query-params';

export default function RenderGroundOverlay({
  rasterAreaList,
  selectedRasterArea
}) {
  const [rasterLayers, setRasterLayer] = useState([]);
  const map = useMap();

  useEffect(() => {
    if (!rasterAreaList) return;
    const rasterLayer = rasterAreaList?.map((area) => {
      const bounds = [
        [area.bounds_geometry.north, area.bounds_geometry.east],
        [area.bounds_geometry.south, area.bounds_geometry.west]
      ];

      return L.imageOverlay(`${area.image_url}?area=${area.area_type}`, bounds);
    });

    setRasterLayer(rasterLayer);
  }, [rasterAreaList]);

  useEffect(() => {
    rasterLayers.forEach(layer => {
      const prsedUrl = parseQueryParameters(layer._url);
      const isSelected = selectedRasterArea.includes(`${prsedUrl.area}`);

      if (isSelected) {
        layer.addTo(map);
      } else {
        layer.removeFrom(map);
      }
    });
  }, [selectedRasterArea]); // eslint-disable-line

  return null;
};