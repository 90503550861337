import React from "react";

export const PieChartIcon = (props) => (
  <svg
    width={52}
    height={52}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 4.08969C12.787 5.10014 4 14.5239 4 26C4 38.1503 13.8497 48 26 48C32.1903 48 37.7828 45.4452 41.7826 41.3269L24.7429 27.5555C24.273 27.1758 24 26.6041 24 26V4.08969ZM28 4.08969V24H47.9103C46.9592 13.4457 38.5543 5.04078 28 4.08969ZM47.9104 28H31.6565L44.2988 38.2174C46.2886 35.2438 47.572 31.7593 47.9104 28ZM0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26C52 32.1899 49.8345 37.8793 46.2214 42.3443C41.4581 48.2307 34.1685 52 26 52C11.6406 52 0 40.3594 0 26Z"
      fill="#F2F3F6"
    />
  </svg>
);
