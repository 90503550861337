import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow)(() => ({
  [`&.${tableRowClasses.selected}`]: {
    backgroundColor: "rgb(179, 157, 219)",
  },
}));

export default StyledTableRow;
