import React from 'react';

function PopupItem({ children }) {
  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default PopupItem;

const styles = {
  borderColor: 'black',
  borderBottom: '1px solid',
  paddingTop: 5,
  paddingBottom: 5
};