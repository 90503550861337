export const exportObstacleTypes = (feature) => {
  const types = [
    {
      id: 1,
      label: "Area 1",
      value: "areaoneobstacles",
      feature: "area",
    },
    {
      id: 2,
      label: "Area 2",
      value: "areatwoobstacles",
      feature: "area",
    },
    {
      id: 3,
      label: "Area 3",
      value: "areathreeobstacles",
      feature: "area",
    },
    {
      id: 4,
      label: "OLS",
      value: "olsobstacles",
      feature: "OLS",
    },
  ];

  if (feature) {
    return types.filter((type) => type.feature === feature);
  }

  return types.filter((type) => type.id === 1);
};

export const areaFilter = [
  {
    id: 1,
    label: "Area",
    value: "area",
  },
  {
    id: 2,
    label: "OLS",
    value: "OLS",
  },
];

export const ofzOptionLabel = {
  BLS: "Balked Landing",
  IAS: "Inner Approach",
  ITS: "Inner Transitional Surface",
};

export const OLSOptionList = [
  {
    id: 1,
    name: "Approach",
    value: "OLS_Approach_List",
    color: "#469990",
    sub: [],
  },
  {
    id: 2,
    name: "Horizontal Surface",
    value: "OLS_HorizontalSurface_List",
    color: "#262261",
    sub: [],
  },
  {
    id: 3,
    name: "Transitional Surface",
    value: "Transitional_Surface",
    color: "#262261",
    sub: [],
  },
  {
    id: 4,
    name: "RWY Strip",
    value: "OLS_RwyStrip_List",
    color: "#000000",
    sub: [],
  },
  {
    id: 5,
    name: "Take off",
    value: "OLS_TakeOffClimb_List",
    color: "#f58231",
    sub: [],
  },
  {
    id: 6,
    name: "Obstacle Free Zone",
    value: "OLS_ObstacleFreeZone_List",
    color: "#262261",
    sub: [],
  },
];
