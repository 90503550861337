import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Header from "../components/Header";
import BrandLogo from "../components/BrandLogo";
import BackButton from "../components/button/BackButton";
import { UploadButton, PrimaryButton } from "../components/button";
import { theme } from "../styles/theme";
import { generateCRC32Q } from "../models/userSession";

export default function CRCCheck() {
  const dispatch = useDispatch();
  const isCRC32QGenerateRequesting = useSelector(
    (state) => state.userSession.isCRC32QGenerateRequesting
  );
  const [generatedCrcCode, setGeneratedCrcCode] = useState(null);
  const [file, setFile] = useState(null);
  const [result, setCheckResult] = useState(null);
  const [values, setValues] = React.useState({
    crccode: "",
  });

  const handleFileUpload = (event) => {
    event.preventDefault();

    const files = event.target.files;
    setFile(files[0]);
    dispatch(
      generateCRC32Q(files[0], (crcCode) => {
        setGeneratedCrcCode(crcCode);
      })
    );
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheckCRC = () => {
    setCheckResult({
      isValid: generatedCrcCode.toString() === values.crccode.toString(),
      fileName: file.name,
      servercode: generatedCrcCode,
    });
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <BrandLogo />
        <BackButton />
      </div>
      <div className="main">
        <Header active="data-management" />
        <div className="crc-content">
          <div style={{ width: "100%" }}>
            <Stack
              direction="row"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                pt: 1,
                pb: 1,
                pl: 2,
              }}
            >
              <Typography>Check File CRC32Q Value</Typography>
            </Stack>
          </div>
          <div style={{ width: "98.3%", padding: 10 }}>
            <UploadButton
              label="Upload File"
              handleUpload={handleFileUpload}
              isLoading={isCRC32QGenerateRequesting}
              sx={{ width: "100%" }}
            />
            {file && <Typography>{file.name}</Typography>}
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
              <InputLabel>CRC32Q CODE</InputLabel>
              <OutlinedInput
                required
                value={values.crccode}
                onChange={handleChange("crccode")}
                label="crccode"
              />
            </FormControl>
            <Stack
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <PrimaryButton
                label="Check"
                disabled={!values.crccode || isCRC32QGenerateRequesting}
                onClick={() => handleCheckCRC()}
                sx={{ width: 200 }}
              />
            </Stack>
            {result && (
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ mt: 2 }}
              >
                <Box>
                  <Typography>ATTACHED FILE NAME</Typography>
                  <Typography>{result.fileName}</Typography>
                </Box>
                <Box>
                  <Typography>SERVER CRC32Q CODE</Typography>
                  <Typography>{result.servercode}</Typography>
                </Box>
                <Box>
                  <Typography>STATUS</Typography>
                  {result.isValid ? (
                    <Typography color="secondary">Valid</Typography>
                  ) : (
                    <Typography color="error">invalid</Typography>
                  )}
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
