import React from 'react';
import { join } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableRowLoader from '../tabel-component/TableRowLoader';

export default function DPRTable({
  isLoading,
  DPRDataList,
  selectedDPR,
}) {
  const rows = DPRDataList.filter((dpr) => {
    const dprName = `${dpr.Airspace_name} ${dpr.designator}`;
    return selectedDPR.includes(dprName);
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 200 }}>
              <Box>Identification</Box>
              <Box>Name</Box>
            </StyledTableCell>
            <StyledTableCell>
              Lateral Limits
            </StyledTableCell>
            <StyledTableCell sx={{ width: 200 }}>
              <Box>Upper Limit</Box>
              <Box>Lower Limit</Box>
            </StyledTableCell>
            <StyledTableCell sx={{ width: 200 }}>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader isLoading={isLoading} data={DPRDataList} colSpan={4} />
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.Airspace_name}`}>
                <StyledTableCell>
                  <Box>{row?.designator || '-'}</Box>
                  <Box>{row?.Airspace_name || '-'}</Box>
                </StyledTableCell>
                <StyledTableCell>{row?.description || '-'}</StyledTableCell>
                <StyledTableCell>
                  <Box>{row?.upperLimit || '-'}</Box>
                  <Box>{row?.lowerLimit || '-'}</Box>
                </StyledTableCell>
                <StyledTableCell>{join(row?.remarks, ', ') || '-'}</StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};