import React, { useState } from 'react';
import { FeatureGroup, CircleMarker } from 'react-leaflet';
import { staticColorMap } from '../../constants/colors';
import { areaHighLightColor } from '../../constants/colors';
import Area3ObstacleInfoPopup from './Area3ObstacleInfoPopup';

export default function RenderArea3Obstacles({
  selctedAirport,
  areaThreeObstacles,
  isShowPenetrationObstacle,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  let visibleArea3Obstacle = areaThreeObstacles;
  if (isAbovePermissibleClick) {
    visibleArea3Obstacle = visibleArea3Obstacle.filter((obstacle) => {
      return obstacle.penetration && Number(obstacle.penetration) >= 0;
    });
  } else if (isBelowPermissibleClick) {
    visibleArea3Obstacle = visibleArea3Obstacle.filter((obstacle) => {
      return obstacle.penetration && Number(obstacle.penetration) <= 0;
    });
  };

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <FeatureGroup pane="popupPane">
      {
        visibleArea3Obstacle.map((obstacles, i) => {
          let obstacleColor = staticColorMap['Obstacles'];

          if (obstacles.penetration === 'NMAE+0.5') {
            obstacleColor = staticColorMap['BlueObstacle'];
          };

          if (isShowPenetrationObstacle && obstacles.penetration !== 'NMAE+0.5') {
            obstacleColor = Number(obstacles.penetration) >= 0
              ? staticColorMap['RedObstacle']
              : staticColorMap['GreenObstacle'];
          };

          return (
            <CircleMarker
              key={i}
              center={{
                lat: obstacles.coordinates[0],
                lng: obstacles.coordinates[1]
              }}
              radius={6}
              pathOptions={{
                fillColor: obstacleColor,
                color: selectedOverE?.id === obstacles.id ? areaHighLightColor : obstacleColor,
                weight: selectedOverE?.id === obstacles.id ? 3 : 1,
                fillOpacity: 1,
              }}
              eventHandlers={{
                mouseover: () => handlePolygonOver(obstacles),
                mouseout: () => handlePolygonOut()
              }}
            >
              <Area3ObstacleInfoPopup
                info={obstacles}
                selctedAirport={selctedAirport}
              />
            </CircleMarker>
          )
        })
      }
    </FeatureGroup>
  );
};