import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uniq } from "lodash";
import { defaultLocationCenter } from "../constants/constant";
import Header from "../components/Header";
import AmdbMap from "../components/amdbOSMMap/MapContainer";
import AmdbSideBar from "../components/AmdbSideBar";
import {
	getAMDBAirports,
	getAMDBDetails,
	exportGeoJSONData,
	setAMDBDetails,
	selectAmdbAirports,
	selectAmdbFeature,
	selectParkingBayLineList,
	selectParkingBayPointsList,
	selectRwyElementMarkingList,
	selectApnElementList,
	selectRwyElementList,
	selectTwyElementList
} from "../models/amdb";

export default function Amdb() {
	const dispatch = useDispatch();
	const isLoadingAllAirportamdb = useSelector((state) => state.aixm.isLoadingAllAirportamdb);
	const isLoadingAMDBDetail = useSelector((state) => state.amdb.isLoadingAMDBData);
	const isExportGeoJSONLoading = useSelector((state) => state.amdb.isExportGeoJSONLoading);
	const allAirports = useSelector(selectAmdbAirports);
	const amdbFeatures = useSelector(selectAmdbFeature);
	const parkingBayLineList = useSelector(selectParkingBayLineList);
	const parkingBayPointsList = useSelector(selectParkingBayPointsList);
	const rwyElementMarkingList = useSelector(selectRwyElementMarkingList);
	const apnElementList = useSelector(selectApnElementList);
	const rwyElementList = useSelector(selectRwyElementList);
	const twyElementList = useSelector(selectTwyElementList);
	const [selctedAirport, setAirport] = useState(null);
	const [zoomLevel, setZoomLevel] = useState(4.7);
	const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
	const [selectedFeatures, setSelectedFeatures] = useState([]);
	const [exportGeoJSON, setExportGeoJSON] = useState([]);

	useEffect(() => {
		dispatch(getAMDBAirports());
	}, []); // eslint-disable-line

	const handleAirport = (option) => {
		setSelectedFeatures([]);

		if (option === null) {
			dispatch(setAMDBDetails(null));
			setAirport(null);
			setAirportLocation(defaultLocationCenter);
			setZoomLevel(4.7);
			handleClearAllFeature();
		} else {
			dispatch(getAMDBDetails(option.id));
			setAirport(option);
			setAirportLocation(option.coordinates);
			setZoomLevel(15);
		}
	};

	const updateSelectedFeatures = (value) => {
		if (!selectedFeatures.includes(value)) {
			selectedFeatures.push(value);
		} else {
			selectedFeatures.splice(selectedFeatures.indexOf(value), 1);
		};
	};

	const handleFeatureCheck = (feature, color) => {
		updateSelectedFeatures(`${feature.value}__${color}`);

		feature.sub?.forEach((f1) => {
			updateSelectedFeatures(`${f1.value}__${color}`);
		});

		setSelectedFeatures(uniq([...selectedFeatures]));
	};

	const handleParentFeatureCheck = (event, feature, color) => {
		const subFeatures = [];
		const isChecked = event.target.checked;
		const exportFeatures = [];

		if (isChecked) {
			exportFeatures.push(feature.value);
			subFeatures.push(`${feature.value}__${color}`);
			feature.sub?.forEach((f1) => {
				subFeatures.push(`${f1.value}__${f1.color}`);
			});
		} else {
			exportGeoJSON.splice(exportGeoJSON.indexOf(feature.value), 1);
			selectedFeatures.splice(selectedFeatures.indexOf(`${feature.value}__${color}`), 1);
			feature.sub?.forEach((f1) => {
				selectedFeatures.splice(selectedFeatures.indexOf(`${f1.value}__${f1.color}`), 1);
			});
		}

		setSelectedFeatures(uniq([...selectedFeatures, ...subFeatures]));
		setExportGeoJSON(uniq([...exportGeoJSON, ...exportFeatures]));
	};

	const handleExportGeoJSON = () => {
		exportGeoJSON.forEach((type) => {
			dispatch(exportGeoJSONData(type, selctedAirport));
		});
	};

	const handleDrawCallback = () => {
		setSelectedFeatures([...selectedFeatures]);
	};

	const handleSelectAllFeature = () => {
		const allFeature = [];
		const allExportfeatures = [];
		amdbFeatures.forEach((f0) => {
			allFeature.push(`${f0.value}__${f0.color}`);
			allExportfeatures.push(f0.value);

			f0.sub?.forEach((f1) => {
				allFeature.push(`${f1.value}__${f1.color}`);
			});
		});

		setSelectedFeatures(uniq([...allFeature]));
		setExportGeoJSON(uniq([...allExportfeatures]));
	};

	const handleClearAllFeature = () => {
		setSelectedFeatures([]);
		setExportGeoJSON([]);
	};

	return (
		<div className="wrapper">
			<AmdbSideBar
				isLoadingAllAirportamdb={isLoadingAllAirportamdb}
				isLoadingAMDBDetail={isLoadingAMDBDetail}
				isExportGeoJSONLoading={isExportGeoJSONLoading}
				allAirports={allAirports}
				features={amdbFeatures}
				selectedFeatures={selectedFeatures}
				selctedAirport={selctedAirport}
				isActiveAirport={!selctedAirport}
				exportGeoJSON={exportGeoJSON}
				handleParentFeatureCheck={handleParentFeatureCheck}
				handleFeatureCheck={handleFeatureCheck}
				handleSelectAllFeature={handleSelectAllFeature}
				handleClearAllFeature={handleClearAllFeature}
				handleAirport={handleAirport}
				handleExportGeoJSON={handleExportGeoJSON}
			/>
			<div className="main">
				<Header active="amdb" />
				<div className="content-area" id="amdb-map-export">
					<AmdbMap
						airportLocation={airportLocation}
						zoomLevel={zoomLevel}
						selctedAirport={selctedAirport}
						selectedFeatures={selectedFeatures}
						parkingBayLineList={parkingBayLineList}
						parkingBayPointsList={parkingBayPointsList}
						rwyElementMarkingList={rwyElementMarkingList}
						rwyElementList={rwyElementList}
						apnElementList={apnElementList}
						twyElementList={twyElementList}
						handleDrawCallback={handleDrawCallback}
					/>
				</div>
			</div>
		</div>
	)
}