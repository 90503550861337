import React, { useEffect, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { split } from 'lodash';
import { Polyline } from 'react-leaflet';

export default function RenderParkingBayLine({
  selectedFeatures,
  parkingBayLineList,
}) {
  const featureKey = 'PARKING_BAYLINE_List';
  const [areaColor, setAreaColor] = useState(null);
  const [isVisibleParkingBayLine, setVisibleParkingBayLine] = useState(false);

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, '__');
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const isVisible =
      selectedFeatures.includes(`${featureKey}__${areaColorT[featureKey]}`);

    setAreaColor(areaColorT);
    setVisibleParkingBayLine(isVisible);
  }, [selectedFeatures]); // eslint-disable-line

  if (!isVisibleParkingBayLine || !areaColor) {
    return null;
  };

  return (
    <FeatureGroup>
      {
        parkingBayLineList.map((pblGeometry) => {
          const pblCoordinates = pblGeometry.coordinates.coordinates;
          const pblCoordinateMap = pblCoordinates.map((pblCoords) => {
            return pblCoords.map((pbl) => {
              return {
                lng: pbl[0],
                lat: pbl[1]
              };
            });
          });

          return pblCoordinateMap.map((pblLinePath, i) => (
            <Polyline
              key={i}
              pane="mapPane"
              positions={pblLinePath}
              pathOptions={{
                color: areaColor[featureKey],
                weight: 2,
                zIndex: 4
              }}
            />
          ));
        })
      }
    </FeatureGroup>
  );
};