import React, { Fragment, useState } from "react";
import { Polyline, Polygon, CircleMarker } from "react-leaflet";
import { flatten } from "lodash";
import { mapDrawType } from "../../constants/constant";
import { areaHighLightColor } from "../../constants/colors";
import InfoPopup from "./InfoPopup";

export default function RenderControlledAirspace({
  controlAirspaceGeometry,
  colorTray,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {controlAirspaceGeometry.map((data, i) => {
        const option = {
          color:
            selectedOverE?.name === data.name
              ? areaHighLightColor
              : colorTray[i],
          weight: selectedOverE?.name === data.name ? 3 : 2,
          fillColor: colorTray[i],
        };

        if (data.typeOfcoordinates === mapDrawType.point) {
          return (
            <CircleMarker
              key={i}
              pane="tooltipPane"
              center={flatten(data.coordinates)[0]}
              pathOptions={{
                ...option,
                color: "#8b0000",
                fillOpacity: 1,
              }}
              radius={6}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, data),
                mouseout: () => handlePolygonOut(),
              }}
            >
              <InfoPopup info={data} isShowHint={false} />
            </CircleMarker>
          );
        }

        if (data.typeOfcoordinates === mapDrawType.lineString) {
          return (
            <Polyline
              key={i}
              positions={flatten(data.coordinates)}
              pathOptions={option}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, data),
                mouseout: () => handlePolygonOut(),
              }}
            >
              <InfoPopup info={data} isShowHint={false} />
            </Polyline>
          );
        }

        return (
          <Polygon
            key={i}
            positions={flatten(data.coordinates)}
            pathOptions={option}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, data),
              mouseout: () => handlePolygonOut(),
            }}
          >
            <InfoPopup info={data} isShowHint={false} />
          </Polygon>
        );
      })}
    </Fragment>
  );
}
