import React from 'react';
import { join } from 'lodash';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { convertToDms } from '../../utils/geocoords';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableRowLoader from '../tabel-component/TableRowLoader';

export default function RNTable({
  isLoading,
  RNData,
  selectedRN,
}) {
  const rows = RNData.filter((rn) => selectedRN.includes(rn.designator));
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name of the Station</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Frequency (Channel)</StyledTableCell>
            <StyledTableCell>Hours of operation</StyledTableCell>
            <StyledTableCell>Coordinates</StyledTableCell>
            <StyledTableCell>DME Antenna Elevation</StyledTableCell>
            <StyledTableCell sx={{ width: 200 }}>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader isLoading={isLoading} data={RNData} colSpan={8} />
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.name}`}>
                <StyledTableCell>{row?.name || '-'}</StyledTableCell>
                <StyledTableCell>{row?.type || '-'}</StyledTableCell>
                <StyledTableCell>{row?.designator || '-'}</StyledTableCell>
                <StyledTableCell>{join(row?.channel_freq, ', ') || '-'}</StyledTableCell>
                <StyledTableCell>{row?.operationalStatus || '-'}</StyledTableCell>
                <StyledTableCell style={{ width: 150 }}>
                  <Box>{convertToDms(row?.dd_coordinates[0])}</Box>
                  <Box>{convertToDms(row?.dd_coordinates[1], true)}</Box>
                </StyledTableCell>
                <StyledTableCell>{row?.elevation || '-'}</StyledTableCell>
                <StyledTableCell>{join(row?.remarks, ', ') || '-'}</StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};