import React, { Fragment } from "react";
import Select from "react-select";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import EChartExportButton from "./EChartExportButton";
import DropDownLabel from "./DropDownLabel";
import BrandLogo from "./BrandLogo";

const ElecronicChartSideBar = ({
  isLoadingAlleChartAirport,
  isLoadingechartPDFData,
  isLoadingechartData,
  isLoadingAlleChartExportUrl,
  allAirports = [],
  selctedAirport,
  handleAirport,
  eChartOptions,
  handleeChartValueChange,
  selectedEchart,
  isShowLabel,
  handleLabelToggle,
  isShowTable,
  handleTableToggle,
  handleChartExport,
  atocaData,
  alleChartExportUrls,
  handleShowMaxTerrainToggle,
  isShowMaxTerrain,
}) => {
  return (
    <div className="sidebar">
      <BrandLogo />
      <Box>
        <DropDownLabel label="Airport" />
        <Select
          isClearable
          isLoading={isLoadingAlleChartAirport}
          value={selctedAirport || null}
          placeholder="Select..."
          onChange={handleAirport}
          options={allAirports}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
        />
      </Box>
      {isLoadingechartPDFData || isLoadingechartData ? (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      ) : (
        <Divider sx={{ mt: 2, mb: 1 }} />
      )}
      <div className="echart-list-header">
        {eChartOptions.length > 0 && (
          <Fragment>
            <Box sx={{ m: 0 }}>
              <Switch
                disabled={!selectedEchart}
                checked={isShowLabel}
                onChange={handleLabelToggle}
              />
              Show Label
            </Box>
          </Fragment>
        )}
        {eChartOptions.length > 0 && (
          <Fragment>
            <Box sx={{ m: 0 }}>
              <Switch
                disabled={!selectedEchart || selectedEchart.startsWith("ATOCA")}
                checked={isShowTable}
                onChange={handleTableToggle}
              />
              Show Table Data
            </Box>
          </Fragment>
        )}
        {eChartOptions.length > 0 && (
          <Fragment>
            <Box sx={{ m: 0 }}>
              <Switch
                disabled={
                  !selectedEchart ||
                  !selectedEchart.startsWith("ATOCA") ||
                  (atocaData &&
                    atocaData.ProfileView.length > 0 &&
                    atocaData.ProfileView.maxterrainprofile_list.length === 0)
                }
                checked={isShowMaxTerrain}
                onChange={handleShowMaxTerrainToggle}
              />
              Show Max Terrain
            </Box>
          </Fragment>
        )}
      </div>
      <div className="check-list echart-check-list">
        <FormControl>
          <RadioGroup
            aria-labelledby="eChart-radio-buttons"
            name="eChart-radio-buttons-group"
            value={selectedEchart || ""}
            onChange={handleeChartValueChange}
            sx={{ ml: 2 }}
          >
            {eChartOptions.map((option, i) => {
              return (
                <FormControlLabel
                  key={i}
                  label={option.label}
                  value={option.value}
                  disabled={!selctedAirport}
                  control={<Radio />}
                />
              );
            })}
            {eChartOptions.length > 0 &&
              atocaData &&
              atocaData.Runway_list.length > 0 &&
              atocaData.Runway_list.map((rwy, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    label={`ATOCA-RWY-${rwy}`}
                    value={`ATOCA-RWY-${rwy}`}
                    disabled={!selctedAirport}
                    control={<Radio />}
                  />
                );
              })}
          </RadioGroup>
        </FormControl>
      </div>
      {alleChartExportUrls && alleChartExportUrls.length > 0 && (
        <div
          style={{ position: "absolute", bottom: "1.5rem", width: "inherit" }}
        >
          <div className="etod-sidebar-footer">
            <EChartExportButton
              label="Export Chart"
              selectedEchart={selectedEchart}
              isLoading={isLoadingAlleChartExportUrl}
              handleExport={handleChartExport}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ElecronicChartSideBar;
