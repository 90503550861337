export const aixmFeatureKeyMap = {
  AeronauticalGroundLight_list: "Aeronautical Ground Light",
  AirTrafficControlService_list: "Air Traffic Control Service",
  AirportGroundService_list: "Airport Ground Service",
  AirportHeliport_list: "Airport Heliport",
  AirportHeliportCollocation: "Airport Heliport Collocation",
  AirportHotSpot: "Airport HotSpot",
  Apron_list: "Apron",
  DesignatedPoint: "Designated Point",
  FlightConditionElementChoice: "Flight Condition Element Choice",
  FlightRoutingElementChoice: "Flight Routing Element Choice",
  GroundTrafficControlService: "Ground Traffic Control Service",
  InformationService_list: "Information Service",
  Navaid_list: "Navaid",
  NavigationSystemCheckpoint_list: "Navigation System Checkpoint",
  NonMovementArea: "Non Movement Area",
  notes_list: "Notes",
  Procedur: "Procedur",
  RadarSystem: "Radar System",
  Road: "Road",
  RulesProcedures_list: "Rules Procedures",
  SafeAltitudeArea: "Safe Altitude Area",
  SignificantPoint: "Significant Point",
  SurveyControlPoint: "Survey Control Point",
  Taxiway_list: "Taxiway",
  TouchDownLiftOff: "Touch Down Lift Off",
  unit_list: "Unit",
  WorkArea: "Work Area",
  ObstacleArea_list: "Obstacle Area",
  Runway_List: "Runway",
  RD_List: "Runway Direction",
  MeteorologicalInformation_list: "Meteorological Information Provided",
  Handling_services_list: "Handling Services and Facilites",
  Passenger_facilities_list: "Passenger Facilities",
  Rescue_list: "Rescue and Fire Fighting Services",
  Seasonal_service_list: "Seasonal Availability Clearing",
  Operator_list: "Operational Hours",
  Surface_Movement_Guidance:
    "Surface Movement Guidance and Control System and Markings",
};

export const aixmCategory = {
  aeronauticalGroundLight_list: "AeronauticalGroundLight_list",
  airTrafficControlService: "AirTrafficControlService_list",
  airportGroundService: "AirportGroundService_list",
  airportHeliport_list: "AirportHeliport_list",
  airportHeliportCollocation: "AirportHeliportCollocation",
  airportHotSpot: "AirportHotSpot",
  apron_list: "Apron_list",
  designatedPoint: "DesignatedPoint",
  flightConditionElementChoice: "FlightConditionElementChoice",
  flightRoutingElementChoice: "FlightRoutingElementChoice",
  groundTrafficControlService: "GroundTrafficControlService",
  informationService: "InformationService_list",
  navaid: "Navaid_list",
  navigationSystemCheckpoint_list: "NavigationSystemCheckpoint_list",
  nonMovementArea: "NonMovementArea",
  notes_list: "notes_list",
  procedur: "Procedur",
  radarSystem: "RadarSystem",
  road: "Road",
  rulesProcedures: "RulesProcedures_list",
  safeAltitudeArea: "SafeAltitudeArea",
  significantPoint: "SignificantPoint",
  surveyControlPoint: "SurveyControlPoint",
  taxiway_list: "Taxiway_list",
  touchDownLiftOff: "TouchDownLiftOff",
  unit_list: "unit_list",
  workArea: "WorkArea",
  runway_List: "Runway_List",
  RD_List: "RD_List",
  obstacleArea_list: "ObstacleArea_list",
  meteorologicalInformationList: "MeteorologicalInformation_list",
  handlingServicesList: "Handling_services_list",
  passengerFacilitiesList: "Passenger_facilities_list",
  rescueList: "Rescue_list",
  seasonalServiceList: "Seasonal_service_list",
  operatorList: "Operator_list",
  surfaceMovementGuidance: "Surface_Movement_Guidance",
};

export const aixmFilterType = {
  airport: "Airport",
  airspace: "Airspace",
};

export const aixmFilterOptions = [
  {
    id: 1,
    label: "Airport",
    value: "Airport",
  },
  {
    id: 2,
    label: "Airspace",
    value: "Airspace",
  },
];

export const aixmCategories = [
  {
    id: 1,
    label: "AeronauticalGroundLight",
    value: "AeronauticalGroundLight_list",
  },
  {
    id: 2,
    label: "AirTrafficControlService",
    value: "AirTrafficControlService_list",
  },
  {
    id: 3,
    label: "AirportGroundService",
    value: "AirportGroundService_list",
  },
  {
    id: 4,
    label: "AirportHeliport",
    value: "AirportHeliport_list",
  },
  {
    id: 5,
    label: "AirportHeliportCollocation",
    value: "AirportHeliportCollocation",
  },
  {
    id: 6,
    label: "AirportHotSpot",
    value: "AirportHotSpot",
  },
  {
    id: 7,
    label: "Apron",
    value: "Apron_list",
  },
  {
    id: 8,
    label: "DesignatedPoint",
    value: "DesignatedPoint",
  },
  {
    id: 9,
    label: "FlightConditionElementChoice",
    value: "FlightConditionElementChoice",
  },
  {
    id: 10,
    label: "FlightRoutingElementChoice",
    value: "FlightRoutingElementChoice",
  },
  {
    id: 11,
    label: "GroundTrafficControlService",
    value: "GroundTrafficControlService",
  },
  {
    id: 12,
    label: "InformationService",
    value: "InformationService_list",
  },
  {
    id: 13,
    label: "Navaid",
    value: "Navaid_list",
  },
  {
    id: 14,
    label: "NavigationSystemCheckpoint",
    value: "NavigationSystemCheckpoint_list",
  },
  {
    id: 15,
    label: "NonMovementArea",
    value: "NonMovementArea",
  },
  {
    id: 16,
    label: "Notes",
    value: "notes_list",
  },
  {
    id: 18,
    label: "Procedur",
    value: "Procedur",
  },
  {
    id: 19,
    label: "RadarSystem",
    value: "RadarSystem",
  },
  {
    id: 20,
    label: "Road",
    value: "Road",
  },
  {
    id: 21,
    label: "RulesProcedures",
    value: "RulesProcedures_list",
  },
  {
    id: 23,
    label: "SafeAltitudeArea",
    value: "SafeAltitudeArea",
  },
  {
    id: 24,
    label: "SignificantPoint",
    value: "SignificantPoint",
  },
  {
    id: 25,
    label: "SurveyControlPoint",
    value: "SurveyControlPoint",
  },
  {
    id: 26,
    label: "Taxiway",
    value: "Taxiway_list",
  },
  {
    id: 27,
    label: "TouchDownLiftOff",
    value: "TouchDownLiftOff",
  },
  {
    id: 28,
    label: "Unit",
    value: "unit_list",
  },
  {
    id: 29,
    label: "WorkArea",
    value: "WorkArea",
  },
];

export const aixmAirportExportItems = [
  {
    id: 1,
    label: "Airport Heliport",
    value: "airportheliport",
  },
  {
    id: 2,
    label: "Runway",
    value: "runway",
  },
  {
    id: 3,
    label: "Runway Direction",
    value: "runway_direction",
  },
  {
    id: 4,
    label: "Runway Protect Area",
    value: "runway_protect_area",
  },
  {
    id: 5,
    label: "Obstacles",
    value: "obstacles",
  },
  {
    id: 6,
    label: "Apron",
    value: "apron",
  },
  {
    id: 7,
    label: "Taxiway",
    value: "taxiway",
  },
];
