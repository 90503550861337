import { combineReducers } from "@reduxjs/toolkit";
import airspace from "../models/airspace";
import userSession from "../models/userSession";
import amdb from "../models/amdb";
import etod from "../models/etod";
import aixm from "../models/aixm";
import survey from "../models/survey";
import eChart from "../models/electronicChart";
import airport from "../models/airport";

const rootReducer = combineReducers({
  airport,
  airspace,
  userSession,
  amdb,
  etod,
  aixm,
  survey,
  eChart,
});

export default rootReducer;
