import React, { Fragment, useEffect, useState } from "react";
import { orderBy, filter } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import { AES_KEY } from "../../constants/constant";
import { surveyReportStatus } from "../../constants/surveyorData";
import { dataChangedRowColorMap } from "../../constants/colors";
import { decryptStatic } from "../../utils/decodeEncodeData";
import { getStorageValue } from "../../utils/localStorage";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";
import ValueItem from "../tabel-component/ValueItem";
import DiffTableCell from "../tabel-component/DiffTableCell";
import TableLoader from "../tabel-component/TableLoader";
import FullContentLoader from "../loader/FullContentLoader";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import SurveyorTopTableRow from "./SurveyorTopTableRow";
import AirportDetailTableRow from "./AirportDetailTableRow";
import DataTableFooterRow from "./DataTableFooterRow";
import VerticalSignificanceAcceptTable from "./VerticalSignificanceAcceptTable";
import VerticalSignificanceInReviewTable from "./VerticalSignificanceInReviewTable";
import { PrimaryButton } from "../button";

export default function VerticalSignificanceReviewTable({
  isLoadingVerification,
  isLoadingCompareData,
  selected,
  selectedReport,
  surveyReportDetail,
  handleSelectAllClick,
  handleClick,
  handleAccept,
  handleReview,
}) {
  const aesKey = getStorageValue(AES_KEY);
  const [isOpenReviewModal, setOpenReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  const notVerified = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.notVerified,
  ]);
  const acceptedData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.accepted,
  ]);
  const inReviewData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.inReview,
  ]);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    if (selected.length === 0) {
      setOpenReviewModal(false);
    }

    const initReviewData = filter(notVerified, (d) =>
      selected.includes(d.id1)
    ).map((d) => ({
      ...d,
      id: d.id1,
      latitude: false,
      longitude: false,
      top_elve: false,
    }));

    setReviewData(initReviewData);
  }, [selected]); // eslint-disable-line

  const handleCellClick = (key, id) => {
    const updateReviewData = reviewData.map((review) => {
      if (id === review.id1) {
        return {
          ...review,
          [key]: !review[key],
        };
      }

      return review;
    });

    setReviewData(updateReviewData);
  };

  const handleSubmitReviewData = () => {
    const rData = reviewData.map((r) => ({
      id: r.id,
      latitude: r.latitude,
      longitude: r.longitude,
      top_elve: r.top_elve,
    }));

    handleReview(rData);
  };

  const handleCloseInReviewModal = () => {
    setOpenReviewModal(false);
  };

  return (
    <Fragment>
      <SurveyorTopTableRow
        serveyorName={
          selectedReport ? decryptStatic(selectedReport?.user_name, aesKey) : ""
        }
        userId={selectedReport?.user_id}
      />
      <AirportDetailTableRow selectedReport={selectedReport} />
      {(isLoadingCompareData || surveyReportDetail.length === 0) && (
        <TableLoader
          isLoading={isLoadingCompareData}
          data={surveyReportDetail}
        />
      )}
      {isLoadingCompareData && surveyReportDetail.length > 0 && (
        <FullContentLoader />
      )}
      <TableContainer>
        {notVerified.length > 0 && (
          <Fragment>
            {selected.length > 0 && (
              <EnhancedTableToolbar numSelected={selected.length} />
            )}
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < notVerified.length
                      }
                      checked={
                        notVerified.length > 0 &&
                        selected.length === notVerified.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all rows",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    UID
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    OBJECT NAME
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "2vw" }}>
                    <Box>LATITUDE</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Box sx={{ typography: "caption" }}>NEW</Box>
                      <Box sx={{ typography: "caption" }}>OLD</Box>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "2vw" }}>
                    <Box>LONGITUDE</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Box sx={{ typography: "caption" }}>NEW</Box>
                      <Box sx={{ typography: "caption" }}>OLD</Box>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>TOP ELEV. IN m. (EGM 08)</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>DISTANCE IN M. FROM NEW ARP</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>MAG. BRG.FROM NEW ARP</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Box sx={{ typography: "caption" }}>NEW</Box>
                        <Box sx={{ typography: "caption" }}>OLD</Box>
                        <Box sx={{ typography: "caption" }}>DIFF</Box>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(notVerified, ["uid1"], ["asc"]).map((row, i) => {
                  const isItemSelected = isSelected(row.id1);
                  const labelId = `enhanced-table-checkbox-${i}`;

                  return (
                    <TableRow
                      key={i}
                      hover
                      onClick={() => handleClick(row.id1)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        background:
                          dataChangedRowColorMap[row.result] ?? "#FFFFFF",
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell>
                      <DiffTableCell align="center">
                        {row["uid1"]}
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        {row["object1"] || "-"}
                      </DiffTableCell>
                      <DiffTableCell align="center" isdiff={row["latdev"]}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["latitude1"]} />
                          <ValueItem value={row["latitude2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center" isdiff={row["longdev"]}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["longitude1"]} />
                          <ValueItem value={row["longitude2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell
                        align="center"
                        isdiff={Boolean(row["top_elevdiff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["top_elev1"]} />
                          <ValueItem value={row["top_elev2"]} />
                          <ValueItem value={row["top_elevdiff"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell
                        align="center"
                        isdiff={Boolean(row["distancediff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["distance1"]} />
                          <ValueItem value={row["distance2"]} />
                          <ValueItem value={row["distancediff"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell
                        align="center"
                        isdiff={Boolean(row["mag_brgdiff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["mag_brg1"]} />
                          <ValueItem value={row["mag_brg2"]} />
                          <ValueItem value={row["mag_brgdiff"]} />
                        </Stack>
                      </DiffTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <DataTableFooterRow
                  colspan1={6}
                  isLoadingVerification={isLoadingVerification}
                  selectedReport={selectedReport}
                  selected={selected}
                  handleAccept={handleAccept}
                  handleReview={() => setOpenReviewModal(true)}
                />
              </TableFooter>
            </Table>
          </Fragment>
        )}
        {acceptedData.length > 0 && (
          <VerticalSignificanceAcceptTable data={acceptedData} />
        )}
        {inReviewData.length > 0 && (
          <VerticalSignificanceInReviewTable data={inReviewData} />
        )}
      </TableContainer>
      <Dialog fullWidth open={isOpenReviewModal} maxWidth="xl">
        <DialogTitle>
          <Box sx={{ mb: -2 }}>In Review Data</Box>
          <Typography variant="caption">
            Select the data set where deviation is found before submit.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  UID
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  OBJECT NAME
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "2vw" }}>
                  <Box>LATITUDE</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "2vw" }}>
                  <Box>LONGITUDE</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>TOP ELEV. IN m. (EGM 08)</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Box sx={{ typography: "caption" }}>NEW</Box>
                      <Box sx={{ typography: "caption" }}>OLD</Box>
                      <Box sx={{ typography: "caption" }}>DIFF</Box>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>DISTANCE IN M. FROM NEW ARP</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Box sx={{ typography: "caption" }}>NEW</Box>
                      <Box sx={{ typography: "caption" }}>OLD</Box>
                      <Box sx={{ typography: "caption" }}>DIFF</Box>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>MAG. BRG.FROM NEW ARP</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Box sx={{ typography: "caption" }}>NEW</Box>
                      <Box sx={{ typography: "caption" }}>OLD</Box>
                      <Box sx={{ typography: "caption" }}>DIFF</Box>
                    </Stack>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(reviewData, ["uid1"], ["asc"]).map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      background:
                        dataChangedRowColorMap[row.result] ?? "#FFFFFF",
                    }}
                  >
                    <DiffTableCell align="center">{row["uid1"]}</DiffTableCell>
                    <DiffTableCell align="center">
                      {row["object1"] || "-"}
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={row["latdev"]}
                      isselected={row.latitude.toString()}
                      onClick={() => handleCellClick("latitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["latitude1"]} />
                        <ValueItem value={row["latitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={row["longdev"]}
                      isselected={row.longitude.toString()}
                      onClick={() => handleCellClick("longitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["longitude1"]} />
                        <ValueItem value={row["longitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["top_elevdiff"])}
                      isselected={row.top_elve.toString()}
                      onClick={() => handleCellClick("top_elve", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["top_elev1"]} />
                        <ValueItem value={row["top_elev2"]} />
                        <ValueItem value={row["top_elevdiff"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["distancediff"])}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["distance1"]} />
                        <ValueItem value={row["distance2"]} />
                        <ValueItem value={row["distancediff"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["mag_brgdiff"])}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["mag_brg1"]} />
                        <ValueItem value={row["mag_brg2"]} />
                        <ValueItem value={row["mag_brgdiff"]} />
                      </Stack>
                    </DiffTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            size="small"
            label="Cancel"
            onClick={handleCloseInReviewModal}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
          <PrimaryButton
            label="Submit"
            size="small"
            onClick={handleSubmitReviewData}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
