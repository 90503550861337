import React, { Fragment } from 'react';
import { join } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';

export default function NavigationSystemCheckpointItem({
  isLoading,
  category,
  airportDetails,
  selctedAirport
}) {
  const checkpointVOR = airportDetails['Checkpoint_VOR'];
  const checkpointINS = airportDetails['Checkpoint_INS'];

  return (
    <Fragment>
      {(isLoading || checkpointVOR.length === 0 || checkpointINS.legnth === 0) && (
        <TableLoader isLoading={isLoading} data={checkpointVOR.concat(checkpointINS)} />
      )}
      <Paper sx={{ mb: 5 }}>
        {checkpointVOR.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <HeaderTableCell colSpan={2}>
                  {selctedAirport.name} {'>'} Checkpoint VOR
                </HeaderTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Elevation</StyledTableCell>
                <StyledTableCell>Coordinates</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                checkpointVOR.map((vor, j) => {
                  return (
                    <TableRow key={`${category}_${j}_vor`}>
                      <StyledTableCell style={{ width: 300 }}>{vor.elevation || '-'}</StyledTableCell>
                      <StyledTableCell>{join(vor.coordinates, ', ') || '-'}</StyledTableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        )}
        {checkpointINS.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <HeaderTableCell colSpan={2}>
                  {selctedAirport.name} {'>'} Checkpoint INS
                </HeaderTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Elevation</StyledTableCell>
                <StyledTableCell>Coordinates</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                checkpointINS.map((vor, j) => {
                  return (
                    <TableRow key={`${category}_${j}_vor`}>
                      <StyledTableCell style={{ width: 300 }}>{vor.elevation || '-'}</StyledTableCell>
                      <StyledTableCell>{join(vor.coordinates, ', ') || '-'}</StyledTableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        )}
      </Paper>
    </Fragment>
  );
}
