import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FaLock } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import { GridIcon } from "../assets/svg/grid";
import { FileIcon } from "../assets/svg/file";
import { LayerIcon } from "../assets/svg/layer";
import { MapMarkerIcon } from "../assets/svg/map-marker";
import { PieChartIcon } from "../assets/svg/pie-chart";
import AirportLogo from "../assets/images/airports-authority.png";
import SatsureiNetraLogo from "../assets/images/satsure-iNetra.png";
import NavButton from "../components/NavButton";
import { getRSA } from "../models/userSession";
import "../styles/home.css";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRSA());
  }, []); // eslint-disable-line

  return (
    <div className="home-container" style={{ justifyContent: "space-between" }}>
      <div className="brand-container">
        <div className="copyright-div">
          <Typography
            variant="subtitle1"
            component="div"
            className="copyright-text"
          >
            {moment().format("YYYY")} AIRPORTS AUTHORITY OF INDIA All Rights
            Reserved
          </Typography>
        </div>
        <div className="brand-image">
          <div>
            <img
              src={AirportLogo}
              alt="airports-authority"
              className="airport-logo"
            />
          </div>
          <div>
            <img
              src={SatsureiNetraLogo}
              alt="satsure-iNetra"
              className="inetra-logo"
            />
          </div>
        </div>
      </div>
      <div className="menu-container">
        <div className="menu-row">
          <div>
            <NavButton
              isShowLeftIndicator
              topRightIcon={<FaLock size={18} color="#FFFFFF" />}
              icon={<FileIcon />}
              label="DATA MANAGEMENT"
              onClick={() => navigate("/data-management")}
            />
          </div>
          <div className="left-margin">
            <NavButton
              icon={<GridIcon />}
              label="AIXM"
              onClick={() => navigate("/aixm")}
            />
          </div>
        </div>
        <div className="menu-row row-margin">
          <div>
            <NavButton
              icon={<PieChartIcon />}
              label="AIRSPACE"
              onClick={() => navigate("/airspace")}
            />
          </div>
          <div className="left-margin">
            <NavButton
              icon={<LayerIcon />}
              label="AMDB"
              onClick={() => navigate("/amdb")}
            />
          </div>
        </div>
        <div className="menu-row">
          <div>
            <NavButton
              icon={<LayerIcon />}
              label="ELECTRONIC CHART"
              onClick={() => navigate("/electronic-chart")}
            />
          </div>
          <div className="left-margin">
            <NavButton
              icon={<MapMarkerIcon />}
              label="eTOD"
              onClick={() => navigate("/etod")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
