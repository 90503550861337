export const aixmFeatureMapData = {
  AirportHeliport_list: {
    Designator: "designator",
    Name: "name",
    "LocationIndicator ICAO": "locationIndicatorICAO",
    "Designator IATA": "designatorIATA",
    "Name of aerodrome operator": "authority_name",
    Address: "address",
    eMail: "eMail",
    Latitude: "latitude",
    Longitude: "longitude",
    Type: "type",
    Voice: "voice",
    Facsimile: "facsimile",
    Linkage: "linkage",
    "Certified ICAO": "certifiedICAO",
    "Private Use": "privateUse",
    "Control Type": "controlType",
    "Field Elevation": "fieldElevation",
    "Field ElevationAccuracy": "fieldElevationAccuracy",
    "Vertical Datum": "verticalDatum",
    "Magnetic Variation": "magneticVariation",
    "Magnetic Variation Accuracy": "magneticVariationAccuracy",
    "Date Magnetic Variation": "dateMagneticVariation",
    "Magnetic Variation Change": "magneticVariationChange",
    "Reference Temperature": "referenceTemperature",
    "Altimeter CheckLocation": "altimeterCheckLocation",
    "Secondary Power Supply": "secondaryPowerSupply",
    "Wind Direction Indicator": "windDirectionIndicator",
    "Reference Point Description": "referencePointDescription",
    "Location Description": "locationDescription",
    "Landing Direction Indicator": "landingDirectionIndicator",
    "Transition Altitude": "transitionAltitude",
    "Transition Level": "transitionLevel",
    "Lowest Temperature": "lowestTemperature",
    Abandoned: "abandoned",
    "certification Date": "certificationDate",
    "Certification Expiration Date": "certificationExpirationDate",
    "IFR/VFR": "type_IFRVFR",
  },
  Runway_List: {
    designator: "designator",
    type: "type",
    nominalLength: "nominalLength",
    lengthAccuracy: "lengthAccuracy",
    nominalWidth: "nominalWidth",
    widthAccuracy: "widthAccuracy",
    widthShoulder: "widthShoulder",
    lengthStrip: "lengthStrip",
    widthStrip: "widthStrip",
    lengthOffset: "lengthOffset",
    widthOffset: "widthOffset",
    abandoned: "abandoned",
  },
  SurfaceCharacteristics_List: {
    composition: "SC_composition",
    preparation: "SC_preparation",
    surfaceCondition: "SC_surfaceCondition",
    classPCN: "SC_classPCN",
    pavementTypePCN: "SC_pavementTypePCN",
    pavementSubgradePCN: "SC_pavementSubgradePCN",
    maxTyrePressurePCN: "SC_maxTyrePressurePCN",
    evaluationMethodPCN: "SC_evaluationMethodPCN",
    classLCN: "SC_classLCN",
    weightSIWL: "SC_weightSIWL",
    tyrePressureSIWL: "SC_tyrePressureSIWL",
    weightAUW: "SC_weightAUW",
  },
  RunwayDirection_List: {
    designator: "designator",
    trueBearing: "trueBearing",
    trueBearingAccuracy: "trueBearingAccuracy",
    magneticBearing: "magneticBearing",
    slopeTDZ: "slopeTDZ",
    elevationTDZ: "elevationTDZ",
    elevationTDZAccuracy: "elevationTDZAccuracy",
    patternVFR: "patternVFR",
    approachMarkingType: "approachMarkingType",
    approachMarkingCondition: "approachMarkingCondition",
    classLightingJAR: "classLightingJAR",
    precisionApproachGuidance: "precisionApproachGuidance",
    "Declared Distace Remarks": "distace_remarks",
  },
  unit_list: {
    type: "type",
    "operational hours": "operationalStatus",
  },
  notes_list: {
    propertyName: "propertyName",
    purpose: "purpose",
    LinguisticNote: "LinguisticNote_Note",
  },
  AeronauticalGroundLight_list: {
    name: "name",
    type: "type",
    colour: "colour",
    flashing: "flashing",
  },
  Apron_list: {
    name: "name",
    abandoned: "abandoned",
    "class PCN": "SC_classPCN",
    composition: "SC_composition",
    "evaluation method PCN": "SC_evaluationMethodPCN",
    "max tyre pressure PCN": "SC_maxTyrePressurePCN",
    "pavement subgrade PCN": "SC_pavementSubgradePCN",
    "pavement type PCN": "SC_pavementTypePCN",
  },
  NavigationSystemCheckpoint_list: {
    category: "category",
    upperLimit: "upperLimit",
    upperLimitReference: "upperLimitReference",
    lowerLimit: "lowerLimit",
    lowerLimitReference: "lowerLimitReference",
    altitudeInterpretation: "altitudeInterpretation",
    distance: "distance",
    angle: "angle",
  },
  Elevated_List: {
    coordinates: "EL_coordinates",
    elevation: "EL_elevation",
    geoidUndulation: "EL_geoidUndulation",
    horizontalAccuracy: "EL_horizontalAccuracy",
    verticalAccuracy: "EL_verticalAccuracy",
    verticalDatum: "EL_verticalDatum",
  },
  Taxiway_list: {
    designator: "designator",
    width: "width",
    abandoned: "abandoned",
    "class PCN": "SC_classPCN",
    composition: "SC_composition",
    "evaluation method PCN": "SC_evaluationMethodPCN",
    "max tyre pressure PCN": "SC_maxTyrePressurePCN",
    "pavement subgrade PCN": "SC_pavementSubgradePCN",
    "pavement type PCN": "SC_pavementTypePCN",
  },
  ObstacleArea_list: {
    obstructionIdSurfaceCondition: "OA_obstructionIdSurfaceCondition",
    type: "OA_type",
    runwayDirection_designator: "RunwayDirection_designator",
  },
  VerticalStructure_list: {
    name: "VS_name",
    type: "VS_type",
    lighted: "VS_lighted",
    markingICAOStandard: "VS_markingICAOStandard",
    group: "VS_group",
    length: "VS_length",
    width: "VS_width",
    radius: "VS_radius",
    lightingICAOStandard: "VS_lightingICAOStandard",
    synchronisedLighting: "VS_synchronisedLighting",
  },
  VerticalStructurePart_list: {
    verticalExtent: "VSP_verticalExtent",
    type: "VSP_type",
    constructionStatus: "VSP_constructionStatus",
    markingPattern: "VSP_markingPattern",
    markingFirstColour: "VSP_markingFirstColour",
    markingSecondColourt: "VSP_markingSecondColourt",
    mobile: "VSP_mobile",
    frangible: "VSP_frangible",
    visibleMaterial: "VSP_visibleMaterial",
    designator: "VSP_designator",
  },
  Navaid_list: {
    "runway direction": "rwy_designator",
    type: "type",
    "signal performance": "signalPerformance",
    identification: "Identification",
    class: "class",
    frequency: "frequency",
    channel: "channel",
    "hours of operation": "operationalstatus",
    latitude: "latitude",
    longitude: "longitude",
    elevation: "elevation",
    remarks: "Remarks",
  },
  NavaidEquipment: {
    designator: "NE_designator",
    name: "NE_name",
    emissionClass: "NE_emissionClass",
    mobile: "NE_mobile",
    magneticVariation: "NE_magneticVariation",
    magneticVariationAccuracy: "NE_magneticVariationAccuracy",
    dateMagneticVariation: "NE_dateMagneticVariation",
    flightChecked: "NE_flightChecked",
  },
  NavaidEquipmentVOR: {
    type: "NE_VOR_type",
    frequency: "NE_VOR_frequency",
    zeroBearingDirection: "NE_VOR_zeroBearingDirection",
    declination: "NE_VOR_declination",
    designator: "NE_VOR_designator",
    name: "NE_VOR_name",
    emissionClass: "NE_VOR_emissionClass",
    mobile: "NE_VOR_mobile",
    magneticVariation: "NE_VOR_magneticVariation",
    magneticVariationAccuracy: "NE_VOR_magneticVariationAccuracy",
    dateMagneticVariation: "NE_VOR_dateMagneticVariation",
    flightChecked: "NE_VOR_flightChecked",
  },
  NavaidEquipmentDME: {
    type: "NE_DME_type",
    channel: "NE_DME_channel",
    ghostFrequency: "NE_DME_ghostFrequency",
    displace: "NE_DME_displace",
    designator: "NE_DME_designator",
    name: "NE_DME_name",
    emissionClass: "NE_DME_emissionClass",
    mobile: "NE_DME_mobile",
    magneticVariation: "NE_DME_magneticVariation",
    magneticVariationAccuracy: "NE_DME_magneticVariationAccuracy",
    dateMagneticVariation: "NE_DME_dateMagneticVariation",
    flightChecked: "NE_DME_flightChecked",
  },
  AirTrafficControlServiceItem_list: {
    type: "type",
    radarAssisted: "radarAssisted",
    dataLinkEnabled: "dataLinkEnabled",
    dataLinkChannel: "dataLinkChannel",
    rank: "rank",
    compliantICAO: "compliantICAO",
    name: "name",
  },
  TrafficSeparationService: {
    radarAssisted: "TSS_radarAssisted",
    dataLinkEnabled: "TSS_dataLinkEnabled",
    dataLinkChannel: "TSS_dataLinkChannel",
    flightOperations: "TSS_flightOperations",
    rank: "TSS_rank",
    compliantICAO: "TSS_compliantICAO",
    name: "TSS_name",
  },
  TrafficSeparationServiceService: {
    flightOperations: "TSS_S_flightOperations",
    rank: "TSS_S_rank",
    compliantICAO: "TSS_S_compliantICAO",
    name: "TSS_S_name",
  },
  AirportGroundService_list: {
    flightOperations: "flightOperations",
    rank: "rank",
    compliantICAO: "compliantICAO",
    name: "name",
  },
  AirportGroundService: {
    flightOperations: "S_flightOperations",
    rank: "S_rank",
    compliantICAO: "S_compliantICAO",
    name: "S_name",
  },
  InformationService_list: {
    "service designation": "designation",
    "call sign": "callsign",
    "channel frequency": "frequency",
    "SATVOICE number": "satvoiceNumber",
    logon: "logon",
    "hours of operation": "operationalstatus",
    remarks: "remarks",
  },
  RulesProcedures_list: {
    category: "category",
    title: "title",
    content: "content",
  },
  Handling_services_list: {
    "Cargo-handling facilities": "Cargo_handling_facilities",
    "Fuel and Oil types": "Fuel_and_Oil",
    "Fuelling facilities and capacity": "Fuelling_facilities",
    "De-icing facilities": "Deicing",
    "Hangar space for visiting aircraft": "HANGAR",
    "Repair facilities for visiting aircraft": "REPAIR",
    Remarks: "HANDLING_SERVICES_REMARK",
  },
};

export const serviceValueObject = [
  "note",
  "remark_time",
  "day",
  "dayTil",
  "startDate",
  "startTime",
  "startEvent",
  "startTimeRelativeEvent",
  "endDate",
  "endEvent",
  "endTimeRelativeEvent",
  "timeReference",
];
