import React, { Fragment, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { flatten } from 'lodash';
import { areaHighLightColor } from '../../constants/colors';
import { airspaceType } from '../../constants/airspaceData';
import InfoPopup from './InfoPopup';

export default function RenderFIRAirspace({
  FIRAirspaceGeometry,
  handleViewMore,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (e, data) => {
    e.target.bringToFront();
    e.target.openPopup();
    setSelectedOverE(data);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonClick = (info) => {
    handleViewMore(info, airspaceType.firuir);
  };

  return (
    <Fragment>
      {
        FIRAirspaceGeometry.map((data, i) => {
          return (
            <Polygon
              key={i}
              pane="tilePane"
              positions={flatten(data.coordinates)}
              pathOptions={{
                color: selectedOverE?.name === data.name ? areaHighLightColor : '#0112dd',
                weight: selectedOverE?.name === data.name ? 3 : 2,
                fillColor: '#0112dd'
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, data),
                mouseout: () => handlePolygonOut(),
                click: () => handlePolygonClick(data)
              }}
            >
              <InfoPopup info={data} />
            </Polygon>
          );
        })
      }
    </Fragment>
  );
};