import { createSlice, createSelector } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { orderBy, isPlainObject } from "lodash";
import moment from "moment";
import {
  GET_ALL_SURVEYOR_AIRPORT_URL,
  GET_SURVEYOR_TEMPLATE_DOWNLOAD_URL,
  GET_SURVEYOR_UPLOAD_FILE_URL,
  GET_ALL_SURVEYOR_REPORT_URL,
  GET_SURVEYOR_REPORT_DETAIL_URL,
  CHANGE_VERIFICATION_URL,
  GET_REVIEW_FEATURE_DATA_URL,
  GET_REVIEW_SURVEY_DATA_URL,
  GET_ALL_SURVEYOR_URL,
  POST_SURVEYOR_REGISTER_URL,
  DISABLE_SURVEYOR_URL,
  ENABLE_SURVEYOR_URL,
  EDIT_SURVEYOR_URL,
  MASTER_RESET_PASSWORD_URL,
  GET_ALL_REQUEST_PASSWORD_URL,
  POST_EDIT_REVIEW_DATA_URL,
  GET_COMPARE_DATA_URL,
  GET_SURVEYOR_AIRPORT_URL,
  GET_REVIEW_REPORT_DATA_URL,
} from "../constants/urls";
import {
  apiCall,
  fileUploadApicall,
  downloadReadableStreamFile,
} from "../utils/connect";

const initialState = {
  isLoadingSurveyAirport: false,
  isDownloadTemplate: false,
  isUploadSurveyFile: false,
  isLoadingAllSurveyReport: false,
  isLoadingSurveyReportDetail: false,
  isLoadingVerification: false,
  surveyAllAirports: [],
  uploadFileData: null,
  allSurveyReports: [],
  selectedReport: null,
  surveyReportDetail: [],
  isLoadingReviewFeaturesdata: false,
  reviewBackFeaturesData: [],
  isLoadingReviewBackSurveyData: false,
  reviewBackSurveyData: null,
  isLoadingGetAllSurveyor: false,
  allSurveyorList: [],
  isLoadingPostSurveyorRegistration: false,
  isLoadingEditSurveyorRegistration: false,
  isLoadingDisableSurveyor: false,
  isLoadingEnableSurveyor: false,
  isResetingMasterPassword: false,
  isLoadingGetallReqPassword: false,
  allReqPassword: [],
  isLoadingPostEditReviewData: false,
  isLoadingCompareData: false,
  comparedata: null,
  isSurveyorAirportLoading: false,
  surveyorAirportData: [],
  isExportReviewReportLoading: false,
};

export const survey = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setLoadingSurveyAirportLoadingRequest: (state, action) => {
      state.isLoadingSurveyAirport = action.payload;
    },
    setTemplateDownloadRequest: (state, action) => {
      state.isDownloadTemplate = action.payload;
    },
    setUplodSurveyFileRequest: (state, action) => {
      state.isUploadSurveyFile = action.payload;
    },
    setAllSurveyReportRequest: (state, action) => {
      state.isLoadingAllSurveyReport = action.payload;
    },
    setSurveyReportDetailRequest: (state, action) => {
      state.isLoadingSurveyReportDetail = action.payload;
    },
    setSurveyVerificationRequest: (state, action) => {
      state.isLoadingVerification = action.payload;
    },
    setAllSurveyAirport: (state, action) => {
      state.surveyAllAirports = action.payload;
    },
    setUploadFileData: (state, action) => {
      state.uploadFileData = action.payload?.survey_data;
    },
    setAllSurveyReports: (state, action) => {
      state.allSurveyReports = action.payload?.SurveyFeaturesa_list;
    },
    setSelectedSurveyReport: (state, action) => {
      state.selectedReport = action.payload;
    },
    setSurveyReportDetail: (state, action) => {
      state.surveyReportDetail = action.payload?.SurveyData_list;
    },
    setReviewFeaturesdataLoading: (state, action) => {
      state.isLoadingReviewFeaturesdata = action.payload;
    },
    setReviewBackFeaturesData: (state, action) => {
      state.reviewBackFeaturesData = action.payload;
    },
    setReviewBackSurveyDataLoading: (state, action) => {
      state.isLoadingReviewBackSurveyData = action.payload;
    },
    setReviewBackSurveyData: (state, action) => {
      state.reviewBackSurveyData = {
        ...state.reviewBackSurveyData,
        [action.payload.id]: action.payload.data,
      };
    },
    setGetAllSurveyorDataLoading: (state, action) => {
      state.isLoadingGetAllSurveyor = action.payload;
    },
    setAllSurveyorList: (state, action) => {
      state.allSurveyorList = action.payload;
    },
    setPostSurveyorRegistrationLoading: (state, action) => {
      state.isLoadingPostSurveyorRegistration = action.payload;
    },
    setEditSurveyorRegistrationLoading: (state, action) => {
      state.isLoadingEditSurveyorRegistration = action.payload;
    },
    setDisableSurveyorLoading: (state, action) => {
      state.isLoadingDisableSurveyor = action.payload;
    },
    setEnableSurveyorLoading: (state, action) => {
      state.isLoadingEnableSurveyor = action.payload;
    },
    setResetingMasterPasswordLoading: (state, action) => {
      state.isResetingMasterPassword = action.payload;
    },
    setLoadingGetallReqPassword: (state, action) => {
      state.isLoadingGetallReqPassword = action.payload;
    },
    setAllReqPassword: (state, action) => {
      state.allReqPassword = action.payload;
    },
    setLoadingPostEditReviewData: (state, action) => {
      state.isLoadingPostEditReviewData = action.payload;
    },
    setLoadingCompareData: (state, action) => {
      state.isLoadingCompareData = action.payload;
    },
    setCompareData: (state, action) => {
      state.comparedata = action.payload;
    },
    setSurveyorAirportLoading: (state, action) => {
      state.isSurveyorAirportLoading = action.payload;
    },
    setSurveyorAirportData: (state, action) => {
      state.surveyorAirportData = action.payload;
    },
    setExportReviewReportLoading: (state, action) => {
      state.isExportReviewReportLoading = action.payload;
    },
  },
});

export const {
  setLoadingSurveyAirportLoadingRequest,
  setTemplateDownloadRequest,
  setUplodSurveyFileRequest,
  setAllSurveyReportRequest,
  setSurveyReportDetailRequest,
  setSurveyVerificationRequest,
  setAllSurveyAirport,
  setUploadFileData,
  setAllSurveyReports,
  setSurveyReportDetail,
  setSelectedSurveyReport,
  setReviewFeaturesdataLoading,
  setReviewBackFeaturesData,
  setReviewBackSurveyDataLoading,
  setReviewBackSurveyData,
  setGetAllSurveyorDataLoading,
  setAllSurveyorList,
  setPostSurveyorRegistrationLoading,
  setEditSurveyorRegistrationLoading,
  setDisableSurveyorLoading,
  setEnableSurveyorLoading,
  setResetingMasterPasswordLoading,
  setLoadingGetallReqPassword,
  setAllReqPassword,
  setLoadingPostEditReviewData,
  setLoadingCompareData,
  setCompareData,
  setSurveyorAirportLoading,
  setSurveyorAirportData,
  setExportReviewReportLoading,
} = survey.actions;

export default survey.reducer;

const selectSearchReviewId = (state, reviewId) => reviewId;
const selectAllReviewBackSurveyData = (state) =>
  state.survey.reviewBackSurveyData;
const selectAllSurveyorData = (state) => state.survey.allSurveyorList;
const selectAllReqPasswordData = (state) => state.survey.allReqPassword;

export const selectAllSurveyReports = (state) => {
  const allSurveyReports = state.survey.allSurveyReports;

  if (allSurveyReports && allSurveyReports.length > 0) {
    const formatedReports = allSurveyReports.map((report) => {
      return {
        ...report,
        created_datetime: moment
          .utc(report.created_datetime)
          .format("MM/DD/YYYY, h:mm:ss a"),
      };
    });

    return orderBy(formatedReports, ["created_datetime"], ["desc"]);
  }

  return [];
};

export const selectReviewBackSurveyData = createSelector(
  [selectAllReviewBackSurveyData, selectSearchReviewId],
  (allReviewBackSurveyData, reviewId) => {
    if (reviewId) {
      return allReviewBackSurveyData && allReviewBackSurveyData[reviewId]
        ? orderBy(allReviewBackSurveyData[reviewId], ["id"], ["asc"])
        : [];
    }

    return allReviewBackSurveyData;
  }
);

const selectUserType = (state, userType) => userType;
export const selectAllSurveyorList = createSelector(
  [selectAllSurveyorData, selectUserType],
  (surveyors, userType) => {
    if (surveyors && surveyors.length > 0) {
      const filteredUsers = surveyors.filter((user) => user.role === userType);
      return orderBy(filteredUsers, ["emp_id"], ["asc"]);
    }

    return [];
  }
);

export const selectAllReqPasswordList = createSelector(
  [selectAllReqPasswordData],
  (allPasswordRequest) => {
    if (allPasswordRequest && allPasswordRequest.length > 0) {
      return orderBy(allPasswordRequest, ["emp_id"], ["asc"]);
    }

    return [];
  }
);

export const getAllSurveyAirport = (token) => (dispatch) => {
  try {
    dispatch(setLoadingSurveyAirportLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllSurveyAirport(response.data));
      dispatch(setLoadingSurveyAirportLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingSurveyAirportLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_ALL_SURVEYOR_AIRPORT_URL,
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingSurveyAirportLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getAllSurveyReport = (token) => (dispatch) => {
  try {
    dispatch(setAllSurveyReportRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllSurveyReports(response.data));
      dispatch(setAllSurveyReportRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAllSurveyReportRequest(false));
    };

    apiCall(
      "GET",
      GET_ALL_SURVEYOR_REPORT_URL,
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setAllSurveyReportRequest(false));
    toast.error(error.message);
  }
};

export const getSurveyReportDetail = (id, type, token) => (dispatch) => {
  try {
    dispatch(setSurveyReportDetailRequest(true));

    const onSuccess = (response) => {
      dispatch(setSurveyReportDetail(response.data));
      dispatch(setSurveyReportDetailRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setSurveyReportDetailRequest(false));
    };

    apiCall(
      "GET",
      GET_SURVEYOR_REPORT_DETAIL_URL(id, type),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setSurveyReportDetailRequest(false));
    toast.error(error.message);
  }
};

export const downloadCategoryTemplate = (template, token) => (dispatch) => {
  try {
    dispatch(setTemplateDownloadRequest(true));

    const onSuccess = (response) => {
      // const newBlob = new Blob([blob]);
      // const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = response.data.url;
      link.setAttribute("download", `${template}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      // window.URL.revokeObjectURL(blobUrl);
      dispatch(setTemplateDownloadRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setTemplateDownloadRequest(false));
    };

    apiCall(
      "GET",
      GET_SURVEYOR_TEMPLATE_DOWNLOAD_URL(template),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setTemplateDownloadRequest(false));
    toast.error(error.message);
  }
};

export const uploadSurveyFile =
  (datesetType, airportId, crcCode, file, token, successCallback) =>
  (dispatch) => {
    try {
      dispatch(setUplodSurveyFileRequest(true));

      const onSuccess = (response) => {
        toast.success("Successfully Uploaded");
        dispatch(setUploadFileData(response.data));
        dispatch(setUplodSurveyFileRequest(false));
        successCallback(response.data);
      };
      const onFailure = (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          toast.error("Something went wrong");
        }
        dispatch(setUplodSurveyFileRequest(false));
      };

      fileUploadApicall(
        GET_SURVEYOR_UPLOAD_FILE_URL(datesetType, airportId, crcCode),
        file,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setUplodSurveyFileRequest(false));
      toast.error(error.message);
    }
  };

export const comparedata =
  (airportId, datesetType, user_id, report_id, token) => (dispatch) => {
    try {
      dispatch(setLoadingCompareData(true));

      const onSuccess = (response) => {
        dispatch(setCompareData(response.data));
        dispatch(setLoadingCompareData(false));
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingCompareData(false));
      };

      apiCall(
        "GET",
        GET_COMPARE_DATA_URL(airportId, datesetType, user_id, report_id),
        "",
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingCompareData(false));
      toast.error(error.message);
    }
  };

export const postSurveyVerification =
  (body, report, token, callback) => (dispatch) => {
    try {
      dispatch(setSurveyVerificationRequest(true));

      const onSuccess = () => {
        dispatch(setSurveyVerificationRequest(false));
        dispatch(
          comparedata(
            report.airport_id,
            report.feature_name,
            report.user_id,
            report.id,
            token
          )
        );
        dispatch(getAllSurveyReport());
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setSurveyVerificationRequest(false));
      };

      apiCall(
        "POST",
        CHANGE_VERIFICATION_URL,
        body,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setSurveyVerificationRequest(false));
      toast.error(error.message);
    }
  };

export const getReviewFeaturesdata = (token) => (dispatch, getState) => {
  try {
    dispatch(setReviewFeaturesdataLoading(true));
    const state = getState();
    const curentUser = state.userSession.user;

    const onSuccess = (response) => {
      dispatch(setReviewBackFeaturesData(response.data));
      dispatch(setReviewFeaturesdataLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setReviewFeaturesdataLoading(false));
    };

    apiCall(
      "GET",
      GET_REVIEW_FEATURE_DATA_URL(curentUser.userId),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setReviewFeaturesdataLoading(false));
    toast.error(error.message);
  }
};

export const getSurveyorAirport = (token) => (dispatch, getState) => {
  try {
    dispatch(setSurveyorAirportLoading(true));
    const state = getState();
    const curentUser = state.userSession.user;

    const onSuccess = (response) => {
      dispatch(setSurveyorAirportData(response.data));
      dispatch(setSurveyorAirportLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setSurveyorAirportLoading(false));
    };

    apiCall(
      "GET",
      GET_SURVEYOR_AIRPORT_URL(curentUser.userId),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setSurveyorAirportLoading(false));
    toast.error(error.message);
  }
};

export const getReviewSurveyData = (id, type, token) => (dispatch) => {
  try {
    dispatch(setReviewBackSurveyDataLoading(true));

    const onSuccess = (response) => {
      dispatch(setReviewBackSurveyData({ id, data: response.data }));
      dispatch(setReviewBackSurveyDataLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setReviewBackSurveyDataLoading(false));
    };

    apiCall(
      "GET",
      GET_REVIEW_SURVEY_DATA_URL(id, type),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setReviewBackSurveyDataLoading(false));
    toast.error(error.message);
  }
};

export const getAllSurveyorData = (token) => (dispatch) => {
  try {
    dispatch(setGetAllSurveyorDataLoading(true));

    const onSuccess = (response) => {
      dispatch(setAllSurveyorList(response.data));
      dispatch(setGetAllSurveyorDataLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setGetAllSurveyorDataLoading(false));
    };

    apiCall("GET", GET_ALL_SURVEYOR_URL, "", onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setGetAllSurveyorDataLoading(false));
    toast.error(error.message);
  }
};

export const getAllSurveyorDataWithoutLoading = (token) => (dispatch) => {
  try {
    const onSuccess = (response) => {
      dispatch(setAllSurveyorList(response.data));
    };
    const onFailure = (error) => {
      toast.error(error.message);
    };

    apiCall("GET", GET_ALL_SURVEYOR_URL, "", onSuccess, onFailure, token);
  } catch (error) {
    toast.error(error.message);
  }
};

export const postSurveyorRegistration =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setPostSurveyorRegistrationLoading(true));

      const onSuccess = () => {
        toast.success("User created successfully");
        dispatch(getAllSurveyorDataWithoutLoading(token));
        dispatch(setPostSurveyorRegistrationLoading(false));
        callback();
      };
      const onFailure = (error) => {
        if (isPlainObject(error.message)) {
          toast.error(error.message?.message);
        } else {
          toast.error(error.message);
        }

        dispatch(setPostSurveyorRegistrationLoading(false));
      };

      apiCall(
        "POST",
        POST_SURVEYOR_REGISTER_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setPostSurveyorRegistrationLoading(false));
      toast.error(error.message);
    }
  };

export const editSurveyorRegistration =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setEditSurveyorRegistrationLoading(true));

      const onSuccess = () => {
        toast.success("User edited successfully");
        dispatch(getAllSurveyorDataWithoutLoading(token));
        dispatch(setEditSurveyorRegistrationLoading(false));
        callback();
      };
      const onFailure = (error) => {
        if (isPlainObject(error.data)) {
          toast.error(error.data?.message);
        } else {
          toast.error(error.message);
        }

        dispatch(setEditSurveyorRegistrationLoading(false));
      };

      apiCall("POST", EDIT_SURVEYOR_URL, payload, onSuccess, onFailure, token);
    } catch (error) {
      dispatch(setEditSurveyorRegistrationLoading(false));
      toast.error(error.message);
    }
  };

export const disabledSurveyor = (userId, token, callback) => (dispatch) => {
  try {
    dispatch(setDisableSurveyorLoading(true));

    const onSuccess = () => {
      dispatch(getAllSurveyorDataWithoutLoading(token));
      dispatch(setDisableSurveyorLoading(false));
      callback();
    };
    const onFailure = (error) => {
      if (isPlainObject(error.data)) {
        toast.error(error.data?.message);
      } else {
        toast.error(error.message);
      }

      dispatch(setDisableSurveyorLoading(false));
    };

    apiCall(
      "DELETE",
      DISABLE_SURVEYOR_URL(userId),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setDisableSurveyorLoading(false));
    toast.error(error.message);
  }
};

export const enableSurveyor = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setEnableSurveyorLoading(true));

    const onSuccess = () => {
      dispatch(getAllSurveyorDataWithoutLoading(token));
      dispatch(setEnableSurveyorLoading(false));
      callback();
    };
    const onFailure = (error) => {
      if (isPlainObject(error.data)) {
        toast.error(error.data?.message);
      } else {
        toast.error(error.message);
      }

      dispatch(setEnableSurveyorLoading(false));
    };

    apiCall("POST", ENABLE_SURVEYOR_URL, payload, onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setEnableSurveyorLoading(false));
    toast.error(error.message);
  }
};

export const masterResetPassword = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setResetingMasterPasswordLoading(true));

    const onSuccess = () => {
      toast.success("Password reset successfully");
      dispatch(getAllRequestPassword(token));
      dispatch(setResetingMasterPasswordLoading(false));
      callback();
    };
    const onFailure = (error) => {
      if (isPlainObject(error.data)) {
        toast.error(error.data?.message);
      } else {
        toast.error(error.message);
      }

      dispatch(setResetingMasterPasswordLoading(false));
    };

    apiCall(
      "POST",
      MASTER_RESET_PASSWORD_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setResetingMasterPasswordLoading(false));
    toast.error(error.message);
  }
};

export const getAllRequestPassword = (token) => (dispatch) => {
  try {
    dispatch(setLoadingGetallReqPassword(true));

    const onSuccess = (response) => {
      dispatch(setAllReqPassword(response.data));
      dispatch(setLoadingGetallReqPassword(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetallReqPassword(false));
    };

    apiCall(
      "GET",
      GET_ALL_REQUEST_PASSWORD_URL,
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingGetallReqPassword(false));
    toast.error(error.message);
  }
};

export const postEditReviewData =
  (payload, id, type, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingPostEditReviewData(true));

      const onSuccess = () => {
        dispatch(getReviewSurveyData(id, type, token));
        dispatch(setLoadingPostEditReviewData(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingPostEditReviewData(false));
      };

      apiCall(
        "POST",
        POST_EDIT_REVIEW_DATA_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingPostEditReviewData(false));
      toast.error(error.message);
    }
  };

export const exportReviewReport = (id, type) => (dispatch) => {
  try {
    dispatch(setExportReviewReportLoading(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${type}.csv`);
      link.setAttribute("download", filename ? filename : `${type}.xls`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportReviewReportLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportReviewReportLoading(false));
    };

    downloadReadableStreamFile(
      "GET",
      GET_REVIEW_REPORT_DATA_URL(id, type),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setExportReviewReportLoading(false));
    toast.error(error.message);
  }
};
