import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader() {
  return (
    <Backdrop open>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color="secondary" />
      </Box>
    </Backdrop>
  );
};
