import React, { useState } from 'react';
import { FeatureGroup, CircleMarker } from 'react-leaflet';
import { chain } from 'lodash';
import { staticColorMap } from '../../constants/colors';
import { areaHighLightColor } from '../../constants/colors';
import AreaObstacleInfoPopup from './AreaObstacleInfoPopup';

export default function RenderObstacles({
  selctedAirport,
  selectedAreas,
  selectedRasterArea,
  obstaclesList,
  selectedObstacles,
  isShowPenetrationObstacle,
  getAreaColors,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const areaColor = getAreaColors();
  const visibleObstaclesListByArea =
    chain(obstaclesList)
      .filter((obstacle) => {
        const obstacleKey = `${obstacle.rwy_designator}_${obstacle.area_coverage}`;
        return selectedAreas.includes(`${obstacleKey}__${areaColor[obstacleKey]}`);
      })
      .value();
  const visibleObstaclesListByDEM =
    chain(obstaclesList)
      .filter((obstacle) => {
        const obstacleKey = `${obstacle.rwy_designator}_${obstacle.area_coverage}`;
        return selectedRasterArea.includes(obstacleKey);
      })
      .value();
  let visibleObstaclesList = visibleObstaclesListByArea;
  if (visibleObstaclesListByArea.length === 0 && visibleObstaclesListByDEM.length > 0) {
    visibleObstaclesList = visibleObstaclesListByDEM;
  };

  if (isAbovePermissibleClick) {
    visibleObstaclesList = visibleObstaclesList.filter((obstacle) => {
      return obstacle.obstacle_penetration && Number(obstacle.obstacle_penetration) >= 0;
    });
  } else if (isBelowPermissibleClick) {
    visibleObstaclesList = visibleObstaclesList.filter((obstacle) => {
      return obstacle.obstacle_penetration && Number(obstacle.obstacle_penetration) < 0;
    });
  };

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  if (selectedAreas.length === 0 && selectedRasterArea.length === 0) {
    return null;
  };

  return (
    <FeatureGroup pane="popupPane">
      {
        visibleObstaclesList.map((obstacles, i) => {
          if (!obstacles.latitude || !obstacles.longitude) {
            return null;
          };

          let obstacleColor = selectedObstacles.color;
          if (isShowPenetrationObstacle) {
            obstacleColor = obstacles.obstacle_penetration &&
              Number(obstacles.obstacle_penetration) >= 0
              ? staticColorMap['RedObstacle']
              : staticColorMap['GreenObstacle'];
          };

          return (
            <CircleMarker
              key={i}
              center={{
                lat: Number(obstacles.latitude),
                lng: Number(obstacles.longitude)
              }}
              radius={6}
              pathOptions={{
                fillColor: obstacleColor,
                color: selectedOverE?.uid === obstacles.uid ? areaHighLightColor : obstacleColor,
                weight: selectedOverE?.uid === obstacles.uid ? 3 : 1,
                fillOpacity: 1,
              }}
              eventHandlers={{
                mouseover: () => handlePolygonOver(obstacles),
                mouseout: () => handlePolygonOut()
              }}
            >
              <AreaObstacleInfoPopup
                info={obstacles}
                selctedAirport={selctedAirport}
                selctedFeature="area"
              />
            </CircleMarker>
          )
        })
      }
    </FeatureGroup>
  );
};