import React from "react";
import { snakeCase, toUpper } from "lodash";
import { Box, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import { dataChangedRowColorMap } from "../../constants/colors";
import { PrimaryButton } from "../../components/button";

export default function DataTableFooterRow({
  colspan1 = 5,
  colspan2 = 2,
  isLoadingVerification,
  selectedReport,
  selected,
  handleAccept,
  handleReview,
}) {
  const fileName = snakeCase(
    `SURVEYOR ${selectedReport?.feature_name} ${selectedReport?.airport_name}`
  );

  return (
    <TableRow>
      <TableCell colSpan={colspan1}>
        <Box>{toUpper(fileName)}</Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: dataChangedRowColorMap.new_obstacle,
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">New entry</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: dataChangedRowColorMap.deviation_detected,
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">
              Spatial/Numerical Deviation detected
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell colSpan={colspan2}>
        <Stack direction="row" spacing={5}>
          <PrimaryButton
            size="small"
            label="Accept"
            onClick={handleAccept}
            isLoading={isLoadingVerification}
            disabled={selected.length === 0 || isLoadingVerification}
          />
          <PrimaryButton
            label="Review"
            size="small"
            onClick={handleReview}
            isLoading={isLoadingVerification}
            disabled={selected.length === 0 || isLoadingVerification}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
