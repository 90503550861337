import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MuiSelect from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  createAd212DirectionValidationSchema,
  Ad212ApproachLightingTypes,
  Ad212Colours,
} from "../../constants/ad2Data";
import { PrimaryButton } from "../button";

const initFormData = {
  rwy_dsg: "",
  rwy_dir_dsg: "",
  true_bearings: "",
  slopeTDZ: "",
  thr_lat: "",
  thr_long: "",
  thr_elev: "",
  rwy_end_elev: "",
  rwy_end_lat: "",
  rwy_end_long: "",
  tdz_elve: "",
  d_stopway_length: "",
  d_stopway_width: "",
  d_clearway_length: "",
  d_clearway_width: "",
  d_resa_length: "",
  d_resa_width: "",
  ofz: false,
  remarks: "",
  tora: "",
  toda: "",
  asda: "",
  lda: "",
  declared_distace_remarks: "",
  als_type: "",
  als_intensityLevel: "",
  als_lenght: "",
  thr_color: "",
  cl_color: "",
  cl_intensity: "",
  cl_length: "",
  cl_spacing: "",
  edge_color: "",
  edge_intensity: "",
  edge_length: "",
  edge_spacing: "",
  end_color: "",
  visual_slope_type: "",
  visual_slope_slopeAngle: "",
  visual_slope_minimumthreshold: "",
  visual_slope_position: "",
};

export default function Ad212RunWayDirectionForm({
  selectedCategory,
  runwayDesignator,
  direction,
  handleRunwayDirection,
}) {
  const isLoadingRunwayDirectionUpload = useSelector(
    (state) => state.airport.isLoadingRunwayDirectionUpload
  );

  const createAd212RunwayDirectionForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      runwayDirections: [
        { ...initFormData, rwy_dsg: runwayDesignator, rwy_dir_dsg: direction },
      ],
    },
    validationSchema: createAd212DirectionValidationSchema,
    onSubmit: (values) => {
      handleRunwayDirection(values.runwayDirections, selectedCategory);
    },
  });

  return (
    <Paper sx={{ pb: 2, pt: 2 }}>
      <FormikProvider value={createAd212RunwayDirectionForm}>
        <form onSubmit={createAd212RunwayDirectionForm.handleSubmit}>
          <FieldArray
            name="runwayDirections"
            render={(arrayHelpers) => (
              <div>
                {createAd212RunwayDirectionForm.values.runwayDirections.map(
                  (_, index) => {
                    const isTouched = createAd212RunwayDirectionForm.touched
                      ?.runwayDirections
                      ? createAd212RunwayDirectionForm.touched
                          ?.runwayDirections[index]
                      : false;
                    const isErrors = createAd212RunwayDirectionForm.errors
                      ?.runwayDirections
                      ? createAd212RunwayDirectionForm.errors?.runwayDirections[
                          index
                        ]
                      : false;

                    return (
                      <Fragment key={index}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Runway Designations</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            {
                              createAd212RunwayDirectionForm.values
                                .runwayDirections[index].rwy_dsg
                            }
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2, mt: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Runway Direction</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            {
                              createAd212RunwayDirectionForm.values
                                .runwayDirections[index].rwy_dir_dsg
                            }
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>True Bearings</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].true_bearings`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].true_bearings
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].true_bearings &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].true_bearings
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].true_bearings
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    deg
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Slope TDZ</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].slopeTDZ`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].slopeTDZ
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].slopeTDZ &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].slopeTDZ
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].slopeTDZ
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Threshold Latitude</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].thr_lat`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].thr_lat
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].thr_lat &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].thr_lat
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].thr_lat
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Threshold Longitude</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].thr_long`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].thr_long
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].thr_long &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].thr_long
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].thr_long
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Threshold Elevation</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].thr_elev`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].thr_elev
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].thr_elev &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].thr_elev
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].thr_elev
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    FT
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Runway End Latitude</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].rwy_end_lat`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].rwy_end_lat
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].rwy_end_lat &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].rwy_end_lat
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].rwy_end_lat
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Runway End Longitude</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].rwy_end_long`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].rwy_end_long
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].rwy_end_long &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].rwy_end_long
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].rwy_end_long
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Runway End Elevation</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].rwy_end_elev`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].rwy_end_elev
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].rwy_end_elev &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].rwy_end_elev
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].rwy_end_elev
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    FT
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>TDZ Elevation</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].tdz_elve`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].tdz_elve
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].tdz_elve &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].tdz_elve
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].tdz_elve
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    FT
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Dimension of Stopway (L X W)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].d_stopway_length`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].d_stopway_length
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].d_stopway_length &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].d_stopway_length
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].d_stopway_length
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                            X
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].d_stopway_width`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].d_stopway_width
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].d_stopway_width &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].d_stopway_width
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].d_stopway_width
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Dimension of Clearway (L X W)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].d_clearway_length`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].d_clearway_length
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].d_clearway_length &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].d_clearway_length
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .d_clearway_length
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                            X
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].d_clearway_width`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].d_clearway_width
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].d_clearway_width &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].d_clearway_width
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].d_clearway_width
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Dimension of Strip (L X W)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].d_resa_length`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].d_resa_length
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].d_resa_length &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].d_resa_length
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].d_resa_length
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                            X
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].d_resa_width`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].d_resa_width
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].d_resa_width &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].d_resa_width
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].d_resa_width
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Existing of an Obstacle Free Zone
                          </Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].ofz &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].ofz
                                )
                              }
                              component="fieldset"
                              variant="standard"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      createAd212RunwayDirectionForm.values
                                        .runwayDirections[index].ofz
                                    }
                                  />
                                }
                                label=""
                                name={`runwayDirections[${index}].ofz`}
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                              />
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].ofz
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>RD Remarks</Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              variant="outlined"
                              sx={{ mb: 2, mt: 2, width: 250 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].remarks &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].remarks
                                )
                              }
                            >
                              <TextareaAutosize
                                minRows={2}
                                name={`runwayDirections[${index}].remarks`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index].remarks
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                                aria-describedby="remarks-textarea"
                              />
                              <FormHelperText id="remarks-textarea">
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].remarks
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>TORA</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].tora`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].tora
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].tora &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].tora
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].tora
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>TODA</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].toda`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].toda
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].toda &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].toda
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].toda
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>ASDA</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].asda`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].asda
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].asda &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].asda
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].asda
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>LDA</Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].lda`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].lda
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].lda &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].lda
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].lda
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Declared Distace Remarks
                          </Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              variant="outlined"
                              sx={{ mb: 2, mt: 2, width: 250 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index]
                                  .declared_distace_remarks &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index]
                                    .declared_distace_remarks
                                )
                              }
                            >
                              <TextareaAutosize
                                minRows={2}
                                name={`runwayDirections[${index}].declared_distace_remarks`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index]
                                    .declared_distace_remarks
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                                aria-describedby="declared_distace_remarks-textarea"
                              />
                              <FormHelperText id="declared_distace_remarks-textarea">
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .declared_distace_remarks
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Approach Lighting System (Type)
                          </Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ width: 200 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].als_type &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].als_type
                                )
                              }
                            >
                              <MuiSelect
                                name={`runwayDirections[${index}].als_type`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index].als_type
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                              >
                                {Ad212ApproachLightingTypes.map((types) => (
                                  <MenuItem key={types.id} value={types.value}>
                                    {types.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].als_type
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Approach Lighting System (Length)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].als_lenght`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].als_lenght
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].als_lenght &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].als_lenght
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].als_lenght
                                  : ""
                              }
                              sx={{ mt: 1 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Approach Lighting System (Intensity Level)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].als_intensityLevel`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].als_intensityLevel
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index]
                                  .als_intensityLevel &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].als_intensityLevel
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .als_intensityLevel
                                  : ""
                              }
                              sx={{ mb: 2, mt: 1 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Threshold Color</Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ mb: 2, width: 200 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].thr_color &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].thr_color
                                )
                              }
                            >
                              <MuiSelect
                                name={`runwayDirections[${index}].thr_color`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index].thr_color
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                              >
                                {Ad212Colours.map((types) => (
                                  <MenuItem key={types.id} value={types.value}>
                                    {types.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].thr_color
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Center Line Light (Length)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].cl_length`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].cl_length
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].cl_length &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].cl_length
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].cl_length
                                  : ""
                              }
                              sx={{ mt: 2 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Center Line Light (Spacing)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].cl_spacing`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].cl_spacing
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].cl_spacing &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].cl_spacing
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].cl_spacing
                                  : ""
                              }
                              sx={{ mt: 1 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Center Line Light (Intensity)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].cl_intensity`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].cl_intensity
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].cl_intensity &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].cl_intensity
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].cl_intensity
                                  : ""
                              }
                              sx={{ mt: 1 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Center Line Light (Color)
                          </Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ mt: 1, mb: 2, width: 200 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].cl_color &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].cl_color
                                )
                              }
                            >
                              <MuiSelect
                                name={`runwayDirections[${index}].cl_color`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index].cl_color
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                              >
                                {Ad212Colours.map((types) => (
                                  <MenuItem key={types.id} value={types.value}>
                                    {types.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].cl_color
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Edge Light (Length)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].edge_length`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].edge_length
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].edge_length &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].edge_length
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].edge_length
                                  : ""
                              }
                              sx={{ mt: 1 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Edge Light (Spacing)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].edge_spacing`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].edge_spacing
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].edge_spacing &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].edge_spacing
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].edge_spacing
                                  : ""
                              }
                              sx={{ mt: 1 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Edge Light (Intensity)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].edge_intensity`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].edge_intensity
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].edge_intensity &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].edge_intensity
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].edge_intensity
                                  : ""
                              }
                              sx={{ mt: 1 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Runway Edge Light (Color)
                          </Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ mt: 1, mb: 2, width: 200 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].edge_color &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].edge_color
                                )
                              }
                            >
                              <MuiSelect
                                name={`runwayDirections[${index}].edge_color`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index].edge_color
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                              >
                                {Ad212Colours.map((types) => (
                                  <MenuItem key={types.id} value={types.value}>
                                    {types.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].edge_color
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Color of Runway end Light
                          </Box>
                          <Box sx={{ pl: 2 }}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ mt: 2, mb: 2, width: 200 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].end_color &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].end_color
                                )
                              }
                            >
                              <MuiSelect
                                name={`runwayDirections[${index}].end_color`}
                                value={
                                  createAd212RunwayDirectionForm.values
                                    .runwayDirections[index].end_color
                                }
                                onChange={
                                  createAd212RunwayDirectionForm.handleChange
                                }
                              >
                                {Ad212Colours.map((types) => (
                                  <MenuItem key={types.id} value={types.value}>
                                    {types.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index].end_color
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Visual Slope Indicator (Type)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].visual_slope_type`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].visual_slope_type
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index].visual_slope_type &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index].visual_slope_type
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .visual_slope_type
                                  : ""
                              }
                              sx={{ mt: 1 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Visual Slope Indicator (Slope Angle)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`runwayDirections[${index}].visual_slope_slopeAngle`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index]
                                  .visual_slope_slopeAngle
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index]
                                  .visual_slope_slopeAngle &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index]
                                    .visual_slope_slopeAngle
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .visual_slope_slopeAngle
                                  : ""
                              }
                              sx={{ mt: 1 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    DEG
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Visual Slope Indicator (Minimum Threshold)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].visual_slope_minimumthreshold`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index]
                                  .visual_slope_minimumthreshold
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index]
                                  .visual_slope_minimumthreshold &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index]
                                    .visual_slope_minimumthreshold
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .visual_slope_minimumthreshold
                                  : ""
                              }
                              sx={{ mt: 1 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>
                            Visual Slope Indicator (Position)
                          </Box>
                          <Box
                            sx={{ pl: 2 }}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`runwayDirections[${index}].visual_slope_position`}
                              value={
                                createAd212RunwayDirectionForm.values
                                  .runwayDirections[index].visual_slope_position
                              }
                              onChange={
                                createAd212RunwayDirectionForm.handleChange
                              }
                              error={
                                isTouched &&
                                isErrors &&
                                createAd212RunwayDirectionForm.touched
                                  ?.runwayDirections[index]
                                  .visual_slope_position &&
                                Boolean(
                                  createAd212RunwayDirectionForm.errors
                                    ?.runwayDirections[index]
                                    .visual_slope_position
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd212RunwayDirectionForm.errors
                                      ?.runwayDirections[index]
                                      .visual_slope_position
                                  : ""
                              }
                              sx={{ mb: 2, mt: 1 }}
                            />
                          </Box>
                        </Stack>
                        <Divider />
                      </Fragment>
                    );
                  }
                )}
                {/* <PrimaryButton
                  label="Add More"
                  onClick={() => arrayHelpers.push(runwayDirection)}
                /> */}
              </div>
            )}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ pr: 2 }}
          >
            <PrimaryButton
              type="submit"
              label="Add"
              isLoading={isLoadingRunwayDirectionUpload}
              disabled={isLoadingRunwayDirectionUpload}
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
    </Paper>
  );
}
