import React, { Fragment, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { flatten, chain } from 'lodash';
import { areaHighLightColor } from '../../constants/colors';

export default function RenderOLSHorizontalSurface({
  OLSHorizontalSurfaceList,
  selectedOls,
  getOLSColors
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSHorizontalSurfaceList =
    chain(OLSHorizontalSurfaceList)
      .filter((hslObj) => {
        return selectedOls.includes(`${hslObj.type}__${olsColor[hslObj.type]}`);
      })
      .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
   <Fragment>
     {
        visibleOLSHorizontalSurfaceList.map((olsHsl) => {
          const hslCoordinates = olsHsl.coordinates.coordinates;
          const hslCoordinateMap = flatten(hslCoordinates).map((hslCoords) => {
            return hslCoords.map((hsl) => {
              return {
                lng: hsl[0],
                lat: hsl[1]
              };
            });
          });

          return hslCoordinateMap.map((hslPolygonPath, i) => (
            <Polygon
              key={i}
              pane="tilePane"
              positions={hslPolygonPath}
              pathOptions={{
                fillColor: olsColor[olsHsl.type],
                color: selectedOverE?.type === olsHsl.type ? areaHighLightColor : olsColor[olsHsl.type],
                weight: selectedOverE?.type === olsHsl.type ? 4 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, olsHsl),
                mouseout: () => handlePolygonOut()
              }}
            />
          ));
        })
     }
   </Fragment> 
  )
}