import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableRowLoader from '../tabel-component/TableRowLoader';

export default function TMATable({
  isLoading,
  TMAData,
  selectedTMA
}) {
  const rows = TMAData.filter((tma) => selectedTMA.includes(tma.name));
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>
              <Box>Lateral Limits</Box>
              <Box>Class of airspace</Box>
            </StyledTableCell>
            <StyledTableCell sx={{ width: 200 }}>Vertical Limits</StyledTableCell>
            <StyledTableCell>Unit providing service</StyledTableCell>
            <StyledTableCell>Call sign, Languages, Hours of service</StyledTableCell>
            <StyledTableCell>Frequency/Purpose</StyledTableCell>
            <StyledTableCell>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader isLoading={isLoading} data={TMAData} colSpan={7} />
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.name}`}>
                <TableCell>{row?.name || '-'}</TableCell>
                <TableCell>
                  <Box>{row?.description || '-'}</Box>
                  <Box>{row?.Airspace_class}</Box>
                  <Box>{row?.Airspace_class_note}</Box>
                </TableCell>
                <TableCell>{row?.AV_upperLimit || '-'} / {row?.AV_lowerLimit || '-'}</TableCell>
                <TableCell>{row?.units}</TableCell>
                <TableCell>{`${row?.callsign}, ${row?.language}, ${row?.hours}`}</TableCell>
                <TableCell>{row?.freq}</TableCell>
                <TableCell>{row?.remarks || '-'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};