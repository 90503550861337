import React, { Fragment } from "react";
import Select from "react-select";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { TreeView, TreeItem } from "@mui/lab";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { staticColorMap } from "../constants/colors";
import { areaFilter, exportObstacleTypes } from "../constants/etodData";
import DropDownLabel from "./DropDownLabel";
import EtodObstacleExportButton from "./EtodObstacleExportButton";
import { LightButton } from "./button";
import BrandLogo from "./BrandLogo";

export default function EtodSideBar({
  isLoadingEtodAirport,
  isLoadingEtodDetails,
  isLoadingEtodObstacles,
  isLoadingEtodOLS,
  isLoadingArea1,
  isLoadingRasterAreas,
  isLoadingEtodOLSObstacles,
  isLoadingArea1Obstacles,
  isLoadingAreaThreeObstacles,
  isLoadingExportObstacle,
  allAirports = [],
  selctedAirport,
  selctedFeature,
  selectedAreas,
  isActiveAirport,
  olsList,
  selectedOls,
  selectedArea1,
  handleFeature,
  handleAirport,
  handleAreaCheck,
  handleSelectAllArea,
  handleClearAllArea,
  handleParentOlsCheck,
  handleOlsCheck,
  handleArea1Check,
  handleRasterAreaCheck,
  selectedRasterArea,
  isShowPenetrationObstacle,
  handlePenetrationObstacleToggle,
  areaOptions,
  handleParentAreaCheck,
  handleParentRasterAreaCheck,
  handleExportObstacle,
}) {
  const isAreaLoading =
    isLoadingEtodDetails || isLoadingRasterAreas || isLoadingEtodOLSObstacles;
  const isDisabled = isActiveAirport || isLoadingEtodOLS || isAreaLoading;
  const isEnabledFilter = Boolean(selctedFeature);

  return (
    <div className="sidebar">
      <BrandLogo />
      <Box sx={{ mb: 1 }}>
        <DropDownLabel label="Airport" />
        <Select
          isClearable
          isLoading={isLoadingEtodAirport}
          value={selctedAirport || null}
          placeholder="Select..."
          onChange={handleAirport}
          options={allAirports}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
        />
      </Box>
      <Box>
        <DropDownLabel label="Feature" />
        <Select
          isClearable
          isDisabled={isActiveAirport || isAreaLoading}
          value={selctedFeature || null}
          placeholder="Select..."
          onChange={handleFeature}
          options={areaFilter}
        />
      </Box>
      {isLoadingEtodDetails || isLoadingEtodOLS ? (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      ) : (
        <Divider sx={{ mt: 2, mb: 1 }} />
      )}
      <div className="check-list-header">
        {(isLoadingEtodObstacles ||
          isLoadingArea1Obstacles ||
          isLoadingAreaThreeObstacles) &&
          selctedFeature?.value === "area" && (
            <Box sx={{ mb: 1, display: "flex" }}>
              Loading obstacles
              <CircularProgress size={20} sx={{ ml: 1 }} />
            </Box>
          )}
        {selctedFeature?.value === "area" &&
          !isLoadingEtodObstacles &&
          !isLoadingArea1Obstacles &&
          !isLoadingAreaThreeObstacles &&
          isEnabledFilter && (
            <Box sx={{ m: 0 }}>
              <Switch
                checked={isShowPenetrationObstacle}
                onChange={handlePenetrationObstacleToggle}
                disabled={
                  isLoadingEtodObstacles ||
                  isLoadingArea1Obstacles ||
                  isLoadingAreaThreeObstacles
                }
              />
              Obstacle Penetration
            </Box>
          )}
        {selctedFeature?.value === "OLS" && isEnabledFilter && (
          <Box sx={{ m: 0 }}>
            <Switch
              checked={isShowPenetrationObstacle}
              onChange={handlePenetrationObstacleToggle}
            />
            Obstacle Penetration
          </Box>
        )}
        {isEnabledFilter && (
          <div className="check-list-action">
            <LightButton
              label="SELECT ALL"
              onClick={handleSelectAllArea}
              disabled={isDisabled}
              sx={{ p: 0 }}
            />
            <LightButton
              label="CLEAR ALL"
              onClick={handleClearAllArea}
              disabled={isDisabled}
              sx={{ p: 0 }}
            />
          </div>
        )}
      </div>
      <div className="check-list etod-check-list-area">
        {selctedFeature && selctedFeature.value === "area" && (
          <Fragment>
            <TreeView
              aria-label="area-features"
              defaultCollapseIcon={<FaAngleDown />}
              defaultExpandIcon={<FaAngleRight />}
              sx={{ overflowY: "auto", overflowX: "hidden" }}
            >
              {areaOptions.map((area) => {
                return (
                  <TreeItem
                    key={area.id.toString()}
                    nodeId={area.id.toString()}
                    label={
                      <FormControlLabel
                        label={area.name}
                        disabled={isDisabled}
                        control={
                          <Checkbox
                            checked={
                              area.type === "dem_area"
                                ? selectedRasterArea.includes(area.value)
                                : selectedAreas.includes(
                                    `${area.value}__${area.color}`
                                  )
                            }
                            onChange={(event) => {
                              if (area.type === "dem_area") {
                                handleParentRasterAreaCheck(event, area);
                              } else {
                                handleParentAreaCheck(event, area, area.color);
                              }
                            }}
                            sx={{
                              color: area.color,
                              "&.Mui-checked": {
                                color: area.color,
                              },
                            }}
                          />
                        }
                      />
                    }
                  >
                    {area.sub &&
                      area.sub.map((layer1) => (
                        <TreeItem
                          key={layer1.id.toString()}
                          nodeId={layer1.id.toString()}
                          label={
                            <FormControlLabel
                              label={layer1.name}
                              disabled={isDisabled}
                              control={
                                <Checkbox
                                  checked={
                                    area.type === "dem_area"
                                      ? selectedRasterArea.includes(
                                          layer1.value
                                        )
                                      : selectedAreas.includes(
                                          `${layer1.value}__${layer1.color}`
                                        )
                                  }
                                  onChange={() => {
                                    if (area.type === "dem_area") {
                                      handleRasterAreaCheck(layer1);
                                    } else {
                                      handleAreaCheck(layer1, layer1.color);
                                    }
                                  }}
                                  sx={{
                                    color: layer1.color,
                                    "&.Mui-checked": {
                                      color: layer1.color,
                                    },
                                  }}
                                />
                              }
                            />
                          }
                        />
                      ))}
                  </TreeItem>
                );
              })}
            </TreeView>
          </Fragment>
        )}
        {(!selctedFeature || selctedFeature.value !== "OLS") && (
          <ListItem disablePadding>
            <ListItemButton
              disabled={isLoadingArea1 || isLoadingArea1Obstacles}
              onClick={() => handleArea1Check(staticColorMap["Area1"])}
              dense
            >
              <ListItemIcon sx={{ mr: -3 }}>
                {(isLoadingArea1 || isLoadingArea1Obstacles) && (
                  <CircularProgress size={16} />
                )}
                {!isLoadingArea1 && !isLoadingArea1Obstacles && (
                  <Checkbox
                    edge="start"
                    checked={Boolean(selectedArea1)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": "Area 1" }}
                    sx={{
                      color: staticColorMap["Area1"],
                      "&.Mui-checked": {
                        color: staticColorMap["Area1"],
                      },
                    }}
                  />
                )}
              </ListItemIcon>
              <ListItemText id="area1" primary="Area 1" />
            </ListItemButton>
          </ListItem>
        )}
        {selctedFeature && selctedFeature.value === "OLS" && (
          <Fragment>
            <TreeView
              aria-label="ols-features"
              defaultCollapseIcon={<FaAngleDown />}
              defaultExpandIcon={<FaAngleRight />}
              sx={{ overflowY: "auto", overflowX: "hidden" }}
            >
              {olsList.map((ols) => {
                return (
                  <TreeItem
                    key={ols.id.toString()}
                    nodeId={ols.id.toString()}
                    label={
                      <FormControlLabel
                        label={ols.name}
                        disabled={isDisabled}
                        control={
                          <Checkbox
                            checked={selectedOls.includes(
                              `${ols.value}__${ols.color}`
                            )}
                            onChange={(event) => {
                              handleParentOlsCheck(event, ols, ols.color);
                            }}
                            sx={{
                              color: ols.color,
                              "&.Mui-checked": {
                                color: ols.color,
                              },
                            }}
                          />
                        }
                      />
                    }
                  >
                    {ols.sub &&
                      ols.sub.map((layer1) => (
                        <TreeItem
                          key={layer1.id.toString()}
                          nodeId={layer1.id.toString()}
                          label={
                            <FormControlLabel
                              label={layer1.name}
                              disabled={isDisabled}
                              control={
                                <Checkbox
                                  checked={selectedOls.includes(
                                    `${layer1.value}__${layer1.color}`
                                  )}
                                  onChange={() => {
                                    handleOlsCheck(layer1, layer1.color);
                                  }}
                                  sx={{
                                    color: layer1.color,
                                    "&.Mui-checked": {
                                      color: layer1.color,
                                    },
                                  }}
                                />
                              }
                            />
                          }
                        />
                      ))}
                  </TreeItem>
                );
              })}
            </TreeView>
          </Fragment>
        )}
      </div>
      <div style={{ position: "absolute", bottom: "1.5rem", width: "inherit" }}>
        <div className="etod-sidebar-footer">
          <EtodObstacleExportButton
            label="Export Obstacles"
            items={exportObstacleTypes(selctedFeature?.value)}
            isLoadingExportObstacle={isLoadingExportObstacle}
            handleExport={handleExportObstacle}
          />
        </div>
      </div>
    </div>
  );
}
