import SvgIcon from '@mui/material/SvgIcon';

export default function LargeStructureIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }} {...props} >
      <svg fill="none">
        <rect x="3" y="7" width="18" height="11" fill="black" />
      </svg>
    </SvgIcon>
  )
};