import React from "react";
import { join } from "lodash";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";

export default function ADIZDetail({ selectedNavid }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name of the Station</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Frequency (Channel)</StyledTableCell>
            <StyledTableCell>Hours of operation</StyledTableCell>
            <StyledTableCell>Coordinates</StyledTableCell>
            <StyledTableCell>DME Antenna Elevation</StyledTableCell>
            <StyledTableCell sx={{ width: 200 }}>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>{selectedNavid?.name || "-"}</StyledTableCell>
            <StyledTableCell>{selectedNavid?.type || "-"}</StyledTableCell>
            <StyledTableCell>
              {selectedNavid?.designator || "-"}
            </StyledTableCell>
            <StyledTableCell>
              {join(selectedNavid?.channel_freq, ", ") || "-"}
            </StyledTableCell>
            <StyledTableCell>
              {selectedNavid?.operationalStatus || "-"}
            </StyledTableCell>
            <StyledTableCell>
              {join(selectedNavid?.coordinates, ", ") || "-"}
            </StyledTableCell>
            <StyledTableCell>{selectedNavid?.elevation || "-"}</StyledTableCell>
            <StyledTableCell>
              {join(selectedNavid?.remarks, ", ") || "-"}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
