import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { includes, remove } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Checkbox, IconButton, Typography } from "@mui/material";
import { aixmFeatureKeyMap } from "../../constants/aixmData";
import { deleteReviewAirportObstacle } from "../../models/airport";
import StyledTableCell from "../tabel-component/StyledTableCell";
import TableRowLoader from "../tabel-component/TableRowLoader";
import ConfirmationDialog from "../ConfirmationDialog";

export default function ObstacleAreaItem({
  isLoading,
  category,
  obstaclesAreaList,
  selctedAirport,
  isActiveDataManagement,
}) {
  const dispatch = useDispatch();
  const isLoadingDeleteAirportObstacles = useSelector(
    (state) => state.airport.isLoadingDeleteAirportObstacles
  );
  const [selectedObstacleIds, setSelectedObstacleIds] = useState([]);
  const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isselectedObstacleIds = (id) => selectedObstacleIds.indexOf(id) !== -1;

  const handleSelectAllAirportObstacle = (event) => {
    if (event.target.checked) {
      const AllSelectedObstacle = obstaclesAreaList.map((n) => n.id);
      setSelectedObstacleIds(AllSelectedObstacle);
      return;
    }
    setSelectedObstacleIds([]);
  };

  const handleAirportObstacle = (id) => {
    if (includes(selectedObstacleIds, id)) {
      remove(selectedObstacleIds, (obstacleId) => obstacleId === id);
    } else {
      selectedObstacleIds.push(id);
    }
    setSelectedObstacleIds([...selectedObstacleIds]);
  };

  const handleActionDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedObstacleIds([]);
  };
  const handleDeleteAirportObstacle = () => {
    const payload = {
      obstacleIds: selectedObstacleIds.map(String),
    };

    dispatch(
      deleteReviewAirportObstacle(
        payload,
        selctedAirport.id,
        selctedAirport.version_id,
        handleCloseConfirmDialog
      )
    );
  };

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={7}>
              {selctedAirport.name} {">"} {aixmFeatureKeyMap[category]}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell colSpan={7} align="center">
              In Approach/Take-off/Circling Area and at AD
            </StyledTableCell>
          </TableRow>
          {selectedObstacleIds.length > 0 && isActiveDataManagement && (
            <TableRow>
              <StyledTableCell
                colSpan={7}
                sx={{ backgroundColor: "#f0eff4 !important" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{selectedObstacleIds.length} Selected</Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleActionDelete()}
                  >
                    <MdDelete color="#d60000" />
                  </IconButton>
                </Box>
              </StyledTableCell>
            </TableRow>
          )}
          <TableRow>
            {isActiveDataManagement && (
              <StyledTableCell padding="checkbox" rowSpan={2}>
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedObstacleIds.length > 0 &&
                    obstaclesAreaList.length > selectedObstacleIds.length
                  }
                  checked={
                    selectedObstacleIds.length > 0 &&
                    obstaclesAreaList.length === selectedObstacleIds.length
                  }
                  onChange={handleSelectAllAirportObstacle}
                  inputProps={{
                    "aria-label": "select all rows",
                  }}
                />
              </StyledTableCell>
            )}
            <StyledTableCell>RWY</StyledTableCell>
            <StyledTableCell>Obstacle type</StyledTableCell>
            <StyledTableCell>Coordinates</StyledTableCell>
            <StyledTableCell>Elevation (EGM 08)</StyledTableCell>
            <StyledTableCell>Marking/LGT</StyledTableCell>
            <StyledTableCell>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader
            isLoading={isLoading}
            data={obstaclesAreaList}
            colSpan={isActiveDataManagement ? 7 : 6}
          />
          {obstaclesAreaList?.map((obstaclesArea, i) => {
            const isItemSelected = isselectedObstacleIds(obstaclesArea.id);
            const labelId = `enhanced-table-checkbox-${i}`;

            return (
              <TableRow
                key={i}
                hover
                onClick={() => handleAirportObstacle(obstaclesArea.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isActiveDataManagement && isItemSelected}
              >
                {isActiveDataManagement && (
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {obstaclesArea.rwy_designator}
                </StyledTableCell>
                <StyledTableCell>{obstaclesArea.obstacle_type}</StyledTableCell>
                <StyledTableCell>
                  <Box>{obstaclesArea.lat}</Box>
                  <Box>{obstaclesArea.lng}</Box>
                </StyledTableCell>
                <StyledTableCell>{obstaclesArea.elevation}</StyledTableCell>
                <StyledTableCell>
                  {obstaclesArea.marking || "-"}/{obstaclesArea.lighted || "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {obstaclesArea.remarks || "-"}
                </StyledTableCell>
              </TableRow>
            );
          })}
          <ConfirmationDialog
            fullWidth
            open={isOpenDeleteDialog}
            title="Delete Request"
            saveButtonColor="error"
            content={`Are you sure you want to delete ${selectedObstacleIds.length} Obstacle Area?`}
            saveButtonLabel="Delete"
            isLoading={isLoadingDeleteAirportObstacles}
            isDisabled={isLoadingDeleteAirportObstacles}
            handleSave={handleDeleteAirportObstacle}
            handleCancel={handleCloseConfirmDialog}
          />
        </TableBody>
      </Table>
    </Paper>
  );
}
