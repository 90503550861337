import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#262261",
    },
    secondary: {
      main: "#116366",
      light: "#dbe5e8",
    },
    tertiary: {
      main: "#f3f3f3",
    },
    white: {
      main: "#ffffff",
      light: "#ffffffb3",
      light2: "#ffffff99",
    },
    black: {
      main: "#000000",
      light: "#424442",
      light2: "#030303",
    },
    grey: {
      main: "#bdbdbd",
      light: "#828282",
      light1: "#95969D",
      borderclr: "#C7C7CC",
      dark: "#42444A",
    },
    lightgreen: {
      main: "#43ffdd",
    },
    red: {
      main: "#EB5757",
    },
    green: {
      main: "#61D26D",
      dark: "#27AE60",
    },
  },
});
