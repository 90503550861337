import React, { Fragment } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const TableRowLoader = ({ isLoading, data, colSpan = 2 }) => {
  return (
    <Fragment>
      {isLoading && (
        <TableRow>
          <TableCell
            align="center"
            colSpan={colSpan}
            sx={{ fontWeight: 'bold' }}
          >
            Loading...
          </TableCell>
        </TableRow>
      )}
      {!isLoading && data?.length === 0 && (
        <TableRow>
          <TableCell align="center" colSpan={colSpan}>
            No Record Found.
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export default TableRowLoader;