import React from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { selecteChartTableData } from "../../models/electronicChart";
import StyledTableRow from "../tabel-component/StyledTableRow";

export default function TaxiWayChartTable() {
  const data = useSelector((state) => selecteChartTableData(state, "Taxiway"));

  return (
    <TableContainer id="eChartTable">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 11 }}>Name</TableCell>
            <TableCell sx={{ fontSize: 11 }}>PCN</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Width</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="eChartTableBody">
          {data.map((row) => (
            <StyledTableRow
              key={row.id}
              id={`table-row-${row.id}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontSize: 11 }}>{row.name}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.pcn}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.width}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.remarks}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
