import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";

const storeSelectedallReqPassword = createWhitelistFilter("survey", [
  "allReqPassword",
]);

const persistConfig = {
  key: "root_aai",
  storage,
  blacklist: [
    "airport",
    "airspace",
    "amdb",
    "etod",
    "aixm",
    "eChart",
  ],
  transforms: [storeSelectedallReqPassword],
};

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export const persistor = persistStore(store);
export default store;
