import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./styles/login.css";
import "./styles/aixm.css";
import "./styles/etod.css";
import "./styles/surveyor.css";
import "./styles/data-management.css";
import "./styles/nav-button.css";
import "./styles/not-found.css";
import "./styles/leaflet.linear-measurement.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import Routes from "./router";

export default function App() {
  return (
    <Fragment>
      <ToastContainer theme="colored" />
      <Routes />
    </Fragment>
  );
}
