import React from "react";
import { Popup } from "react-leaflet";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PopupBox from "../map/PopupBox";
import PopupItem from "../map/PopupItem";

export default function InfoPopup({ info, isShowHint = true }) {
  return (
    <Popup>
      <PopupBox>
        {info.name && <PopupItem>Name:</PopupItem>}
        {info.name && <PopupItem>{info.name}</PopupItem>}
        {info.designation && <PopupItem>Designation:</PopupItem>}
        {info.designation && <PopupItem>{info.designation}</PopupItem>}
        {info?.designator && <PopupItem>Designator:</PopupItem>}
        {info?.designator && <PopupItem>{info?.designator}</PopupItem>}
      </PopupBox>
      {isShowHint && (
        <Box>
          <Typography variant="caption" display="block">
            click the feature to show more detail
          </Typography>
        </Box>
      )}
    </Popup>
  );
}
