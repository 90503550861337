import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LightButton } from "./button";

export default function ConfirmationDialog({
  title,
  content,
  saveButtonLabel = "Save",
  saveButtonColor = "primary",
  isLoading = false,
  isDisabled = false,
  handleSave,
  handleCancel,
  ...props
}) {
  return (
    <Dialog {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <LightButton
          label="Cancel"
          onClick={handleCancel}
          disabled={isDisabled}
        />
        <LightButton
          label={saveButtonLabel}
          onClick={handleSave}
          color={saveButtonColor}
          disabled={isDisabled}
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
}
