import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { chunk } from "lodash";

export default function RenderGeometryLabel({
  data,
  isShowLabel,
  handleLabelClick,
}) {
  const map = useMap();
  const currentLayer = useRef(null);
  const mainFeatureGroup = L.featureGroup();

  useEffect(() => {
    if (currentLayer.current) {
      currentLayer.current.clearLayers();
    }

    if (!isShowLabel || !data) {
      return;
    }

    const componentLabelChunk = chunk(data.ComponentLabel_List, 10);

    componentLabelChunk.forEach((componentLabels) => {
      componentLabels.forEach((label) => {
        const coordinate = label.geometry_json.coordinates[0];
        const html =
          '<span style="color: #000000; font-size: ' +
          label.text_size +
          'px !important;">' +
          label.text +
          "</span>";
        const myIcon = L.divIcon({ className: "node-label-text", html: html });
        const marker = L.marker(coordinate, { icon: myIcon });
        marker.on("click", () => {
          handleLabelClick(label);
        });

        mainFeatureGroup.addLayer(marker);
      });
    });

    mainFeatureGroup.addTo(map);
    currentLayer.current = mainFeatureGroup;
  }, [data, isShowLabel]); // eslint-disable-line

  return null;
}
