import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "../../constants/colors";

export default function RenderOLSTakeOffClimb({
  OLSTakeOffClimbList,
  selectedOls,
  getOLSColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSTakeOffClimbList = chain(OLSTakeOffClimbList)
    .filter((takeOff) => {
      const name = `TAKE OFF_${takeOff.rwy_designator}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleOLSTakeOffClimbList.map((olsTol) => {
        const tolCoordinates = olsTol.coordinates.coordinates;
        const name = `TAKE OFF_${olsTol.rwy_designator}`;
        const tolCoordinateMap = flatten(tolCoordinates).map((tolCoords) => {
          return tolCoords.map((tol) => {
            return {
              lng: tol[0],
              lat: tol[1],
            };
          });
        });

        return tolCoordinateMap.map((tolPolygonPath, i) => (
          <Polygon
            key={i}
            pane="overlayPane"
            positions={tolPolygonPath}
            pathOptions={{
              fillColor: olsColor[name],
              color:
                selectedOverE?.rwy_designator === olsTol.rwy_designator
                  ? areaHighLightColor
                  : olsColor[name],
              weight:
                selectedOverE?.rwy_designator === olsTol.rwy_designator ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, olsTol),
              mouseout: () => handlePolygonOut(),
            }}
          />
        ));
      })}
    </Fragment>
  );
}
