import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce, remove, findIndex } from "lodash";
import Select from "react-select";
import { FaFilter, FaFileExport } from "react-icons/fa";
import { Box, Stack, Typography } from "@mui/material";
import AIXMBanner from "../assets/images/aixm-banner.jpg";
import { aixmCategory, aixmFilterType } from "../constants/aixmData";
import {
  airspaceFilterSortType,
  airspaceFilterType,
  DPRTypeFilterOptions,
} from "../constants/airspaceData";
import { defaultLocationCenter } from "../constants/constant";
import Header from "../components/Header";
import AixmMap from "../components/AixmOSMMap";
import AixmSideBar from "../components/AixmSideBar";
import { PrimaryButton } from "../components/button";
import AixmAirportDetailTables from "../components/aixm-table-item/AixmAirportDetailTables";
import AirspaceTables from "../components/airspace/AirspaceTables";
import {
  getFIRData,
  getTMAData,
  getCTAData,
  getRegulatedAirspaceData,
  getALLRoutes,
  getRouteData,
  getAllNavaid,
  getAllSignificantPoint,
  getAllTRA_TSA_ADIZ,
  getAllDPR,
  selectFIRData,
  selectTMAData,
  selectCTAData,
  selectRegulatedAirspaceData,
  selectAllRoutesOptions,
  selectRoutesNameList,
  selectAllNavaidOptions,
  selectAllNavaidData,
  selectAllSignificantPointOptions,
  selectAllSignificantPoints,
  selectAllDPROptions,
  selectDPRNameList,
  selectDPRDataList,
  selectAllTRA_TSA_ADIZOptions,
  selectTRA_TSA_ADIZNameList,
  selectTRA_TSA_ADIZDataList,
  exportAirspaceSheet,
  exportRouteSheet,
  exportDRPSheet,
  exportTRASheet,
} from "../models/airspace";
import {
  getObstaclesData,
  setAllObstaclesAreaList,
  getAllAIXMAirport,
  getAirportDetails,
  getRunWayDetailData,
  setAirportDetails,
  getAixmDataVersion,
  selectAIXMCategoryOptions,
  setRunwayDetails,
  setSelectedVersion,
} from "../models/aixm";
import { theme } from "../styles/theme";

export default function Aixm() {
  const dispatch = useDispatch();
  const isLoadingAirport = useSelector((state) => state.aixm.isLoadingAirport);
  const isLoadingAirportDetail = useSelector(
    (state) => state.aixm.isLoadingAirportDetail
  );
  const isLoadingRunwayDetails = useSelector(
    (state) => state.aixm.isLoadingRunwayDetails
  );
  const isLoadingAixmObstacles = useSelector(
    (state) => state.aixm.isLoadingAixmObstacles
  );
  const isLoadingGetAixmDataVersion = useSelector(
    (state) => state.aixm.isLoadingGetAixmDataVersion
  );
  const allAirports = useSelector((state) => state.aixm.allAirports);
  const airportDetails = useSelector(
    (state) => state.aixm.airportDetails || {}
  );
  const runwayDetails = useSelector((state) => state.aixm.runwayDetails || {});
  const obstaclesAreaList = useSelector(
    (state) => state.aixm.obstaclesAreaList
  );
  const aixmDataVersions = useSelector((state) => state.aixm.aixmDataVersions);
  const selectedVersion = useSelector((state) => state.aixm.selectedVersion);
  const categories = useSelector(selectAIXMCategoryOptions);
  const [selctedAirport, setAirport] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selctedFeature, setFeature] = useState(null);
  const [tabValue, setTabValue] = useState(null);

  // airspace
  const [searchQuery, setsearchQuery] = useState("");
  const isLoadingAirspaceExport = useSelector(
    (state) => state.airspace.isLoadingAirspaceExport
  );
  const isLoadingFIRData = useSelector(
    (state) => state.airspace.isLoadingFIRData
  );
  const isLoadingTMAData = useSelector(
    (state) => state.airspace.isLoadingTMAData
  );
  const isLoadingCTAData = useSelector(
    (state) => state.airspace.isLoadingCTAData
  );
  const isLoadingRegulatedAirspaceData = useSelector(
    (state) => state.airspace.isLoadingRegulatedAirspaceData
  );
  const isLoadingGetAllNavaid = useSelector(
    (state) => state.airspace.isLoadingGetAllNavaid
  );
  const isLoadingGetAllsignificantPoint = useSelector(
    (state) => state.airspace.isLoadingGetAllsignificantPoint
  );
  const isLoadingGetAllRoute = useSelector(
    (state) => state.airspace.isLoadingGetAllRoute
  );
  const isLoadingGetRouteData = useSelector(
    (state) => state.airspace.isLoadingGetRouteData
  );
  const isLoadingGetAllDPR = useSelector(
    (state) => state.airspace.isLoadingGetAllDPR
  );
  const isLoadingGetAllTRA_TSA_ADIZ = useSelector(
    (state) => state.airspace.isLoadingGetAllTRA_TSA_ADIZ
  );
  const FIROptions = useSelector((state) => selectFIRData(state, searchQuery));
  const FIRData = useSelector((state) => selectFIRData(state));
  const TMAOptions = useSelector((state) => selectTMAData(state, searchQuery));
  const TMAData = useSelector((state) => selectTMAData(state));
  const CTAOptions = useSelector((state) => selectCTAData(state, searchQuery));
  const CTAData = useSelector((state) => selectCTAData(state));
  const regulatedAirspaceOptions = useSelector((state) =>
    selectRegulatedAirspaceData(state, searchQuery)
  );
  const regulatedAirspaceData = useSelector((state) =>
    selectRegulatedAirspaceData(state)
  );
  const [selctedRouteFilter, setSelctedRouteFilter] = useState(null);
  const allRoutesOptions = useSelector(selectAllRoutesOptions);
  const routesNameList = useSelector((state) =>
    selectRoutesNameList(state, selctedRouteFilter?.value, searchQuery)
  );
  const routeData = useSelector((state) => state.airspace.routeData);
  const navaidOptions = useSelector((state) =>
    selectAllNavaidOptions(state, searchQuery)
  );
  const allNavaidData = useSelector((state) => selectAllNavaidData(state));
  const significantPointOptions = useSelector((state) =>
    selectAllSignificantPointOptions(state, searchQuery)
  );
  const allSignificantPoints = useSelector((state) =>
    selectAllSignificantPoints(state)
  );
  const allDPROptions = useSelector((state) => selectAllDPROptions(state));
  const allTRA_TSA_ADIZOptions = useSelector((state) =>
    selectAllTRA_TSA_ADIZOptions(state)
  );
  const [selctedDPRFilter, setSelctedDPRFilter] = useState(null);
  const [selctedDPRTypeFilter, setSelctedDPRTypeFilter] = useState(null);
  const dprNameList = useSelector((state) =>
    selectDPRNameList(
      state,
      selctedDPRFilter?.value,
      selctedDPRTypeFilter?.value,
      searchQuery
    )
  );
  const DPRDataList = useSelector((state) =>
    selectDPRDataList(
      state,
      selctedDPRFilter?.value,
      selctedDPRTypeFilter?.value
    )
  );
  const [selctedMETAFilter, setSelctedMETAFilter] = useState(null);
  const METANameList = useSelector((state) =>
    selectTRA_TSA_ADIZNameList(state, selctedMETAFilter?.value, searchQuery)
  );
  const METADataList = useSelector((state) =>
    selectTRA_TSA_ADIZDataList(state, selctedMETAFilter?.value)
  );
  const [selctedAirspaceFilter, setSelctedAirspaceFilter] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedAirspaceRows, setSelectedAirspaceRows] = useState([]);
  const hasSectedAirspace = selectedAirspaceRows.length > 0 || selectedRoute;

  useEffect(() => {
    dispatch(setSelectedVersion(null));
    dispatch(getAixmDataVersion());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!tabValue && selectedCategories.length > 0) {
      setTabValue(selectedCategories[0].value);
    }

    if (selectedCategories.length === 0) {
      setTabValue(null);
    }
  }, [selectedCategories]); // eslint-disable-line

  const handleFeature = (option) => {
    if (option === null) {
      setFeature(null);
    } else {
      setFeature(option);
      if (option.value === "Airport") {
        dispatch(getAllAIXMAirport());
      }
    }

    dispatch(setAirportDetails(null));
    dispatch(setRunwayDetails(null));
    dispatch(setAllObstaclesAreaList({ ObstacleArea_list: [] }));
    setAirport(null);
    setAirportLocation(defaultLocationCenter);
    setZoomLevel(5);
    handleClearAllCategory();
    // airspace
    setSelctedAirspaceFilter(null);
    setSelctedRouteFilter(null);
    setSelctedMETAFilter(null);
    setSelectedRoute(null);
    setSelctedDPRFilter(null);
    setSelctedDPRTypeFilter(null);
    setsearchQuery(null);
    handleAirspaceClear();
  };

  const handleAirport = (option) => {
    dispatch(setAllObstaclesAreaList({ ObstacleArea_list: [] }));
    dispatch(setAirportDetails(null));
    dispatch(setRunwayDetails(null));

    if (option === null) {
      setAirport(null);
      setAirportLocation(defaultLocationCenter);
      setZoomLevel(5);
      handleClearAllCategory();
    } else {
      const version = selectedVersion.id;

      setTimeout(() => {
        dispatch(getAirportDetails(option.id, version));
        dispatch(getRunWayDetailData(option.id, version));

        if (selectedCategories.length > 0) {
          const isObstacle = selectedCategories.find(
            (o) => o.value === aixmCategory.obstacleArea_list
          );

          if (isObstacle && !isLoadingAixmObstacles) {
            dispatch(getObstaclesData(option.id, version));
          }
        }
      }, 0);
      setAirport(option);
      setAirportLocation(option.coordinates);
      setZoomLevel(15);
    }
  };

  const updateSelectedValues = (selectedValues, value) => {
    if (!selectedValues.includes(value)) {
      selectedValues.push(value);
    } else {
      selectedValues.splice(selectedValues.indexOf(value), 1);
    }
  };

  const handleCategoryFilter = (option) => {
    setSelectedCategories(option);

    if (option.length > 0) {
      const isObstacle = option.find(
        (o) => o.value === aixmCategory.obstacleArea_list
      );
      const version = selectedVersion.id;

      if (
        isObstacle &&
        obstaclesAreaList.length === 0 &&
        !isLoadingAixmObstacles
      ) {
        dispatch(getObstaclesData(selctedAirport.id, version));
      }
    }
  };

  const handleClearAllCategory = () => {
    setSelectedCategories([]);
    setTabValue(null);
  };

  const handleVersion = (option) => {
    dispatch(setSelectedVersion(option));
  };

  const handleDateFilter = () => {
    const version = selectedVersion.id;

    if (selctedFeature?.value === aixmFilterType.airport) {
      dispatch(setAllObstaclesAreaList({ ObstacleArea_list: [] }));
      dispatch(setAirportDetails(null));
      dispatch(setRunwayDetails(null));
      dispatch(getAllAIXMAirport(version));
      dispatch(getAirportDetails(selctedAirport?.id, version));
      dispatch(getRunWayDetailData(selctedAirport.id, version));

      if (selectedCategories.length > 0) {
        const isObstacle = selectedCategories.find(
          (o) => o.value === aixmCategory.obstacleArea_list
        );

        if (isObstacle && !isLoadingAixmObstacles) {
          dispatch(getObstaclesData(selctedAirport.id, version));
        }
      }
    } else if (selctedFeature?.value === aixmFilterType.airspace) {
      setSelectedRoute(null);

      if (selctedAirspaceFilter.value === airspaceFilterType.firuir) {
        dispatch(getFIRData(version));
      } else if (selctedAirspaceFilter.value === airspaceFilterType.tma) {
        dispatch(getTMAData(version));
      } else if (
        selctedAirspaceFilter.value === airspaceFilterType.controlArea
      ) {
        dispatch(getCTAData(version));
      } else if (
        selctedAirspaceFilter.value ===
        airspaceFilterType.otherRegulatedAirspace
      ) {
        dispatch(getRegulatedAirspaceData(version));
      } else if (
        selctedAirspaceFilter.value === airspaceFilterType.radioNavigation
      ) {
        dispatch(getAllNavaid(version));
      } else if (
        selctedAirspaceFilter.value === airspaceFilterType.significantPoints
      ) {
        dispatch(getAllSignificantPoint(version));
      } else if (
        selctedAirspaceFilter.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas
      ) {
        dispatch(getAllDPR(version));
      } else if (
        selctedAirspaceFilter.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas
      ) {
        dispatch(getAllTRA_TSA_ADIZ(version));
      } else if (selctedAirspaceFilter.value === airspaceFilterType.routes) {
        dispatch(getALLRoutes(version));
      }
    }
  };

  // airspace
  const handleAirspaceSearch = debounce((event) => {
    setsearchQuery(event.target.value);
  }, 500);

  const handleAirspaceFilter = (option) => {
    handleAirspaceClear();
    setSelctedRouteFilter(null);
    setSelctedMETAFilter(null);
    setSelectedRoute(null);
    setSelctedDPRFilter(null);
    setSelctedDPRTypeFilter(null);
    setsearchQuery(null);

    if (option === null) {
      setSelctedAirspaceFilter(null);
    } else {
      setSelctedAirspaceFilter(option);

      if (option.value === airspaceFilterType.firuir && FIRData.length === 0) {
        dispatch(getFIRData());
      } else if (
        option.value === airspaceFilterType.tma &&
        TMAData.length === 0
      ) {
        dispatch(getTMAData());
      } else if (
        option.value === airspaceFilterType.controlArea &&
        CTAData.length === 0
      ) {
        dispatch(getCTAData());
      } else if (
        option.value === airspaceFilterType.otherRegulatedAirspace &&
        regulatedAirspaceData.length === 0
      ) {
        dispatch(getRegulatedAirspaceData());
      } else if (
        option.value === airspaceFilterType.radioNavigation &&
        allNavaidData.length === 0
      ) {
        dispatch(getAllNavaid());
      } else if (
        option.value === airspaceFilterType.significantPoints &&
        allSignificantPoints.length === 0
      ) {
        dispatch(getAllSignificantPoint());
      } else if (
        option.value ===
          airspaceFilterType.prohibitedRestrictedandDangerAreas &&
        allDPROptions.length === 0
      ) {
        dispatch(getAllDPR());
      } else if (
        option.value === airspaceFilterType.militaryExecriseandTrainingAreas &&
        allTRA_TSA_ADIZOptions.length === 0
      ) {
        dispatch(getAllTRA_TSA_ADIZ());
      } else if (
        option.value === airspaceFilterType.routes &&
        allRoutesOptions.length === 0
      ) {
        dispatch(getALLRoutes());
      }
    }
  };

  const handleRouteFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelectedRoute(null);

    if (option === null) {
      setSelctedRouteFilter(null);
    } else {
      setSelctedRouteFilter(option);
    }
  };

  const handleMETAFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelectedRoute(null);

    if (option === null) {
      setSelctedMETAFilter(null);
    } else {
      setSelctedMETAFilter(option);
    }
  };

  const handleDPRFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelctedDPRFilter(null);
    setSelctedDPRTypeFilter(null);

    if (option === null) {
      setSelctedDPRFilter(null);
    } else {
      setSelctedDPRFilter(option);
    }
  };

  const handleDPRTypeFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelctedDPRTypeFilter(null);

    if (option === null) {
      setSelctedDPRTypeFilter(null);
    } else {
      setSelctedDPRTypeFilter(option);
    }
  };

  const handleAirspaceNameCheck = (data) => {
    updateSelectedValues(selectedAirspaceRows, data.name);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
  };

  const handleAirspaceDesignatorCheck = (data) => {
    updateSelectedValues(selectedAirspaceRows, data.designator);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
  };

  const handleAiespaceAerodromeCheck = (ra) => {
    updateSelectedValues(selectedAirspaceRows, ra.Aerodrome);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
  };

  const handleDPRMetaCheck = (dpr) => {
    const dprName = `${dpr.Airspace_name} ${dpr.designator}`;
    updateSelectedValues(selectedAirspaceRows, dprName);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
  };

  const handleRoute = (route) => {
    setSelectedRoute(route);
    dispatch(getRouteData(route.id));
  };

  const handleAirspaceAllSelect = () => {
    if (selctedAirspaceFilter.value === airspaceFilterType.firuir) {
      const firMap = [];
      FIRData.forEach((fir) => firMap.push(fir.name));
      setSelectedAirspaceRows([...firMap]);
    } else if (selctedAirspaceFilter.value === airspaceFilterType.tma) {
      const tmaMap = [];
      TMAData.forEach((tma) => tmaMap.push(tma.name));
      setSelectedAirspaceRows([...tmaMap]);
    } else if (selctedAirspaceFilter.value === airspaceFilterType.controlArea) {
      const ctaMap = [];
      CTAData.forEach((cta) => ctaMap.push(cta.name));
      setSelectedAirspaceRows([...ctaMap]);
    } else if (
      selctedAirspaceFilter.value === airspaceFilterType.otherRegulatedAirspace
    ) {
      const raMap = [];
      regulatedAirspaceData.forEach((ra) => raMap.push(ra.Aerodrome));
      setSelectedAirspaceRows([...raMap]);
    } else if (
      selctedAirspaceFilter.value === airspaceFilterType.radioNavigation
    ) {
      const rnMap = [];
      navaidOptions.forEach((rn) => rnMap.push(rn.designator));
      setSelectedAirspaceRows([...rnMap]);
    } else if (
      selctedAirspaceFilter.value === airspaceFilterType.significantPoints
    ) {
      const spMap = [];
      significantPointOptions.forEach((sp) => spMap.push(sp.name));
      setSelectedAirspaceRows([...spMap]);
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.prohibitedRestrictedandDangerAreas
    ) {
      const dprMap = [];
      dprNameList.forEach((dpr) =>
        dprMap.push(`${dpr.Airspace_name} ${dpr.designator}`)
      );
      setSelectedAirspaceRows([...dprMap]);
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.militaryExecriseandTrainingAreas
    ) {
      const metaMap = [];
      METANameList.forEach((meta) =>
        metaMap.push(`${meta.Airspace_name} ${meta.designator}`)
      );
      setSelectedAirspaceRows([...metaMap]);
    }
  };

  const handleAirspaceClear = () => {
    setSelectedAirspaceRows([]);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseTab = (category) => {
    const deleteIndex = findIndex(selectedCategories, category);

    remove(selectedCategories, (c) => {
      return c.value === category.value;
    });

    const selectedTabIndex = findIndex(
      selectedCategories,
      (c) => c.value === tabValue
    );

    setTabValue(null);
    if (selectedTabIndex <= -1) {
      if (selectedCategories.length === 1) {
        setTabValue(selectedCategories[0].value);
      } else if (selectedCategories.length === 2) {
        if (deleteIndex > 0) {
          setTabValue(selectedCategories[deleteIndex - 1].value);
        } else if (deleteIndex === 0) {
          setTabValue(selectedCategories[0].value);
        }
      } else if (selectedCategories.length > 2) {
        if (deleteIndex > 0) {
          setTabValue(selectedCategories[deleteIndex - 1].value);
        } else if (deleteIndex === 0) {
          setTabValue(selectedCategories[0].value);
        }
      }
    } else {
      setTimeout(() => {
        setTabValue(tabValue);
      }, 500);
    }

    setSelectedCategories([...selectedCategories]);
  };

  const handleDataExport = () => {
    if (selctedAirspaceFilter.value === airspaceFilterType.routes) {
      dispatch(exportRouteSheet(selectedRoute.id, selectedVersion?.id));
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.prohibitedRestrictedandDangerAreas
    ) {
      dispatch(
        exportDRPSheet(
          selctedDPRFilter.value,
          selctedDPRTypeFilter.value,
          selectedVersion?.id
        )
      );
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.militaryExecriseandTrainingAreas
    ) {
      dispatch(exportTRASheet(selctedMETAFilter.value, selectedVersion?.id));
    } else {
      dispatch(
        exportAirspaceSheet(
          airspaceFilterSortType[selctedAirspaceFilter.value],
          selectedVersion?.id
        )
      );
    }
  };

  return (
    <div className="wrapper">
      <AixmSideBar
        isLoadingAirport={isLoadingAirport}
        isLoadingAirportDetail={isLoadingAirportDetail}
        isLoadingGetAixmDataVersion={isLoadingGetAixmDataVersion}
        isLoadingFIRData={isLoadingFIRData}
        isLoadingTMAData={isLoadingTMAData}
        isLoadingCTAData={isLoadingCTAData}
        isLoadingRegulatedAirspaceData={isLoadingRegulatedAirspaceData}
        isLoadingGetAllNavaid={isLoadingGetAllNavaid}
        isLoadingGetAllsignificantPoint={isLoadingGetAllsignificantPoint}
        isLoadingGetAllRoute={isLoadingGetAllRoute}
        isLoadingGetAllDPR={isLoadingGetAllDPR}
        isLoadingGetAllTRA_TSA_ADIZ={isLoadingGetAllTRA_TSA_ADIZ}
        isLoadingGetRouteData={isLoadingGetRouteData}
        allAirports={allAirports}
        categories={categories}
        selectedCategories={selectedCategories}
        selctedAirport={selctedAirport}
        isActiveAirport={!selctedAirport}
        selctedFeature={selctedFeature}
        selctedAirspaceFilter={selctedAirspaceFilter}
        firOptions={FIROptions}
        selectedAirspaceRows={selectedAirspaceRows}
        TMAOptions={TMAOptions}
        CTAOptions={CTAOptions}
        regulatedAirspaceOptions={regulatedAirspaceOptions}
        allRoutesOptions={allRoutesOptions}
        selctedRouteFilter={selctedRouteFilter}
        routesNameList={routesNameList}
        selectedRoute={selectedRoute}
        navaidOptions={navaidOptions}
        allDPROptions={allDPROptions}
        allTRA_TSA_ADIZOptions={allTRA_TSA_ADIZOptions}
        selctedDPRFilter={selctedDPRFilter}
        significantPointOptions={significantPointOptions}
        DPRTypeFilterOptions={DPRTypeFilterOptions}
        dprNameList={dprNameList}
        hasSectedAirspace={hasSectedAirspace}
        handleCategoryFilter={handleCategoryFilter}
        handleAirport={handleAirport}
        handleFeature={handleFeature}
        handleAirspaceFilter={handleAirspaceFilter}
        handleAirspaceSearch={handleAirspaceSearch}
        handleAirspaceAllSelect={handleAirspaceAllSelect}
        handleAirspaceNameCheck={handleAirspaceNameCheck}
        handleAirspaceDesignatorCheck={handleAirspaceDesignatorCheck}
        handleAiespaceAerodromeCheck={handleAiespaceAerodromeCheck}
        handleRouteFilter={handleRouteFilter}
        handleRoute={handleRoute}
        handleAirspaceClear={handleAirspaceClear}
        handleDPRFilter={handleDPRFilter}
        selctedDPRTypeFilter={selctedDPRTypeFilter}
        handleDPRTypeFilter={handleDPRTypeFilter}
        handleDPRMetaCheck={handleDPRMetaCheck}
        selctedMETAFilter={selctedMETAFilter}
        handleMETAFilter={handleMETAFilter}
        METANameList={METANameList}
      />
      <div className="main">
        <Header active="aixm" />
        {(selectedCategories.length > 0 || hasSectedAirspace) && (
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              zIndex: 6,
              pt: 1,
              pb: 1,
              pr: 2,
            }}
          >
            <Box>
              <Typography variant="caption">Published Date</Typography>
              <Select
                isClearable
                isLoading={isLoadingGetAixmDataVersion}
                isDisabled={isLoadingAirportDetail}
                value={selectedVersion || null}
                placeholder="Publication Date"
                onChange={handleVersion}
                options={aixmDataVersions}
                getOptionLabel={(option) => option.published_date}
                getOptionValue={(option) => option.published_date}
                styles={{
                  container: (base) => ({ ...base, width: 220 }),
                }}
              />
            </Box>
            <Box>
              <Typography variant="caption">Effective Date</Typography>
              <Select
                isClearable
                isLoading={isLoadingGetAixmDataVersion}
                isDisabled={isLoadingAirportDetail}
                value={selectedVersion || null}
                placeholder="Effective Date"
                onChange={handleVersion}
                options={aixmDataVersions}
                getOptionLabel={(option) => option.effective_date}
                getOptionValue={(option) => option.effective_date}
                styles={{
                  container: (base) => ({ ...base, width: 220 }),
                }}
              />
            </Box>
            <Box>
              <PrimaryButton
                label="Change Date"
                endIcon={<FaFilter size={15} />}
                disabled={isLoadingAirportDetail || !Boolean(selectedVersion)}
                onClick={handleDateFilter}
                sx={{ widh: 100, mr: 1, mt: 2.8 }}
              />
              {selctedFeature.value === "Airspace" && (
                <PrimaryButton
                  label="Export"
                  isLoading={isLoadingAirspaceExport}
                  disabled={isLoadingAirspaceExport}
                  endIcon={<FaFileExport size={15} />}
                  onClick={handleDataExport}
                  sx={{ widh: 100, mt: 2.8 }}
                />
              )}
            </Box>
          </Stack>
        )}
        <div className="content-area">
          {!selctedAirport &&
            selectedAirspaceRows.length === 0 &&
            !selectedRoute && (
              <img src={AIXMBanner} alt="aixm" className="aixm-banner" />
            )}
          {selctedAirport && selectedCategories.length === 0 && (
            <AixmMap
              airportLocation={airportLocation}
              zoomLevel={zoomLevel}
              selctedAirport={selctedAirport}
            />
          )}
          {selectedCategories?.length > 0 && (
            <div className="aixm-category-table">
              {tabValue && (
                <AixmAirportDetailTables
                  isActiveDataManagement={false}
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  handleCloseTab={handleCloseTab}
                  selectedCategories={selectedCategories}
                  isLoadingAirportDetail={isLoadingAirportDetail}
                  selctedAirport={selctedAirport}
                  airportDetails={airportDetails}
                  runwayDetails={runwayDetails}
                  isLoadingRunwayDetails={isLoadingRunwayDetails}
                  isLoadingAixmObstacles={isLoadingAixmObstacles}
                  obstaclesAreaList={obstaclesAreaList}
                />
              )}
            </div>
          )}
          {selectedCategories?.length === 0 && (
            <AirspaceTables
              selctedAirspaceFilter={selctedAirspaceFilter}
              selectedAirspaceRows={selectedAirspaceRows}
              selectedRoute={selectedRoute}
              routeData={routeData}
              allNavaidData={allNavaidData}
              allSignificantPoints={allSignificantPoints}
              DPRDataList={DPRDataList}
              METADataList={METADataList}
              FIRData={FIRData}
              TMAData={TMAData}
              CTAData={CTAData}
              regulatedAirspaceData={regulatedAirspaceData}
              isLoadingFIRData={isLoadingFIRData}
              isLoadingTMAData={isLoadingTMAData}
              isLoadingCTAData={isLoadingCTAData}
              isLoadingRegulatedAirspaceData={isLoadingRegulatedAirspaceData}
              isLoadingGetAllNavaid={isLoadingGetAllNavaid}
              isLoadingGetAllsignificantPoint={isLoadingGetAllsignificantPoint}
              isLoadingGetAllDPR={isLoadingGetAllDPR}
              isLoadingGetAllTRA_TSA_ADIZ={isLoadingGetAllTRA_TSA_ADIZ}
              selctedMETAFilter={selctedMETAFilter}
              isLoadingGetRouteData={isLoadingGetRouteData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
