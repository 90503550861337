import React, { Fragment } from 'react';
import { join } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableRowLoader from '../tabel-component/TableRowLoader';

export default function METATable({
  isLoading,
  METADataList,
  selectedMETA,
  selctedMETAFilter,
}) {
  const rows = METADataList.filter((meta) => {
    const metaName = `${meta.Airspace_name} ${meta.designator}`;
    return selectedMETA.includes(metaName);
  });

  if (selctedMETAFilter?.value === 'ADIZ') {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: 150 }}>Name</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowLoader isLoading={isLoading} data={METADataList} colSpan={2} />
            {rows.map((row, i) => {
              return (
                <TableRow key={`${i}_${row?.Airspace_name}`}>
                  <StyledTableCell>
                    <Box sx={{ typography: 'subtitle2' }}>
                      {row?.designator || '-'}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.description || '-'}
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  };

  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Box>Name</Box>
              <Box>Lateral Limits</Box>
            </StyledTableCell>
            <StyledTableCell sx={{ width: 150 }}>
              <Box>Upper Limit</Box>
              <Box>Lower Limit</Box>
            </StyledTableCell>
            <StyledTableCell>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <StyledTableCell align="center" colSpan={3}>
                Loading...
              </StyledTableCell>
            </TableRow>
          )}
          {!isLoading && METADataList.length === 0 && (
            <TableRow>
              <StyledTableCell align="center" colSpan={3}>
                No Record Found.
              </StyledTableCell>
            </TableRow>
          )}
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.Airspace_name}`}>
                <StyledTableCell>
                  <Box sx={{ typography: 'subtitle2' }}>
                    {row?.designator || '-'} [{row?.Airspace_name || '-'}]
                  </Box>
                  <Box>{row?.description || '-'}</Box>
                </StyledTableCell>
                <StyledTableCell sx={{ width: 100 }}>
                  <Fragment>
                    <Box>{row?.upperLimit || '-'}</Box>
                    <Box>{row?.lowerLimit || '-'}</Box>
                  </Fragment>
                </StyledTableCell>
                <StyledTableCell>{join(row?.remarks, ', ') || '-'}</StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};