import * as yup from "yup";

export const airportVersionDate = [
  {
    id: 1,
    effective_date: "11 AUG 2022",
    published_date: "30 JUN 2022",
  },
  {
    id: 2,
    effective_date: "08 SEP 2022",
    published_date: "28 JUL 2022",
  },
  {
    id: 3,
    effective_date: "03 NOV 2022",
    published_date: "22 SEP 2022",
  },
];

export const rwyDesignationDir = [
  {
    id: 0,
    label: "Clear",
    value: "",
  },
  {
    id: 1,
    label: "R",
    value: "R",
  },
  {
    id: 2,
    label: "L",
    value: "L",
  },
  {
    id: 3,
    label: "C",
    value: "C",
  },
];

export const icaoPrefixTypes = [
  {
    id: 1,
    label: "VO",
    value: "VO",
  },
  {
    id: 2,
    label: "VE",
    value: "VE",
  },
  {
    id: 3,
    label: "VA",
    value: "VA",
  },
  {
    id: 4,
    label: "VI",
    value: "VI",
  },
];

export const airportDataCategories = [
  {
    id: 1,
    label: "(AD 2.2) AERODROME GEOGRAPHICAL AND ADMINISTRATIVE DATA",
    value: "AD 2.2 AERODROME GEOGRAPHICAL AND ADMINISTRATIVE DATA",
  },
  {
    id: 2,
    label: "(AD 2.10) AERODROME OBSTACLES",
    value: "AD 2.10 AERODROME OBSTACLES",
  },
  {
    id: 3,
    label: "(AD 2.12) RUNWAY DETAILS",
    value: "AD 2.12 RUNWAY DETAILS",
  },
  {
    id: 4,
    label: "(AD 2.12) RUNWAY DIRECTION",
    value: "AD 2.12 RUNWAY DIRECTION",
  },
  {
    id: 5,
    label: "(AD 2.8) APRON",
    value: "AD 2.8 APRON",
  },
  {
    id: 6,
    label: "(AD 2.8) Taxiway",
    value: "AD 2.8 Taxiway",
  },
  {
    id: 7,
    label: "(AD 2.19) Navids",
    value: "AD 2.19 Navids",
  },
  {
    id: 8,
    label: "(AD 2.24) Charts",
    value: "AD 2.24 Charts",
  },
];

export const ad2Category = {
  ad2_2_aerodom_geographical_and_administrative_data:
    "AD 2.2 AERODROME GEOGRAPHICAL AND ADMINISTRATIVE DATA",
  ad2_10_aerodom_obstacles: "AD 2.10 AERODROME OBSTACLES",
  ad2_12_runway_details: "AD 2.12 RUNWAY DETAILS",
  ad2_12_runway_direction: "AD 2.12 RUNWAY DIRECTION",
  ad2_8_apron: "AD 2.8 APRON",
  ad2_8_taxiway: "AD 2.8 Taxiway",
  ad2_19_navids: "AD 2.19 Navids",
  ad2_24_charts: "AD 2.24 Charts",
};

export const ad2ExportCategoryTypes = [
  {
    id: 1,
    label: "Airport Heliport",
    value: "airportheliport",
  },
  {
    id: 2,
    label: "(AD 2.12) Runway",
    value: "runway",
  },
  {
    id: 3,
    label: "Runway Direction",
    value: "runway_direction",
  },
  {
    id: 4,
    label: "Runway Protect Area",
    value: "runway_protect_area",
  },
  {
    id: 5,
    label: "(AD 2.10) Aerodrome Obstacles",
    value: "obstacles",
  },
  {
    id: 6,
    label: "(AD 2.8) Apron",
    value: "apron",
  },
  {
    id: 7,
    label: "(AD 2.8) Taxiway",
    value: "taxiway",
  },
];

export const AirportStatus = {
  notStarted: "Not Started",
  inProcess: "In Process",
  closed: "Closed",
};

export const Ad212SurfaceTypes = [
  {
    id: 1,
    label: "(ASPH) Asphalt",
    value: "ASPH",
  },
  {
    id: 2,
    label: "(ASPH_GRASS) Asphalt and grass",
    value: "ASPH_GRASS",
  },
  {
    id: 3,
    label: "(CONC) Concrete",
    value: "CONC",
  },
  {
    id: 4,
    label: "(CONC_ASPH) Concrete and asphalt",
    value: "CONC_ASPH",
  },
  {
    id: 5,
    label: "(CONC_GRS) Concrete and grass",
    value: "CONC_GRS",
  },
  {
    id: 6,
    label: "(GRASS) Grass including portions of turf or bare earth",
    value: "GRASS",
  },
  {
    id: 7,
    label: "(SAND) Sand",
    value: "SAND",
  },
  {
    id: 8,
    label: "(WATER) Water",
    value: "WATER",
  },
  {
    id: 9,
    label: "(BITUM) Bituminous tar or asphalt",
    value: "BITUM",
  },
  {
    id: 10,
    label: "(BRICK) Brick",
    value: "BRICK",
  },
  {
    id: 11,
    label:
      "(BRIMACADAMCK) A macadam or tarmac surface consisting of water-bound crushed rock",
    value: "BRIMACADAMCK",
  },
  {
    id: 12,
    label: "(STONE) Stone",
    value: "STONE",
  },
  {
    id: 13,
    label: "(CORAL) Coral",
    value: "CORAL",
  },
  {
    id: 14,
    label: "(CLAY) Clay",
    value: "CLAY",
  },
  {
    id: 15,
    label: "(LATERITE) Laterite - a high iron clay formed in tropical areas",
    value: "LATERITE",
  },
  {
    id: 16,
    label: "(GRAVEL) Gravel",
    value: "GRAVEL",
  },
  {
    id: 17,
    label: "(EARTH) Earth (in general)",
    value: "EARTH",
  },
  {
    id: 18,
    label: "(ICE) Ice",
    value: "ICE",
  },
  {
    id: 19,
    label: "(SNOW) Snow",
    value: "SNOW",
  },
  {
    id: 20,
    label: "(MEMBRANE) A protective laminate usually made of rubber",
    value: "MEMBRANE",
  },
  {
    id: 21,
    label: "(METAL) Metal - steel, aluminium",
    value: "METAL",
  },
  {
    id: 22,
    label: "(MATS) Landing mat portable system usually made of aluminium",
    value: "MATS",
  },
  {
    id: 23,
    label: "(PIERCED_STEEL) Pierced steel planking",
    value: "PIERCED_STEEL",
  },
  {
    id: 24,
    label: "(WOOD) Wood",
    value: "WOOD",
  },
  {
    id: 25,
    label: "(NON_BITUM_MIX) Non Bituminous mix",
    value: "NON_BITUM_MIX",
  },
  {
    id: 26,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212PavementTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "Rigid pavement",
    value: "RIGID",
  },
  {
    id: 3,
    label: "Flexible pavement",
    value: "FLEXIBLE",
  },
  {
    id: 4,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212EveluationTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "(TECH) Technical evaluation",
    value: "TECH",
  },
  {
    id: 3,
    label: "(ACFT) Based on aircraft experience",
    value: "ACFT",
  },
  {
    id: 4,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212MaxTyrePressureTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "(W) High: No pressure limit",
    value: "W",
  },
  {
    id: 3,
    label: "(X) Medium: Pressure limited to 1.5 MPa (217 psi)",
    value: "X",
  },
  {
    id: 4,
    label: "(Y) Low: Pressure limited to 1.00 MPa (145 psi)",
    value: "Y",
  },
  {
    id: 5,
    label: "(Z) Very low: Pressure limited to 0.50 MPa (73 psi)",
    value: "Z",
  },
  {
    id: 6,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212PavementSubgradeTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "(A) High strength sub-grade",
    value: "A",
  },
  {
    id: 3,
    label: "(B)Medium strength sub-grade",
    value: "B",
  },
  {
    id: 4,
    label: "(C) Low strength sub-grade",
    value: "C",
  },
  {
    id: 5,
    label: "(D) Ultra-low strength sub-grade",
    value: "D",
  },
  {
    id: 6,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212ApproachLightingTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label:
      "(ALSAF) 3000 Foot high intensity Approach Lighting System with Sequenced Flashing Lights",
    value: "ALSAF",
  },
  {
    id: 3,
    label: "(MALS) 1400 Foot Medium Intensity Approach Lighting System",
    value: "MALS",
  },
  {
    id: 4,
    label:
      "(MALSR) 1400 Foot Medium Intensity Approach Lighting System with Runway Alignment Indicator Lights",
    value: "MALSR",
  },
  {
    id: 5,
    label: "(SALS) Simple Approach Lighting System",
    value: "SALS",
  },
  {
    id: 6,
    label: "(SSALS) Simplified Short Approach Lighting System",
    value: "SSALS",
  },
  {
    id: 7,
    label:
      "(SSALR) Simplified Short Approach Lighting System with Runway Alignment Indicator Lights",
    value: "SSALR",
  },
  {
    id: 8,
    label: "(LDIN) Lead-In Lighting System",
    value: "LDIN",
  },
  {
    id: 9,
    label: "(ODALS) Omni Directional Approach Lighting System",
    value: "ODALS",
  },
  {
    id: 10,
    label:
      "(AFOVRN) US Air Force Overrun 1000 Foot Standard Approach Lighting System",
    value: "AFOVRN",
  },
  {
    id: 11,
    label: "(MILOVRN) Military Overrun",
    value: "MILOVRN",
  },
  {
    id: 12,
    label: "(CALVERT) Calvert Approach Lighting System",
    value: "CALVERT",
  },
  {
    id: 13,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212Colours = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "Yellow",
    value: "YELLOW",
  },
  {
    id: 3,
    label: "Red",
    value: "RED",
  },
  {
    id: 4,
    label: "White",
    value: "WHITE",
  },
  {
    id: 5,
    label: "Blue",
    value: "BLUE",
  },
  {
    id: 6,
    label: "Green",
    value: "GREEN",
  },
  {
    id: 7,
    label: "Purple",
    value: "PURPLE",
  },
  {
    id: 8,
    label: "Orange",
    value: "ORANGE",
  },
  {
    id: 9,
    label: "amber",
    value: "AMBER",
  },
  {
    id: 10,
    label: "Black",
    value: "BLACK",
  },
  {
    id: 11,
    label: "Brown",
    value: "BROWN",
  },
  {
    id: 12,
    label: "Grey",
    value: "GREY",
  },
  {
    id: 13,
    label: "Light grey",
    value: "LIGHT_GREY",
  },
  {
    id: 14,
    label: "Magenta",
    value: "MAGENTA",
  },
  {
    id: 15,
    label: "Pink",
    value: "PINK",
  },
  {
    id: 16,
    label: "Violet",
    value: "VIOLET",
  },
  {
    id: 17,
    label: "Other",
    value: "OTHER",
  },
];

export const navidTypes = [
  {
    id: 1,
    label: "ILS",
    value: "ILS",
  },
  {
    id: 2,
    label: "ILS_DME",
    value: "ILS_DME",
  },
  {
    id: 3,
    label: "NBD",
    value: "NBD",
  },
  {
    id: 4,
    label: "L",
    value: "L",
  },
  {
    id: 5,
    label: "MKR",
    value: "MKR",
  },
  {
    id: 6,
    label: "DME",
    value: "DME",
  },
  {
    id: 7,
    label: "VOR_DME",
    value: "VOR_DME",
  },
];

export const hoursOfOperation = [
  {
    id: 1,
    label: "H24",
    value: "H24",
  },
  {
    id: 2,
    label: "HJ",
    value: "HJ",
  },
  {
    id: 3,
    label: "HN",
    value: "HN",
  },
  {
    id: 4,
    label: "HX",
    value: "HX",
  },
  {
    id: 5,
    label: "HO",
    value: "HO",
  },
  {
    id: 6,
    label: "NOTAM",
    value: "NOTAM",
  },
];

const icaoRegEx = /^[A-Z]{2}$/;
// const latRegEx = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g;
// const lngRegEx = /^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g;
export const createAirportValidationSchema = yup.object().shape({
  icaoPrefix: yup.string().required("Required"),
  icao: yup
    .string()
    .min(2, "Min 2 characters")
    .max(2, "Max 2 characters")
    .matches(icaoRegEx, "Enter a valid code")
    .required("Required"),
  name: yup.string("Enter airport name").required("Required"),
  latitude: yup.string().required("Required"),
  longitude: yup.string().required("Required"),
  effective_date: yup.string().required("Required"),
  published_date: yup.string().required("Required"),
});

const phoneRegEx =
  /^((\\+[1-9]{1,4}[\\-]*)|(\\([0-9]{2,3}\\)[\\-]*)|([0-9]{2,4})[\\-]*)*?[0-9]{3,4}?[\\-]*[0-9]{3,4}?$/;
const numberRegEx = /^[0-9]*$/;

export const createAd22tValidationSchema = yup.object().shape({
  site: yup.string().required("site is required"),
  dir_distance_city: yup
    .string()
    .required("Direction Distance and City is required"),
  elevation: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Elevation is required"),
  temp: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Temperature is required"),
  dateMagneticVariation: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Magnetic variation is required"),
  magneticVariation: yup.string().required("Date of information is required"),
  magneticVariationChange: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Annual change is required"),
  traffic_type: yup.string().required("Types of traffic permitted is required"),
  authority_name: yup
    .string()
    .required("Name of aerodrome operator name is required"),
  address: yup.string().required("Address is required"),
  telephone: yup
    .string()
    .min(10, "Phone Number min 10 characters")
    .max(10, "Phone Number max 10 characters")
    .matches(phoneRegEx, "Phone Number is not valid")
    .required("Required"),
  email: yup.string().email("Enter a valid email"),
  remark: yup.string().required("Remark is required"),
});

export const createAd228ValidationSchema = yup.object().shape({
  file_name: yup.string().required("Required"),
  file: yup.string().required("Required"),
});

const rwyDsgRegEx = /^[0-9]{2}$/;
export const createAd212tValidationSchema = yup.object({
  runways: yup.array().of(
    yup.object().shape({
      rwy_dsg1: yup
        .string()
        .min(2, "Min 2 characters")
        .max(2, "Max 2 characters")
        .matches(rwyDsgRegEx, "Enter a valid code")
        .required("Required"),
      rwy_dsg2: yup
        .string()
        .min(2, "Min 2 characters")
        .max(2, "Max 2 characters")
        .matches(rwyDsgRegEx, "Enter a valid code")
        .required("Required"),
      nominalLength: yup.string().required("Required"),
      nominalWidth: yup.string().required("Required"),
      lengthStrip: yup.string().required("Required"),
      widthStrip: yup.string().required("Required"),
      surface: yup.string().required("Required"),
      classPCN: yup.string().required("Required"),
      pavementTypePCN: yup.string().required("Required"),
      pavementSubgradePCN: yup.string().required("Required"),
      maxTyrePressurePCN: yup.string().required("Required"),
      evaluationMethodPCN: yup.string().required("Required"),
    })
  ),
});

export const createAd212DirectionValidationSchema = yup.object({
  runwayDirections: yup.array().of(
    yup.object().shape({
      true_bearings: yup.string().required("Required"),
      slopeTDZ: yup.string().required("Required"),
      thr_lat: yup.string().required("Required"),
      thr_long: yup.string().required("Required"),
      thr_elev: yup.string().required("Required"),
      rwy_end_elev: yup.string().required("Required"),
      rwy_end_lat: yup.string().required("Required"),
      rwy_end_long: yup.string().required("Required"),
      tdz_elve: yup.string().required("Required"),
      // d_stopway_length: yup.string().required("Required"),
      // d_stopway_width: yup.string().required("Required"),
      // d_clearway_length: yup.string().required("Required"),
      // d_clearway_width: yup.string().required("Required"),
      // d_resa_length: yup.string().required("Required"),
      // d_resa_width: yup.string().required("Required"),
      remarks: yup.string().required("Required"),
      tora: yup.string().required("Required"),
      toda: yup.string().required("Required"),
      asda: yup.string().required("Required"),
      lda: yup.string().required("Required"),
      declared_distace_remarks: yup.string().required("Required"),
      als_type: yup.string().required("Required"),
      als_intensityLevel: yup.string().required("Required"),
      als_lenght: yup.string().required("Required"),
      thr_color: yup.string().required("Required"),
      // cl_color: yup.string().required("Required"),
      // cl_intensity: yup.string().required("Required"),
      // cl_length: yup.string().required("Required"),
      // cl_spacing: yup.string().required("Required"),
      edge_color: yup.string().required("Required"),
      edge_intensity: yup.string().required("Required"),
      edge_length: yup.string().required("Required"),
      edge_spacing: yup.string().required("Required"),
      end_color: yup.string().required("Required"),
      // visual_slope_type: yup.string().required("Required"),
      // visual_slope_slopeAngle: yup.string().required("Required"),
      // visual_slope_minimumthreshold: yup.string().required("Required"),
      // visual_slope_position: yup.string().required("Required"),
    })
  ),
});

export const createAd28ApronValidationSchema = yup.object({
  aprons: yup.array().of(
    yup.object().shape({
      designation: yup.string().required("Required"),
      surface: yup.string().required("Required"),
      classPCN: yup.string().required("Required"),
      pavementTypePCN: yup.string().required("Required"),
      pavementSubgradePCN: yup.string().required("Required"),
      maxTyrePressurePCN: yup.string().required("Required"),
      evaluationMethodPCN: yup.string().required("Required"),
    })
  ),
});

export const createAd28TaxiwayValidationSchema = yup.object({
  taxiways: yup.array().of(
    yup.object().shape({
      designation: yup.string().required("Required"),
      width: yup.string().required("Required"),
      surface: yup.string().required("Required"),
      classPCN: yup.string().required("Required"),
      pavementTypePCN: yup.string().required("Required"),
      pavementSubgradePCN: yup.string().required("Required"),
      maxTyrePressurePCN: yup.string().required("Required"),
      evaluationMethodPCN: yup.string().required("Required"),
    })
  ),
});

/* eslint-disable */
export const createAd219NavidValidationSchema = yup.object({
  navids: yup.array().of(
    yup.object().shape({
      identification: yup.string().required("Required"),
      type: yup.string().required("Required"),
      rwy_dir: yup.string().required("Required"),
      LOC: yup.object().when("type", {
        is: "ILS",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          signalperformance: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      GP: yup.object().when("type", {
        is: "ILS",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      LOC: yup.object().when("type", {
        is: "ILS_DME",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          signalperformance: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      GP: yup.object().when("type", {
        is: "ILS_DME",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      DME: yup.object().when("type", {
        is: "ILS_DME",
        then: yup.object({
          channel: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      frequency: yup.string().when("type", {
        is: "NBD",
        then: yup.string().required("Required"),
      }),
      frequency: yup.string().when("type", {
        is: "L",
        then: yup.string().required("Required"),
      }),
      frequency: yup.string().when("type", {
        is: "MKR",
        then: yup.string().required("Required"),
      }),
      frequency: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      channel: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      channel: yup.string().when("type", {
        is: "DME",
        then: yup.string().required("Required"),
      }),
      // latitude: yup.string().required("Required"),
      // longitude: yup.string().required("Required"),
      // hours_of_operation: yup.string().required("Required"),
      // elevation: yup.string().required("Required"),
      // remarks: yup.string().required("Required"),
    })
  ),
});
/* eslint-enable */
