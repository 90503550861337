import React from "react";
import { Popup } from "react-leaflet";
import PopupBox from "../map/PopupBox";
import PopupItem from "../map/PopupItem";

export default function WayPointsInfoPopup({ info }) {
  return (
    <Popup>
      <PopupBox>
        <PopupItem>Name:</PopupItem>
        <PopupItem>{info?.name}</PopupItem>
        <PopupItem>Latitude:</PopupItem>
        <PopupItem>{info?.coordinates[0]}</PopupItem>
        <PopupItem>Longitude:</PopupItem>
        <PopupItem>{info?.coordinates[1]}</PopupItem>
      </PopupBox>
    </Popup>
  );
}
