import { createSlice, createSelector } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { chain, orderBy, uniqBy, isEmpty, keys } from "lodash";
import * as XLSX from "xlsx";
import { apiCall, downloadReadableStreamFile } from "../utils/connect";
import {
  firColumnsData,
  tmaColumnsData,
  ctaColumnsData,
  raColumnsData,
  spColumnsData,
  rnColumnsData,
  dprColumnsData,
  metaColumnsData,
  routeColumnsData,
} from "../utils/airspaceExport";
import {
  FIRUIRKeyMap,
  ADIZKeyMap,
  airspaceFilterType,
} from "../constants/airspaceData";
import {
  GET_ALL_FIR_DATA_URL,
  GET_ALL_TMA_DATA_URL,
  GET_ALL_CTA_DATA_URL,
  GET_ALL_REGULATED_AIRSPACE_DATA_URL,
  GET_RESTRICTIVE_AIRSPACE_GEOMETRY_URL,
  GET_CONTROL_AIRSPACE_GEOMETRY_URL,
  GET_FIR_AIRSPACE_GEOMETRY_URL,
  GET_ALL_ROUTE_URL,
  GET_ROUTE_DATA_URL,
  GET_ALL_NAVID_URL,
  GET_ALL_SIGNIFICANT_POINT_URL,
  GET_ALL_TRA_TSA_ADIZ_URL,
  GET_ALL_DPR_URL,
  GET_ADIZ_GEODATA_URL,
  GET_RESTRICTIVE_AIRSPACE_DETAILS_URL,
  GET_ALL_RNAV_CNAV_ROUTES_URL,
  GET_WAYPOINT_ROUTE_DATA_URL,
  EXPORT_AIRSPACE_SHEET_URL,
  EXPORT_ROUTE_SHEET_URL,
  EXPORT_DRP_SHEET_URL,
  EXPORT_TRA_SHEET_URL,
} from "../constants/urls";

const initialState = {
  isLoadingFIRData: false,
  isLoadingTMAData: false,
  isLoadingCTAData: false,
  isLoadingRegulatedAirspaceData: false,
  isLoadingRestrictiveAirspaceGeometry: false,
  isLoadingControlAirspaceGeometry: false,
  FIRData: [],
  TMAData: [],
  CTAData: [],
  regulatedAirspaceData: [],
  restrictiveAirspaceGeometry: [],
  controlAirspaceGeometry: [],
  isLoadingFIRAirspaceGeometry: false,
  FIRAirspaceGeometry: [],
  isLoadingGetAllRoute: false,
  allRoutes: {},
  isLoadingGetRouteData: false,
  routeData: [],
  isLoadingAreaNavigationalRouteData: false,
  areaNavigationalRouteData: [],
  isLoadingConventionalRouteData: false,
  conventionalRouteData: [],
  isLoadingGetAllNavaid: false,
  allNavaid: [],
  isLoadingGetAllsignificantPoint: false,
  allsignificantPoint: [],
  isLoadingGetAllDPR: false,
  allDPR: [],
  isLoadingGetAllTRA_TSA_ADIZ: false,
  allTRA_TSA_ADIZ: [],
  isLoadingGetADIZGeoData: false,
  adizGeoData: [],
  isLoadingRestrictiveAirspaceDetails: false,
  restrictiveAirspaceDetails: null,
  isLoadingRNAVRoutesGeometry: false,
  isLoadingCNAVRoutesGeometry: false,
  isLoadingRNAVRoutes: false,
  RNAVRoutesData: [],
  isLoadingCNAVRoutes: false,
  CNAVRoutesData: [],
  isLoadingAirspaceExport: false,
  isLoadingWaypointRouteData: false,
  waypointRouteData: [],
};

export const airspace = createSlice({
  name: "airspace",
  initialState,
  reducers: {
    setFIRdataLoadingRequest: (state, action) => {
      state.isLoadingFIRData = action.payload;
    },
    setTMAdataLoadingRequest: (state, action) => {
      state.isLoadingTMAData = action.payload;
    },
    setCTAdataLoadingRequest: (state, action) => {
      state.isLoadingCTAData = action.payload;
    },
    setRegulatedAirspaceDataLoadingRequest: (state, action) => {
      state.isLoadingRegulatedAirspaceData = action.payload;
    },
    setRestrictiveAirspaceGeometryLoadingRequest: (state, action) => {
      state.isLoadingRestrictiveAirspaceGeometry = action.payload;
    },
    setControlAirspaceGeometryLoadingRequest: (state, action) => {
      state.isLoadingControlAirspaceGeometry = action.payload;
    },
    setFIRDetails: (state, action) => {
      state.FIRData = action.payload;
    },
    setTMADetails: (state, action) => {
      state.TMAData = action.payload;
    },
    setCTADetails: (state, action) => {
      state.CTAData = action.payload;
    },
    setRegulatedAirspaceDetails: (state, action) => {
      state.regulatedAirspaceData = action.payload;
    },
    setRestrictiveAirspaceGeometryData: (state, action) => {
      state.restrictiveAirspaceGeometry = action.payload;
    },
    setControlAirspaceGeometryData: (state, action) => {
      state.controlAirspaceGeometry = action.payload;
    },
    setFIRAirspaceGeometryLoadingRequest: (state, action) => {
      state.isLoadingFIRAirspaceGeometry = action.payload;
    },
    setFIRAirspaceGeometryData: (state, action) => {
      state.FIRAirspaceGeometry = action.payload;
    },
    setLoadingGetAllRoute: (state, action) => {
      state.isLoadingGetAllRoute = action.payload;
    },
    setAllRoutes: (state, action) => {
      state.allRoutes = action.payload;
    },
    setLoadingGetRouteData: (state, action) => {
      state.isLoadingGetRouteData = action.payload;
    },
    setRouteData: (state, action) => {
      state.routeData = action.payload;
    },
    setLoadingAreaNavigationalRouteData: (state, action) => {
      state.isLoadingAreaNavigationalRouteData = action.payload;
    },
    setAreaNavigationalRouteData: (state, action) => {
      state.areaNavigationalRouteData = action.payload;
    },
    setLoadingConventionalRouteData: (state, action) => {
      state.isLoadingConventionalRouteData = action.payload;
    },
    setConventionalRouteData: (state, action) => {
      state.conventionalRouteData = action.payload;
    },
    setLoadingGetAllNavaid: (state, action) => {
      state.isLoadingGetAllNavaid = action.payload;
    },
    setAllNavaid: (state, action) => {
      state.allNavaid = action.payload;
    },
    setLoadingGetAllsignificantPoint: (state, action) => {
      state.isLoadingGetAllsignificantPoint = action.payload;
    },
    setAllsignificantPoint: (state, action) => {
      state.allsignificantPoint = action.payload;
    },
    setLoadingGetAllDPR: (state, action) => {
      state.isLoadingGetAllDPR = action.payload;
    },
    setAllDPR: (state, action) => {
      state.allDPR = action.payload;
    },
    setLoadingGetAllTRA_TSA_ADIZ: (state, action) => {
      state.isLoadingGetAllTRA_TSA_ADIZ = action.payload;
    },
    setAllTRA_TSA_ADIZ: (state, action) => {
      state.allTRA_TSA_ADIZ = action.payload;
    },
    setLoadingGetADIZGeoData: (state, action) => {
      state.isLoadingGetADIZGeoData = action.payload;
    },
    setADIZGeoData: (state, action) => {
      state.adizGeoData = action.payload;
    },
    setLoadingRestrictiveAirspaceDetails: (state, action) => {
      state.isLoadingRestrictiveAirspaceDetails = action.payload;
    },
    setRestrictiveAirspaceDetails: (state, action) => {
      state.restrictiveAirspaceDetails = action.payload;
    },
    setLoadingRNAVRoutesGeometry: (state, action) => {
      state.isLoadingRNAVRoutesGeometry = action.payload;
    },
    setLoadingCNAVRoutesGeometry: (state, action) => {
      state.isLoadingCNAVRoutesGeometry = action.payload;
    },
    setLoadingRNAVRoutes: (state, action) => {
      state.isLoadingRNAVRoutes = action.payload;
    },
    setRNAVRoutesData: (state, action) => {
      state.RNAVRoutesData = action.payload;
    },
    setLoadingCNAVRoutes: (state, action) => {
      state.isLoadingCNAVRoutes = action.payload;
    },
    setCNAVRoutesData: (state, action) => {
      state.CNAVRoutesData = action.payload;
    },
    setLoadingAirspaceExport: (state, action) => {
      state.isLoadingAirspaceExport = action.payload;
    },
    setLoadingWaypointRouteData: (state, action) => {
      state.isLoadingWaypointRouteData = action.payload;
    },
    setWaypointRouteData: (state, action) => {
      state.waypointRouteData = action.payload;
    },
  },
});

export const {
  setFIRdataLoadingRequest,
  setTMAdataLoadingRequest,
  setCTAdataLoadingRequest,
  setRegulatedAirspaceDataLoadingRequest,
  setRestrictiveAirspaceGeometryLoadingRequest,
  setControlAirspaceGeometryLoadingRequest,
  setFIRDetails,
  setTMADetails,
  setCTADetails,
  setRegulatedAirspaceDetails,
  setRestrictiveAirspaceGeometryData,
  setControlAirspaceGeometryData,
  setFIRAirspaceGeometryLoadingRequest,
  setFIRAirspaceGeometryData,
  setLoadingGetAllRoute,
  setAllRoutes,
  setLoadingGetRouteData,
  setRouteData,
  setLoadingGetAllNavaid,
  setAllNavaid,
  setLoadingGetAllsignificantPoint,
  setAllsignificantPoint,
  setLoadingGetAllDPR,
  setAllDPR,
  setLoadingGetAllTRA_TSA_ADIZ,
  setAllTRA_TSA_ADIZ,
  setLoadingGetADIZGeoData,
  setADIZGeoData,
  setLoadingRestrictiveAirspaceDetails,
  setRestrictiveAirspaceDetails,
  setLoadingRNAVRoutesGeometry,
  setLoadingCNAVRoutesGeometry,
  setLoadingRNAVRoutes,
  setRNAVRoutesData,
  setLoadingCNAVRoutes,
  setCNAVRoutesData,
  setLoadingAreaNavigationalRouteData,
  setAreaNavigationalRouteData,
  setLoadingConventionalRouteData,
  setConventionalRouteData,
  setLoadingAirspaceExport,
  setLoadingWaypointRouteData,
  setWaypointRouteData,
} = airspace.actions;

export default airspace.reducer;

const selectSearchQuery = (state, searchQuery) => searchQuery;
const selectAllTMAData = (state) => state.airspace.TMAData;
const selectAllFIRData = (state) => state.airspace.FIRData;
const selectAllCTAData = (state) => state.airspace.CTAData;
const selectAllRestrictiveAirspaceGeometry = (state) =>
  state.airspace.restrictiveAirspaceGeometry;
const selectAllRegulatedAirspaceData = (state) =>
  state.airspace.regulatedAirspaceData;
const selectAllControlAirspaceData = (state) =>
  state.airspace.controlAirspaceGeometry;
const selectAllFIRAirspaceData = (state) => state.airspace.FIRAirspaceGeometry;

export const selectFIRData = createSelector(
  [selectAllFIRData, selectSearchQuery],
  (firData, searchQuery) => {
    if (searchQuery) {
      return chain(firData)
        .filter((fir) => {
          return fir.name.toLowerCase().includes(searchQuery);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(firData, ["name"], ["asc"]);
  }
);

const selectFindQuery = (state, findQuery) => findQuery;
export const findFIRData = createSelector(
  [selectAllFIRData, selectFindQuery],
  (firData, findQuery) => {
    if (findQuery) {
      const name = FIRUIRKeyMap[findQuery];
      return firData.find(
        (fir) => fir.name.toLowerCase() === name.toLowerCase()
      );
    }

    return null;
  }
);

export const selectTMAData = createSelector(
  [selectAllTMAData, selectSearchQuery],
  (tmaData, searchQuery) => {
    if (searchQuery) {
      return chain(tmaData)
        .filter((tma) => {
          return tma.name.toLowerCase().includes(searchQuery);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(tmaData, ["name"], ["asc"]);
  }
);

export const selectCTAData = createSelector(
  [selectAllCTAData, selectSearchQuery],
  (ctaData, searchQuery) => {
    if (searchQuery) {
      return chain(ctaData)
        .filter((cta) => {
          return cta.name.toLowerCase().includes(searchQuery);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(ctaData, ["name"], ["asc"]);
  }
);

export const selectRegulatedAirspaceData = createSelector(
  [selectAllRegulatedAirspaceData, selectSearchQuery],
  (raData, searchQuery) => {
    if (searchQuery) {
      return chain(raData)
        .filter((ra) => {
          return ra.Aerodrome.toLowerCase().includes(searchQuery);
        })
        .orderBy(["Aerodrome"], ["asc"])
        .value();
    }

    return orderBy(raData, ["Aerodrome"], ["asc"]);
  }
);

export const selectRestrictiveAirspaceGeometry = createSelector(
  [selectAllRestrictiveAirspaceGeometry, selectSearchQuery],
  (restrictiveAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.name);

      if (serachValue.includes("All")) {
        return orderBy(
          uniqBy(restrictiveAirspaceGeometry, "name"),
          ["name"],
          ["asc"]
        );
      }

      return chain(restrictiveAirspaceGeometry)
        .filter((rag) => {
          return serachValue.includes(rag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectRegulatedAirspaceOption = (state) => {
  const restrictiveAirspaceData = state.airspace.restrictiveAirspaceGeometry;

  if (restrictiveAirspaceData.length > 0) {
    return orderBy(uniqBy(restrictiveAirspaceData, "name"), ["name"], ["asc"]);
  }

  return [];
};

const searchQueryFIR = (a, b, fir) => fir;
export const selectPDRAirspaceGeometry = createSelector(
  [selectAllRestrictiveAirspaceGeometry, selectSearchQuery, searchQueryFIR],
  (restrictiveAirspaceGeometry, searchQuery, selectedfir) => {
    if (searchQuery.length > 0) {
      const firDesignations = [];
      const serachValue = searchQuery.map((s) => s.name);
      selectedfir.forEach((fir) => {
        if (fir.designation === "All") {
          firDesignations.push(fir.designation);
          return;
        }

        const code = fir.designation.substring(0, 2);
        firDesignations.push(code);
      });
      const pdrAirspaceGeometry = restrictiveAirspaceGeometry.filter((a) => {
        if (firDesignations.length > 0 && !firDesignations.includes("All")) {
          return (
            ["P", "D", "R"].includes(a.type) && firDesignations.includes(a.fir)
          );
        }

        return ["P", "D", "R"].includes(a.type);
      });

      if (serachValue.includes("All")) {
        return orderBy(uniqBy(pdrAirspaceGeometry, "name"), ["name"], ["asc"]);
      }

      return chain(pdrAirspaceGeometry)
        .filter((rag) => {
          return serachValue.includes(rag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

const searchOptionFIR = (a, fir) => fir;
export const selectPDRAirspaceOption = createSelector(
  [selectAllRestrictiveAirspaceGeometry, searchOptionFIR],
  (restrictiveAirspaceData, selectedfir) => {
    if (restrictiveAirspaceData.length > 0) {
      const firDesignations = [];

      selectedfir.forEach((fir) => {
        if (fir.designation === "All") {
          firDesignations.push(fir.designation);
          return;
        }

        const code = fir.designation.substring(0, 2);
        firDesignations.push(code);
      });

      const pdrAirspace = restrictiveAirspaceData.filter((a) => {
        if (firDesignations.length > 0 && !firDesignations.includes("All")) {
          return (
            ["P", "D", "R"].includes(a.type) && firDesignations.includes(a.fir)
          );
        }

        return ["P", "D", "R"].includes(a.type);
      });

      return orderBy(uniqBy(pdrAirspace, "name"), ["name"], ["asc"]);
    }

    return [];
  }
);

export const selectTraTsaAirspaceOption = (state) => {
  const restrictiveAirspaceData = state.airspace.restrictiveAirspaceGeometry;

  if (restrictiveAirspaceData.length > 0) {
    const traTsaAirspace = restrictiveAirspaceData.filter((a) => {
      return ["TRA", "TSA"].includes(a.type);
    });

    return orderBy(uniqBy(traTsaAirspace, "name"), ["name"], ["asc"]);
  }

  return [];
};

export const selectTraTsaAirspaceGeometry = createSelector(
  [selectAllRestrictiveAirspaceGeometry, selectSearchQuery],
  (restrictiveAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const traTsaAirspace = restrictiveAirspaceGeometry.filter((a) => {
        return ["TRA", "TSA"].includes(a.type);
      });
      const serachValue = searchQuery.map((s) => s.name);

      if (serachValue.includes("All")) {
        return orderBy(uniqBy(traTsaAirspace, "name"), ["name"], ["asc"]);
      }

      return chain(traTsaAirspace)
        .filter((rag) => {
          return serachValue.includes(rag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

const searchControlQueryFIR = (a, b, fir) => fir;
export const selectControlAirspaceGeometry = createSelector(
  [selectAllControlAirspaceData, selectSearchQuery, searchControlQueryFIR],
  (controlAirspaceGeometryData, searchQuery, selectedfir) => {
    if (searchQuery.length > 0) {
      const firDesignations = [];
      const serachValue = searchQuery.map((s) => s.name);

      selectedfir.forEach((fir) => {
        if (fir.designation === "All") {
          firDesignations.push(fir.designation);
          return;
        }

        const code = fir.designation.substring(0, 2);
        firDesignations.push(code);
      });

      const controlAirspaceGeometry = controlAirspaceGeometryData.filter(
        (a) => {
          const airspaceCode = a.designation.substring(0, 2);

          if (firDesignations.length > 0 && !firDesignations.includes("All")) {
            return firDesignations.includes(airspaceCode);
          }

          return true;
        }
      );

      if (serachValue.includes("All")) {
        return orderBy(
          uniqBy(controlAirspaceGeometry, "name"),
          ["name"],
          ["asc"]
        );
      }

      return chain(controlAirspaceGeometry)
        .filter((cag) => {
          return serachValue.includes(cag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectControlAirspaceOption = (state) => {
  const controlAirspaceData = state.airspace.controlAirspaceGeometry;

  if (controlAirspaceData.length > 0) {
    return orderBy(uniqBy(controlAirspaceData, "name"), ["name"], ["asc"]);
  }

  return [];
};

export const selectFIRAirspaceGeometry = createSelector(
  [selectAllFIRAirspaceData, selectSearchQuery],
  (FIRAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.name);

      if (serachValue.includes("All")) {
        return orderBy(FIRAirspaceGeometry, ["name"], ["asc"]);
      }

      return chain(FIRAirspaceGeometry)
        .filter((cag) => {
          return serachValue.includes(cag.name);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectFIRAirspaceOption = (state) => {
  const FIRAirspaceData = state.airspace.FIRAirspaceGeometry;

  if (FIRAirspaceData.length > 0) {
    return orderBy(uniqBy(FIRAirspaceData, "name"), ["name"], ["asc"]);
  }

  return [];
};

export const selectAllRoutesOptions = (state) => {
  const allRoutes = state.airspace.allRoutes;

  if (!isEmpty(allRoutes)) {
    const options = keys(allRoutes).map((key, i) => ({
      id: i + 1,
      label: key,
      value: key,
    }));
    return orderBy(options, ["label"], ["asc"]);
  }

  return [];
};

const selectAllRoutes = (state) => state.airspace.allRoutes;
const selectSearchRoutes = (state, route) => route;
const selectsearchQueryRoute = (state, route, searchQuery) => searchQuery;

export const selectRoutesNameList = createSelector(
  [selectAllRoutes, selectSearchRoutes, selectsearchQueryRoute],
  (allRoutes, name, searchQuery) => {
    if (allRoutes && name && allRoutes[name]) {
      if (searchQuery) {
        return chain(allRoutes[name])
          .filter((r) => {
            const name = `${r.designatorSecondLetter}${r.multipleIdentifier}${r.designatorNumber}`;
            return name.toLowerCase().includes(searchQuery);
          })
          .orderBy(["id"], ["asc"])
          .value();
      }

      return orderBy(allRoutes[name], ["id"], ["asc"]);
    }

    return [];
  }
);

const selectAllNavaid = (state) => state.airspace.allNavaid;
export const selectAllNavaidOptions = createSelector(
  [selectAllNavaid, selectSearchQuery],
  (allNavid, searchQuery) => {
    if (searchQuery) {
      return chain(allNavid)
        .filter((o) => {
          return o.designator.toLowerCase().includes(searchQuery);
        })
        .uniqBy("designator")
        .orderBy(["designator"], ["asc"])
        .value();
    }

    return orderBy(uniqBy(allNavid, "designator"), ["designator"], ["asc"]);
  }
);

export const selectAllNavaidData = createSelector(
  [selectAllNavaid],
  (allNavid) => {
    return orderBy(allNavid, ["name"], ["asc"]);
  }
);

export const selectFilterNavaidData = createSelector(
  [selectAllNavaid, selectSearchQuery],
  (allNavid, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.designator);

      if (serachValue.includes("All")) {
        return orderBy(allNavid, ["name"], ["asc"]);
      }

      return chain(allNavid)
        .filter((navid) => {
          return serachValue.includes(navid.designator);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

const selectAllSignificantPoint = (state) => state.airspace.allsignificantPoint;
export const selectAllSignificantPointOptions = createSelector(
  [selectAllSignificantPoint, selectSearchQuery],
  (allSignificantPoint, searchQuery) => {
    if (searchQuery) {
      return chain(allSignificantPoint)
        .filter((o) => {
          return o.name.toLowerCase().includes(searchQuery);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(uniqBy(allSignificantPoint, "name"), ["name"], ["asc"]);
  }
);

export const selectfilterSignificantPoints = createSelector(
  [selectAllSignificantPoint, selectSearchQuery],
  (allSignificantPoint, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.name);

      if (serachValue.includes("All")) {
        return orderBy(allSignificantPoint, ["name"], ["asc"]);
      }

      return chain(allSignificantPoint)
        .filter((sp) => {
          return serachValue.includes(sp.name);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectAllSignificantPoints = createSelector(
  [selectAllSignificantPoint],
  (allSignificantPoint) => {
    return orderBy(allSignificantPoint, ["name"], ["asc"]);
  }
);

export const selectAllDPROptions = (state) => {
  const allDPR = state.airspace.allDPR;

  if (!isEmpty(allDPR)) {
    const options = keys(allDPR).map((key, i) => ({
      id: i + 1,
      label: key,
      value: key,
    }));
    return orderBy(options, ["label"], ["asc"]);
  }

  return [];
};

const selectAllDPR = (state) => state.airspace.allDPR;
const selectSearchDPR = (state, dpr) => dpr;
const selectSearchDPRType = (state, dpr, type) => type;
const selectsearchQueryDPR = (state, route, type, searchQuery) => searchQuery;

export const selectDPRNameList = createSelector(
  [selectAllDPR, selectSearchDPR, selectSearchDPRType, selectsearchQueryDPR],
  (allDPR, dpr, type, searchQuery) => {
    if (allDPR && dpr && type && allDPR[dpr][type]) {
      if (searchQuery) {
        return chain(allDPR[dpr][type])
          .filter((r) => {
            const name = `${r.Airspace_name}${r.designator}`;
            return name.toLowerCase().includes(searchQuery);
          })
          .orderBy(["Airspace_name", "designator"], ["asc", "asc"])
          .value();
      }

      return orderBy(
        allDPR[dpr][type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

export const selectDPRDataList = createSelector(
  [selectAllDPR, selectSearchDPR, selectSearchDPRType],
  (allDPR, dpr, type) => {
    if (allDPR && dpr && type && allDPR[dpr][type]) {
      return orderBy(
        allDPR[dpr][type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

export const selectAllTRA_TSA_ADIZOptions = (state) => {
  const allTRA_TSA_ADIZ = state.airspace.allTRA_TSA_ADIZ;

  if (!isEmpty(allTRA_TSA_ADIZ)) {
    const options = keys(allTRA_TSA_ADIZ).map((key, i) => ({
      id: i + 1,
      label: key,
      value: key,
    }));
    return orderBy(options, ["label"], ["asc"]);
  }

  return [];
};

const selectAllTRA_TSA_ADIZ = (state) => state.airspace.allTRA_TSA_ADIZ;
const selectSearchTRA_TSA_ADIZ = (state, type) => type;
const selectSearchQueryTRA_TSA_ADIZ = (state, route, searchQuery) =>
  searchQuery;

export const selectTRA_TSA_ADIZNameList = createSelector(
  [
    selectAllTRA_TSA_ADIZ,
    selectSearchTRA_TSA_ADIZ,
    selectSearchQueryTRA_TSA_ADIZ,
  ],
  (allTRA_TSA_ADIZ, type, searchQuery) => {
    if (allTRA_TSA_ADIZ && type && allTRA_TSA_ADIZ[type]) {
      if (searchQuery) {
        return chain(allTRA_TSA_ADIZ[type])
          .filter((r) => {
            const name = `${r.Airspace_name}${r.designator}`;
            return name.toLowerCase().includes(searchQuery);
          })
          .orderBy(["Airspace_name", "designator"], ["asc", "asc"])
          .value();
      }

      return orderBy(
        allTRA_TSA_ADIZ[type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

export const selectTRA_TSA_ADIZDataList = createSelector(
  [selectAllTRA_TSA_ADIZ, selectSearchTRA_TSA_ADIZ],
  (allTRA_TSA_ADIZ, type) => {
    if (allTRA_TSA_ADIZ && type && allTRA_TSA_ADIZ[type]) {
      return orderBy(
        allTRA_TSA_ADIZ[type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

const findQueryADIZ = (state, findQuery) => findQuery;
export const findADIZDataList = createSelector(
  [selectAllTRA_TSA_ADIZ, findQueryADIZ],
  (allTRA_TSA_ADIZ, findQuery) => {
    if (findQuery && allTRA_TSA_ADIZ && allTRA_TSA_ADIZ["ADIZ"]) {
      const ADIZData = allTRA_TSA_ADIZ["ADIZ"];
      const designator = ADIZKeyMap[findQuery];
      return ADIZData.find(
        (adiz) => adiz.designator.toLowerCase() === designator.toLowerCase()
      );
    }

    return null;
  }
);

const selectAllADIZGeoData = (state) => state.airspace.adizGeoData;
const selectSearchQueryADIZGeoData = (state, searchQuery) => searchQuery;
export const selectADIZAirspaceGeometry = createSelector(
  [selectAllADIZGeoData, selectSearchQueryADIZGeoData],
  (adizAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.designation);

      if (serachValue.includes("All")) {
        return orderBy(
          uniqBy(adizAirspaceGeometry, "designation"),
          ["designation"],
          ["asc"]
        );
      }
      return chain(adizAirspaceGeometry)
        .filter((adiz) => {
          return serachValue.includes(adiz.designation);
        })
        .uniqBy("designation")
        .orderBy(["designation"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectADIZAirspaceOption = (state) => {
  const ADIZAirspaceData = state.airspace.adizGeoData;

  if (ADIZAirspaceData.length > 0) {
    return orderBy(
      uniqBy(ADIZAirspaceData, "designation"),
      ["designation"],
      ["asc"]
    );
  }

  return [];
};

const selectAllRNAVGeoData = (state) => state.airspace.RNAVRoutesGeometryData;
const selectSearchQueryRNAVGeoData = (state, searchQuery) => searchQuery;
export const selectRNAVAirspaceGeometry = createSelector(
  [selectAllRNAVGeoData, selectSearchQueryRNAVGeoData],
  (rnavAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map(
        (s) => `${s.designatorLetter}${s.designatorNumber}`
      );
      return chain(rnavAirspaceGeometry)
        .filter((rnav) => {
          const key = `${rnav.designatorLetter}${rnav.designatorNumber}`;
          return serachValue.includes(key);
        })
        .orderBy(["designatorLetter", "designatorNumber"], ["asc", "asc"])
        .value();
    }

    return orderBy(
      rnavAirspaceGeometry,
      "designatorNumber",
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }
);

export const selectRNAVAirspaceOption = (state) => {
  const RNAVAirspaceData = state.airspace.RNAVRoutesGeometryData;

  if (RNAVAirspaceData.length > 0) {
    return orderBy(
      uniqBy(RNAVAirspaceData, "designatorNumber"),
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }

  return [];
};

const selectAllCNAVGeoData = (state) => state.airspace.CNAVRoutesGeometryData;
const selectSearchQueryCNAVGeoData = (state, searchQuery) => searchQuery;
export const selectCNAVAirspaceGeometry = createSelector(
  [selectAllCNAVGeoData, selectSearchQueryCNAVGeoData],
  (cnavAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map(
        (s) => `${s.designatorLetter}${s.designatorNumber}`
      );
      return chain(cnavAirspaceGeometry)
        .filter((cnav) => {
          const key = `${cnav.designatorLetter}${cnav.designatorNumber}`;
          return serachValue.includes(key);
        })
        .orderBy(["designatorLetter", "designatorNumber"], ["asc", "asc"])
        .value();
    }

    return orderBy(
      cnavAirspaceGeometry,
      "designatorNumber",
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }
);

export const selectCNAVAirspaceOption = (state) => {
  const CNAVAirspaceData = state.airspace.CNAVRoutesGeometryData;

  if (CNAVAirspaceData.length > 0) {
    return orderBy(
      uniqBy(CNAVAirspaceData, "designatorNumber"),
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }

  return [];
};

export const selectRNAVOptions = (state) => {
  const RNAVRoutesList = state.airspace.RNAVRoutesData;

  if (RNAVRoutesList.length > 0) {
    return orderBy(RNAVRoutesList, ["id"], ["asc"]);
  }

  return [];
};

export const selectCNAVOptions = (state) => {
  const CNAVRoutesList = state.airspace.CNAVRoutesData;

  if (CNAVRoutesList.length > 0) {
    return orderBy(CNAVRoutesList, ["id"], ["asc"]);
  }

  return [];
};

export const exportAirspaceData =
  (selctedAirspaceFilter, selectedAirspaceRows, DPRDataList, METADataList) =>
  (dispatch, getState) => {
    try {
      dispatch(setLoadingAirspaceExport(true));

      const { airspace } = getState();
      const wb = XLSX.utils.book_new();
      let sheet = null;

      if (selctedAirspaceFilter?.value === airspaceFilterType.firuir) {
        const rows = airspace.FIRData.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = firColumnsData(rows);
      } else if (selctedAirspaceFilter?.value === airspaceFilterType.tma) {
        const rows = airspace.TMAData.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = tmaColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value === airspaceFilterType.controlArea
      ) {
        const rows = airspace.CTAData.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = ctaColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value ===
        airspaceFilterType.otherRegulatedAirspace
      ) {
        const rows = airspace.regulatedAirspaceData.filter((data) =>
          selectedAirspaceRows.includes(data.Aerodrome)
        );
        sheet = raColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation
      ) {
        const rows = airspace.allNavaid.filter((data) =>
          selectedAirspaceRows.includes(data.designator)
        );
        sheet = rnColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value === airspaceFilterType.significantPoints
      ) {
        const rows = airspace.allsignificantPoint.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = spColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas
      ) {
        const rows = DPRDataList.filter((data) => {
          const dprName = `${data.Airspace_name} ${data.designator}`;
          return selectedAirspaceRows.includes(dprName);
        });
        sheet = dprColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas
      ) {
        const rows = METADataList.filter((data) => {
          const metaName = `${data.Airspace_name} ${data.designator}`;
          return selectedAirspaceRows.includes(metaName);
        });
        sheet = metaColumnsData(rows);
      } else if (selctedAirspaceFilter?.value === airspaceFilterType.routes) {
        const rows = airspace.routeData;
        sheet = routeColumnsData(rows);
      }

      if (sheet) {
        const ws = XLSX.utils.json_to_sheet(sheet);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${selctedAirspaceFilter.value}.csv`);
      }

      dispatch(setLoadingAirspaceExport(false));
    } catch (error) {
      dispatch(setLoadingAirspaceExport(false));
      toast.error(error.message);
    }
  };

export const exportAirspaceSheet = (category, version) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename ? filename : `${category}.xls`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_AIRSPACE_SHEET_URL(category, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const exportRouteSheet = (id, version) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename ? filename : `route_${id}.xls`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_ROUTE_SHEET_URL(id, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const exportDRPSheet = (airport_icao, type, version) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename ? filename : `${airport_icao}_${type}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_DRP_SHEET_URL(airport_icao, type, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const exportTRASheet = (type, version) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename ? filename : `tra_${type}.xls`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_TRA_SHEET_URL(type, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const getFIRData = (version) => (dispatch) => {
  try {
    dispatch(setFIRdataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setFIRDetails(response.data));
      dispatch(setFIRdataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setFIRdataLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_FIR_DATA_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setFIRdataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getTMAData = (version) => (dispatch) => {
  try {
    dispatch(setTMAdataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setTMADetails(response.data));
      dispatch(setTMAdataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setTMAdataLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_TMA_DATA_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setTMAdataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getCTAData = (version) => (dispatch) => {
  try {
    dispatch(setCTAdataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setCTADetails(response.data));
      dispatch(setCTAdataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setCTAdataLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_CTA_DATA_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setCTAdataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getRegulatedAirspaceData = (version) => (dispatch) => {
  try {
    dispatch(setRegulatedAirspaceDataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setRegulatedAirspaceDetails(response.data));
      dispatch(setRegulatedAirspaceDataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setRegulatedAirspaceDataLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_ALL_REGULATED_AIRSPACE_DATA_URL(version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setRegulatedAirspaceDataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getRestrictiveairspaceGeometry = () => (dispatch) => {
  try {
    dispatch(setRestrictiveAirspaceGeometryLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setRestrictiveAirspaceGeometryData(response.data));
      dispatch(setRestrictiveAirspaceGeometryLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setRestrictiveAirspaceGeometryLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_RESTRICTIVE_AIRSPACE_GEOMETRY_URL,
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setRestrictiveAirspaceGeometryLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getControlAirspaceGeometry = () => (dispatch) => {
  try {
    dispatch(setControlAirspaceGeometryLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setControlAirspaceGeometryData(response.data));
      dispatch(setControlAirspaceGeometryLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setControlAirspaceGeometryLoadingRequest(false));
    };

    apiCall("GET", GET_CONTROL_AIRSPACE_GEOMETRY_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setControlAirspaceGeometryLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getFIRAirspaceGeometry = () => (dispatch) => {
  try {
    dispatch(setFIRAirspaceGeometryLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setFIRAirspaceGeometryData(response.data));
      dispatch(setFIRAirspaceGeometryLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setFIRAirspaceGeometryLoadingRequest(false));
    };

    apiCall("GET", GET_FIR_AIRSPACE_GEOMETRY_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setFIRAirspaceGeometryLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getALLRoutes = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllRoute(true));

    const onSuccess = (response) => {
      dispatch(setAllRoutes(response.data));
      dispatch(setLoadingGetAllRoute(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllRoute(false));
    };

    apiCall("GET", GET_ALL_ROUTE_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllRoute(false));
    toast.error(error.message);
  }
};

export const getRouteData = (routeId) => (dispatch) => {
  try {
    dispatch(setLoadingGetRouteData(true));

    const onSuccess = (response) => {
      dispatch(setRouteData(response.data));
      dispatch(setLoadingGetRouteData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetRouteData(false));
    };

    apiCall("GET", GET_ROUTE_DATA_URL(routeId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetRouteData(false));
    toast.error(error.message);
  }
};

export const getAreaNavigationalRouteData = (routeId) => (dispatch) => {
  try {
    dispatch(setLoadingAreaNavigationalRouteData(true));

    const onSuccess = (response) => {
      dispatch(setAreaNavigationalRouteData(response.data));
      dispatch(setLoadingAreaNavigationalRouteData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAreaNavigationalRouteData(false));
    };

    apiCall("GET", GET_ROUTE_DATA_URL(routeId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAreaNavigationalRouteData(false));
    toast.error(error.message);
  }
};

export const getConventionalRouteData = (routeId) => (dispatch) => {
  try {
    dispatch(setLoadingConventionalRouteData(true));

    const onSuccess = (response) => {
      dispatch(setConventionalRouteData(response.data));
      dispatch(setLoadingConventionalRouteData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingConventionalRouteData(false));
    };

    apiCall("GET", GET_ROUTE_DATA_URL(routeId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingConventionalRouteData(false));
    toast.error(error.message);
  }
};

export const getAllNavaid = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllNavaid(true));

    const onSuccess = (response) => {
      dispatch(setAllNavaid(response.data));
      dispatch(setLoadingGetAllNavaid(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllNavaid(false));
    };

    apiCall("GET", GET_ALL_NAVID_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllNavaid(false));
    toast.error(error.message);
  }
};

export const getAllSignificantPoint = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllsignificantPoint(true));

    const onSuccess = (response) => {
      dispatch(setAllsignificantPoint(response.data));
      dispatch(setLoadingGetAllsignificantPoint(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllsignificantPoint(false));
    };

    apiCall(
      "GET",
      GET_ALL_SIGNIFICANT_POINT_URL(version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingGetAllsignificantPoint(false));
    toast.error(error.message);
  }
};

export const getAllTRA_TSA_ADIZ = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllTRA_TSA_ADIZ(true));

    const onSuccess = (response) => {
      dispatch(setAllTRA_TSA_ADIZ(response.data));
      dispatch(setLoadingGetAllTRA_TSA_ADIZ(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllTRA_TSA_ADIZ(false));
    };

    apiCall("GET", GET_ALL_TRA_TSA_ADIZ_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllTRA_TSA_ADIZ(false));
    toast.error(error.message);
  }
};

export const getAllDPR = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllDPR(true));

    const onSuccess = (response) => {
      dispatch(setAllDPR(response.data));
      dispatch(setLoadingGetAllDPR(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllDPR(false));
    };

    apiCall("GET", GET_ALL_DPR_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllDPR(false));
    toast.error(error.message);
  }
};

export const getADIZGeoData = () => (dispatch) => {
  try {
    dispatch(setLoadingGetADIZGeoData(true));

    const onSuccess = (response) => {
      dispatch(setADIZGeoData(response.data));
      dispatch(setLoadingGetADIZGeoData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetADIZGeoData(false));
    };

    apiCall("GET", GET_ADIZ_GEODATA_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetADIZGeoData(false));
    toast.error(error.message);
  }
};

export const getRestrictiveAirspaceDetails = (airspace_id) => (dispatch) => {
  try {
    dispatch(setLoadingRestrictiveAirspaceDetails(true));

    const onSuccess = (response) => {
      dispatch(setRestrictiveAirspaceDetails(response.data));
      dispatch(setLoadingRestrictiveAirspaceDetails(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingRestrictiveAirspaceDetails(false));
    };

    apiCall(
      "GET",
      GET_RESTRICTIVE_AIRSPACE_DETAILS_URL(airspace_id),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingRestrictiveAirspaceDetails(false));
    toast.error(error.message);
  }
};

export const getAllRNAVRoutes = (version) => (dispatch) => {
  try {
    dispatch(setLoadingRNAVRoutes(true));

    const onSuccess = (response) => {
      dispatch(setRNAVRoutesData(response.data));
      dispatch(setLoadingRNAVRoutes(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingRNAVRoutes(false));
    };

    apiCall(
      "GET",
      GET_ALL_RNAV_CNAV_ROUTES_URL("RNAV", version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingRNAVRoutes(false));
    toast.error(error.message);
  }
};

export const getAllCNAVRoutes = (version) => (dispatch) => {
  try {
    dispatch(setLoadingCNAVRoutes(true));

    const onSuccess = (response) => {
      dispatch(setCNAVRoutesData(response.data));
      dispatch(setLoadingCNAVRoutes(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingCNAVRoutes(false));
    };

    apiCall(
      "GET",
      GET_ALL_RNAV_CNAV_ROUTES_URL("CNAV", version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingCNAVRoutes(false));
    toast.error(error.message);
  }
};

export const getWaypointRouteData = (id) => (dispatch) => {
  try {
    dispatch(setLoadingWaypointRouteData(true));

    const onSuccess = (response) => {
      dispatch(setWaypointRouteData(response.data));
      dispatch(setLoadingWaypointRouteData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingWaypointRouteData(false));
    };

    apiCall("GET", GET_WAYPOINT_ROUTE_DATA_URL(id), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingWaypointRouteData(false));
    toast.error(error.message);
  }
};
