import SvgIcon from '@mui/material/SvgIcon';

export default function LargeStructureIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }} {...props}>
      <svg fill="none">
        <path d="M7 19.4928L12.2174 5L17 19.4928" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="12.2174" cy="18.6232" r="0.869565" fill="black" />
      </svg>
    </SvgIcon>
  )
};