import React, { Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FeatureGroup, Polyline } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { ACCESS_TOKEN } from "../../constants/constant";
import { editEchartPolyline } from "../../models/electronicChart";
import { getAsyncStorageValue } from "../../utils/localStorage";
import ConfirmationDialog from "../ConfirmationDialog";

export default function EchartDrawControl({
  selctedAirport,
  editableLayer,
  handleCallback,
}) {
  const dispatch = useDispatch();
  const featureGroupRef = useRef();
  const isLoadingEditPolyline = useSelector(
    (state) => state.eChart.isLoadingEditPolyline
  );
  const [isOpenEditConfirmDialog, setOpenEditConfirmDialog] = useState(false);
  const [editedCoords, setEditedCoords] = useState([]);
  const coordinateMap = editableLayer
    ? editableLayer.geometry_json.coordinates
    : [];

  const onEdited = (e) => {
    let coords = [];
    e.layers.eachLayer(function (layer) {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates;
    });

    setEditedCoords(coords);
    setOpenEditConfirmDialog(true);
  };

  const handleSaveEditDialog = () => {
    const finalEditCoords = editedCoords.map((coord) => {
      return {
        lng: coord[0],
        lat: coord[1],
      };
    });

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        editEchartPolyline(
          {
            coord_list: finalEditCoords,
            geo_json_id: editableLayer?.id,
          },
          selctedAirport?.id,
          token,
          handleCloseEditDialog
        )
      );
    });
  };

  const handleCloseEditDialog = () => {
    featureGroupRef.current.clearLayers();
    handleCallback();
    setOpenEditConfirmDialog(false);
  };

  return (
    <Fragment>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="bottomright"
          onEdited={onEdited}
          draw={{
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: false,
          }}
          edit={{
            edit: true,
            remove: false,
          }}
        />
        {coordinateMap.map((coord, i) => {
          return (
            <Polyline
              key={`${editableLayer.id}_${i}`}
              positions={coord}
              pathOptions={{
                color: "#84b3fc",
                weight: 3,
                opacity: 1,
              }}
            />
          );
        })}
      </FeatureGroup>
      {isOpenEditConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenEditConfirmDialog}
          title="Edit"
          content="Are you sure you want to edit this polyline?"
          isLoading={isLoadingEditPolyline}
          isDisabled={isLoadingEditPolyline}
          handleSave={handleSaveEditDialog}
          handleCancel={handleCloseEditDialog}
        />
      )}
    </Fragment>
  );
}
