import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { orderBy, pickBy, isArray, remove } from "lodash";
import { apiCall, downloadReadableStreamFile } from "../utils/connect";
import { aixmFeatureKeyMap } from "../constants/aixmData";
import {
  GET_OBSTACLES_DATA_URL,
  GET_ALL_AIXM_AIRPORT_URL,
  GET_AIRPORT_DETAIL_URL,
  EXPORT_AIXM_SHEET,
  GET_AIXM_DATA_VERSION,
  GET_RUNWAY_DETAIL_DATA_URL,
} from "../constants/urls";

const initialState = {
  isLoadingAixmObstacles: false,
  isLoadingAirport: false,
  isLoadingAirportDetail: false,
  isExportingSheet: false,
  airportDetails: null,
  allAirports: [],
  obstaclesAreaList: [],
  isLoadingGetAixmDataVersion: false,
  aixmDataVersions: [],
  isLoadingRunwayDetails: false,
  runwayDetails: null,
  selectedVersion: null,
};

export const aixm = createSlice({
  name: "aixm",
  initialState,
  reducers: {
    setLoadingAixmObstaclesLoadingRequest: (state, action) => {
      state.isLoadingAixmObstacles = action.payload;
    },
    setAirportLoadingRequest: (state, action) => {
      state.isLoadingAirport = action.payload;
    },
    setAirportDetailLoadingRequest: (state, action) => {
      state.isLoadingAirportDetail = action.payload;
    },
    setExportSheetRequest: (state, action) => {
      state.isExportingSheet = action.payload;
    },
    setAllObstaclesAreaList: (state, action) => {
      state.obstaclesAreaList = orderBy(
        action.payload.ObstacleArea_list,
        "id",
        "asc"
      );
    },
    setAllAirport: (state, action) => {
      state.allAirports = orderBy(action.payload, "name", "asc");
    },
    setAirportDetails: (state, action) => {
      state.airportDetails = action.payload;
    },
    setLoadingGetAixmDataVersion: (state, action) => {
      state.isLoadingGetAixmDataVersion = action.payload;
    },
    setAixmDataVersions: (state, action) => {
      state.aixmDataVersions = orderBy(action.payload, "id", "desc");
      const versions = orderBy(action.payload, ["id"], "desc");
      state.selectedVersion = versions[0];
    },
    setLoadingRunwayDetails: (state, action) => {
      state.isLoadingRunwayDetails = action.payload;
    },
    setRunwayDetails: (state, action) => {
      state.runwayDetails = action.payload;
    },
    setSelectedVersion: (state, action) => {
      state.selectedVersion = action.payload;
    },
  },
});

export const {
  setLoadingAixmObstaclesLoadingRequest,
  setAirportLoadingRequest,
  setAirportDetailLoadingRequest,
  setExportSheetRequest,
  setAllObstaclesAreaList,
  setAllAirport,
  setAirportDetails,
  setLoadingGetAixmDataVersion,
  setAixmDataVersions,
  setLoadingRunwayDetails,
  setRunwayDetails,
  setSelectedVersion,
} = aixm.actions;

export default aixm.reducer;

export const selectAIXMCategoryOptions = (state) => {
  const airportDetails = state.aixm.airportDetails;

  if (airportDetails) {
    const validCategories = pickBy(airportDetails, (value) => {
      return value && isArray(value) && value.length > 0;
    });
    const categories = Object.keys(validCategories);
    remove(categories, (c) => {
      return [
        "Checkpoint_VOR",
        "Checkpoint_INS",
        "AirTrafficControlService_list",
        "AirportGroundService_list",
        "unit_list",
      ].includes(c);
    });
    categories.push("NavigationSystemCheckpoint_list");
    categories.push("Runway_List");
    categories.push("RD_List");
    categories.push("ObstacleArea_list");
    categories.push("Handling_services_list");
    categories.push("Passenger_facilities_list");
    categories.push("Rescue_list");
    categories.push("Seasonal_service_list");
    categories.push("Operator_list");
    categories.push("Surface_Movement_Guidance");

    return categories.map((category, i) => {
      return {
        id: i + 1,
        label: aixmFeatureKeyMap[category],
        value: category,
      };
    });
  }

  return [];
};

export const getAllAIXMAirport = (version) => (dispatch) => {
  try {
    dispatch(setAirportLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllAirport(response.data));
      dispatch(setAirportLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAirportLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_AIXM_AIRPORT_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setAirportLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getAirportDetails = (airportId, version) => (dispatch) => {
  try {
    dispatch(setAirportDetailLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAirportDetails(response.data));
      dispatch(setAirportDetailLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAirportDetailLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_AIRPORT_DETAIL_URL(airportId, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setAirportDetailLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getRunWayDetailData = (airportId, version) => (dispatch) => {
  try {
    dispatch(setLoadingRunwayDetails(true));

    const onSuccess = (response) => {
      dispatch(setRunwayDetails(response.data));
      dispatch(setLoadingRunwayDetails(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingRunwayDetails(false));
    };

    apiCall(
      "GET",
      GET_RUNWAY_DETAIL_DATA_URL(airportId, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingRunwayDetails(false));
    toast.error(error.message);
  }
};

export const getObstaclesData = (airportId, version) => (dispatch) => {
  try {
    dispatch(setLoadingAixmObstaclesLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllObstaclesAreaList(response.data));
      dispatch(setLoadingAixmObstaclesLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAixmObstaclesLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_OBSTACLES_DATA_URL(airportId, version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAixmObstaclesLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getAixmDataVersion = () => (dispatch) => {
  try {
    dispatch(setLoadingGetAixmDataVersion(true));

    const onSuccess = (response) => {
      dispatch(setAixmDataVersions(response.data));
      dispatch(setLoadingGetAixmDataVersion(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAixmDataVersion(false));
    };

    apiCall("GET", GET_AIXM_DATA_VERSION, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAixmDataVersion(false));
    toast.error(error.message);
  }
};

export const exportAixmSheet = (body) => (dispatch) => {
  try {
    dispatch(setExportSheetRequest(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename ? filename : "AIXMReport.xls");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportSheetRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportSheetRequest(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_AIXM_SHEET,
      body,
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setExportSheetRequest(false));
    toast.error(error.message);
  }
};
