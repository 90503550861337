import React, { Fragment } from 'react';
import { Polyline } from 'react-leaflet';
import { chunk } from 'lodash';

export default function RenderGeometryLabelLine({
  data
}) {
  const geometryLabelLineChunk = chunk(data.ComponentLabellingLine_List, 10);

  return (
    <Fragment>
      {
        geometryLabelLineChunk.map((geometryLabelLines) => {
          return geometryLabelLines.map((geometryLabelLine) => {
            const coordinates = geometryLabelLine.geometry_json.coordinates;
            
            return coordinates.map((coord, i) => {
              return (
                <Polyline
                  key={`${geometryLabelLine.id}_${i}`}
                  positions={coord}
                  pathOptions={{
                    color: '#000000',
                    weight: 1,
                    opacity: 0.8
                  }}
                />
              )
            });
          })
        })
      }
    </Fragment>
  );
};