import React, { Fragment } from 'react';
import { keysIn } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';
import { aixmFeatureKeyMap } from '../../constants/aixmData';

export default function AirTrafficControlServiceItem({
  isLoading,
  category,
  airportDetails,
  aixmFeatureMapData,
  selctedAirport
}) {
  const airTrafficControlServiceList = airportDetails[category];
  const AirTrafficControlServiceMap = aixmFeatureMapData['AirTrafficControlServiceItem_list'];
  const trafficSeparationServiceMap = aixmFeatureMapData['TrafficSeparationService'];
  const trafficSeparationServiceServiceMap = aixmFeatureMapData['TrafficSeparationServiceService'];

  return (
    <Fragment>
      {(isLoading || airTrafficControlServiceList.length === 0) && (
        <TableLoader isLoading={isLoading} data={airTrafficControlServiceList} />
      )}
      {
        airTrafficControlServiceList.map((atcs, i) => {
          return (
            <Paper sx={{ mb: 5 }} key={`${category}_${i}`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell colSpan={2}>
                      {selctedAirport.name} {">"} {aixmFeatureKeyMap[category]}
                    </HeaderTableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(AirTrafficControlServiceMap).map((key, j) => {
                    const airTrafficControlServiceKey = AirTrafficControlServiceMap[key];

                    return (
                      <TableRow
                        key={j}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {key}
                        </StyledTableCell>
                        <StyledTableCell>{atcs[airTrafficControlServiceKey] || "-"}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={2}>
                      {aixmFeatureKeyMap[category]} {">"} Traffic Separation Service
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(trafficSeparationServiceMap).map((key, j) => {
                    const trafficSeparationServiceKey = trafficSeparationServiceMap[key];
                    return (
                      <TableRow
                        key={j}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {key}
                        </StyledTableCell>
                        <StyledTableCell>{atcs[trafficSeparationServiceKey] || "-"}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={2}>
                      {aixmFeatureKeyMap[category]} {">"} Traffic Separation Service {'>'} Service
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(trafficSeparationServiceServiceMap).map((key, j) => {
                    const trafficSeparationServiceServiceMapKey = trafficSeparationServiceServiceMap[key];
                    return (
                      <TableRow
                        key={j}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {key}
                        </StyledTableCell>
                        <StyledTableCell>{atcs[trafficSeparationServiceServiceMapKey] || "-"}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          );
        })
      }
    </Fragment>
  );
}
