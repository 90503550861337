import React, { Fragment, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { flatten } from 'lodash';
import { areaHighLightColor } from '../../constants/colors';

export default function RenderArea1({
  selectedArea1,
  area1Data,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const area1coordinates = area1Data[0]?.coordinates.coordinates || [];

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {
        flatten(area1coordinates).map((coordinate, i) => {
          const area1CoordinateMap = coordinate.map((area1Coords) => {
            return {
              lng: area1Coords[0],
              lat: area1Coords[1]
            };
          });

          return (
            <Polygon
              key={i}
              positions={area1CoordinateMap}
              pathOptions={{
                fillColor: selectedArea1.color,
                color: selectedOverE?.area1 ? areaHighLightColor : selectedArea1.color,
                weight: selectedOverE?.area1 ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: () => handlePolygonOver({ area1: true }),
                mouseout: () => handlePolygonOut()
              }}
            />
          )
        })
      }
    </Fragment>
  );
};