import React from "react";

export const MapMarkerIcon = (props) => (
  <svg
    width={42}
    height={50}
    viewBox="0 0 42 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 18C10.6667 12.4772 15.1438 8 20.6667 8C26.1895 8 30.6667 12.4772 30.6667 18C30.6667 23.5228 26.1895 28 20.6667 28C15.1438 28 10.6667 23.5228 10.6667 18ZM20.6667 12C17.353 12 14.6667 14.6863 14.6667 18C14.6667 21.3137 17.353 24 20.6667 24C23.9804 24 26.6667 21.3137 26.6667 18C26.6667 14.6863 23.9804 12 20.6667 12Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21709 15.6726C3.94712 6.81607 11.3481 0 20.2347 0H21.0986C29.9852 0 37.3862 6.81607 38.1163 15.6726C38.5076 20.42 37.0411 25.134 34.0258 28.8217L24.4397 40.5452C22.4896 42.9301 18.8437 42.9301 16.8937 40.5452L7.30752 28.8216C4.29221 25.134 2.82577 20.42 3.21709 15.6726ZM20.2347 4C13.4299 4 7.76259 9.21937 7.20357 16.0012C6.89739 19.7158 8.04479 23.4042 10.4041 26.2896L19.9902 38.0132C20.3399 38.4408 20.9935 38.4408 21.3431 38.0132L30.9292 26.2896C33.2886 23.4042 34.436 19.7158 34.1298 16.0012C33.5708 9.21937 27.9035 4 21.0986 4H20.2347Z"
      fill="#F2F3F6"
    />
    <path
      d="M9.12219 37.5611C9.61617 36.5731 9.21572 35.3718 8.22776 34.8778C7.23981 34.3838 6.03846 34.7843 5.54448 35.7722L0.211148 46.4389C-0.0988407 47.0589 -0.0657127 47.7952 0.298701 48.3848C0.663114 48.9744 1.30685 49.3333 2 49.3333H39.3333C40.0265 49.3333 40.6702 48.9744 41.0346 48.3848C41.3991 47.7952 41.4322 47.0589 41.1222 46.4389L35.7889 35.7722C35.2949 34.7843 34.0935 34.3838 33.1056 34.8778C32.1176 35.3718 31.7172 36.5731 32.2112 37.5611L36.0973 45.3333H5.23607L9.12219 37.5611Z"
      fill="#F2F3F6"
    />
  </svg>
);
