import React, { Fragment, useState } from 'react';
import { Polyline, Polygon, CircleMarker } from 'react-leaflet';
import { flatten } from 'lodash';
import { mapDrawType } from '../../constants/constant';
import { areaHighLightColor } from '../../constants/colors';
import { airspaceType } from '../../constants/airspaceData';
import InfoPopup from './InfoPopup';

export default function RenderPDRAirspace({
  pdrAirspaceGeometry,
  colorTray,
  handleViewMore
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (e, data) => {
    e.target.bringToFront();
    e.target.openPopup();
    setSelectedOverE(data);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonClick = (info) => {
    handleViewMore(info, airspaceType.restrictedAirspace);
  };

  return (
    <Fragment>
      {
        pdrAirspaceGeometry.map((data, i) => {
          const option = {
            color: selectedOverE?.name === data.name ? areaHighLightColor : colorTray[i],
            weight: selectedOverE?.name === data.name ? 3 : 2,
            fillColor: colorTray[i],
          };

          if (data.typeOfcoordinates === mapDrawType.point) {
            return (
              <CircleMarker
                key={i}
                pane="tooltipPane"
                center={flatten(data.coordinates)[0]}
                pathOptions={{
                  ...option,
                  fillOpacity: 1,
                }}
                radius={6}
                eventHandlers={{
                  mouseover: (e) => handlePolygonOver(e, data),
                  mouseout: () => handlePolygonOut(),
                  click: () => handlePolygonClick(data)
                }}
              >
                <InfoPopup info={data} />
              </CircleMarker>
            )
          };

          if (data.typeOfcoordinates === mapDrawType.lineString) {
            return (
              <Polyline
                key={i}
                positions={flatten(data.coordinates)}
                pathOptions={option}
                eventHandlers={{
                  mouseover: (e) => handlePolygonOver(e, data),
                  mouseout: () => handlePolygonOut(),
                  click: () => handlePolygonClick(data)
                }}
              >
                <InfoPopup info={data} />
              </Polyline>
            );
          };

          return (
            <Polygon
              key={i}
              positions={flatten(data.coordinates)}
              pathOptions={option}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, data),
                mouseout: () => handlePolygonOut(),
                click: () => handlePolygonClick(data)
              }}
            >
              <InfoPopup info={data} />
            </Polygon>
          );
        })
      }
    </Fragment>
  );
};