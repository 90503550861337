import React from 'react';
import { Popup } from 'react-leaflet';
import { convertToDms } from '../../utils/geocoords';
import { getObstacleSymbol } from '../../utils/obstacleSymbols';
import PopupBox from '../map/PopupBox';
import PopupItem from '../map/PopupItem';

export default function Area1ObstacleInfoPopup({ info }) {
  return (
    <Popup>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 1,
          paddingBottom: 1
        }}
      >
        {getObstacleSymbol(info.obstacle_type)}
      </div>
      <PopupBox>
        <PopupItem>ICAO</PopupItem>
        <PopupItem>{info.airport_icao}</PopupItem>
        <PopupItem>OBJECT</PopupItem>
        <PopupItem>{info.obstacle_type || '-'}</PopupItem>
        <PopupItem>LATITIUDE</PopupItem>
        <PopupItem>{convertToDms(info.latitude) || '-'}</PopupItem>
        <PopupItem>LONGITUDE</PopupItem>
        <PopupItem>{convertToDms(info.longitude, true) || '-'}</PopupItem>
        <PopupItem>AIRPORT ELEVATION (ft)</PopupItem>
        <PopupItem>{info.airport_elevation || '-'}</PopupItem>
        <PopupItem>HEIGHT (ft)</PopupItem>
        <PopupItem>{info.height_in_ft || '-'}</PopupItem>
        <PopupItem>HEIGHT (m)</PopupItem>
        <PopupItem>{info.height_in_m || '-'}</PopupItem>
        <PopupItem>ELEVATION (ft)</PopupItem>
        <PopupItem>{info.elevation_in_ft || '-'}</PopupItem>
        <PopupItem>RWY AREA AFFECTED</PopupItem>
        <PopupItem>{info.rwy_or_area_affected || '-'}</PopupItem>
        <PopupItem>REMARK</PopupItem>
        <PopupItem>{info.remark || '-'}</PopupItem>
      </PopupBox>
    </Popup>
  );
};