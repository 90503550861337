import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const PopupBox = styled(Box)(() => ({
  bgcolor: '#FFFFFF',
  borderRadius: 1,
  textAlign: 'center',
  typography: 'subtitle2',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
}));

export default PopupBox;