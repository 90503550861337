import * as yup from "yup";

export const feedbackFormValidationSchema = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
  email: yup.string().email("Enter a valid email"),
  feedback: yup.string().required("Required"),
});

export const loginFormValidationSchema = yup.object().shape({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});
