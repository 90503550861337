import React, { useEffect, useState } from 'react';
import { FeatureGroup, CircleMarker, Popup } from 'react-leaflet';
import { split } from 'lodash';
import { convertToDms } from '../../utils/geocoords';
import { areaHighLightColor } from '../../constants/colors';
import PopupBox from '../map/PopupBox';
import PopupItem from '../map/PopupItem';

export default function RenderParkingBayPoints({
  selectedFeatures,
  parkingBayPointsList,
}) {
  const featureKey = 'PARKING_BAY_POINTS_List';
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [areaColor, setAreaColor] = useState(null);
  const [isVisibleParkingBayPoints, setVisibleParkingBayPoints] = useState(false);

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, '__');
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const isVisible =
      selectedFeatures.includes(`${featureKey}__${areaColorT[featureKey]}`);

    setAreaColor(areaColorT);
    setVisibleParkingBayPoints(isVisible);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  if (!isVisibleParkingBayPoints || !areaColor) {
    return null;
  };

  return (
    <FeatureGroup pane="popupPane">
      {
        parkingBayPointsList.map((pbplGeometry, i) => {
          const position = {
            lat: Number(pbplGeometry.latitude),
            lng: Number(pbplGeometry.longitude)
          };

          return (
            <CircleMarker
              key={i}
              center={position}
              radius={6}
              pathOptions={{
                fillColor: areaColor[featureKey],
                color: selectedOverE?.point_name === pbplGeometry.point_name ? areaHighLightColor : areaColor[featureKey],
                weight: selectedOverE?.point_name === pbplGeometry.point_name ? 5 : 2,
                fillOpacity: 1,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, pbplGeometry),
                mouseout: () => handlePolygonOut()
              }}
            >
              <Popup>
                <PopupBox>
                  <PopupItem>Name:</PopupItem>
                  <PopupItem>{pbplGeometry?.point_name}</PopupItem>
                  <PopupItem>Latitude:</PopupItem>
                  <PopupItem>{convertToDms(pbplGeometry?.latitude)}</PopupItem>
                  <PopupItem>Longitude:</PopupItem>
                  <PopupItem>{convertToDms(pbplGeometry?.longitude, true)}</PopupItem>
                  <PopupItem>Elevation:</PopupItem>
                  <PopupItem>{pbplGeometry?.top_elev}</PopupItem>
                </PopupBox>
              </Popup>
            </CircleMarker>
          )
        })
      }
    </FeatureGroup>
  );
};