import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { remove } from "lodash";
import {
  apiCall,
  fileUploadApicall,
  downloadReadableStreamFile,
} from "../utils/connect";
import { getObstaclesData } from "./aixm";
import {
  GET_ALL_NEW_AIRPORT_URL,
  CREATE_NEW_AIRPORT_URL,
  ADD_AIRPORT_DETAIL_URL,
  CHANGE_STATUS_AIRPORT_URL,
  UPLOAD_OBSTACLE_SHEET_URL,
  DOWNLOAD_XML_AD2_URL,
  RUNWAY_DETAIL_UPLOAD_URL,
  RUNWAY_DIRECTION_UPLOAD_URL,
  APRON_UPLOAD_URL,
  TAXIWAY_UPLOAD_URL,
  NAVID_UPLOAD_URL,
  GET_AD_RUNWAY_INFO_URL,
  GET_AIRPORT_CHART_URL,
  AIRPORT_CHART_UPLOAD_URL,
  DELETE_REVIEW_AIRPORT_OBSTACLE_URL,
  DELETE_REVIEW_AIRPORT_URL,
} from "../constants/urls";

const initialState = {
  isLoadingAllAirports: false,
  allAirports: [],
  isLoadingPostCreateNewAirport: false,
  selctedAirportFormCategories: [],
  isLoadingAddAirportDetail: false,
  isLoadingAirportStatusChange: false,
  isUplodeObstacleFileRequest: false,
  isExportAD2XML: false,
  isLoadingRunwayDetailUpload: false,
  isLoadingRunwayDirectionUpload: false,
  isLoadingApronUpload: false,
  isLoadingTaxiwayUpload: false,
  isLoadingNavidUpload: null,
  isLoadingAdRunwayInfo: false,
  adRunwayInfo: null,
  isLoadingAirportChartList: false,
  allAirportsCharts: [],
  isUplodeChartFileRequest: false,
  isLoadingDeleteAirportObstacles: false,
  isLoadingDeleteReviewAirport: false,
};

export const airport = createSlice({
  name: "airport",
  initialState,
  reducers: {
    setLoadingAllAirports: (state, action) => {
      state.isLoadingAllAirports = action.payload;
    },
    setAllAirports: (state, action) => {
      state.allAirports = action.payload;
    },
    setLoadingPostCreateNewAirport: (state, action) => {
      state.isLoadingPostCreateNewAirport = action.payload;
    },
    setSelctedAirportFormCategories: (state, action) => {
      state.selctedAirportFormCategories = action.payload;
    },
    removeSelctedAirportFormCategories: (state, action) => {
      const categories = state.selctedAirportFormCategories;
      remove(categories, (category) => {
        return category.value === action.payload;
      });

      state.selctedAirportFormCategories = categories;
    },
    setLoadingAddAirportDetail: (state, action) => {
      state.isLoadingAddAirportDetail = action.payload;
    },
    setLoadingAirportStatusChange: (state, action) => {
      state.isLoadingAirportStatusChange = action.payload;
    },
    setUplodeObstacleFileRequest: (state, action) => {
      state.isUplodeObstacleFileRequest = action.payload;
    },
    setExportXMLAD2Loading: (state, action) => {
      state.isExportXMLAD2Loading = action.payload;
    },
    setLoadingRunwayDetailUpload: (state, action) => {
      state.isLoadingRunwayDetailUpload = action.payload;
    },
    setLoadingRunwayDirectionUpload: (state, action) => {
      state.isLoadingRunwayDirectionUpload = action.payload;
    },
    setLoadingApronUpload: (state, action) => {
      state.isLoadingApronUpload = action.payload;
    },
    setLoadingTaxiwayUpload: (state, action) => {
      state.isLoadingTaxiwayUpload = action.payload;
    },
    setLoadingNavidUpload: (state, action) => {
      state.isLoadingNavidUpload = action.payload;
    },
    setLoadingAdRunwayInfo: (state, action) => {
      state.isLoadingAdRunwayInfo = action.payload;
    },
    setAdRunwayInfo: (state, action) => {
      state.adRunwayInfo = action.payload;
    },
    setAllAirportsCharts: (state, action) => {
      state.allAirportsCharts = action.payload;
    },
    setLoadingAirportChartList: (state, action) => {
      state.isLoadingAirportChartList = action.payload;
    },
    setUplodeChartFileRequest: (state, action) => {
      state.isUplodeChartFileRequest = action.payload;
    },
    setLoadingDeleteAirportObstacles: (state, action) => {
      state.isLoadingDeleteAirportObstacles = action.payload;
    },
    setLoadingDeleteReviewAirport: (state, action) => {
      state.isLoadingDeleteReviewAirport = action.payload;
    },
  },
});

export const {
  setLoadingAllAirports,
  setAllAirports,
  setLoadingPostCreateNewAirport,
  setSelctedAirportFormCategories,
  removeSelctedAirportFormCategories,
  setLoadingAddAirportDetail,
  setLoadingAirportStatusChange,
  setUplodeObstacleFileRequest,
  setExportXMLAD2Loading,
  setLoadingRunwayDetailUpload,
  setLoadingRunwayDirectionUpload,
  setLoadingApronUpload,
  setLoadingTaxiwayUpload,
  setLoadingNavidUpload,
  setLoadingAdRunwayInfo,
  setAdRunwayInfo,
  setAllAirportsCharts,
  setLoadingAirportChartList,
  setUplodeChartFileRequest,
  setLoadingDeleteAirportObstacles,
  setLoadingDeleteReviewAirport
} = airport.actions;

export default airport.reducer;

export const getAllNewAirports = (token) => (dispatch) => {
  try {
    dispatch(setLoadingAllAirports(true));

    const onSuccess = (response) => {
      dispatch(setAllAirports(response.data));
      dispatch(setLoadingAllAirports(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAllAirports(false));
    };

    apiCall("GET", GET_ALL_NEW_AIRPORT_URL, "", onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setLoadingAllAirports(false));
    toast.error(error.message);
  }
};

export const getAdRunwayInfo = (airportId, version, token) => (dispatch) => {
  try {
    dispatch(setLoadingAdRunwayInfo(true));

    const onSuccess = (response) => {
      dispatch(setAdRunwayInfo(response.data));
      dispatch(setLoadingAdRunwayInfo(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAdRunwayInfo(false));
    };

    apiCall(
      "GET",
      GET_AD_RUNWAY_INFO_URL(airportId, version),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAdRunwayInfo(false));
    toast.error(error.message);
  }
};

export const postCreateNewAirport =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingPostCreateNewAirport(true));

      const onSuccess = () => {
        toast.success("Airport created successfully");
        dispatch(getAllNewAirports(token));
        dispatch(setLoadingPostCreateNewAirport(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingPostCreateNewAirport(false));
      };

      apiCall(
        "POST",
        CREATE_NEW_AIRPORT_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingPostCreateNewAirport(false));
      toast.error(error.message);
    }
  };

export const addAirportDetail = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAddAirportDetail(true));

    const onSuccess = () => {
      toast.success("Airport detail updated successfully");
      dispatch(setLoadingAddAirportDetail(false));
      callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAddAirportDetail(false));
    };

    apiCall(
      "POST",
      ADD_AIRPORT_DETAIL_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAddAirportDetail(false));
    toast.error(error.message);
  }
};

export const changeStatusAirport = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAirportStatusChange(true));

    const onSuccess = () => {
      dispatch(getAllNewAirports());
      dispatch(setLoadingAirportStatusChange(false));
      callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirportStatusChange(false));
    };

    apiCall(
      "POST",
      CHANGE_STATUS_AIRPORT_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirportStatusChange(false));
    toast.error(error.message);
  }
};

export const uploadObstacelFile =
  (airportId, crcCode, file, token, successCallback) => (dispatch) => {
    try {
      dispatch(setUplodeObstacleFileRequest(true));

      const onSuccess = () => {
        toast.success("Successfully Uploaded");
        dispatch(setUplodeObstacleFileRequest(false));
        successCallback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setUplodeObstacleFileRequest(false));
      };

      fileUploadApicall(
        UPLOAD_OBSTACLE_SHEET_URL(airportId, crcCode),
        file,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setUplodeObstacleFileRequest(false));
      toast.error(error.message);
    }
  };

export const exportXMLAD2Data = (type, airport) => (dispatch) => {
  try {
    dispatch(setExportXMLAD2Loading(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename
          : `${airport.name ?? airport.designator}_${type}.xml`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportXMLAD2Loading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportXMLAD2Loading(false));
    };

    downloadReadableStreamFile(
      "GET",
      DOWNLOAD_XML_AD2_URL(airport?.version_id, airport?.id, type),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setExportXMLAD2Loading(false));
    toast.error(error.message);
  }
};

export const runwayDetailUpload =
  (payload, version, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingRunwayDetailUpload(true));

      const onSuccess = () => {
        toast.success("Runway detail uploded successfully");
        dispatch(getAdRunwayInfo(payload.airport_id, version));
        dispatch(setLoadingRunwayDetailUpload(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingRunwayDetailUpload(false));
      };

      apiCall(
        "POST",
        RUNWAY_DETAIL_UPLOAD_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingRunwayDetailUpload(false));
      toast.error(error.message);
    }
  };

export const runwayDirectionUpload =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingRunwayDirectionUpload(true));

      const onSuccess = () => {
        toast.success("Runway direction uploded successfully");
        dispatch(setLoadingRunwayDirectionUpload(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingRunwayDirectionUpload(false));
      };

      apiCall(
        "POST",
        RUNWAY_DIRECTION_UPLOAD_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingRunwayDirectionUpload(false));
      toast.error(error.message);
    }
  };

export const apronUpload = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingApronUpload(true));

    const onSuccess = () => {
      toast.success("Apron uploded successfully");
      dispatch(setLoadingApronUpload(false));
      callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingApronUpload(false));
    };

    apiCall("POST", APRON_UPLOAD_URL, payload, onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setLoadingApronUpload(false));
    toast.error(error.message);
  }
};

export const taxiwayUpload = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingTaxiwayUpload(true));

    const onSuccess = () => {
      toast.success("Taxiway uploded successfully");
      dispatch(setLoadingTaxiwayUpload(false));
      callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingTaxiwayUpload(false));
    };

    apiCall("POST", TAXIWAY_UPLOAD_URL, payload, onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setLoadingTaxiwayUpload(false));
    toast.error(error.message);
  }
};

export const navidUpload = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingNavidUpload(true));

    const onSuccess = () => {
      toast.success("Navid uploded successfully");
      dispatch(setLoadingNavidUpload(false));
      callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingNavidUpload(false));
    };

    apiCall("POST", NAVID_UPLOAD_URL, payload, onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setLoadingNavidUpload(false));
    toast.error(error.message);
  }
};

export const getAirportChartList = (airportId, token) => (dispatch) => {
  try {
    dispatch(setLoadingAirportChartList(true));

    const onSuccess = (response) => {
      dispatch(setAllAirportsCharts(response.data));
      dispatch(setLoadingAirportChartList(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirportChartList(false));
    };

    apiCall(
      "GET",
      GET_AIRPORT_CHART_URL(airportId),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirportChartList(false));
    toast.error(error.message);
  }
};

export const uploadAirportChartFile =
  (airportId, file, payload, token, successCallback) => (dispatch) => {
    try {
      dispatch(setUplodeChartFileRequest(true));

      const onSuccess = () => {
        toast.success("Successfully Uploaded");
        dispatch(setUplodeChartFileRequest(false));
        dispatch(getAirportChartList(airportId, token));
        successCallback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setUplodeChartFileRequest(false));
      };

      fileUploadApicall(
        AIRPORT_CHART_UPLOAD_URL(airportId, payload?.filename),
        file,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setUplodeChartFileRequest(false));
      toast.error(error.message);
    }
  };

export const deleteReviewAirportObstacle =
  (payload, airportId, version, callback) => (dispatch) => {
    try {
      dispatch(setLoadingDeleteAirportObstacles(true));

      const onSuccess = () => {
        toast.success("Deleted successfully");
        dispatch(getObstaclesData(airportId, version));
        dispatch(setLoadingDeleteAirportObstacles(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingDeleteAirportObstacles(false));
        callback();
      };

      apiCall(
        "DELETE",
        DELETE_REVIEW_AIRPORT_OBSTACLE_URL(airportId, version),
        payload,
        onSuccess,
        onFailure
      );
    } catch (error) {
      toast.error(error.message);
      dispatch(setLoadingDeleteAirportObstacles(false));
      callback();
    }
  };

export const deleteReviewAirport =
  (airportId, version, callback) => (dispatch) => {
    try {
      dispatch(setLoadingDeleteReviewAirport(true));

      const onSuccess = () => {
        toast.success("Airport Deleted successfully");
        dispatch(getAllNewAirports(airportId, version));
        dispatch(setLoadingDeleteReviewAirport(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingDeleteReviewAirport(false));
        callback();
      };

      apiCall(
        "DELETE",
        DELETE_REVIEW_AIRPORT_URL(airportId, version),
        "",
        onSuccess,
        onFailure
      );
    } catch (error) {
      toast.error(error.message);
      dispatch(setLoadingDeleteReviewAirport(false));
      callback();
    }
  };
