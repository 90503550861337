import React from "react";
import Typography from '@mui/material/Typography';
import RowStack from '../../components/tabel-component/RowStack';

export default function TableSheetHeaderRow({
  selctedAirport,
  selctedCategory
}) {
  return (
    <RowStack
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="body1" gutterBottom>
        {selctedAirport?.name} {">"} {selctedCategory?.value}
      </Typography>
    </RowStack>
  );
};