import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function DropDownLabel({ label, ...props }) {
  return (
    <Box display="flex">
      <Typography variant="subtitle2" {...props}>{label}</Typography>
      <Typography color="error">*</Typography>
    </Box>
  );
};