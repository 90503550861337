import React from "react";
import Typography from "@mui/material/Typography";
import RowStack from "../../components/tabel-component/RowStack";

export default function SurveyorTopTableRow({ serveyorName, userId }) {
  return (
    <RowStack direction="row" justifyContent="space-between">
      <Typography variant="body1" gutterBottom>
        SURVEYOR NAME: {serveyorName ?? "-"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        UNIQUE ID: {userId ?? "-"}
      </Typography>
    </RowStack>
  );
}
