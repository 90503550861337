import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defaultLocationCenter } from "../constants/constant";
import Header from "../components/Header";
import ElecronicChartSideBar from "../components/ElecronicChartSideBar";
import ElecronicChartMap from "../components/elecronicChartOSMMap/MapContainer";
import EchartSplitMap from "../components/elecronicChartOSMMap/EchartSplitMap";
import ADCChartTable from "../components/elecronicChartOSMMap/ADCChartTable";
import PDCChartTable from "../components/elecronicChartOSMMap/PDCChartTable";
import TaxiWayChartTable from "../components/elecronicChartOSMMap/TaxiWayChartTable";
import {
  geteChartAirports,
  geteChartData,
  geteChartExportUrls,
  getATOCAData,
  getEchartTableData,
  setechartData,
  setATOCAData,
  selecteChartAirports,
  selecteChartPDFData,
  selecteChartOptions,
  selecteChartData,
  setTableData,
} from "../models/electronicChart";

export default function ElecronicChart() {
  const dispatch = useDispatch();
  const isLoadingAlleChartAirport = useSelector(
    (state) => state.eChart.isLoadingAlleChartAirport
  );
  const isLoadingechartPDFData = useSelector(
    (state) => state.eChart.isLoadingechartPDFData
  );
  const isLoadingechartData = useSelector(
    (state) => state.eChart.isLoadingechartData
  );
  const isLoadingAlleChartExportUrl = useSelector(
    (state) => state.eChart.isLoadingAlleChartExportUrl
  );
  const isLoadingEchartTableData = useSelector(
    (state) => state.eChart.isLoadingEchartTableData
  );
  const allAirports = useSelector(selecteChartAirports);
  const eChartPDFData = useSelector(selecteChartPDFData);
  const eChartData = useSelector(selecteChartData);
  const eChartOptions = useSelector(selecteChartOptions);
  const alleChartExportUrls = useSelector(
    (state) => state.eChart.alleChartExportUrls
  );
  const atocaData = useSelector((state) => state.eChart.atocaData);
  const [selctedAirport, setAirport] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(4.7);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const [selectedEchart, setSelectedEchart] = useState(null);
  const [isShowLabel, setShowLabel] = useState(false);
  const [isShowTable, setShowTable] = useState(false);
  const [isShowMaxTerrain, setShowMaxTerrain] = useState(false);
  const [isShowMapView, setShowMapView] = useState(true);
  const [selectedMapLabel, setSelectedMapLabel] = useState(null);
  const [selectedExportType, setSelectedExportType] = useState(null);

  useEffect(() => {
    dispatch(geteChartAirports());
    dispatch(setechartData(null));
    dispatch(setATOCAData(null));
  }, []); // eslint-disable-line

  const handleAirport = (option) => {
    setSelectedEchart(null);
    setSelectedExportType(null);
    dispatch(setechartData(null));
    dispatch(setTableData(null));
    dispatch(setATOCAData(null));
    setShowLabel(false);
    setShowTable(false);
    setShowMaxTerrain(false);
    setShowMapView(false);
    setTimeout(() => {
      setShowMapView(true);
    }, 0);

    if (option === null) {
      setAirport(null);
      setAirportLocation(defaultLocationCenter);
      setZoomLevel(4.7);
    } else {
      dispatch(geteChartData(option.id));
      dispatch(getATOCAData(option.id));
      dispatch(getEchartTableData(option.id));
      dispatch(geteChartExportUrls(option.id));
      setAirport(option);
      setAirportLocation(option.coordinates);
      setZoomLevel(14);
    }
  };

  const handleeChartValueChange = (event) => {
    const value = event.target.value;
    setSelectedExportType(null);
    setSelectedEchart(null);
    setShowMapView(false);
    setSelectedEchart(value);
    if (value.startsWith("ATOCA")) {
      setShowTable(false);
    }
    setAirportLocation(defaultLocationCenter);
    setZoomLevel(14);
    setTimeout(() => {
      setShowMapView(true);
      setAirportLocation(selctedAirport.coordinates);
      setZoomLevel(15);
    }, 0);
  };

  const handleLabelToggle = (event) => {
    setShowLabel(event.target.checked);
  };

  const handleTableToggle = (event) => {
    setShowTable(event.target.checked);
    setShowMapView(false);
    setTimeout(() => {
      setShowMapView(true);
    }, 0);
  };

  const handleShowMaxTerrainToggle = (event) => {
    setShowMaxTerrain(event.target.checked);
  };

  const handleLabelClickOnMap = (label) => {
    if (isShowTable) {
      setSelectedMapLabel(label);
    }
  };

  const handleChartExport = (selectedValue, type) => {
    if (type === "full") {
      const fullScaleChart = alleChartExportUrls.find(
        (c) => c.type === "Full_Scale" && c.chart_name === selectedValue
      );

      if (fullScaleChart) {
        downloadChart(fullScaleChart);
      }
    }

    setSelectedExportType(type);
  };

  const handleChartGridExport = (type) => {
    const gridChart = alleChartExportUrls.find((c) => c.type === type);
    if (gridChart) {
      downloadChart(gridChart);
    }
  };

  const downloadChart = (chart) => {
    fetch(chart.chart_pdf_url).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${selctedAirport.name}_${chart.chart_name}.pdf`;
        alink.click();
        window.URL.revokeObjectURL(fileURL);
      });
    });
  };

  const ATOCAMapE = useMemo(() => {
    return (
      <div className="echart-content">
        <EchartSplitMap
          selctedAirport={selctedAirport}
          airportLocation={airportLocation}
          zoomLevel={zoomLevel - 2}
          atocaData={atocaData}
          isShowLabel={isShowLabel}
          selectedEchart={selectedEchart}
          isShowMaxTerrain={isShowMaxTerrain}
        />
      </div>
    );

    // eslint-disable-next-line
  }, [
    selectedEchart,
    atocaData,
    airportLocation,
    isShowLabel,
    isShowMaxTerrain,
  ]);

  return (
    <div className="wrapper">
      <ElecronicChartSideBar
        isLoadingAlleChartAirport={isLoadingAlleChartAirport}
        isLoadingechartPDFData={isLoadingechartPDFData}
        isLoadingechartData={isLoadingechartData}
        isLoadingAlleChartExportUrl={isLoadingAlleChartExportUrl}
        isLoadingEchartTableData={isLoadingEchartTableData}
        allAirports={allAirports}
        selctedAirport={selctedAirport}
        eChartPDFData={eChartPDFData}
        handleAirport={handleAirport}
        handleeChartValueChange={handleeChartValueChange}
        selectedEchart={selectedEchart}
        eChartOptions={eChartOptions}
        isShowLabel={isShowLabel}
        handleLabelToggle={handleLabelToggle}
        isShowTable={isShowTable}
        handleTableToggle={handleTableToggle}
        handleChartExport={handleChartExport}
        atocaData={atocaData}
        alleChartExportUrls={alleChartExportUrls}
        handleShowMaxTerrainToggle={handleShowMaxTerrainToggle}
        isShowMaxTerrain={isShowMaxTerrain}
      />
      <div className="main">
        <Header active="electronic-chart" />
        {Boolean(selectedEchart) && selectedEchart.startsWith("ATOCA") ? (
          ATOCAMapE
        ) : (
          <div className="echart-content">
            <div className="echart-content-area">
              {isShowMapView && (
                <ElecronicChartMap
                  selctedAirport={selctedAirport}
                  selectedEchart={selectedEchart}
                  airportLocation={airportLocation}
                  zoomLevel={zoomLevel}
                  eChartData={eChartData}
                  isShowLabel={isShowLabel}
                  isShowTable={isShowTable}
                  selectedExportType={selectedExportType}
                  handleLabelClickOnMap={handleLabelClickOnMap}
                  handleChartGridExport={handleChartGridExport}
                />
              )}
            </div>
            {Boolean(selectedEchart) && isShowTable && (
              <div style={{ flex: 1, overflow: "auto" }}>
                {selectedEchart === "ADC" && (
                  <ADCChartTable selectedEchart={selectedEchart} />
                )}
                {selectedEchart.startsWith("PDC") && (
                  <>
                    <PDCChartTable
                      selectedMapLabel={selectedMapLabel}
                      selectedEchart={selectedEchart}
                    />
                    <TaxiWayChartTable />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
