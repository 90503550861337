import React, { Fragment } from 'react';
import { keysIn, isEmpty, join } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import StyledTableCell from '../tabel-component/StyledTableCell';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import TableLoader from '../tabel-component/TableLoader';

export default function RunWayDiractionItem({
  isLoading,
  category,
  runwayDetails,
  aixmFeatureMapData,
  selctedAirport
}) {
  const runWayDiractionList = runwayDetails[category];
  const runWayDiractionMap = aixmFeatureMapData['RunwayDirection_List'];

  return (
    <Fragment key={category}>
      {(isLoading || runWayDiractionList?.length === 0) && (
        <TableLoader isLoading={isLoading} data={runWayDiractionList} />
      )}
      {
        runWayDiractionList.map((runwayD, i) => {
          const distances = runwayD.Distance;
          const runwayCentrelinePoints = runwayD.RunwayCentrelinePoint;
          const runwayDirectionLightSystems = runwayD.RunwayDirectionLightSystem;
          const runwayProtectAreas = runwayD.RunwayProtectArea;
          const approachLightingSystem = runwayD.ApproachLightingSystem;
          const visualGlideSlopeIndicator = runwayD.VisualGlideSlopeIndicator;

          return (
            <Paper sx={{ mb: 5 }} key={`${category}_${i}`}>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell colSpan={2}>
                      {selctedAirport.name} {">"} Runway Direction {runwayD.designator}
                    </HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keysIn(runWayDiractionMap).map((key, j) => {
                    const runWayDiractionKey = runWayDiractionMap[key];
                    return (
                      <TableRow
                        key={`${category}_${i}_${j}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <StyledTableCell style={{ width: 300 }}>{key}</StyledTableCell>
                        <StyledTableCell>{runwayD[runWayDiractionKey] || "-"}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              {distances.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center' colSpan={4}>
                        Declared Distances - {runwayD.designator}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Type</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Distance</StyledTableCell>
                      <StyledTableCell>Distance Accuracy</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      distances.map((distance, j) => {
                        return (
                          <TableRow key={`${category}_${j}_distance`}>
                            <StyledTableCell>{distance.type || '-'}</StyledTableCell>
                            <StyledTableCell>{distance.role || '-'}</StyledTableCell>
                            <StyledTableCell>{distance.distance || '-'}</StyledTableCell>
                            <StyledTableCell>{distance.distanceAccuracy || '-'}</StyledTableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              )}
              {runwayCentrelinePoints.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center' colSpan={4}>
                        Runway Centreline Point - {runwayD.designator}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Designator</StyledTableCell>
                      <StyledTableCell>Coordinates</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Elevation</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      runwayCentrelinePoints.map((point, j) => {
                        return (
                          <TableRow key={`${category}_${j}_point`}>
                            <StyledTableCell>{point.designator || '-'}</StyledTableCell>
                            <StyledTableCell>{join(point.coordinates, ', ') || '-'}</StyledTableCell>
                            <StyledTableCell>{point.role || '-'}</StyledTableCell>
                            <StyledTableCell>{point.elevation || '-'}</StyledTableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              )}
              {runwayDirectionLightSystems.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center' colSpan={4}>
                        Runway DirectionLight System - {runwayD.designator}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Position</StyledTableCell>
                      <StyledTableCell>Colour</StyledTableCell>
                      <StyledTableCell>Intensity Level</StyledTableCell>
                      <StyledTableCell>Emergency Lighting</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      runwayDirectionLightSystems.map((light, j) => {
                        return (
                          <TableRow key={`${category}_${j}_light`}>
                            <StyledTableCell>{light.position || '-'}</StyledTableCell>
                            <StyledTableCell>{light.colour || '-'}</StyledTableCell>
                            <StyledTableCell>{light.intensityLevel || '-'}</StyledTableCell>
                            <StyledTableCell>{light.emergencyLighting || '-'}</StyledTableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              )}
              {runwayProtectAreas.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center' colSpan={7}>
                        Runway Protect Area - {runwayD.designator}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Type</StyledTableCell>
                      <StyledTableCell>Width</StyledTableCell>
                      <StyledTableCell>Length</StyledTableCell>
                      <StyledTableCell>ObstacleFree</StyledTableCell>
                      <StyledTableCell>Lighting</StyledTableCell>
                      <StyledTableCell>Colour of Lights</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      runwayProtectAreas.map((area, j) => {
                        return (
                          <TableRow key={`${category}_${j}_area`}>
                            <StyledTableCell>{area.type || '-'}</StyledTableCell>
                            <StyledTableCell>{area.width || '-'}</StyledTableCell>
                            <StyledTableCell>{area.length || '-'}</StyledTableCell>
                            <StyledTableCell>{area.obstacleFree || '-'}</StyledTableCell>
                            <StyledTableCell>{area.lighting || '-'}</StyledTableCell>
                            <StyledTableCell>{area.colour_lights || '-'}</StyledTableCell>
                            <StyledTableCell>{area.status || '-'}</StyledTableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              )}
              {!isEmpty(approachLightingSystem) && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center' colSpan={8}>
                        Approach Lighting System - {runwayD.designator}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Class ICAO</StyledTableCell>
                      <StyledTableCell>Intensity Level</StyledTableCell>
                      <StyledTableCell>Length</StyledTableCell>
                      <StyledTableCell>Alignment Indicator</StyledTableCell>
                      <StyledTableCell>Colour</StyledTableCell>
                      <StyledTableCell>Emergency Lighting</StyledTableCell>
                      <StyledTableCell>Sequenced Flashing</StyledTableCell>
                      <StyledTableCell>Type</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell>{approachLightingSystem.classICAO || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.intensityLevel || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.length || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.alignmentIndicator || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.colour || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.emergencyLighting || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.sequencedFlashing || '-'}</StyledTableCell>
                      <StyledTableCell>{approachLightingSystem.type || '-'}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              {!isEmpty(visualGlideSlopeIndicator) && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center' colSpan={9}>
                        Visual Glide Slope Indicator - {runwayD.designator}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>Type</StyledTableCell>
                      <StyledTableCell>Position</StyledTableCell>
                      <StyledTableCell>Slope Angle</StyledTableCell>
                      <StyledTableCell>Minimum EyeHeight Over Threshold</StyledTableCell>
                      <StyledTableCell>Colour</StyledTableCell>
                      <StyledTableCell>Emergency Lighting</StyledTableCell>
                      <StyledTableCell>Intensity Level</StyledTableCell>
                      <StyledTableCell>Number Box</StyledTableCell>
                      <StyledTableCell>Portable</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell>{visualGlideSlopeIndicator.type || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.position || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.slopeAngle || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.minimumEyeHeightOverThreshold || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.colour || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.emergencyLighting || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.intensityLevel || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.numberBox || '-'}</StyledTableCell>
                      <StyledTableCell>{visualGlideSlopeIndicator.portable || '-'}</StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Paper>
          );
        })
      }
    </Fragment>
  );
}
