import React from "react";
import Box from '@mui/material/Box';

export default function NavButton({
  icon,
  label,
  onClick,
  topRightIcon = null,
  isShowLeftIndicator,
}) {
  return (
    <button
      className="nav-button-container"
      onClick={onClick}
    >
      {topRightIcon && (
        <Box
          sx={{
            position: 'relative',
            top: -18,
            right: -145,
          }}
        >
          {topRightIcon}
        </Box>
      )}
      <div className="icon-row">
        {isShowLeftIndicator && <span className="side-rect" />}
        {icon}
      </div>
      <div className="button-line" />
      <label className="button-label">{label}</label>
    </button>
  );
}