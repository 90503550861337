import { styled } from "@mui/material/styles";
import Stack from '@mui/material/Stack';

const RowStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  paddingTop: 10,
  paddingLeft: 20,
  paddingRight: 50
}));

export default RowStack;