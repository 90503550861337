import React from 'react';
import Select from 'react-select';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { TreeView, TreeItem } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import DropDownLabel from './DropDownLabel';
import BrandLogo from './BrandLogo';
import { PrimaryButton, LightButton } from './button';

export default function AmdbSideBar({
  isLoadingAllAirportamdb,
  isLoadingAMDBDetail,
  isExportGeoJSONLoading,
  allAirports = [],
  features = [],
  selectedFeatures = [],
  selctedAirport,
  isActiveAirport,
  exportGeoJSON = [],
  handleFeatureCheck,
  handleParentFeatureCheck,
  handleSelectAllFeature,
  handleClearAllFeature,
  handleAirport,
  handleExportGeoJSON,
}) {
  const isDisabled = isActiveAirport || isLoadingAMDBDetail;

  return (
    <div className="sidebar">
      <BrandLogo />
      <Box>
        <DropDownLabel label="Airport" />
        <Select
          isClearable
          isLoading={isLoadingAllAirportamdb}
          value={selctedAirport || null}
          placeholder="Select..."
          onChange={handleAirport}
          options={allAirports}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
        />
      </Box>
      {isLoadingAMDBDetail
        ? <LinearProgress sx={{ mt: 2, mb: 1 }} />
        : <Divider sx={{ mt: 2, mb: 1 }} />
      }
      <div className="check-list-header">
        <Typography sx={{ mb: 1 }}>FEATURES</Typography>
        <div className="check-list-action">
          <LightButton
            label="SELECT ALL"
            onClick={handleSelectAllFeature}
            disabled={isDisabled}
            sx={{ p: 0 }}
          />
          <LightButton
            label="CLEAR ALL"
            onClick={handleClearAllFeature}
            disabled={isDisabled}
            sx={{ p: 0 }}
          />
        </div>
      </div>
      <div className="check-list amdb-check-list">
        <TreeView
          aria-label="amdb-features"
          defaultCollapseIcon={<FaAngleDown />}
          defaultExpandIcon={<FaAngleRight />}
          sx={{ overflowY: "auto", overflowX: "hidden" }}
        >
          {features.map((feature) => {
            return (
              <TreeItem
                key={feature.id.toString()}
                nodeId={feature.id.toString()}
                label={
                  <FormControlLabel
                    label={feature.name}
                    disabled={isDisabled}
                    control={
                      <Checkbox
                        checked={selectedFeatures.includes(`${feature.value}__${feature.color}`)}
                        onChange={(event) => {
                          handleParentFeatureCheck(event, feature, feature.color);
                        }}
                        sx={{
                          color: feature.color,
                          '&.Mui-checked': {
                            color: feature.color,
                          }
                        }}
                      />
                    }
                  />
                }
              >
                {feature.sub &&
                  feature.sub.map((layer1) => (
                    <TreeItem
                      key={layer1.id.toString()}
                      nodeId={layer1.id.toString()}
                      label={
                        <FormControlLabel
                          label={layer1.name}
                          disabled={isDisabled}
                          control={
                            <Checkbox
                              checked={selectedFeatures.includes(`${layer1.value}__${layer1.color}`)}
                              onChange={() => {
                                handleFeatureCheck(layer1, layer1.color);
                              }}
                              sx={{
                                color: layer1.color,
                                '&.Mui-checked': {
                                  color: layer1.color,
                                }
                              }}
                            />
                          }
                        />
                      }
                    />
                  ))}
              </TreeItem>
            );
          })}
        </TreeView>
      </div>
      <div className="amdb-sidebar-footer">
        <PrimaryButton
          isLoading={isExportGeoJSONLoading}
          disabled={isDisabled || exportGeoJSON.length <= 0}
          label="Export GeoJSON"
          onClick={() => handleExportGeoJSON()}
        />
      </div>
    </div>
  );
}