import React, { Fragment, useEffect, useState } from 'react';
import { flatten, split } from 'lodash';
import { Polygon } from 'react-leaflet';

export default function RenderRwyElementMarking({
  selectedFeatures,
  rwyElementMarkingList,
}) {
  const featureKey = 'RWY_ELE_MARKING_List';
  const [areaColor, setAreaColor] = useState(null);
  const [isVisibleRwyElementMarking, setVisibleRwyElementMarking] = useState(false);

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, '__');
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const isVisible =
      selectedFeatures.includes(`${featureKey}__${areaColorT[featureKey]}`);

    setAreaColor(areaColorT);
    setVisibleRwyElementMarking(isVisible);
  }, [selectedFeatures]); // eslint-disable-line

  if (!isVisibleRwyElementMarking || !areaColor) {
    return null;
  };

  return (
    <Fragment>
      {
        rwyElementMarkingList.map((remlGeometry, i) => {
          const remlCoordinates = remlGeometry.coordinates.coordinates;
          const remlCoordinateMap = flatten(remlCoordinates).map((remlCoords) => {
            return remlCoords.map((reml) => {
              return {
                lng: reml[0],
                lat: reml[1]
              };
            });
          });

          return remlCoordinateMap.map((remlPolygonPath, i) => (
            <Polygon
              key={i}
              positions={remlPolygonPath}
              pathOptions={{
                color: areaColor[featureKey],
                weight: 2,
                zIndex: 6
              }}
            />
          ));
        })
      }
    </Fragment>
  );
};