import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { requestNewPasswordValidationSchema } from "../constants/surveyorData";
import { ACCESS_TOKEN } from "../constants/constant";
import Header from "../components/Header";
import BrandLogo from "../components/BrandLogo";
import Loader from "../components/loader/FullScreenLoader";
import {
  OutlinedButton,
  PrimaryButton,
  LightButton,
} from "../components/button";
import { userLogin, getRSA, requestNewPassword } from "../models/userSession";
import { rsaEnc } from "../utils/decodeEncodeData";
import { getAsyncStorageValue } from "../utils/localStorage";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoginLoading, isRSALoading, rsaKey, isRequestNewPassowrdLoading } =
    useSelector((state) => state.userSession);
  const [isShowPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openRequestPasswordDialog, setOpenRequestPasswordDialog] =
    useState(false);
  const newPasswordRequestForm = useFormik({
    initialValues: {
      user_email: "",
    },
    validationSchema: requestNewPasswordValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          requestNewPassword(values, token, handleCloseRequestPasswordDialog)
        );
      });
    },
  });

  useEffect(() => {
    if (!rsaKey) {
      dispatch(getRSA());
    }
  }, []); // eslint-disable-line

  const handleLogin = () => {
    dispatch(
      userLogin(
        {
          username: rsaEnc(username, rsaKey),
          password: rsaEnc(password, rsaKey),
        },
        handleLoginCallback
      )
    );
  };

  const handleLoginCallback = () => {
    navigate("/data-management", { replace: true });
  };

  const handleCloseRequestPasswordDialog = () => {
    setOpenRequestPasswordDialog(false);
    newPasswordRequestForm.resetForm();
    newPasswordRequestForm.setSubmitting(false);
  };

  const handleCRC = () => {
    navigate("/data-management/crc-check");
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <BrandLogo />
        <div className="login-sidebar-footer">
          <Box></Box>
          <Box>
            <PrimaryButton
              label="Check CRC32Q"
              onClick={handleCRC}
              sx={{ width: 200 }}
            />
          </Box>
        </div>
      </div>
      <div className="main">
        <Header active="data-management" />
        <div className="content-area linear-bg">
          <div className="login-area">
            <div className="login-box">
              <div className="login-box-header">User Login</div>
              <div className="p-5">
                <TextField
                  required
                  fullWidth
                  label="USER ID"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <FormControl
                  required
                  fullWidth
                  sx={{ mt: 2 }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="password">PASSWORD</InputLabel>
                  <OutlinedInput
                    id="password"
                    label="Password"
                    type={isShowPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="password"
                          onClick={() => setShowPassword(!isShowPassword)}
                          onMouseDown={() => setShowPassword(!isShowPassword)}
                          edge="end"
                        >
                          {isShowPassword ? <FaEyeSlash /> : <FaEye />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div className="forgot-password">
                  <LightButton
                    label="Request a New Password?"
                    onClick={() => setOpenRequestPasswordDialog(true)}
                    sx={{ p: 0 }}
                  />
                </div>
                <div className="login-footer mt-4">
                  <PrimaryButton
                    label="LOGIN"
                    onClick={handleLogin}
                    disabled={isLoginLoading}
                    isLoading={isLoginLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          {isRSALoading && <Loader />}
        </div>
      </div>
      <Dialog fullWidth open={openRequestPasswordDialog}>
        <DialogTitle>Request New Password</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              id="user_email"
              name="user_email"
              label="Email"
              value={newPasswordRequestForm.values.user_email}
              onChange={newPasswordRequestForm.handleChange}
              error={
                newPasswordRequestForm.touched.user_email &&
                Boolean(newPasswordRequestForm.errors.user_email)
              }
              helperText={
                newPasswordRequestForm.touched.user_email &&
                newPasswordRequestForm.errors.user_email
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseRequestPasswordDialog}
            sx={{ width: 120 }}
          />
          <PrimaryButton
            label="Request"
            onClick={() => newPasswordRequestForm.handleSubmit()}
            disabled={isRequestNewPassowrdLoading}
            isLoading={isRequestNewPassowrdLoading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
