import React from "react";
import { Typography } from "@mui/material";

export default function FullContentLoader() {
  return (
    <div className="full-screen-loader">
      <div className="full-screen-loader-content">
        <Typography
          variant="h6"
          sx={{ backgroundColor: "#ffffff", padding: 2 }}
        >
          Loading...
        </Typography>
      </div>
    </div>
  );
}
