import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MuiSelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { ACCESS_TOKEN } from "../../constants/constant";
import {
  createAd28ApronValidationSchema,
  Ad212SurfaceTypes,
  Ad212PavementTypes,
  Ad212EveluationTypes,
  Ad212MaxTyrePressureTypes,
  Ad212PavementSubgradeTypes,
} from "../../constants/ad2Data";
import {
  removeSelctedAirportFormCategories,
  apronUpload,
} from "../../models/airport";
import { PrimaryButton } from "../button";
import { getAsyncStorageValue } from "../../utils/localStorage";

const apronDetail = {
  designation: "",
  surface: "",
  classPCN: "",
  pavementTypePCN: "",
  pavementSubgradePCN: "",
  maxTyrePressurePCN: "",
  evaluationMethodPCN: "",
};

export default function Ad28ApronForm({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const isLoadingApronUpload = useSelector(
    (state) => state.airport.isLoadingApronUpload
  );

  const createAd28ApronForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      aprons: [apronDetail],
    },
    validationSchema: createAd28ApronValidationSchema,
    onSubmit: (values) => {
      const apronValues = values.aprons.map((apron) => {
        return apron;
      });

      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          apronUpload(
            {
              airport_id: selectedAirport.id,
              form: apronValues,
            },
            token,
            handleUploadSuccessCallback
          )
        );
      });
    },
  });

  const handleUploadSuccessCallback = () => {
    createAd28ApronForm.resetForm();
    createAd28ApronForm.setSubmitting(false);
    dispatch(removeSelctedAirportFormCategories(selectedCategory));
  };

  return (
    <Paper sx={{ pb: 2, pt: 2 }}>
      <FormikProvider value={createAd28ApronForm}>
        <form onSubmit={createAd28ApronForm.handleSubmit}>
          <FieldArray
            name="aprons"
            render={(arrayHelpers) => (
              <div>
                {createAd28ApronForm.values.aprons.map((_, index) => {
                  const isTouched = createAd28ApronForm.touched?.aprons
                    ? createAd28ApronForm.touched?.aprons[index]
                    : false;
                  const isErrors = createAd28ApronForm.errors?.aprons
                    ? createAd28ApronForm.errors?.aprons[index]
                    : false;

                  return (
                    <Fragment key={index}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Designation</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            name={`aprons[${index}].designation`}
                            value={
                              createAd28ApronForm.values.aprons[index]
                                .designation
                            }
                            onChange={createAd28ApronForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .designation &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .designation
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .designation
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Surface</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .surface &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .surface
                              )
                            }
                          >
                            <MuiSelect
                              name={`aprons[${index}].surface`}
                              value={
                                createAd28ApronForm.values.aprons[index].surface
                              }
                              onChange={createAd28ApronForm.handleChange}
                            >
                              {Ad212SurfaceTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .surface
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Class PCN</Box>
                        <Box sx={{ pl: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            type="number"
                            name={`aprons[${index}].classPCN`}
                            value={
                              createAd28ApronForm.values.aprons[index].classPCN
                            }
                            onChange={createAd28ApronForm.handleChange}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .classPCN &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .classPCN
                              )
                            }
                            helperText={
                              isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .classPCN
                                : ""
                            }
                            sx={{ mb: 2, mt: 2 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  NUM
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Pavement Type PCN</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .pavementTypePCN &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .pavementTypePCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`aprons[${index}].pavementTypePCN`}
                              value={
                                createAd28ApronForm.values.aprons[index]
                                  .pavementTypePCN
                              }
                              onChange={createAd28ApronForm.handleChange}
                            >
                              {Ad212PavementTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .pavementTypePCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Pavement Subgrade PCN</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .pavementSubgradePCN &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .pavementSubgradePCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`aprons[${index}].pavementSubgradePCN`}
                              value={
                                createAd28ApronForm.values.aprons[index]
                                  .pavementSubgradePCN
                              }
                              onChange={createAd28ApronForm.handleChange}
                            >
                              {Ad212PavementSubgradeTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .pavementSubgradePCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Max Tyre Pressure</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .maxTyrePressurePCN &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .maxTyrePressurePCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`aprons[${index}].maxTyrePressurePCN`}
                              value={
                                createAd28ApronForm.values.aprons[index]
                                  .maxTyrePressurePCN
                              }
                              onChange={createAd28ApronForm.handleChange}
                            >
                              {Ad212MaxTyrePressureTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .maxTyrePressurePCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pl: 2, pr: 2 }}
                      >
                        <Box sx={{ width: "30%" }}>Evaluation Method PCN</Box>
                        <Box sx={{ pl: 2 }}>
                          <FormControl
                            fullWidth
                            size="small"
                            sx={{ mb: 2, width: 200 }}
                            error={
                              isTouched &&
                              isErrors &&
                              createAd28ApronForm.touched?.aprons[index]
                                .evaluationMethodPCN &&
                              Boolean(
                                createAd28ApronForm.errors?.aprons[index]
                                  .evaluationMethodPCN
                              )
                            }
                          >
                            <MuiSelect
                              name={`aprons[${index}].evaluationMethodPCN`}
                              value={
                                createAd28ApronForm.values.aprons[index]
                                  .evaluationMethodPCN
                              }
                              onChange={createAd28ApronForm.handleChange}
                            >
                              {Ad212EveluationTypes.map((types) => (
                                <MenuItem key={types.id} value={types.value}>
                                  {types.label}
                                </MenuItem>
                              ))}
                            </MuiSelect>
                            <FormHelperText>
                              {isErrors && isTouched
                                ? createAd28ApronForm.errors?.aprons[index]
                                    .evaluationMethodPCN
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Stack>
                      <Stack alignItems="flex-end">
                        <PrimaryButton
                          label="Remove"
                          color="error"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </Stack>
                      <Divider />
                    </Fragment>
                  );
                })}
                <PrimaryButton
                  label="Add More"
                  onClick={() => arrayHelpers.push(apronDetail)}
                />
              </div>
            )}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ pr: 2 }}
          >
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingApronUpload}
              disabled={isLoadingApronUpload}
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
    </Paper>
  );
}
