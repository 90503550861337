export const BASE_URL = process.env.REACT_APP_BASE_URL;

// auth
export const RSA_URL = `${BASE_URL}/rsa`;
export const LOGIN_URL = `${BASE_URL}/user-login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const REFRESH_BOTH_TOKEN = `${BASE_URL}/refresh_both_tokens`;
export const RSA_ENCRYPT_URL = `${BASE_URL}/RSA-encrypt`;
export const RSA_DECRYPT_URL = `${BASE_URL}/RSA-decrypt`;
export const AES_ENCRYPT_URL = `${BASE_URL}/aes-encrypt`;
export const AES_DECRYPT_URL = `${BASE_URL}/aes-decrypt`;
export const AES_KEY_URL = `${BASE_URL}/encrypt/`;
export const CRC32Q_GENERATE = `${BASE_URL}/crcgenerate`;
export const REQUEST_FEEDBACK_URL = `${BASE_URL}/feedback`;

//aixm
export const GET_ALL_AIXM_AIRPORT_URL = (version) => {
  let url = `${BASE_URL}/aixm/getallAirport`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_AIRPORT_DETAIL_URL = (airportId, version) => {
  let url = `${BASE_URL}/aixm/airport_details?id=${airportId}`;
  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const GET_OBSTACLES_DATA_URL = (airportId, version) =>
  `${BASE_URL}/aixm/eaip/airport/${airportId}/version/${version}/aixmobstacles`;
export const GET_RUNWAY_DETAIL_DATA_URL = (airportId, version) => {
  let url = `${BASE_URL}/aixm/runway_details?id=${airportId}`;
  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const EXPORT_AIRSPACE_SHEET_URL = (category, version) => {
  let url = `${BASE_URL}/aixm/airspace_export?category=${category}`;

  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const EXPORT_ROUTE_SHEET_URL = (id, version) => {
  let url = `${BASE_URL}/aixm/route_export?id=${id}`;

  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const EXPORT_DRP_SHEET_URL = (airport_icao, type, version) => {
  let url = `${BASE_URL}/aixm/drp_export?airport_icao=${airport_icao}&type=${type}`;

  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const EXPORT_TRA_SHEET_URL = (type, version) => {
  let url = `${BASE_URL}/aixm/tra_tsa_adiz_export?type=${type}`;

  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const GET_AIXM_DATA_VERSION = `${BASE_URL}/aixm/getaixm_data_version`;
export const EXPORT_AIXM_SHEET = `${BASE_URL}/aixm/exportsheet`;
export const GET_ALL_NEW_AIRPORT_URL = `${BASE_URL}/aixm/getallnewairport`;
export const GET_AD_RUNWAY_INFO_URL = (airportId, version) =>
  `${BASE_URL}/aixm/ad_runway_info?airport_id=${airportId}&version=${version}`;
export const CREATE_NEW_AIRPORT_URL = `${BASE_URL}/aixm/create_airport`;
export const ADD_AIRPORT_DETAIL_URL = `${BASE_URL}/aixm/airport_details_upload`;
export const RUNWAY_DETAIL_UPLOAD_URL = `${BASE_URL}/aixm/runway_details_upload`;
export const RUNWAY_DIRECTION_UPLOAD_URL = `${BASE_URL}/aixm/runway_direction_upload`;
export const APRON_UPLOAD_URL = `${BASE_URL}/aixm/apron_upload`;
export const TAXIWAY_UPLOAD_URL = `${BASE_URL}/aixm/taxiway_upload`;
export const NAVID_UPLOAD_URL = `${BASE_URL}/aixm/navaid_upload`;
export const CHANGE_STATUS_AIRPORT_URL = `${BASE_URL}/aixm/change_status_airport`;
export const UPLOAD_OBSTACLE_SHEET_URL = (airport_id, crcCode) =>
  `${BASE_URL}/aixm/obstacles_upload?airport_id=${airport_id}&crc_code=${crcCode}`;
export const AIRPORT_CHART_UPLOAD_URL = (airport_id, filename) =>
  `${BASE_URL}/aixm/chart_upload?airport_id=${airport_id}&file_name=${filename}`;
export const GET_AIRPORT_CHART_URL = (airport_id) =>
  `${BASE_URL}/aixm/getairportchart?airport_id=${airport_id}`;
export const DOWNLOAD_XML_AD2_URL = (version, airport_id, ad_type) =>
  `${BASE_URL}/aixm/xml_export?version=${version}&airport_id=${airport_id}&ad_type=${ad_type}`;
export const DELETE_REVIEW_AIRPORT_URL = (airportId, version) =>
  `${BASE_URL}/aixm/eaip/airport/${airportId}/version/${version}`;

//airspace
export const GET_ALL_FIR_DATA_URL = (version) => {
  let url = `${BASE_URL}/aixm/firdata`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_TMA_DATA_URL = (version) => {
  let url = `${BASE_URL}/aixm/tmadata`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_CTA_DATA_URL = (version) => {
  let url = `${BASE_URL}/aixm/ctadata`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_REGULATED_AIRSPACE_DATA_URL = (version) => {
  let url = `${BASE_URL}/aixm/atz_ctr_data`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_NAVID_URL = (version) => {
  let url = `${BASE_URL}/aixm/getallnavaid`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_SIGNIFICANT_POINT_URL = (version) => {
  let url = `${BASE_URL}/aixm/getallsignificantpoint`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_TRA_TSA_ADIZ_URL = (version) => {
  let url = `${BASE_URL}/aixm/getalltra_tsa_adiz`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_DPR_URL = (version) => {
  let url = `${BASE_URL}/aixm/getalldpr`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_ROUTE_URL = (version) => {
  let url = `${BASE_URL}/aixm/getallroute`;
  if (version) {
    url += `?version=${version}`;
  }

  return url;
};
export const GET_ALL_RNAV_CNAV_ROUTES_URL = (type, version) => {
  let url = `${BASE_URL}/airspace/getall_georoutes`;
  url += `?type=${type}`;

  if (version) {
    url += `&version=${version}`;
  }

  return url;
};
export const GET_ROUTE_DATA_URL = (route_id) =>
  `${BASE_URL}/aixm/routedata?route_id=${route_id}`;
export const GET_RESTRICTIVE_AIRSPACE_DETAILS_URL = (airspace_id) =>
  `${BASE_URL}/airspace/restrictiveairspace_details?id=${airspace_id}`;
export const GET_RESTRICTIVE_AIRSPACE_GEOMETRY_URL = `${BASE_URL}/airspace/restrictiveairspacegeometry`;
export const GET_CONTROL_AIRSPACE_GEOMETRY_URL = `${BASE_URL}/airspace/controlairspacegeometry`;
export const GET_FIR_AIRSPACE_GEOMETRY_URL = `${BASE_URL}/airspace/getfirgeodata`;
export const GET_ADIZ_GEODATA_URL = `${BASE_URL}/airspace/getadizgeodata`;
export const GET_WAYPOINT_ROUTE_DATA_URL = (id) =>
  `${BASE_URL}/airspace/waypoint_route_info?id=${id}`;

// amdb
export const GET_AMDB_AIRPORT_URL = `${BASE_URL}/amdb/getallairportamdb`;
export const GET_AMDB_DATA_URL = (airportId) =>
  `${BASE_URL}/amdb/getamdbdata?id=${airportId}`;
export const EXPORT_GEO_JSON_DATA_URL = (type, airportId) =>
  `${BASE_URL}/amdb/amdb_jsonexport?type=${type}&airport_id=${airportId}`;
export const ADD_EDIT_AMDB_POLYGON_URL = `${BASE_URL}/amdb/amdbpolygonedit`;
export const DELETE_AMDB_POLYGON_URL = (poly_id, feature) =>
  `${BASE_URL}/amdb/amdbpolygondelete?poly_id=${poly_id}&feature=${feature}`;

//etod
export const GET_ALL_ETOD_AIRPORT_URL = `${BASE_URL}/etod/getallairportetod`;
export const GET_ETOD_DETAIL_URL = (id) =>
  `${BASE_URL}/etod/etod_details?id=${id}`;
export const GET_ETOD_OBSTACLES_DATA_URL = (id) =>
  `${BASE_URL}/etod/etodobstacles_data?id=${id}`;
export const GET_ETOD_OLS_OBSTACLES_DATA_URL = (id) =>
  `${BASE_URL}/etod/etodolsobstacles_data?id=${id}`;
export const GET_ETOD_OLS_DATA_URL = (id) =>
  `${BASE_URL}/etod/etodols?id=${id}`;
export const GET_RASTER_AREA_URL = (airportId) =>
  `${BASE_URL}/etod/etod_area?id=${airportId}`;
export const GET_AREA_THREE_OBSTACLES_URL = (airportId) =>
  `${BASE_URL}/etod/area_three_obstacles?id=${airportId}`;
export const GET_ETOD_AREA_1_URL = `${BASE_URL}/etod/getareas`;
export const GET_ETOD_AREA_1_OBSTACLES_URL = `${BASE_URL}/etod/area_one_obstacles`;
export const EXPORT_OBSTACLES_URL = (airportId, type) =>
  `${BASE_URL}/etod/etodobs_xlsx?type=${type}&id=${airportId}`;

// surveyor
export const GET_ALL_SURVEYOR_AIRPORT_URL = `${BASE_URL}/management/getallsurveyairport`;
export const REQUEST_NEW_PASSWORD_URL = `${BASE_URL}/reqnewpassword`;
export const POST_EDIT_REVIEW_DATA_URL = `${BASE_URL}/management/editreviewdata`;
export const GET_SURVEYOR_TEMPLATE_DOWNLOAD_URL = (template) =>
  `${BASE_URL}/management/downloadtemplate?type=${template}`;
export const GET_SURVEYOR_UPLOAD_FILE_URL = (datesetType, airportId, crcCode) =>
  `${BASE_URL}/management/file-upload?type=${datesetType}&airport_id=${airportId}&crc_code=${crcCode}`;
export const GET_COMPARE_DATA_URL = (airportId, type, user_id, report_id) =>
  `${BASE_URL}/management/comparedata?airport_id=${airportId}&type=${type}&user_id=${user_id}&report_id=${report_id}`;
export const GET_REVIEW_FEATURE_DATA_URL = (userId) =>
  `${BASE_URL}/management/getreviewfeaturesdata?user_id=${userId}`;
export const GET_SURVEYOR_AIRPORT_URL = (userId) =>
  `${BASE_URL}/management/surveyorairport?user_id=${userId}`;
export const GET_REVIEW_SURVEY_DATA_URL = (id, type) =>
  `${BASE_URL}/management/getreviewsurveydata?id=${id}&type=${type}`;
export const GET_REVIEW_REPORT_DATA_URL = (id, type) =>
  `${BASE_URL}/management/exportreviewreport?report_id=${id}&type=${type}`;

// surveyor user admin
export const GET_ALL_SURVEYOR_URL = `${BASE_URL}/getallusers`;
export const POST_SURVEYOR_REGISTER_URL = `${BASE_URL}/register_user`;
export const EDIT_SURVEYOR_URL = `${BASE_URL}/editprofile`;
export const MASTER_RESET_PASSWORD_URL = `${BASE_URL}/master-reset-password`;
export const GET_ALL_REQUEST_PASSWORD_URL = `${BASE_URL}/getallreqpassword`;
export const DISABLE_SURVEYOR_URL = (userId) =>
  `${BASE_URL}/disable_surveyor/${userId}`;
export const ENABLE_SURVEYOR_URL = `${BASE_URL}/enableuser`;
export const GET_ALL_SURVEYOR_REPORT_URL = `${BASE_URL}/management/getallsurveyreport`;
export const CHANGE_VERIFICATION_URL = `${BASE_URL}/management/changeverification`;
export const GET_SURVEYOR_REPORT_DETAIL_URL = (id, type) =>
  `${BASE_URL}/management/getsurveyreport?id=${id}&type=${type}`;

export const DELETE_REVIEW_AIRPORT_OBSTACLE_URL = (airportId, version) =>
  `${BASE_URL}/aixm/eaip/airport/${airportId}/version/${version}/aixmobstacles`;

// eChart
export const GET_ECHART_PDF_DATA_URL = (id) =>
  `${BASE_URL}/echart/echartdata?id=${id}`;
export const GET_ECHART_DATA_URL = (id) => `${BASE_URL}/echart/echart?id=${id}`;
export const ECHART_EDIT_DATA_URL = `${BASE_URL}/echart/echartedit`;
export const ECHART_EXPORT_URL = (id) =>
  `${BASE_URL}/echart/echartdownload?id=${id}`;
export const ECHART_ATOCA_URL = (airport_id) =>
  `${BASE_URL}/echart/echartatoca?airport_id=${airport_id}`;
export const ECHART_TABLE_DETAIL_URL = (airport_id) =>
  `${BASE_URL}/echart/echartdetails?airport_id=${airport_id}`;
