import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defaultLocationCenter } from '../constants/constant';
import Header from '../components/Header';
import AirspaceMap from '../components/airspaceOSMMap/MapContainer';
import AirspaceSideBar from '../components/AirspaceSideBar';
import {
	getRestrictiveairspaceGeometry,
	getControlAirspaceGeometry,
	getFIRAirspaceGeometry,
	getADIZGeoData,
	getAllRNAVRoutes,
	getAllCNAVRoutes,
	getAreaNavigationalRouteData,
	getConventionalRouteData,
	getAllNavaid,
	getAllSignificantPoint,
	selectControlAirspaceGeometry,
	selectControlAirspaceOption,
	selectFIRAirspaceGeometry,
	selectFIRAirspaceOption,
	selectADIZAirspaceGeometry,
	selectADIZAirspaceOption,
	selectRNAVOptions,
	selectCNAVOptions,
	setAreaNavigationalRouteData,
	setConventionalRouteData,
	selectAllNavaidOptions,
	selectAllSignificantPointOptions,
	selectFilterNavaidData,
	selectfilterSignificantPoints,
	selectPDRAirspaceOption,
	selectPDRAirspaceGeometry,
	selectTraTsaAirspaceOption,
	selectTraTsaAirspaceGeometry,
	getWaypointRouteData,
	setWaypointRouteData,
} from '../models/airspace';
import { colorTray } from '../constants/colors';

export default function Airspace() {
	const dispatch = useDispatch();
	const isLoadingRestrictiveAirspaceGeometry =
		useSelector((state) => state.airspace.isLoadingRestrictiveAirspaceGeometry);
	const isLoadingControlAirspaceGeometry =
		useSelector((state) => state.airspace.isLoadingControlAirspaceGeometry);
	const isLoadingFIRAirspaceGeometry =
		useSelector((state) => state.airspace.isLoadingFIRAirspaceGeometry);
	const isLoadingGetADIZGeoData = useSelector((state) => state.airspace.isLoadingGetADIZGeoData);
	const isLoadingRNAVRoutesGeometry = useSelector((state) => state.airspace.isLoadingRNAVRoutesGeometry);
	const isLoadingCNAVRoutesGeometry = useSelector((state) => state.airspace.isLoadingCNAVRoutesGeometry);
	const isLoadingRNAVRoutes = useSelector((state) => state.airspace.isLoadingRNAVRoutes);
	const isLoadingCNAVRoutes = useSelector((state) => state.airspace.isLoadingCNAVRoutes);
	const isLoadingAreaNavigationalRouteData = useSelector((state) => state.airspace.isLoadingAreaNavigationalRouteData);
	const isLoadingConventionalRouteData = useSelector((state) => state.airspace.isLoadingConventionalRouteData);
	const isLoadingGetAllNavaid = useSelector((state) => state.airspace.isLoadingGetAllNavaid);
	const isLoadingGetAllsignificantPoint = useSelector((state) => state.airspace.isLoadingGetAllsignificantPoint);
	const isLoadingWaypointRouteData = useSelector((state) => state.airspace.isLoadingWaypointRouteData);
	const [filterPdrAirspace, setFilterPdrAirspace] = useState([]);
	const [filterTraTsaAirspace, setFilterTraTsaAirspace] = useState([]);
	const [filterControlAirspace, setFilterControlAirspace] = useState([]);
	const [filterFIRAirspace, setFilterFIRAirspace] = useState([]);
	const [filterADIZAirspace, setFilterADIZAirspace] = useState([]);
	const [filterRNAVAirspace, setFilterRNAVAirspace] = useState(null);
	const [filterCNAVAirspace, setFilterCNAVAirspace] = useState(null);
	const [filterNavidAirspace, setFilterNavidAirspace] = useState([]);
	const [filterWayPointsAirspace, setFilterWayPointsAirspace] = useState([]);
	const pdrAirspaceGeometry = useSelector((state) =>
		selectPDRAirspaceGeometry(state, filterPdrAirspace, filterFIRAirspace));
	const traTsaAirspaceGeometry = useSelector((state) =>
		selectTraTsaAirspaceGeometry(state, filterTraTsaAirspace));
	const controlAirspaceGeometry = useSelector((state) =>
		selectControlAirspaceGeometry(state, filterControlAirspace, filterFIRAirspace));
	const FIRAirspaceGeometry = useSelector((state) =>
		selectFIRAirspaceGeometry(state, filterFIRAirspace));
	const ADIZAirspaceGeometry = useSelector((state) =>
		selectADIZAirspaceGeometry(state, filterADIZAirspace));
	const navidAirspaceGeometry = useSelector((state) =>
		selectFilterNavaidData(state, filterNavidAirspace));
	const wayPointsAirspaceGeometry = useSelector((state) =>
		selectfilterSignificantPoints(state, filterWayPointsAirspace));
	const waypointRouteGeometry = useSelector((state) => state.airspace.waypointRouteData);
	const pdrAirspaceOption = useSelector((state) =>
		selectPDRAirspaceOption(state, filterFIRAirspace));
	const traTsaAirspaceOption = useSelector(selectTraTsaAirspaceOption);
	const controlAirspaceOption = useSelector(selectControlAirspaceOption);
	const FIRAirspaceOption = useSelector(selectFIRAirspaceOption);
	const ADIZAirspaceOption = useSelector(selectADIZAirspaceOption);
	const RNAVAirspaceOption = useSelector(selectRNAVOptions);
	const CNAVAirspaceOption = useSelector(selectCNAVOptions);
	const navaidOptions = useSelector(selectAllNavaidOptions);
	const significantPointOptions = useSelector(selectAllSignificantPointOptions);
	const areaNavigationalRouteData = useSelector((state) => state.airspace.areaNavigationalRouteData);
	const conventionalRouteData = useSelector((state) => state.airspace.conventionalRouteData);
	const [zoomLevel, setZoomLevel] = useState(4.7);
	const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);

	useEffect(() => {
		dispatch(getRestrictiveairspaceGeometry());
		dispatch(getControlAirspaceGeometry());
		dispatch(getFIRAirspaceGeometry());
		dispatch(getADIZGeoData());
		dispatch(getAllRNAVRoutes());
		dispatch(getAllCNAVRoutes());
		dispatch(getAllNavaid());
		dispatch(getAllSignificantPoint());
		dispatch(setAreaNavigationalRouteData([]));
		dispatch(setConventionalRouteData([]));
	}, []); // eslint-disable-line

	const handleChangeCenter = () => {
		setAirportLocation(null);
		setZoomLevel(4.6);
		setTimeout(() => {
			setAirportLocation(defaultLocationCenter);
			setZoomLevel(4.7);
		}, 600);
	};

	const handlePdrAirspaceFilter = (option) => {
		setFilterPdrAirspace(option);
	};
	
	const handletraTsaAirspaceFilter = (option) => {
		setFilterTraTsaAirspace(option);
	};

	const handleControlAirspaceFilter = (option) => {
		setFilterControlAirspace(option);
	};

	const handleFIRAirspaceFilter = (option) => {
		setFilterFIRAirspace(option);
		handleChangeCenter();
	};

	const handleADIZAirspaceFilter = (option) => {
		setFilterADIZAirspace(option);
		handleChangeCenter();
	};

	const handleRNAVAirspaceFilter = (option) => {
		dispatch(setAreaNavigationalRouteData([]));
		setFilterRNAVAirspace(option);
		if (option?.id) {
			dispatch(getAreaNavigationalRouteData(option.id));
		};
		handleChangeCenter();
	};

	const handleCNAVAirspaceFilter = (option) => {
		dispatch(setConventionalRouteData([]));
		setFilterCNAVAirspace(option);
		if (option?.id) {
			dispatch(getConventionalRouteData(option.id));
		};
		handleChangeCenter();
	};

	const handleNavidAirspaceFilter = (option) => {
		setFilterNavidAirspace(option);
		handleChangeCenter();
	};

	const handleWayPointAirspaceFilter = (option) => {
		dispatch(setWaypointRouteData([]));
		if (!option) {
			setFilterWayPointsAirspace([]);
			return;
		}

		if (option?.name !== 'All') {
			dispatch(getWaypointRouteData(option.id));
		};
		
		setFilterWayPointsAirspace([option]);
		handleChangeCenter();
	};

	return (
		<div className="wrapper">
			<AirspaceSideBar
				isLoadingRestrictiveAirspaceGeometry={isLoadingRestrictiveAirspaceGeometry}
				isLoadingControlAirspaceGeometry={isLoadingControlAirspaceGeometry}
				isLoadingFIRAirspaceGeometry={isLoadingFIRAirspaceGeometry}
				isLoadingGetADIZGeoData={isLoadingGetADIZGeoData}
				isLoadingRNAVRoutesGeometry={isLoadingRNAVRoutesGeometry}
				isLoadingCNAVRoutesGeometry={isLoadingCNAVRoutesGeometry}
				isLoadingRNAVRoutes={isLoadingRNAVRoutes}
				isLoadingCNAVRoutes={isLoadingCNAVRoutes}
				isLoadingAreaNavigationalRouteData={isLoadingAreaNavigationalRouteData}
				isLoadingConventionalRouteData={isLoadingConventionalRouteData}
				isLoadingGetAllNavaid={isLoadingGetAllNavaid}
				isLoadingGetAllsignificantPoint={isLoadingGetAllsignificantPoint}
				controlAirspaceOption={controlAirspaceOption}
				filterPdrAirspace={filterPdrAirspace}
				filterTraTsaAirspace={filterTraTsaAirspace}
				filterControlAirspace={filterControlAirspace}
				filterFIRAirspace={filterFIRAirspace}
				filterADIZAirspace={filterADIZAirspace}
				FIRAirspaceOption={FIRAirspaceOption}
				ADIZAirspaceOption={ADIZAirspaceOption}
				RNAVAirspaceOption={RNAVAirspaceOption}
				filterRNAVAirspace={filterRNAVAirspace}
				CNAVAirspaceOption={CNAVAirspaceOption}
				filterCNAVAirspace={filterCNAVAirspace}
				pdrAirspaceOption={pdrAirspaceOption}
				traTsaAirspaceOption={traTsaAirspaceOption}
				handlePdrAirspaceFilter={handlePdrAirspaceFilter}
				handletraTsaAirspaceFilter={handletraTsaAirspaceFilter}
				handleControlAirspaceFilter={handleControlAirspaceFilter}
				handleFIRAirspaceFilter={handleFIRAirspaceFilter}
				handleADIZAirspaceFilter={handleADIZAirspaceFilter}
				handleRNAVAirspaceFilter={handleRNAVAirspaceFilter}
				handleCNAVAirspaceFilter={handleCNAVAirspaceFilter}
				navaidOptions={navaidOptions}
				filterNavidAirspace={filterNavidAirspace}
				handleNavidAirspaceFilter={handleNavidAirspaceFilter}
				significantPointOptions={significantPointOptions}
				filterWayPointsAirspace={filterWayPointsAirspace}
				handleWayPointAirspaceFilter={handleWayPointAirspaceFilter}
				isLoadingWaypointRouteData={isLoadingWaypointRouteData}
			/>
			<div className="main">
				<Header active="airspace" />
				<div className="content-area">
					<AirspaceMap
						airportLocation={airportLocation}
						zoomLevel={zoomLevel}
						colorTray={colorTray.sort()}
						pdrAirspaceGeometry={pdrAirspaceGeometry}
						traTsaAirspaceGeometry={traTsaAirspaceGeometry}
						controlAirspaceGeometry={controlAirspaceGeometry}
						FIRAirspaceGeometry={FIRAirspaceGeometry}
						ADIZAirspaceGeometry={ADIZAirspaceGeometry}
						areaNavigationalRouteData={areaNavigationalRouteData}
						conventionalRouteData={conventionalRouteData}
						navidAirspaceGeometry={navidAirspaceGeometry}
						wayPointsAirspaceGeometry={wayPointsAirspaceGeometry}
						waypointRouteGeometry={waypointRouteGeometry}
					/>
				</div>
			</div>
		</div>
	)
};