import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as XLSX from "xlsx";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { FileIcon } from "../../assets/svg/file";
import { ACCESS_TOKEN, roles } from "../../constants/constant";
import {
  surveyCategory,
  surveyComapareTableHeaderName,
} from "../../constants/surveyorData";
import Header from "../../components/Header";
import DataManagementSideBar from "../../components/DataManagementSideBar";
import NavButton from "../../components/NavButton";
import DataCompareTable from "../../components/surveyor-tables/DataCompareTable";
import ApproachTable from "../../components/surveyor-tables/ApproachTable";
import VerticalSignificanceTable from "../../components/surveyor-tables/VerticalSignificanceTable";
import ObjectsPreviewDataTable from "../../components/surveyor-tables/ObjectsPreviewDataTable";
import PreviewDataTable from "../../components/surveyor-tables/PreviewDataTable";
import ApproachPreviewTable from "../../components/surveyor-tables/ApproachPreviewTable";
import VerticalSignificancePreviewTable from "../../components/surveyor-tables/VerticalSignificancePreviewTable";
import TopTableHeaderRow from "../../components/surveyor-tables/TopTableHeaderRow";
import TableSheetHeaderRow from "../../components/surveyor-tables/TableSheetHeaderRow";
import { PrimaryButton } from "../../components/button";
import {
  downloadCategoryTemplate,
  uploadSurveyFile,
  setUploadFileData,
  setCompareData,
  getReviewFeaturesdata,
  getAllRequestPassword,
  comparedata,
  getSurveyorAirport,
} from "../../models/survey";
import { generateCRC32Q } from "../../models/userSession";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { sheetValidation } from "../../utils/sheetValidation";
import SurveyorReviewList from "./SurveyorReviewList";

export default function UserDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;
  const isAAI = user?.role === roles.aai;
  const isSurveyor = user?.role === roles.surveyor;
  const isParentSorveyorPath = location.pathname === "/data-management";
  const currentUser = useSelector((state) => state.userSession.user);
  const isDownloadTemplate = useSelector(
    (state) => state.survey.isDownloadTemplate
  );
  const isUploadSurveyFile = useSelector(
    (state) => state.survey.isUploadSurveyFile
  );
  const isLoadingCompareData = useSelector(
    (state) => state.survey.isLoadingCompareData
  );
  const isSurveyorAirportLoading = useSelector(
    (state) => state.survey.isSurveyorAirportLoading
  );
  const isLoadingReviewFeaturesdata = useSelector(
    (state) => state.survey.isLoadingReviewFeaturesdata
  );
  const isLoadingGetallReqPassword = useSelector(
    (state) => state.survey.isLoadingGetallReqPassword
  );
  const surveyorAirportData = useSelector(
    (state) => state.survey.surveyorAirportData
  );
  const uploadFileData = useSelector((state) => state.survey.uploadFileData);
  const compareFiledata = useSelector((state) => state.survey.comparedata);
  const reviewBackFeaturesData = useSelector(
    (state) => state.survey.reviewBackFeaturesData
  );
  const allReqPasswordData = useSelector(
    (state) => state.survey.allReqPassword
  );
  const [selctedCategory, setCategory] = useState(null);
  const [selctedAirport, setAirport] = useState(null);
  const [uploaLocalFileData, setUploaLocalFileData] = useState(null);
  const [uploadLocalFile, setUploadLocalFile] = useState(null);
  const [isShowDatasetReValidation, setShowDatasetReValidation] =
    useState(false);
  const [CRCcode, setCRCcode] = useState(null);
  const [isOpenCrcPopup, setOpenCrcPopup] = useState(false);

  useEffect(() => {
    if (!CRCcode) return;
    setOpenCrcPopup(true);
  }, [CRCcode]);

  useEffect(() => {
    if (!isParentSorveyorPath) return;
    dispatch(setUploadFileData(null));
    dispatch(setCompareData(null));

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (!isAdmin) {
        dispatch(getReviewFeaturesdata(token));
      }
      dispatch(getSurveyorAirport(token));
      if (isAdmin) {
        dispatch(getAllRequestPassword(token));
      }
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (surveyorAirportData && surveyorAirportData.length > 0) {
      setAirport(surveyorAirportData[0]);
    }
  }, [surveyorAirportData]);

  const handleCategory = (option) => {
    if (option === null) {
      setCategory(null);
    } else {
      setCategory(option);
    }

    setUploaLocalFileData(null);
    setUploadLocalFile(null);
    dispatch(setUploadFileData(null));
    dispatch(setCompareData(null));
    setShowDatasetReValidation(false);
  };

  const handleTemplateDownload = (template) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(downloadCategoryTemplate(template, token));
    });
  };

  const handleDateReValidation = () => {
    setShowDatasetReValidation(true);
  };

  const handleSuccessFileUploadCallback = (reportData) => {
    setUploadLocalFile(null);
    setUploaLocalFileData(null);

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        comparedata(
          selctedAirport.id,
          selctedCategory.value,
          currentUser.userId,
          reportData.report_id,
          token
        )
      );
    });
  };

  const handleFileUpload = (event) => {
    event.preventDefault();

    const files = event.target.files;
    const size = (files[0].size / 1024 / 1024).toFixed(2);
    setUploadLocalFile(files);

    if (size > 10) {
      toast.error("File should be less than 10 MB.");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws);
      const sheetCoulmns = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const validation = sheetValidation(selctedCategory.value, sheetCoulmns);

      if (validation.isValid) {
        setUploaLocalFileData(dataParse);
        dispatch(
          generateCRC32Q(files[0], (crcCode) => {
            setCRCcode(crcCode);
          })
        );
      } else {
        setUploaLocalFileData(null);
        setUploadLocalFile(null);
        toast.error(validation.message);
      }
    };

    reader.readAsBinaryString(files[0]);
  };

  const handleFileUploadSubmit = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        uploadSurveyFile(
          selctedCategory.value,
          selctedAirport.id,
          CRCcode,
          uploadLocalFile[0],
          token,
          handleSuccessFileUploadCallback
        )
      );
    });
  };

  const handlePasswordRequest = () => {
    navigate("new-password-request");
  };

  return (
    <div className="wrapper">
      <DataManagementSideBar
        isDownloadTemplate={isDownloadTemplate}
        isUploadSurveyFile={isUploadSurveyFile}
        isLoadingCompareData={isLoadingCompareData}
        isLoadingReviewFeaturesdata={isLoadingReviewFeaturesdata}
        isActiveAirport={!selctedAirport}
        selctedCategory={selctedCategory}
        isActiveCategory={!selctedCategory}
        reviewBackFeaturesData={reviewBackFeaturesData || []}
        isLoadingGetallReqPassword={isLoadingGetallReqPassword}
        allReqPasswordData={allReqPasswordData}
        handleCategory={handleCategory}
        handleTemplateDownload={handleTemplateDownload}
        handleFileUpload={handleFileUpload}
        handleDateReValidation={handleDateReValidation}
        handlePasswordRequest={handlePasswordRequest}
      />
      <div className="main">
        <Header active="data-management" />
        {isAdmin && (
          <Fragment>
            <div className="content-area linear-bg">
              {isParentSorveyorPath && (
                <div className="admin-home">
                  <Stack direction="row" spacing={12}>
                    <NavButton
                      isShowLeftIndicator
                      icon={<FileIcon />}
                      label="MANAGE USERS"
                      onClick={() => {
                        navigate("manage-users");
                      }}
                    />
                    <NavButton
                      isShowLeftIndicator
                      icon={<FileIcon />}
                      label="REVIEW DATASETS"
                      onClick={() => {
                        navigate("surveyor-report-list");
                      }}
                    />
                    <NavButton
                      isShowLeftIndicator
                      icon={<FileIcon />}
                      label="MANAGE NEW AIRPORT DATA"
                      onClick={() => {
                        navigate("airport-review-list");
                      }}
                    />
                  </Stack>
                </div>
              )}
              {!isParentSorveyorPath && <Outlet />}
            </div>
          </Fragment>
        )}
        {(isSurveyor || isAAI) && (
          <div className="content-area linear-bg">
            {!uploaLocalFileData &&
              !uploadFileData &&
              !isShowDatasetReValidation && (
                <div className="welcome-area">
                  <Typography variant="h3" component="div">
                    WELCOME TO
                  </Typography>
                  {selctedAirport && (
                    <Typography variant="subtitle2" component="div">
                      {selctedAirport?.name}
                    </Typography>
                  )}
                  <Typography variant="subtitle2" component="div">
                    {isAAI ? "AAI Portal" : "Surveyor Portal"}
                  </Typography>
                  {isSurveyor && isSurveyorAirportLoading && (
                    <Typography variant="subtitle1" component="div">
                      Loading...
                    </Typography>
                  )}
                </div>
              )}
            {isLoadingCompareData && (
              <div className="welcome-area">
                <Typography variant="subtitle2" component="div">
                  Comapring Data...
                </Typography>
              </div>
            )}
            {uploaLocalFileData && uploaLocalFileData.length > 0 && (
              <div className="surveyor-category-table white-bg">
                <TopTableHeaderRow />
                <TableSheetHeaderRow
                  selctedAirport={selctedAirport}
                  selctedCategory={selctedCategory}
                />
                {selctedCategory?.value === surveyCategory.approach && (
                  <ApproachPreviewTable data={uploaLocalFileData} />
                )}
                {selctedCategory?.value === surveyCategory.objects && (
                  <ObjectsPreviewDataTable data={uploaLocalFileData} />
                )}
                {selctedCategory?.value ===
                  surveyCategory.vertical_significance && (
                  <VerticalSignificancePreviewTable data={uploaLocalFileData} />
                )}
                {selctedCategory?.value !==
                  surveyCategory.vertical_significance &&
                  selctedCategory?.value !== surveyCategory.approach &&
                  selctedCategory?.value !== surveyCategory.objects && (
                    <PreviewDataTable
                      data={uploaLocalFileData}
                      selctedCategory={selctedCategory}
                    />
                  )}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ pr: 2, pt: 2 }}
                >
                  <PrimaryButton
                    label="Submit"
                    isLoading={isUploadSurveyFile}
                    disabled={isUploadSurveyFile}
                    onClick={handleFileUploadSubmit}
                    sx={{ width: 200 }}
                  />
                </Stack>
              </div>
            )}
            {uploadFileData && compareFiledata && !isLoadingCompareData && (
              <div className="surveyor-category-table white-bg">
                {selctedCategory?.value === surveyCategory.approach && (
                  <ApproachTable
                    rows={compareFiledata || []}
                    selctedAirport={selctedAirport}
                    selctedCategory={selctedCategory}
                  />
                )}
                {selctedCategory?.value !==
                  surveyCategory.vertical_significance &&
                  selctedCategory?.value !== surveyCategory.approach && (
                    <DataCompareTable
                      isLoading={isUploadSurveyFile || isLoadingCompareData}
                      rows={compareFiledata || []}
                      selctedAirport={selctedAirport}
                      selctedCategory={selctedCategory}
                      headerName={
                        surveyComapareTableHeaderName[selctedCategory?.value]
                      }
                    />
                  )}
                {selctedCategory?.value ===
                  surveyCategory.vertical_significance && (
                  <VerticalSignificanceTable
                    rows={compareFiledata || []}
                    selctedAirport={selctedAirport}
                    selctedCategory={selctedCategory}
                  />
                )}
              </div>
            )}
            {isShowDatasetReValidation && !uploadFileData && (
              <SurveyorReviewList
                reviewBackFeaturesData={reviewBackFeaturesData || []}
              />
            )}
          </div>
        )}
      </div>
      <Snackbar
        open={isOpenCrcPopup}
        autoHideDuration={6000}
        message={`CRC32Q code is ${CRCcode}`}
        onClose={() => setOpenCrcPopup(false)}
        action={
          <CopyToClipboard text={CRCcode}>
            <Button color="inherit" size="small">
              Copy
            </Button>
          </CopyToClipboard>
        }
      />
    </div>
  );
}
