import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const TableLoader = ({ isLoading, data, colSpan = 2 }) => {
  return (
    <Table>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell align="center" colSpan={colSpan} sx={{ fontWeight: 'bold' }}>
              Loading...
            </TableCell>
          </TableRow>
        )}
        {!isLoading && data?.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={colSpan}>
              No Record Found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableLoader;