import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { split } from "lodash";
import parseQueryParameters from "parse-url-query-params";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  getAllNewAirports,
  getAdRunwayInfo,
  setSelctedAirportFormCategories,
  removeSelctedAirportFormCategories,
  runwayDirectionUpload,
  getAirportChartList,
} from "../../models/airport";
import { ACCESS_TOKEN } from "../../constants/constant";
import { ad2Category } from "../../constants/ad2Data";
import Ad22From from "../../components/airport-forms/Ad22Form";
import Ad210From from "../../components/airport-forms/Ad210Form";
import Ad212RunWayDetailFrom from "../../components/airport-forms/Ad212RunWayDetailForm";
import Ad212RunWayDirectionFrom from "../../components/airport-forms/Ad212RunWayDirectionForm";
import Ad28ApronForm from "../../components/airport-forms/Ad28ApronForm";
import Ad28TaxiwayForm from "../../components/airport-forms/Ad28TaxiwayForm";
import Ad219NavidForm from "../../components/airport-forms/Ad219NavidForm";
import Ad224ChartsForm from "../../components/airport-forms/Ad224ChartsForm";
import { PrimaryButton } from "../../components/button";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { theme } from "../../styles/theme";

export default function AddAirportData() {
  const dispatch = useDispatch();
  const location = useLocation();
  const selctedAirportFormCategories = useSelector(
    (state) => state.airport.selctedAirportFormCategories
  );
  const isLoadingAdRunwayInfo = useSelector(
    (state) => state.airport.isLoadingAdRunwayInfo
  );
  const isLoadingRunwayDirectionUpload = useSelector(
    (state) => state.airport.isLoadingRunwayDirectionUpload
  );
  const allNewAirports = useSelector((state) => state.airport.allAirports);
  const adRunwayInfo = useSelector((state) => state.airport.adRunwayInfo);
  const allAirportsCharts = useSelector(
    (state) => state.airport.allAirportsCharts
  );
  const [tabValue, setTabValue] = useState(null);
  const [runwayFormValues, setRunwayFormValues] = useState([]);
  const [selectedRunwayCategory, setSelectedRunwayCategory] = useState(null);
  const prsedUrl = parseQueryParameters(
    decodeURIComponent(`${location.pathname}${location.search}`)
  );
  const selectedAirport = allNewAirports.find(
    (airport) => airport.id === Number(prsedUrl.airportId)
  );

  useEffect(() => {
    dispatch(setSelctedAirportFormCategories([]));
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllNewAirports(token));
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!selectedAirport) {
      return;
    }

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        getAdRunwayInfo(selectedAirport?.id, selectedAirport?.version_id)
      );
      dispatch(getAirportChartList(selectedAirport?.id, token));
    });
  }, [selectedAirport]); // eslint-disable-line

  useEffect(() => {
    if (selctedAirportFormCategories.length === 0) {
      setTabValue(null);
      return;
    }

    if (!tabValue && selctedAirportFormCategories.length > 0) {
      setTabValue(selctedAirportFormCategories[0].value);
      return;
    }

    if (tabValue && selctedAirportFormCategories.length > 0) {
      setTabValue(selctedAirportFormCategories[0].value);
      return;
    }
  }, [selctedAirportFormCategories]); // eslint-disable-line

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseTab = (category) => {
    dispatch(removeSelctedAirportFormCategories(category.value));
    setTabValue(null);
  };

  const handleRunwayDirection = (runway, category) => {
    runwayFormValues.push(runway[0]);
    setRunwayFormValues([...runwayFormValues]);
    setSelectedRunwayCategory(category);
  };

  const handleRunwatSubmit = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        runwayDirectionUpload(
          {
            airport_id: selectedAirport.id,
            form: runwayFormValues,
          },
          token,
          handleUploadSuccessCallback
        )
      );
    });
  };

  const handleUploadSuccessCallback = () => {
    dispatch(removeSelctedAirportFormCategories(selectedRunwayCategory));
  };

  if (!selectedAirport) return null;

  return (
    <div className="add-airport-data-from">
      <Stack
        direction="row"
        sx={{
          backgroundColor: theme.palette.secondary.light,
          pt: 2,
          pb: 2,
          pl: 2,
        }}
      >
        {selectedAirport?.name}
      </Stack>
      {selctedAirportFormCategories.length > 0 && tabValue && (
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "calc(100vw - 333px)",
            }}
          >
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {selctedAirportFormCategories.map((category, i) => (
                <Tab
                  key={`tab_${category.value}_${i}`}
                  label={category.label}
                  value={category.value}
                  iconPosition="end"
                  icon={
                    <IoCloseSharp
                      size={20}
                      onClick={() => handleCloseTab(category)}
                    />
                  }
                  sx={{ textTransform: "none" }}
                />
              ))}
            </TabList>
          </Box>
          {selctedAirportFormCategories.map((category, i) => {
            if (
              category.value ===
              ad2Category.ad2_2_aerodom_geographical_and_administrative_data
            ) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad22From
                    selectedAirport={selectedAirport}
                    selectedCategory={category.value}
                  />
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_10_aerodom_obstacles) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad210From
                    selectedAirport={selectedAirport}
                    selectedCategory={category.value}
                  />
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_12_runway_details) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad212RunWayDetailFrom
                    selectedAirport={selectedAirport}
                    selectedCategory={category.value}
                  />
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_12_runway_direction) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Fragment>
                    {isLoadingAdRunwayInfo && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography>Loding...</Typography>
                      </Box>
                    )}
                    {!adRunwayInfo ||
                      (adRunwayInfo?.Runway_List.length === 0 && (
                        <Stack display="flex" alignItems="center">
                          <Box sx={{ mt: 2 }}>
                            <Typography>
                              No runway details found. Please first add runway
                              details
                            </Typography>
                          </Box>
                        </Stack>
                      ))}
                    {!isLoadingAdRunwayInfo &&
                      adRunwayInfo &&
                      adRunwayInfo?.Runway_List.length > 0 &&
                      adRunwayInfo?.Runway_List.map((runway) => {
                        const runwayDirectionLength = split(
                          runway.designator,
                          "-"
                        )[1].length;
                        const runwayDirection1 = split(
                          runway.designator,
                          "-"
                        )[1].substring(0, runwayDirectionLength / 2);
                        const runwayDirection2 = split(
                          runway.designator,
                          "-"
                        )[1].slice(-runwayDirectionLength / 2);
                        const runwayDirections = [
                          runwayDirection1,
                          runwayDirection2,
                        ];
                        return runwayDirections.map((direction) => {
                          return (
                            <Accordion key={direction}>
                              <AccordionSummary
                                expandIcon={<MdOutlineKeyboardArrowDown />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  {runway.designator} {direction}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Ad212RunWayDirectionFrom
                                  runwayDesignator={runway.designator}
                                  direction={direction}
                                  selectedAirport={selectedAirport}
                                  selectedCategory={category.value}
                                  handleRunwayDirection={handleRunwayDirection}
                                />
                              </AccordionDetails>
                            </Accordion>
                          );
                        });
                      })}
                    {adRunwayInfo &&
                      adRunwayInfo?.Runway_List.length > 0 &&
                      adRunwayInfo?.Runway_List.length ===
                        adRunwayInfo?.Runway_List.length * 2 && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-end"
                          sx={{ pr: 2, mt: 2 }}
                        >
                          <PrimaryButton
                            label="Submit"
                            isLoading={isLoadingRunwayDirectionUpload}
                            disabled={isLoadingRunwayDirectionUpload}
                            onClick={handleRunwatSubmit}
                            sx={{ width: 200 }}
                          />
                        </Stack>
                      )}
                  </Fragment>
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_8_apron) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad28ApronForm
                    selectedAirport={selectedAirport}
                    selectedCategory={category.value}
                  />
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_8_taxiway) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad28TaxiwayForm
                    selectedAirport={selectedAirport}
                    selectedCategory={category.value}
                  />
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_19_navids) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad219NavidForm
                    selectedAirport={selectedAirport}
                    selectedCategory={category.value}
                    adRunwayInfo={adRunwayInfo}
                  />
                </TabPanel>
              );
            }

            if (category.value === ad2Category.ad2_24_charts) {
              return (
                <TabPanel
                  key={`tabpanel_${category.value}_${i}`}
                  value={category.value}
                  sx={{ p: 0 }}
                >
                  <Ad224ChartsForm
                    selectedAirport={selectedAirport}
                    allAirportsCharts={allAirportsCharts}
                  />
                </TabPanel>
              );
            }

            return null;
          })}
        </TabContext>
      )}
    </div>
  );
}
