import React from "react";
import { Popup } from "react-leaflet";
import { getObstacleSymbol } from "../../utils/obstacleSymbols";
import { getEtodErea } from "../../utils/util";
import PopupBox from "../map/PopupBox";
import PopupItem from "../map/PopupItem";

export default function AreaObstacleInfoPopup({
  info,
  selctedAirport,
  selctedFeature,
}) {
  return (
    <Popup>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        {getObstacleSymbol(info.object_type)}
      </div>
      <PopupBox>
        <PopupItem>AIRPORT</PopupItem>
        <PopupItem>{selctedAirport.name || "-"}</PopupItem>
        <PopupItem>UID</PopupItem>
        <PopupItem>
          {`${selctedAirport.location_indicator}_${info.uid}`}
        </PopupItem>
        <PopupItem>OBJECT</PopupItem>
        <PopupItem>{info.point_name || "-"}</PopupItem>
        <PopupItem>LATITIUDE</PopupItem>
        <PopupItem>{info.latitude || "-"}</PopupItem>
        <PopupItem>LONGITUDE</PopupItem>
        <PopupItem>{info.longitude || "-"}</PopupItem>
        <PopupItem>ETOD AREA</PopupItem>
        {selctedFeature === "area" && (
          <PopupItem>
            {info.area || info.area_coverage || getEtodErea()}
          </PopupItem>
        )}
        {selctedFeature === "OLS" && (
          <PopupItem>{info.ols_surface || "-"}</PopupItem>
        )}
        <PopupItem>TOP ELEVATION (m)</PopupItem>
        <PopupItem>{info.top_elev || "-"}</PopupItem>
        <PopupItem>PERMISSIBLE ELEVATION (m)</PopupItem>
        {selctedFeature === "area" && (
          <PopupItem>{info.permissible_ele_combined || "-"}</PopupItem>
        )}
        {selctedFeature === "OLS" && (
          <PopupItem>{info.perissible_elevation || "-"}</PopupItem>
        )}
        <PopupItem>OBSTACLE (m)</PopupItem>
        {selctedFeature === "area" && (
          <PopupItem>{info.obstacle_penetration || "-"}</PopupItem>
        )}
        {selctedFeature === "OLS" && (
          <PopupItem>{info.elevation_difference || "-"}</PopupItem>
        )}
      </PopupBox>
    </Popup>
  );
}
