import React from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import DropDownLabel from "./DropDownLabel";
import BrandLogo from "./BrandLogo";

export default function AirportReviewSideBar({
  isLoadingAirportDetail,
  isLoadingAllAirports,
  selctedAirport,
  categories = [],
  selectedCategories,
  handleCategoryFilter,
}) {
  const navigate = useNavigate();

  return (
    <div className="sidebar">
      <BrandLogo />
      <Button
        variant="text"
        size="small"
        startIcon={<FaArrowLeft />}
        sx={{ mt: -5 }}
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <Box sx={{ mt: 1 }}>
        <Typography variant="subtitle2">Airport Name</Typography>
        <Typography variant="body2">
          {selctedAirport?.name} ({selctedAirport?.designator})
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <DropDownLabel label="Category" />
        <Select
          isClearable
          isMulti
          isDisabled={isLoadingAirportDetail || isLoadingAllAirports}
          isLoading={isLoadingAirportDetail || isLoadingAllAirports}
          value={selectedCategories}
          placeholder="Select..."
          onChange={handleCategoryFilter}
          options={categories}
        />
      </Box>
      {(isLoadingAirportDetail || isLoadingAllAirports) &&
      selectedCategories.length === 0 ? (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      ) : (
        <Divider sx={{ mt: 2, mb: 1 }} />
      )}
    </div>
  );
}
