import React from "react";
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import DiffTableCell from '../tabel-component/DiffTableCell';
import ValueItem from '../tabel-component/ValueItem';
import { surveyCategory } from '../../constants/surveyorData';
import { dataChangedRowColorMap } from '../../constants/colors';

export default function DataTableRow({ row, selctedCategory }) {
  const isShowBaseElev =
    selctedCategory?.value === surveyCategory.objects ||
    selctedCategory?.value === surveyCategory.navaids;

  return (
    <TableRow sx={{ backgroundColor: dataChangedRowColorMap[row.result] ?? '#FFFFFF' }}>
      <DiffTableCell>{row['uid1']}</DiffTableCell>
      <DiffTableCell>{row['point_name1']}</DiffTableCell>
      <DiffTableCell isdiff={row['latdev']}>
        <Stack
          direction="row"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <ValueItem value={row['latitude1']} />
          <ValueItem value={row['latitude2']} />
        </Stack>
      </DiffTableCell>
      <DiffTableCell isdiff={row['longdev']}>
        <Stack
          direction="row"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <ValueItem value={row['longitude1']} />
          <ValueItem value={row['longitude2']} />
        </Stack>
      </DiffTableCell>
      <DiffTableCell align="center" isdiff={Boolean(row['top_elevdiff'])}>
        <Stack
          direction="row"
          justifyContent="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
        >
          <ValueItem value={row['top_elev1']} />
          <ValueItem value={row['top_elev2']} />
          <ValueItem value={row['top_elevdiff']} />
        </Stack>
      </DiffTableCell>
      {isShowBaseElev && (
        <DiffTableCell isdiff={Boolean(row['base_elevdiff'])}>
          <Stack
            direction="row"
            justifyContent="center"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <ValueItem value={row['base_elev1']} />
            <ValueItem value={row['base_elev2']} />
            <ValueItem value={row['base_elevdiff']} />
          </Stack>
        </DiffTableCell>
      )}
      {!isShowBaseElev && (
        <DiffTableCell isdiff={Boolean(row['ellipsoidaldiff'])}>
          <Stack
            direction="row"
            justifyContent="center"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <ValueItem value={row['ellipsoidal_elev1']} />
            <ValueItem value={row['ellipsoidal_elev2']} />
            <ValueItem value={row['ellipsoidaldiff']} />
          </Stack>
        </DiffTableCell>
      )}
    </TableRow>
  );
};