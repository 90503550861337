import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "react-leaflet-fullscreen-control";

export default function FullScreenControl() {
  const map = useMap();

  useEffect(() => {
    map.addControl(
      new L.Control.Fullscreen({
        position: "topright",
        title: {
          false: "View Fullscreen",
          true: "Exit Fullscreen",
        },
      })
    );
  }, []); // eslint-disable-line

  return null;
}
