import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MuiBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1),
  borderLeft: '8px solid',
  borderColor: theme.palette.secondary.dark,
  borderRadius: 2
}));

export default function PageTitle({ title }) {
  return (
    <MuiBox>
      <Typography color="secondary">{title}</Typography>
    </MuiBox>
  );
};