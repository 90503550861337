import React, { Fragment, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { flatten, chain } from 'lodash';
import { areaHighLightColor } from '../../constants/colors';

export default function RenderArea({
  etodAreaList,
  selectedAreas,
  getAreaColors
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const areaColor = getAreaColors();
  const visibleAreaList =
    chain(etodAreaList)
      .filter((area) => {
        return selectedAreas.includes(`${area.rwy_area}__${areaColor[area.rwy_area]}`);
      })
      .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {
        visibleAreaList.map((area) => {
          const areaCoordinates = area.coordinates.coordinates;
          const areaCoordinateMap = flatten(areaCoordinates).map((areaCoords) => {
            return areaCoords.map((areal) => {
              return {
                lng: areal[0],
                lat: areal[1]
              };
            });
          });

          return areaCoordinateMap?.map((areaPolygonPath, i) => (
            <Polygon
              key={i}
              pane="tilePane"
              positions={areaPolygonPath}
              pathOptions={{
                fillColor: areaColor[area.rwy_area],
                color: selectedOverE?.rwy_area === area.rwy_area ? areaHighLightColor : areaColor[area.rwy_area],
                weight: selectedOverE?.rwy_area === area.rwy_area ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, area),
                mouseout: () => handlePolygonOut()
              }}
            />
          ));
        })
      }
    </Fragment>
  );
};