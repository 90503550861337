import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Typography } from "@mui/material";
import UserIcon from "../assets/images/user.png";
import { feVersion } from "../constants/constant";
import { userLogout } from "../models/userSession";
import { decryptValue } from "../utils/decodeEncodeData";
import { shortLabel } from "../utils/util";
import NavLink from "./NavLink";
import { capitalize } from "lodash";

export default function Header({ active, handleNavCallback }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSession.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isAEROversion = process.env.REACT_APP_FE_VERSION === feVersion.aero;
  const isAAIversion = process.env.REACT_APP_FE_VERSION === feVersion.aai;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(userLogout(handleLogoutCallback));
  };

  const handleLogoutCallback = () => {
    navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <div className="header">
      <NavLink to="/aixm" isActive={active === "aixm"}>
        AIXM
      </NavLink>
      {((isAEROversion && currentUser?.userId) || isAAIversion) && (
        <>
          <NavLink to="/airspace" isActive={active === "airspace"}>
            Airspace
          </NavLink>
          <NavLink
            to="/data-management"
            isActive={active === "data-management"}
            onClick={handleNavCallback}
          >
            Data Management
          </NavLink>
        </>
      )}
      <NavLink to="/electronic-chart" isActive={active === "electronic-chart"}>
        Electronic Chart
      </NavLink>
      <NavLink to="/etod" isActive={active === "etod"}>
        eTOD
      </NavLink>
      <NavLink to="/amdb" isActive={active === "amdb"}>
        AMDB
      </NavLink>
      <IconButton
        disabled={!currentUser?.userId}
        onClick={handleMenu}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          alt={
            currentUser && currentUser?.userName
              ? decryptValue(currentUser.userName)
              : "user"
          }
          src={UserIcon}
        />
      </IconButton>
      {currentUser?.userId && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {currentUser && currentUser?.userName && (
            <MenuItem onClick={handleClose}>
              <Avatar
                style={{
                  color: "#fff",
                  backgroundColor: "#262261",
                  marginRight: "6px",
                  fontSize: "13px",
                }}
              >
                {currentUser && currentUser?.userName
                  ? shortLabel(
                      decryptValue(currentUser.userName).replace(/_/g, " ")
                    )
                  : shortLabel(currentUser.role.replace(/_/g, " "))}
              </Avatar>
              <div className="user-detail">
                <Typography sx={{ fontSize: "12px" }}>
                  {currentUser && currentUser?.userName
                    ? capitalize(
                        decryptValue(currentUser.userName).replace(/_/g, " ")
                      )
                    : currentUser.role.replace(/_/g, " ")}
                </Typography>
                <Typography sx={{ fontSize: "12px", lineHeight: 1 }}>
                  {currentUser &&
                    currentUser?.role &&
                    capitalize(currentUser.role.replace(/_/g, " "))}
                </Typography>
              </div>
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <FaSignOutAlt />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
