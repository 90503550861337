import React, { useState } from 'react';
import { FeatureGroup, CircleMarker } from 'react-leaflet';
import { staticColorMap } from '../../constants/colors';
import { areaHighLightColor } from '../../constants/colors';
import Area1ObstacleInfoPopup from './Area1ObstacleInfoPopup';

export default function RenderArea1Obstacles({
  isShowPenetrationObstacle,
  area1Obstacles,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  let visibleArea1Obstacles = area1Obstacles;
  if (isAbovePermissibleClick) {
    visibleArea1Obstacles = visibleArea1Obstacles.filter((obstacle) => {
      return obstacle.height_in_m > 100;
    });
  } else if (isBelowPermissibleClick) {
    visibleArea1Obstacles = visibleArea1Obstacles.filter((obstacle) => {
      return obstacle.height_in_m < 100;
    });
  };

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <FeatureGroup pane="popupPane">
      {
        visibleArea1Obstacles.map((obstacles, i) => {
          if (!obstacles.latitude || !obstacles.longitude) {
            return null;
          };

          let obstacleColor = staticColorMap['area1Obstacle'];

          if (isShowPenetrationObstacle) {
            obstacleColor = obstacles.height_in_m > 100
              ? staticColorMap['RedObstacle']
              : staticColorMap['GreenObstacle'];
          };

          return (
            <CircleMarker
              key={i}
              center={{
                lat: Number(obstacles.latitude),
                lng: Number(obstacles.longitude)
              }}
              radius={6}
              pathOptions={{
                fillColor: obstacleColor,
                color: selectedOverE?.id === obstacles.id ? areaHighLightColor : obstacleColor,
                weight: selectedOverE?.id === obstacles.id ? 4 : 1,
                fillOpacity: 1,
              }}
              eventHandlers={{
                mouseover: () => handlePolygonOver(obstacles),
                mouseout: () => handlePolygonOut()
              }}
            >
              <Area1ObstacleInfoPopup info={obstacles} />
            </CircleMarker>
          )
        })
      }
    </FeatureGroup>
  );
};