import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';
import { aixmFeatureKeyMap } from '../../constants/aixmData';
import { serviceValueObject } from '../../constants/aximFeatureData';

export default function MeteorologicalInformationItem({
  isLoading,
  category,
  airportDetails,
  selctedAirport
}) {
  const meteorologicalInformation = airportDetails[category];

  const joinObjectValue = (object) => {
    return serviceValueObject
      .map((v) => {
        return object[v] ? object[v] : undefined;
      })
      .filter((f) => f !== null || f !== '' || f !== ' ' || f !== 'null' || f !== undefined)
      .join(' ');
  };

  return (
    <Fragment key={category}>
      {(isLoading || meteorologicalInformation.length === 0) && (
        <TableLoader isLoading={isLoading} data={meteorologicalInformation} />
      )}
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {'>'} {aixmFeatureKeyMap[category]}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Name of the associated meteorological office
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].name || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Hours of service and, where applicable, the designation of the responsible meteorological office outside these hours
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {joinObjectValue(meteorologicalInformation[0]['hours']) || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Office responsible for preparation of TAFs and periods of validity and interval of issuance of the forecasts
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].office || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Availability of the trend forecast for the aerodrome and interval of issuance
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].forecast || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Information on how briefing and/or consultation is provided
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].briefing || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Types of flight documentation supplied and language(s) used in flight documentation
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].flight_documentation || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Charts and other information displayed or available for briefing or consultation
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].charts || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Supplementary equipment available for providing information on meteorological conditions, e.g. weather radar and receiver for satellite images;
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].sup_met || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                The air traffic services unit(s) provided with meteorological information
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].air_traffic || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '50%' }}>
                Additional information, e.g. concerning any limitation of service.
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {meteorologicalInformation[0].remarks || 'NIL'}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
