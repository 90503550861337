import React from "react";
import { useNavigate } from "react-router-dom";
import AppBrandLogo from "../assets/images/brand-logo.png";

export default function BrandLogo() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div className="logo-brand" onClick={handleClick}>
      <img
        src={AppBrandLogo}
        alt="logo"
        className="satsure-img"
      />
    </div>
  );
};