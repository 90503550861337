import React from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { selecteChartTableData } from "../../models/electronicChart";
import StyledTableRow from "../tabel-component/StyledTableRow";

export default function ADCChartTable({ selectedEchart }) {
  const data = useSelector((state) =>
    selecteChartTableData(state, selectedEchart)
  );

  return (
    <TableContainer id="eChartTable">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 11 }}>Id</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Bearing Strength</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Direction</TableCell>
            <TableCell sx={{ fontSize: 11 }}>RWY</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Coordinates</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Elev</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="eChartTableBody">
          {data.map((row) => (
            <StyledTableRow
              key={row.id}
              id={`table-row-${row.id}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontSize: 11 }}>{row.id}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>
                {row.bearing_strength}
              </TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.direction}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.rwy}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.thr_coordinates}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.thr_elev}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
