import React from "react";
import Select from "react-select";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import BrandLogo from "./BrandLogo";
import DropDownLabel from "./DropDownLabel";

export default function AirspaceSideBar({
  isLoadingRestrictiveAirspaceGeometry,
  isLoadingControlAirspaceGeometry,
  isLoadingFIRAirspaceGeometry,
  isLoadingGetADIZGeoData,
  isLoadingRNAVRoutes,
  isLoadingCNAVRoutes,
  isLoadingAreaNavigationalRouteData,
  isLoadingConventionalRouteData,
  isLoadingGetAllNavaid,
  isLoadingGetAllsignificantPoint,
  pdrAirspaceOption,
  traTsaAirspaceOption,
  controlAirspaceOption,
  filterPdrAirspace,
  filterTraTsaAirspace,
  filterControlAirspace,
  filterFIRAirspace,
  filterADIZAirspace,
  FIRAirspaceOption,
  ADIZAirspaceOption,
  RNAVAirspaceOption,
  filterRNAVAirspace,
  CNAVAirspaceOption,
  filterCNAVAirspace,
  handlePdrAirspaceFilter,
  handletraTsaAirspaceFilter,
  handleControlAirspaceFilter,
  handleFIRAirspaceFilter,
  handleADIZAirspaceFilter,
  handleRNAVAirspaceFilter,
  handleCNAVAirspaceFilter,
  navaidOptions,
  filterNavidAirspace,
  handleNavidAirspaceFilter,
  significantPointOptions,
  filterWayPointsAirspace,
  handleWayPointAirspaceFilter,
  isLoadingWaypointRouteData,
}) {
  const slectAllName = [{ name: "All", designation: "All", designator: "All" }];

  return (
    <div className="sidebar">
      <BrandLogo />
      <div className="check-list airspace-check-list-5">
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="FIR / UIR" />
          <Select
            isClearable
            isMulti
            isLoading={isLoadingFIRAirspaceGeometry}
            isDisabled={isLoadingFIRAirspaceGeometry}
            value={filterFIRAirspace}
            placeholder="Select..."
            onChange={handleFIRAirspaceFilter}
            options={slectAllName.concat(FIRAirspaceOption)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="ADIZ" />
          <Select
            isClearable
            isMulti
            isLoading={isLoadingGetADIZGeoData}
            isDisabled={isLoadingGetADIZGeoData}
            value={filterADIZAirspace}
            placeholder="Select..."
            onChange={handleADIZAirspaceFilter}
            options={slectAllName.concat(ADIZAirspaceOption)}
            getOptionLabel={(option) => option.designation}
            getOptionValue={(option) => option.designation}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2">Restricted Airspace</Typography>
          <DropDownLabel
            label="Prohibited, Restricted and Danger Airspace"
            sx={{ fontSize: 12 }}
          />
          <Select
            isClearable
            isMulti
            isLoading={isLoadingRestrictiveAirspaceGeometry}
            isDisabled={isLoadingRestrictiveAirspaceGeometry}
            value={filterPdrAirspace}
            placeholder="Select..."
            onChange={handlePdrAirspaceFilter}
            options={slectAllName.concat(pdrAirspaceOption)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel
            label="Military Execrise and Training Airspace"
            sx={{ fontSize: 12 }}
          />
          <Select
            isClearable
            isMulti
            isLoading={isLoadingRestrictiveAirspaceGeometry}
            isDisabled={isLoadingRestrictiveAirspaceGeometry}
            value={filterTraTsaAirspace}
            placeholder="Select..."
            onChange={handletraTsaAirspaceFilter}
            options={slectAllName.concat(traTsaAirspaceOption)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="Controlled Airspace" />
          <Select
            isClearable
            isMulti
            isLoading={isLoadingControlAirspaceGeometry}
            isDisabled={isLoadingControlAirspaceGeometry}
            value={filterControlAirspace}
            placeholder="Select..."
            onChange={handleControlAirspaceFilter}
            options={slectAllName.concat(controlAirspaceOption)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="Conventional Routes" />
          <Select
            isClearable
            isLoading={isLoadingCNAVRoutes || isLoadingConventionalRouteData}
            isDisabled={isLoadingCNAVRoutes || isLoadingConventionalRouteData}
            value={filterCNAVAirspace}
            placeholder="Select..."
            onChange={handleCNAVAirspaceFilter}
            options={CNAVAirspaceOption}
            getOptionLabel={(option) =>
              `${option.designatorSecondLetter}${option.designatorNumber}${
                option.multipleIdentifier ? option.multipleIdentifier : ""
              }`
            }
            getOptionValue={(option) =>
              `${option.designatorSecondLetter}_${option.designatorNumber}_${
                option.multipleIdentifier ? option.multipleIdentifier : ""
              }`
            }
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="Area Navigational Routes" />
          <Select
            isClearable
            isLoading={
              isLoadingRNAVRoutes || isLoadingAreaNavigationalRouteData
            }
            isDisabled={
              isLoadingRNAVRoutes || isLoadingAreaNavigationalRouteData
            }
            value={filterRNAVAirspace}
            placeholder="Select..."
            onChange={handleRNAVAirspaceFilter}
            options={RNAVAirspaceOption}
            getOptionLabel={(option) =>
              `${option.designatorSecondLetter}${option.designatorNumber}${
                option.multipleIdentifier ? option.multipleIdentifier : ""
              }`
            }
            getOptionValue={(option) =>
              `${option.designatorSecondLetter}_${option.designatorNumber}_${
                option.multipleIdentifier ? option.multipleIdentifier : ""
              }`
            }
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="Navaids" />
          <Select
            isClearable
            isMulti
            isLoading={isLoadingGetAllNavaid}
            isDisabled={isLoadingGetAllNavaid}
            value={filterNavidAirspace}
            placeholder="Select..."
            onChange={handleNavidAirspaceFilter}
            options={slectAllName.concat(navaidOptions)}
            getOptionLabel={(option) => option.designator}
            getOptionValue={(option) => option.designator}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="Reporting Points" />
          <Select
            isClearable
            isLoading={
              isLoadingGetAllsignificantPoint || isLoadingWaypointRouteData
            }
            isDisabled={
              isLoadingGetAllsignificantPoint || isLoadingWaypointRouteData
            }
            value={filterWayPointsAirspace}
            placeholder="Select..."
            onChange={handleWayPointAirspaceFilter}
            options={slectAllName.concat(significantPointOptions)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
          />
        </Box>
        <Divider />
      </div>
    </div>
  );
}
