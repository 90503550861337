import { upperFirst } from "lodash";

export const getEtodErea = (info) => {
  if (info && info.rwy_area) {
    return info.rwy_area.slice(-2);
  }

  return "-";
};

export const shortLabel = (string) => {
  const label = string
    .split(" ")
    .map((word) => upperFirst(word[0]))
    .join("");

  return label;
};
