import React from "react";

export const GridIcon = (props) => (
  <svg
    width={52}
    height={52}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.178 4.3309C8.30415 4.12147 6.36282 4.12147 4.489 4.3309C4.45419 4.33479 4.41977 4.35054 4.39147 4.37844C4.36383 4.40568 4.35304 4.43323 4.35031 4.45661C4.12672 6.36827 4.12672 8.29947 4.35031 10.2111C4.35304 10.2345 4.36383 10.262 4.39147 10.2893C4.41977 10.3172 4.45419 10.3329 4.489 10.3368C6.36282 10.5463 8.30415 10.5463 10.178 10.3368C10.2128 10.3329 10.2472 10.3172 10.2755 10.2893C10.3031 10.262 10.3139 10.2345 10.3167 10.2111C10.5403 8.29947 10.5403 6.36826 10.3167 4.45661C10.3139 4.43323 10.3031 4.40568 10.2755 4.37844C10.2472 4.35054 10.2128 4.33479 10.178 4.3309ZM4.04471 0.355649C6.21381 0.113221 8.45317 0.113221 10.6223 0.355649C12.5218 0.567951 14.0639 2.06244 14.2896 3.99194C14.5493 6.21232 14.5493 8.45541 14.2896 10.6758C14.0639 12.6053 12.5218 14.0998 10.6223 14.3121C8.45316 14.5545 6.21381 14.5545 4.04471 14.3121C2.14517 14.0998 0.60306 12.6053 0.377388 10.6758C0.117694 8.45541 0.117694 6.21232 0.377388 3.99194C0.60306 2.06244 2.14517 0.567951 4.04471 0.355649Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8446 4.3309C26.9708 4.12147 25.0295 4.12147 23.1557 4.3309C23.1209 4.33479 23.0864 4.35054 23.0581 4.37844C23.0305 4.40568 23.0197 4.43323 23.017 4.45661C22.7934 6.36827 22.7934 8.29947 23.017 10.2111C23.0197 10.2345 23.0305 10.262 23.0581 10.2893C23.0864 10.3172 23.1209 10.3329 23.1557 10.3368C25.0295 10.5463 26.9708 10.5463 28.8446 10.3368C28.8794 10.3329 28.9139 10.3172 28.9422 10.2893C28.9698 10.262 28.9806 10.2345 28.9833 10.2111C29.2069 8.29947 29.2069 6.36826 28.9833 4.45661C28.9806 4.43323 28.9698 4.40568 28.9422 4.37844C28.9139 4.35054 28.8794 4.33479 28.8446 4.3309ZM22.7114 0.355649C24.8805 0.113221 27.1198 0.113221 29.2889 0.355649C31.1885 0.567951 32.7306 2.06244 32.9563 3.99194C33.2159 6.21232 33.2159 8.45541 32.9563 10.6758C32.7306 12.6053 31.1885 14.0998 29.2889 14.3121C27.1198 14.5545 24.8805 14.5545 22.7114 14.3121C20.8118 14.0998 19.2697 12.6053 19.0441 10.6758C18.7844 8.45541 18.7844 6.21232 19.0441 3.99194C19.2697 2.06244 20.8118 0.567951 22.7114 0.355649Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5113 4.3309C45.6375 4.12147 43.6962 4.12147 41.8223 4.3309C41.7875 4.33479 41.7531 4.35054 41.7248 4.37844C41.6972 4.40568 41.6864 4.43323 41.6836 4.45661C41.4601 6.36827 41.4601 8.29947 41.6836 10.2111C41.6864 10.2345 41.6972 10.262 41.7248 10.2893C41.7531 10.3172 41.7875 10.3329 41.8223 10.3368C43.6962 10.5463 45.6375 10.5463 47.5113 10.3368C47.5461 10.3329 47.5805 10.3172 47.6088 10.2893C47.6365 10.262 47.6473 10.2345 47.65 10.2111C47.8736 8.29947 47.8736 6.36826 47.65 4.45661C47.6473 4.43323 47.6365 4.40568 47.6088 4.37844C47.5805 4.35054 47.5461 4.33479 47.5113 4.3309ZM41.378 0.355649C43.5471 0.113221 45.7865 0.113221 47.9556 0.355649C49.8551 0.567951 51.3972 2.06244 51.6229 3.99194C51.8826 6.21232 51.8826 8.45541 51.6229 10.6758C51.3972 12.6053 49.8551 14.0998 47.9556 14.3121C45.7865 14.5545 43.5471 14.5545 41.378 14.3121C39.4785 14.0998 37.9364 12.6053 37.7107 10.6758C37.451 8.45541 37.451 6.21232 37.7107 3.99194C37.9364 2.06244 39.4785 0.567951 41.378 0.355649Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.178 22.9976C8.30415 22.7881 6.36282 22.7881 4.489 22.9976C4.45419 23.0015 4.41977 23.0172 4.39147 23.0451C4.36383 23.0724 4.35304 23.0999 4.35031 23.1233C4.12672 25.0349 4.12672 26.9661 4.35031 28.8778C4.35304 28.9012 4.36383 28.9287 4.39147 28.956C4.41977 28.9839 4.45419 28.9996 4.489 29.0035C6.36282 29.2129 8.30415 29.2129 10.178 29.0035C10.2128 28.9996 10.2472 28.9839 10.2755 28.956C10.3031 28.9287 10.3139 28.9012 10.3167 28.8778C10.5403 26.9661 10.5403 25.0349 10.3167 23.1233C10.3139 23.0999 10.3031 23.0724 10.2755 23.0451C10.2472 23.0172 10.2128 23.0015 10.178 22.9976ZM4.04471 19.0223C6.21381 18.7799 8.45317 18.7799 10.6223 19.0223C12.5218 19.2346 14.0639 20.7291 14.2896 22.6586C14.5493 24.879 14.5493 27.1221 14.2896 29.3425C14.0639 31.272 12.5218 32.7664 10.6223 32.9788C8.45316 33.2212 6.21381 33.2212 4.04471 32.9788C2.14517 32.7664 0.60306 31.272 0.377388 29.3425C0.117694 27.1221 0.117694 24.879 0.377388 22.6586C0.60306 20.7291 2.14517 19.2346 4.04471 19.0223Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8446 22.9976C26.9708 22.7881 25.0295 22.7881 23.1557 22.9976C23.1209 23.0015 23.0864 23.0172 23.0581 23.0451C23.0305 23.0724 23.0197 23.0999 23.017 23.1233C22.7934 25.0349 22.7934 26.9661 23.017 28.8778C23.0197 28.9012 23.0305 28.9287 23.0581 28.956C23.0864 28.9839 23.1209 28.9996 23.1557 29.0035C25.0295 29.2129 26.9708 29.2129 28.8446 29.0035C28.8794 28.9996 28.9139 28.9839 28.9422 28.956C28.9698 28.9287 28.9806 28.9012 28.9833 28.8778C29.2069 26.9661 29.2069 25.0349 28.9833 23.1233C28.9806 23.0999 28.9698 23.0724 28.9422 23.0451C28.9139 23.0172 28.8794 23.0015 28.8446 22.9976ZM22.7114 19.0223C24.8805 18.7799 27.1198 18.7799 29.2889 19.0223C31.1885 19.2346 32.7306 20.7291 32.9563 22.6586C33.2159 24.879 33.2159 27.1221 32.9563 29.3425C32.7306 31.272 31.1885 32.7664 29.2889 32.9788C27.1198 33.2212 24.8805 33.2212 22.7114 32.9788C20.8118 32.7664 19.2697 31.272 19.0441 29.3425C18.7844 27.1221 18.7844 24.879 19.0441 22.6586C19.2697 20.7291 20.8118 19.2346 22.7114 19.0223Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5113 22.9976C45.6375 22.7881 43.6962 22.7881 41.8223 22.9976C41.7875 23.0015 41.7531 23.0172 41.7248 23.0451C41.6972 23.0724 41.6864 23.0999 41.6836 23.1233C41.4601 25.0349 41.4601 26.9661 41.6836 28.8778C41.6864 28.9012 41.6972 28.9287 41.7248 28.956C41.7531 28.9839 41.7875 28.9996 41.8223 29.0035C43.6962 29.2129 45.6375 29.2129 47.5113 29.0035C47.5461 28.9996 47.5805 28.9839 47.6088 28.956C47.6365 28.9287 47.6473 28.9012 47.65 28.8778C47.8736 26.9661 47.8736 25.0349 47.65 23.1233C47.6473 23.0999 47.6365 23.0724 47.6088 23.0451C47.5805 23.0172 47.5461 23.0015 47.5113 22.9976ZM41.378 19.0223C43.5471 18.7799 45.7865 18.7799 47.9556 19.0223C49.8551 19.2346 51.3972 20.7291 51.6229 22.6586C51.8826 24.879 51.8826 27.1221 51.6229 29.3425C51.3972 31.272 49.8551 32.7664 47.9556 32.9788C45.7865 33.2212 43.5471 33.2212 41.378 32.9788C39.4785 32.7664 37.9364 31.272 37.7107 29.3425C37.451 27.1221 37.451 24.879 37.7107 22.6586C37.9364 20.7291 39.4785 19.2346 41.378 19.0223Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.178 41.6642C8.30415 41.4548 6.36282 41.4548 4.489 41.6642C4.45419 41.6681 4.41977 41.6839 4.39147 41.7118C4.36383 41.739 4.35304 41.7666 4.35031 41.7899C4.12672 43.7016 4.12672 45.6328 4.35031 47.5445C4.35304 47.5678 4.36383 47.5954 4.39147 47.6226C4.41977 47.6505 4.45419 47.6663 4.489 47.6702C6.36282 47.8796 8.30415 47.8796 10.178 47.6702C10.2128 47.6663 10.2472 47.6505 10.2755 47.6226C10.3031 47.5954 10.3139 47.5678 10.3167 47.5445C10.5403 45.6328 10.5403 43.7016 10.3167 41.7899C10.3139 41.7666 10.3031 41.739 10.2755 41.7118C10.2472 41.6839 10.2128 41.6681 10.178 41.6642ZM4.04471 37.689C6.21381 37.4466 8.45317 37.4466 10.6223 37.689C12.5218 37.9013 14.0639 39.3958 14.2896 41.3253C14.5493 43.5457 14.5493 45.7887 14.2896 48.0091C14.0639 49.9386 12.5218 51.4331 10.6223 51.6454C8.45316 51.8878 6.21381 51.8878 4.04471 51.6454C2.14517 51.4331 0.60306 49.9386 0.377388 48.0091C0.117694 45.7887 0.117694 43.5457 0.377388 41.3253C0.60306 39.3958 2.14517 37.9013 4.04471 37.689Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8446 41.6642C26.9708 41.4548 25.0295 41.4548 23.1557 41.6642C23.1209 41.6681 23.0864 41.6839 23.0581 41.7118C23.0305 41.739 23.0197 41.7666 23.017 41.7899C22.7934 43.7016 22.7934 45.6328 23.017 47.5445C23.0197 47.5678 23.0305 47.5954 23.0581 47.6226C23.0864 47.6505 23.1209 47.6663 23.1557 47.6702C25.0295 47.8796 26.9708 47.8796 28.8446 47.6702C28.8794 47.6663 28.9139 47.6505 28.9422 47.6226C28.9698 47.5954 28.9806 47.5678 28.9833 47.5445C29.2069 45.6328 29.2069 43.7016 28.9833 41.7899C28.9806 41.7666 28.9698 41.739 28.9422 41.7118C28.9139 41.6839 28.8794 41.6681 28.8446 41.6642ZM22.7114 37.689C24.8805 37.4466 27.1198 37.4466 29.2889 37.689C31.1885 37.9013 32.7306 39.3958 32.9563 41.3253C33.2159 43.5457 33.2159 45.7887 32.9563 48.0091C32.7306 49.9386 31.1885 51.4331 29.2889 51.6454C27.1198 51.8878 24.8805 51.8878 22.7114 51.6454C20.8118 51.4331 19.2697 49.9386 19.0441 48.0091C18.7844 45.7887 18.7844 43.5457 19.0441 41.3253C19.2697 39.3958 20.8118 37.9013 22.7114 37.689Z"
      fill="#F2F3F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5113 41.6642C45.6375 41.4548 43.6962 41.4548 41.8223 41.6642C41.7875 41.6681 41.7531 41.6839 41.7248 41.7118C41.6972 41.739 41.6864 41.7666 41.6836 41.7899C41.4601 43.7016 41.4601 45.6328 41.6836 47.5445C41.6864 47.5678 41.6972 47.5954 41.7248 47.6226C41.7531 47.6505 41.7875 47.6663 41.8223 47.6702C43.6962 47.8796 45.6375 47.8796 47.5113 47.6702C47.5461 47.6663 47.5805 47.6505 47.6088 47.6226C47.6365 47.5954 47.6473 47.5678 47.65 47.5445C47.8736 45.6328 47.8736 43.7016 47.65 41.7899C47.6473 41.7666 47.6365 41.739 47.6088 41.7118C47.5805 41.6839 47.5461 41.6681 47.5113 41.6642ZM41.378 37.689C43.5471 37.4466 45.7865 37.4466 47.9556 37.689C49.8551 37.9013 51.3972 39.3958 51.6229 41.3253C51.8826 43.5457 51.8826 45.7887 51.6229 48.0091C51.3972 49.9386 49.8551 51.4331 47.9556 51.6454C45.7865 51.8878 43.5471 51.8878 41.378 51.6454C39.4785 51.4331 37.9364 49.9386 37.7107 48.0091C37.451 45.7887 37.451 43.5457 37.7107 41.3253C37.9364 39.3958 39.4785 37.9013 41.378 37.689Z"
      fill="#F2F3F6"
    />
  </svg>
);
