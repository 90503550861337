import { Navigate, useLocation } from "react-router-dom";
import { getStorageValue } from "../utils/localStorage";
import { ACCESS_TOKEN } from "../constants/constant";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const accessToken = getStorageValue(ACCESS_TOKEN);

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
