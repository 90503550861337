import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { remove, findIndex } from "lodash";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ACCESS_TOKEN } from "../constants/constant";
import Header from "../components/Header";
import AixmMap from "../components/AixmOSMMap";
import AirportReviewSideBar from "../components/AirportReviewSideBar";
import AixmAirportDetailTables from "../components/aixm-table-item/AixmAirportDetailTables";
import {
  getObstaclesData,
  getAirportDetails,
  getRunWayDetailData,
  selectAIXMCategoryOptions,
} from "../models/aixm";
import { getAllNewAirports } from "../models/airport";
import { theme } from "../styles/theme";
import { getAsyncStorageValue } from "../utils/localStorage";

export default function AirportReview() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const isLoadingAllAirports = useSelector(
    (state) => state.airport.isLoadingAllAirports
  );
  const isLoadingAirportDetail = useSelector(
    (state) => state.aixm.isLoadingAirportDetail
  );
  const isLoadingRunwayDetails = useSelector(
    (state) => state.aixm.isLoadingRunwayDetails
  );
  const isLoadingAixmObstacles = useSelector(
    (state) => state.aixm.isLoadingAixmObstacles
  );
  const allNewAirports = useSelector((state) => state.airport.allAirports);
  const airportDetails = useSelector(
    (state) => state.aixm.airportDetails || {}
  );
  const runwayDetails = useSelector((state) => state.aixm.runwayDetails || {});
  const obstaclesAreaList = useSelector(
    (state) => state.aixm.obstaclesAreaList
  );
  const categories = useSelector(selectAIXMCategoryOptions);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tabValue, setTabValue] = useState(null);
  const [selctedAirport, setSelctedAirport] = useState(null);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllNewAirports(token));
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    const selctedAirport = allNewAirports.find(
      (airport) => airport.id === Number(routeParams.airportId)
    );

    if (selctedAirport) {
      dispatch(
        getAirportDetails(selctedAirport.id, selctedAirport.version_id)
      );
      dispatch(
        getRunWayDetailData(selctedAirport.id, selctedAirport.version_id)
      );
      dispatch(
        getObstaclesData(selctedAirport.id, selctedAirport.version_id)
      );
    }

    setSelctedAirport(selctedAirport);
  }, [allNewAirports]); // eslint-disable-line

  useEffect(() => {
    if (!tabValue && selectedCategories.length > 0) {
      setTabValue(selectedCategories[0].value);
    }

    if (selectedCategories.length === 0) {
      setTabValue(null);
    }
  }, [selectedCategories]); // eslint-disable-line

  const handleCategoryFilter = (option) => {
    setSelectedCategories(option);
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseTab = (category) => {
    const deleteIndex = findIndex(selectedCategories, category);

    remove(selectedCategories, (c) => {
      return c.value === category.value;
    });

    const selectedTabIndex = findIndex(
      selectedCategories,
      (c) => c.value === tabValue
    );

    setTabValue(null);
    if (selectedTabIndex <= -1) {
      if (selectedCategories.length === 1) {
        setTabValue(selectedCategories[0].value);
      } else if (selectedCategories.length === 2) {
        if (deleteIndex > 0) {
          setTabValue(selectedCategories[deleteIndex - 1].value);
        } else if (deleteIndex === 0) {
          setTabValue(selectedCategories[0].value);
        }
      } else if (selectedCategories.length > 2) {
        if (deleteIndex > 0) {
          setTabValue(selectedCategories[deleteIndex - 1].value);
        } else if (deleteIndex === 0) {
          setTabValue(selectedCategories[0].value);
        }
      }
    } else {
      setTimeout(() => {
        setTabValue(tabValue);
      }, 500);
    }

    setSelectedCategories([...selectedCategories]);
  };

  return (
    <div className="wrapper">
      <AirportReviewSideBar
        isLoadingAirportDetail={isLoadingAirportDetail}
        isLoadingAllAirports={isLoadingAllAirports}
        selctedAirport={selctedAirport}
        categories={categories}
        selectedCategories={selectedCategories}
        handleCategoryFilter={handleCategoryFilter}
      />
      <div className="main">
        <Header active="data-management" />
        <Stack
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          sx={{
            backgroundColor: theme.palette.secondary.light,
            zIndex: 6,
            pt: 1,
            pb: 1,
            pr: 2,
          }}
        >
          <Box>
            <Typography variant="subtitle2">Publication Date</Typography>
            <Typography variant="body2">
              {selctedAirport?.published_date}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Effective Date</Typography>
            <Typography variant="body2">
              {selctedAirport?.effective_date}
            </Typography>
          </Box>
        </Stack>
        <div className="content-area">
          {selctedAirport && selectedCategories.length === 0 && (
            <AixmMap
              airportLocation={{
                lat: selctedAirport?.dd_latitude,
                lng: selctedAirport?.dd_longitude,
              }}
              zoomLevel={15}
              selctedAirport={selctedAirport}
            />
          )}
          {selectedCategories?.length > 0 && (
            <div className="aixm-category-table">
              {tabValue && (
                <AixmAirportDetailTables
                  isActiveDataManagement
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  handleCloseTab={handleCloseTab}
                  selectedCategories={selectedCategories}
                  isLoadingAirportDetail={isLoadingAirportDetail}
                  selctedAirport={selctedAirport}
                  airportDetails={airportDetails}
                  runwayDetails={runwayDetails}
                  isLoadingRunwayDetails={isLoadingRunwayDetails}
                  isLoadingAixmObstacles={isLoadingAixmObstacles}
                  obstaclesAreaList={obstaclesAreaList}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
