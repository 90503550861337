import React, { Fragment, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { flatten, chain } from 'lodash';
import { areaHighLightColor } from '../../constants/colors';

export default function RenderOLSRwyStrip({
  OLSRwyStripList,
  selectedOls,
  getOLSColors
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSRwyStripList =
    chain(OLSRwyStripList)
      .filter((rwy) => {
        const name = `RWY STRIP_${rwy.rwy_designator}`;
        return selectedOls.includes(`${name}__${olsColor[name]}`);
      })
      .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
   <Fragment>
     {
        visibleOLSRwyStripList.map((olsRsl) => {
          const rslCoordinates = olsRsl.coordinates.coordinates;
          const name = `RWY STRIP_${olsRsl.rwy_designator}`;
          const rslCoordinateMap = flatten(rslCoordinates).map((rslCoords) => {
            return rslCoords.map((rsl) => {
              return {
                lng: rsl[0],
                lat: rsl[1]
              };
            });
          });

          return rslCoordinateMap.map((rslPolygonPath, i) => (
            <Polygon
              key={i}
              pane="tilePane"
              positions={rslPolygonPath}
              pathOptions={{
                fillColor: olsColor[name],
                color: selectedOverE?.rwy_designator === olsRsl.rwy_designator ? areaHighLightColor : olsColor[name],
                weight: selectedOverE?.rwy_designator === olsRsl.rwy_designator ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, olsRsl),
                mouseout: () => handlePolygonOut()
              }}
            />
          ));
        })
     }
   </Fragment> 
  )
}