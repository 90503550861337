import { decode } from "js-base64";
import crypto from "crypto";
import keypair from "keypair";
import { JSEncrypt } from "jsencrypt";
import { toast } from "react-toastify";
import { AES_KEY_URL } from "../constants/urls";
import { getStorageValue, setStorageValue } from "../utils/localStorage";

import { AES_KEY } from "../constants/constant";
const CryptoJS = require("crypto-js");
const header = {
  "Content-Type": "application/json",
  accept: "application/json",
  "access-control-allow-credentials": true,
};

export async function decryptRsa(token) {
  const pair = keypair();
  const publicKey = {
    key: encodeURIComponent(pair.public),
  };

  header["Authorization"] = token;

  const formData = {
    method: "POST",
    headers: header,
    body: JSON.stringify(publicKey),
  };

  return await fetch(AES_KEY_URL, formData, 500)
    .then((response) => {
      return response
        .json()
        .then((responseJson) => {
          const dataEnc = responseJson.data;
          const decrypt = new JSEncrypt();
          decrypt.setPrivateKey(pair.private);
          const plainText = decrypt.decrypt(dataEnc) || "DECRYPTION FAILED";
          setStorageValue(AES_KEY, plainText);
          return plainText;
        })
        .catch((error) => {
          toast.error(error.message);
        });
    })
    .catch((error) => {
      toast.error(error.message);
    });
}

export function decryptStatic(bs4Text, plainText) {
  const ciphertext = CryptoJS.enc.Base64.parse(bs4Text);

  // split iv and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  const key = CryptoJS.enc.Utf8.parse(plainText);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export async function decrypt(bs4Text, plainText) {
  const ciphertext = CryptoJS.enc.Base64.parse(bs4Text);

  // split iv and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  const key = CryptoJS.enc.Utf8.parse(plainText);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
  });

  return await decrypted.toString(CryptoJS.enc.Utf8);
}

export function encryptStatic(text, plainText) {
  const key = CryptoJS.enc.Utf8.parse(plainText);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
  });

  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export async function encrypt(text, plainText) {
  const key = CryptoJS.enc.Utf8.parse(plainText);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
  });

  return await iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export const rsaEnc = (data, key) => {
  const publicKeyValue = decode(key);
  const encryptedData = crypto.publicEncrypt(
    {
      key: publicKeyValue,
      padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
      oaepHash: "sha256",
    },
    // We convert the data string to a buffer using `Buffer.from`
    Buffer.from(data)
  );

  return encryptedData.toString("base64");
};

export const decryptValue = (string) => {
  const aesKey = getStorageValue(AES_KEY);
  return decryptStatic(string, aesKey);
};
