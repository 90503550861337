import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import StyledTableCell from "../../components/tabel-component/StyledTableCell";

export default function ADIZDetail({ isLoadingGetAllTRA_TSA_ADIZ, ADIZData }) {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {isLoadingGetAllTRA_TSA_ADIZ && (
            <TableRow>
              <TableCell align="center">Loading...</TableCell>
            </TableRow>
          )}
          {!isLoadingGetAllTRA_TSA_ADIZ && !ADIZData && (
            <TableRow>
              <TableCell align="center">No Record Found.</TableCell>
            </TableRow>
          )}
          {ADIZData && (
            <Fragment>
              <TableRow>
                <StyledTableCell>
                  <Box sx={{ typography: "subtitle2" }}>
                    {ADIZData?.designator || "-"}
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {ADIZData?.description || "-"}
                </StyledTableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
