import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import HeaderTableCell from "../tabel-component/HeaderTableCell";
import StyledTableCell from "../tabel-component/StyledTableCell";
import TableLoader from "../tabel-component/TableLoader";
import { aixmFeatureKeyMap } from "../../constants/aixmData";
import { serviceValueObject } from "../../constants/aximFeatureData";

export default function OperatorList({
  isLoading,
  category,
  airportDetails,
  selctedAirport,
}) {
  const operatorList = airportDetails[category];
  const keys = Object.keys(operatorList);

  const joinValue = (object) => {
    return serviceValueObject
      .map((v) => {
        return object && object[v] ? object[v] : undefined;
      })
      .filter(
        (f) =>
          f !== null || f !== "" || f !== " " || f !== "null" || f !== undefined
      )
      .join(" ");
  };

  return (
    <Fragment key={category}>
      {(isLoading || keys.length === 0) && (
        <TableLoader isLoading={isLoading} data={keys} />
      )}
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {aixmFeatureKeyMap[category]}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                Aerodrome Operator
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Aerodrome_Operator"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                Custom and immigration
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Custom_and_immigration"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                Health and sanitation
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Health_and_sanitation"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                AIS briefing office
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["BOF_briefing_office"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                ATS reporting office (ARO)
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["ARO_Briefing_office"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                MET Briefing office
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["MET_Briefing_office"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>
                Air Traffic Service
              </StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Air_Traffic_Service"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>Fuelling</StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Fuel"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>Handling</StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Handling"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>Security</StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["Security"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>De-icing</StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>{"NIL"}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: "20%" }}>Remarks</StyledTableCell>
              <StyledTableCell sx={{ width: "80%" }}>
                {joinValue(operatorList["OPERATIONAL_HOURS_REMARK"]) || "NIL"}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
