import React, { Fragment } from "react";
import { Popup } from "react-leaflet";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PopupBox from "../map/PopupBox";
import PopupItem from "../map/PopupItem";

export default function RouteInfoPopup({ info, type }) {
  return (
    <Popup>
      <PopupBox>
        <PopupItem>Name:</PopupItem>
        <PopupItem>
          {info?.designatorSecondLetter}
          {info.designatorNumber}
        </PopupItem>
        {type === "both" && (
          <Fragment>
            <PopupItem>Start Point:</PopupItem>
            <PopupItem>{info?.startpoint_name}</PopupItem>
            <PopupItem>End Point:</PopupItem>
            <PopupItem>{info?.endpoint_name}</PopupItem>
          </Fragment>
        )}
        {type === "start" && (
          <Fragment>
            <PopupItem>Point Name:</PopupItem>
            <PopupItem>{info?.startpoint_name}</PopupItem>
          </Fragment>
        )}
        {type === "end" && (
          <Fragment>
            <PopupItem>Point Name:</PopupItem>
            <PopupItem>{info?.endpoint_name}</PopupItem>
          </Fragment>
        )}
      </PopupBox>
      <Box>
        <Typography variant="caption" display="block">
          click the feature to show more detail
        </Typography>
      </Box>
    </Popup>
  );
}
