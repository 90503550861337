import axios from "axios";
import { toast } from "react-toastify";

const headers = {
  "Content-Type": "application/json",
  accept: "application/json",
  "Access-Control-Allow-Headers": "Content-Type",
  "access-control-allow-credentials": true,
  "Access-Control-Allow-Methods": "POST, GET, DELETE",
  "Access-Control-Request-Method": "POST, GET, DELETE",
  "Access-Control-Request-Headers": "POST, GET, DELETE",
};

export const Timeout = (time) => {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), time * 2000);
  return controller;
};

export function apiCall(
  requestMethod,
  url,
  body,
  onSuccess,
  onFailure,
  accessToken = null
) {
  if (accessToken !== null) {
    headers["Authorization"] = accessToken;
  }

  let formData = {
    method: requestMethod,
    headers: headers,
    signal: Timeout(100).signal,
  };
  let formBody = JSON.stringify(body);

  if (body !== undefined && body !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData, 500)
    .then((response) => {
      response
        .json()
        .then((responseJson) => {
          if (responseJson.status) {
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((error) => {
          onFailure(error);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

export async function refreshAccessToken(requestMethod, url, refreshToken) {
  const formData = {
    method: requestMethod,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: refreshToken,
    },
  };

  return await fetch(url, formData, 500)
    .then((response) => {
      return response
        .json()
        .then((responseJson) => {
          return responseJson;
        })
        .catch(() => {
          toast.error("Refresh token expired");
          return null;
        });
    })
    .catch(() => {
      toast.error("Refresh token expired");
      return null;
    });
}

export function downloadReadableStreamFile(
  requestMethod,
  url,
  body,
  onSuccess,
  onFailure,
  accessToken = null
) {
  if (accessToken !== null) {
    headers["Authorization"] = accessToken;
  }

  const formData = {
    method: requestMethod,
    headers: headers,
    signal: Timeout(100).signal,
  };
  let formBody = JSON.stringify(body);

  if (body !== undefined && body !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData, 500)
    .then((response) => {
      let filename = response.headers.get("content-disposition");
      filename = filename ? filename.split("=")[1] : "";
      filename = filename ? filename : "";

      response
        .blob()
        .then((blob) => {
          onSuccess(blob, filename);
        })
        .catch((error) => {
          onFailure(error);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

export function fileUploadApicall(
  url,
  file,
  onSuccess,
  onFailure,
  accessToken = null
) {
  const formData = new FormData();
  formData.append("file", file, file.name);

  if (accessToken !== null) {
    headers["Authorization"] = accessToken;
  }

  try {
    axios
      .post(url, formData, { headers })
      .then((response) => {
        const responseJson = response.data;
        if (responseJson.status) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      })
      .catch((error) => {
        if (error.response) {
          onFailure(error.response.data.data);
        } else {
          onFailure(error);
        }
      });
  } catch (error) {
    onFailure(error);
  }
}
