import React, { Fragment } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { dataChangedRowColorMap } from "../../constants/colors";
import StyledTableCell from "../tabel-component/StyledTableCell";
import DiffTableCell from "../tabel-component/DiffTableCell";
import ValueItem from "../tabel-component/ValueItem";
import TopTableHeaderRow from "./TopTableHeaderRow";
import TableSheetHeaderRow from "./TableSheetHeaderRow";
import TableFooterRow from "./TableFooterRow";

export default function VerticalSignificanceTable({
  rows,
  selctedAirport,
  selctedCategory,
}) {
  return (
    <Fragment>
      <TopTableHeaderRow />
      <TableSheetHeaderRow
        selctedAirport={selctedAirport}
        selctedCategory={selctedCategory}
      />
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                UID
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "2vw" }}>
                <Box>LATITUDE</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <Box sx={{ typography: "caption" }}>NEW</Box>
                  <Box sx={{ typography: "caption" }}>OLD</Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "2vw" }}>
                <Box>LONGITUDE</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <Box sx={{ typography: "caption" }}>NEW</Box>
                  <Box sx={{ typography: "caption" }}>OLD</Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                OBJECT NAME
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>TOP ELEV. IN m. (EGM 08)</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                    <Box sx={{ typography: "caption" }}>DIFF</Box>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>DISTANCE IN M. FROM NEW ARP</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                    <Box sx={{ typography: "caption" }}>DIFF</Box>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>MAG. BRG.FROM NEW ARP</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box sx={{ typography: "caption" }}>NEW</Box>
                    <Box sx={{ typography: "caption" }}>OLD</Box>
                    <Box sx={{ typography: "caption" }}>DIFF</Box>
                  </Stack>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  background: dataChangedRowColorMap[row.result] ?? "#FFFFFF",
                }}
              >
                <DiffTableCell>{row["uid1"]}</DiffTableCell>
                <DiffTableCell isdiff={row["latdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["latitude1"]} />
                    <ValueItem value={row["latitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell isdiff={row["longdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["longitude1"]} />
                    <ValueItem value={row["longitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell>{row["object1"] || "-"}</DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["top_elevdiff"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["top_elev1"]} />
                    <ValueItem value={row["top_elev2"]} />
                    <ValueItem value={row["top_elevdiff"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell isdiff={Boolean(row["distancediff"])}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["distance1"]} />
                    <ValueItem value={row["distance2"]} />
                    <ValueItem value={row["distancediff"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell isdiff={Boolean(row["mag_brgdiff"])}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["mag_brg1"]} />
                    <ValueItem value={row["mag_brg2"]} />
                    <ValueItem value={row["mag_brgdiff"]} />
                  </Stack>
                </DiffTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterRow
              colspan={7}
              selctedAirport={selctedAirport}
              selctedCategory={selctedCategory}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
