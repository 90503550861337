import {
  CompulsoryIcon,
  OnRequestIcon,
  FlyByCompulsoryIcon,
  FlyByOnrequestIcon
} from './mapIcon';

export const getRouteWayPointIcon = (name) => {
  let icon = CompulsoryIcon;

  switch (name) {
    case 'p':
      icon = CompulsoryIcon;
      break;
    case 'r':
      icon = OnRequestIcon;
      break;
    case 'c':
      icon = FlyByCompulsoryIcon;
      break;
    case 'h':
      icon = FlyByOnrequestIcon;
      break;
    default:
      icon = CompulsoryIcon;
      break;
  };

  return icon;
};