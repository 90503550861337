import SvgIcon from '@mui/material/SvgIcon';

export default function AerodromeReferencePointIcon(props) {
  return (
    <SvgIcon sx={{ width: 50, height: 50 }} {...props} >
      <svg fill="none">
        <path d="M3 12.2348H21" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M12.0783 3L12.0783 21" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        <circle cx="12.2348" cy="12.2348" r="4.72826" stroke="black" strokeWidth="1.5" />
      </svg>
    </SvgIcon>
  )
};