import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "../public-screen/NotFound";
import Home from "../containers/Home";
import Aixm from "../containers/Aixm";
import AirportReview from "../containers/AirportReview";
import UserDashboard from "../containers/dataManagement/UserDashboard";
import SurveyorReportList from "../containers/dataManagement/SurveyorReportList";
import ReviewSurveyData from "../containers/dataManagement/ReviewSurveyData";
import ManageUsers from "../containers/dataManagement/ManageUsers";
import NewPasswordRequest from "../containers/dataManagement/NewPasswordRequest";
import AirportReviewList from "../containers/dataManagement/AirportReviewList";
import AddAirportData from "../containers/dataManagement/AddAirportData";
import AD2DataEntry from "../containers/dataManagement/AD2DataEntry";
import Amdb from "../containers/Amdb";
import Etod from "../containers/Etod";
import Airspace from "../containers/Airspace";
import ElecronicChart from "../containers/ElecronicChart";
import { feVersion } from "../constants/constant";
import AeroHome from "../containers/AeroHome";
import Login from "../containers/Login";
import CRCCheck from "../containers/CRCCheck";
import PrivateRoute from "./PrivateRoute";

const WebRoutes = () => {
  const isAEROversion = process.env.REACT_APP_FE_VERSION === feVersion.aero;

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/"
          element={isAEROversion ? <AeroHome /> : <Home />}
        />
        <Route exact path="/data-management/crc-check" element={<CRCCheck />} />
        <Route exact path="/amdb" element={<Amdb />} />
        <Route exact path="/etod" element={<Etod />} />
        {isAEROversion ? (
          <Route
            exact
            path="/airspace"
            element={
              <PrivateRoute>
                <Airspace />
              </PrivateRoute>
            }
          />
        ) : (
          <Route exact path="/airspace" element={<Airspace />} />
        )}
        <Route exact path="/electronic-chart" element={<ElecronicChart />} />
        <Route exact path="/aixm" element={<Aixm />} />
        <Route
          exact
          path="/data-management"
          element={
            <PrivateRoute>
              <UserDashboard />
            </PrivateRoute>
          }
        >
          <Route
            exact
            path="manage-users"
            element={
              <PrivateRoute>
                <ManageUsers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="new-password-request"
            element={
              <PrivateRoute>
                <NewPasswordRequest />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="surveyor-report-list"
            element={
              <PrivateRoute>
                <SurveyorReportList />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="surveyor-report-list/:reportId"
            element={
              <PrivateRoute>
                <ReviewSurveyData />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="airport-review-list"
            element={
              <PrivateRoute>
                <AirportReviewList />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path="ad-2-data-entry"
          element={
            <PrivateRoute>
              <AD2DataEntry />
            </PrivateRoute>
          }
        >
          <Route
            exact
            path="add-airport-data"
            element={
              <PrivateRoute>
                <AddAirportData />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path="/review-airport-data/:airportId"
          element={
            <PrivateRoute>
              <AirportReview />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
