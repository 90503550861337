import React from 'react';
import { join } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableRowLoader from '../tabel-component/TableRowLoader';

export default function RATable({
  isLoading,
  RAData,
  selectedRA
}) {
  const rows = RAData.filter((ra) => selectedRA.includes(ra.Aerodrome));
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: 150 }}>Aerodrome</StyledTableCell>
            <StyledTableCell>Hours of Ops</StyledTableCell>
            <StyledTableCell sx={{ width: 250 }}>
              Controlled airspace lateral limits
            </StyledTableCell>
            <StyledTableCell>Upper limit / Lower Limit</StyledTableCell>
            <StyledTableCell>Language</StyledTableCell>
            <StyledTableCell sx={{ width: 200 }}>Remarks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader isLoading={isLoading} data={RAData} colSpan={6} />
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.name}`}>
                <StyledTableCell>{row?.Aerodrome || '-'}</StyledTableCell>
                <StyledTableCell>{row?.hours || '-'}</StyledTableCell>
                <StyledTableCell>{row?.description || '-'}</StyledTableCell>
                <StyledTableCell>{row?.upperLimit || '-'} / {row?.lowerLimit || '-'}</StyledTableCell>
                <StyledTableCell>{row?.language || '-'}</StyledTableCell>
                <StyledTableCell>{join(row?.remarks, ', ') || '-'}</StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};