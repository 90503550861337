import React, { Fragment } from "react";
import { keysIn } from "lodash";
import { IoCloseSharp } from "react-icons/io5";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { aixmFeatureKeyMap, aixmCategory } from "../../constants/aixmData";
import { aixmFeatureMapData } from "../../constants/aximFeatureData";
import HeaderTableCell from "../tabel-component/HeaderTableCell";
import StyledTableCell from "../tabel-component/StyledTableCell";
import TableLoader from "../tabel-component/TableLoader";
import NavaidItem from "./NavaidItem";
import AeronauticalGroundLightItem from "./AeronauticalGroundLightItem";
import NavigationSystemCheckpointItem from "./NavigationSystemCheckpointItem";
import AirTrafficControlServiceItem from "./AirTrafficControlServiceItem";
import AirportGroundServiceItem from "./AirportGroundServiceItem";
import RunWayItem from "./RunWayItem";
import RunWayDiractionItem from "./RunWayDiractionItem";
import ObstacleAreaItem from "./ObstacleAreaItem";
import MeteorologicalInformationItem from "./MeteorologicalInformationItem";
import HandlingServicesList from "./HandlingServicesList";
import PassengerFacilitiesList from "./PassengerFacilitiesList";
import RescueList from "./RescueList";
import SeasonalServiceList from "./SeasonalServiceList";
import OperatorList from "./OperatorList";
import SurfaceMovement from "./SurfaceMovement";

export default function AixmAirportDetailTables({
  tabValue,
  handleTabChange,
  selectedCategories,
  handleCloseTab,
  isLoadingAirportDetail,
  selctedAirport,
  airportDetails,
  runwayDetails,
  isLoadingRunwayDetails,
  isLoadingAixmObstacles,
  obstaclesAreaList,
  isActiveDataManagement,
}) {
  return (
    <TabContext value={tabValue}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "calc(100vw - 333px)",
        }}
      >
        <TabList
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {selectedCategories.map((category, i) => (
            <Tab
              key={`tab_${category.value}_${i}`}
              label={category.label}
              value={category.value}
              iconPosition="end"
              icon={
                <IoCloseSharp
                  size={20}
                  onClick={() => handleCloseTab(category)}
                />
              }
              sx={{ textTransform: "none" }}
            />
          ))}
        </TabList>
      </Box>
      {selectedCategories.map((category, i) => {
        if (
          category.value === aixmCategory.aeronauticalGroundLight_list &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <AeronauticalGroundLightItem
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.airTrafficControlService &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <AirTrafficControlServiceItem
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.airportGroundService &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <AirportGroundServiceItem
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.runway_List &&
          runwayDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <RunWayItem
                isLoading={isLoadingRunwayDetails}
                category={category.value}
                runwayDetails={runwayDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.RD_List &&
          runwayDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <RunWayDiractionItem
                isLoading={isLoadingRunwayDetails}
                category={category.value}
                runwayDetails={runwayDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.navigationSystemCheckpoint_list &&
          (airportDetails["Checkpoint_VOR"] || airportDetails["Checkpoint_INS"])
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <NavigationSystemCheckpointItem
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.navaid &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <NavaidItem
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.meteorologicalInformationList &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <MeteorologicalInformationItem
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (category.value === aixmCategory.obstacleArea_list) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <ObstacleAreaItem
                isActiveDataManagement={isActiveDataManagement}
                category={category.value}
                isLoading={isLoadingAixmObstacles}
                obstaclesAreaList={obstaclesAreaList}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.handlingServicesList &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <HandlingServicesList
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.passengerFacilitiesList &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <PassengerFacilitiesList
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.rescueList &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <RescueList
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.seasonalServiceList &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <SeasonalServiceList
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.operatorList &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <OperatorList
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          category.value === aixmCategory.surfaceMovementGuidance &&
          airportDetails[category.value]
        ) {
          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <SurfaceMovement
                isLoading={isLoadingAirportDetail}
                category={category.value}
                airportDetails={airportDetails}
                aixmFeatureMapData={aixmFeatureMapData}
                selctedAirport={selctedAirport}
              />
            </TabPanel>
          );
        }

        if (
          airportDetails[category.value] &&
          airportDetails[category.value].length > 0
        ) {
          const categotyData = airportDetails[category.value];
          const aixmCategoryData = aixmFeatureMapData[category.value];

          return (
            <TabPanel
              key={`tabpanel_${category.value}_${i}`}
              value={category.value}
              sx={{ p: 0 }}
            >
              <Paper sx={{ mb: 5 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <HeaderTableCell colSpan={2}>
                        {selctedAirport.name} {">"}{" "}
                        {aixmFeatureKeyMap[category.value]}
                      </HeaderTableCell>
                    </TableRow>
                  </TableHead>
                  {categotyData?.map((data, j) => {
                    return (
                      <Fragment key={`${j}_${category.value}`}>
                        <TableHead>
                          {category.value ===
                          aixmCategory.informationService ? (
                            <TableRow>
                              <StyledTableCell colSpan={2}>
                                {aixmFeatureKeyMap[category.value]}{" "}
                                {data?.designation || "-"}
                              </StyledTableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <StyledTableCell colSpan={2}>
                                {aixmFeatureKeyMap[category.value]}{" "}
                                {data?.designator || j + 1}
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableHead>
                        <TableBody>
                          {keysIn(aixmCategoryData).map((key, k) => {
                            const originalKey = aixmCategoryData[key];
                            return (
                              <TableRow
                                key={`${k}_${j}_${category.value}`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell sx={{ width: 300 }}>
                                  {key}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data[originalKey] || "-"}
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Fragment>
                    );
                  })}
                </Table>
              </Paper>
            </TabPanel>
          );
        }

        return (
          <TabPanel
            key={`tabpanel_${category.value}_${i}`}
            value={category.value}
            sx={{ p: 0 }}
          >
            <TableLoader
              isLoading={isLoadingAirportDetail}
              data={airportDetails[category.value]}
            />
          </TabPanel>
        );
      })}
    </TabContext>
  );
}
