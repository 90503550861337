import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten } from "lodash";
import { areaHighLightColor } from "../../constants/colors";
import { airspaceType } from "../../constants/airspaceData";
import InfoPopup from "./InfoPopup";

export default function RenderADIZAirspace({
  ADIZAirspaceGeometry,
  handleViewMore,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (e, data) => {
    e.target.bringToFront();
    e.target.openPopup();
    setSelectedOverE(data);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonClick = (info) => {
    handleViewMore(info, airspaceType.adiz);
  };

  return (
    <Fragment>
      {ADIZAirspaceGeometry.map((data, i) => {
        return (
          <Polygon
            key={i}
            pane="overlayPane"
            positions={flatten(data.coordinates)}
            pathOptions={{
              color:
                selectedOverE?.designation === data.designation
                  ? areaHighLightColor
                  : "#007900",
              weight: selectedOverE?.designation === data.designation ? 3 : 2,
              fillColor: "#007900",
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, data),
              mouseout: () => handlePolygonOut(),
              click: () => handlePolygonClick(data),
            }}
          >
            <InfoPopup info={data} />
          </Polygon>
        );
      })}
    </Fragment>
  );
}
