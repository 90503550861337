import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { selecteChartTableData } from "../../models/electronicChart";
import StyledTableRow from "../tabel-component/StyledTableRow";

export default function PDCChartTable({ selectedEchart, selectedMapLabel }) {
  const data = useSelector((state) =>
    selecteChartTableData(state, selectedEchart)
  );

  useEffect(() => {
    if (!selectedMapLabel) return;
    const rowElm = document.getElementById(
      `table-row-${selectedMapLabel.text}`
    );

    if (rowElm) {
      rowElm.scrollIntoView(true);
    }
  }, [selectedMapLabel]);

  return (
    <TableContainer id="eChartTable">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: 11 }}>Stand Num</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Latitude</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Longitude</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Elev</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Suitability</TableCell>
            <TableCell sx={{ fontSize: 11 }}>PCN</TableCell>
            <TableCell sx={{ fontSize: 11 }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="eChartTableBody">
          {orderBy(data, ["stand_num"], ["asc"]).map((row) => (
            <StyledTableRow
              hover
              selected={selectedMapLabel?.text === `PB${row.stand_num}`}
              key={row.id}
              id={`table-row-PB${row.stand_num}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ fontSize: 11 }}>{row.stand_num}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.latitude}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.longitude}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.elev}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.suitability}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.pcn}</TableCell>
              <TableCell sx={{ fontSize: 11 }}>{row.status}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
