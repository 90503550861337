import React, { Fragment } from "react";
import { orderBy } from "lodash";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FilterTableHeadRow from "./FilterTableHeadRow";

export default function AcceptDataTable({ data, isShowBaseElev }) {
  return (
    <Fragment>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Accepted
        </Typography>
      </Toolbar>
      <Table stickyHeader size="small">
        <TableHead>
          <FilterTableHeadRow isShowBaseElev={isShowBaseElev} />
        </TableHead>
        <TableBody>
          {orderBy(data, ["uid1"], ["asc"]).map((row, i) => {
            return (
              <TableRow key={i}>
                <TableCell align="center">{row["uid1"]}</TableCell>
                <TableCell align="center">{row["point_name1"]}</TableCell>
                <TableCell align="center">{row["latitude1"]}</TableCell>
                <TableCell align="center">{row["longitude1"]}</TableCell>
                <TableCell>{row["top_elev1"]}</TableCell>
                {isShowBaseElev && (
                  <TableCell align="center">{row["base_elev1"]}</TableCell>
                )}
                {!isShowBaseElev && (
                  <TableCell align="center">
                    {row["ellipsoidal_elev1"]}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
}
