import React, { Fragment } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';
import { aixmFeatureKeyMap } from '../../constants/aixmData';

export default function MeteorologicalInformationItem({
  isLoading,
  category,
  airportDetails,
  selctedAirport
}) {
  const surfaceMovementData = airportDetails[category];
  const keys = Object.keys(surfaceMovementData);

  return (
    <Fragment key={category}>
      {(isLoading || keys.length === 0) && (
        <TableLoader isLoading={isLoading} data={keys} />
      )}
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {'>'} {aixmFeatureKeyMap[category]}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell sx={{ width: '30%' }}>
                Use of aircraft stand identification signs, taxiway guidelines and visual docking/parking guidance system at aircraft stands
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {surfaceMovementData['Use of aircraft stand identification signs, taxiway guidelines and visual docking/parking guidance system at aircraft stands'] || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '30%' }}>
                Runway and taxiway markings and lights
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {surfaceMovementData['Runway and taxiway markings and lights'] || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '30%' }}>
                Stop bars (if any)
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {surfaceMovementData['Stop bars (if any)'] || 'NIL'}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ width: '30%' }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell sx={{ width: '30%' }}>
                {surfaceMovementData.Remarks || 'NIL'}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
