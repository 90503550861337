import React from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { ACCESS_TOKEN } from "../../constants/constant";
import {
  addShapValidationSchema,
  modifyCategories,
} from "../../constants/amdbData";
import { addEditAMDBpolygon } from "../../models/amdb";
import { getAsyncStorageValue } from "../../utils/localStorage";
import { OutlinedButton, PrimaryButton } from "../button";

export default function AddAmdbShapModal({
  isOpenAddShapDialog,
  selctedAirport,
  coords,
  handleClose,
}) {
  const dispatch = useDispatch();
  const isLoadingAddEditPolygon = useSelector(
    (state) => state.amdb.isLoadingAddEditPolygon
  );
  const addShapForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      geo: coords,
      polytype: "MULTIPOLYGON",
      poly_id: "new",
      airport_id: selctedAirport?.id || "",
      feature: "",
      shape_name: "",
    },
    validationSchema: addShapValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAMDBpolygon(
            values,
            selctedAirport?.id,
            token,
            onCloseAddShapDialog
          )
        );
      });
    },
  });
  const onCloseAddShapDialog = () => {
    addShapForm.resetForm();
    addShapForm.setSubmitting(false);
    handleClose();
  };

  return (
    <Dialog fullWidth open={isOpenAddShapDialog}>
      <DialogTitle>Add Shap</DialogTitle>
      <DialogContent>
        <form>
          <FormControl
            fullWidth
            sx={{ mt: 1 }}
            error={
              addShapForm.touched.feature && Boolean(addShapForm.errors.feature)
            }
          >
            <InputLabel id="category">Feature*</InputLabel>
            <Select
              labelId="feature"
              id="feature"
              name="feature"
              label="feature"
              value={addShapForm.values.feature}
              onChange={addShapForm.handleChange}
            >
              <MenuItem value={modifyCategories.runway}>RunWay</MenuItem>
              <MenuItem value={modifyCategories.apron}>Apron</MenuItem>
              <MenuItem value={modifyCategories.taxiway}>Taxiway</MenuItem>
              {/* <MenuItem value="parking bayline">Parking Bayline</MenuItem>
              <MenuItem value="parking bay points">Parking Bay Points</MenuItem>
              <MenuItem value="runway marking">Runway Marking</MenuItem> */}
            </Select>
            <FormHelperText>
              {addShapForm.touched.feature && addShapForm.errors.feature}
            </FormHelperText>
          </FormControl>
          <TextField
            fullWidth
            variant="outlined"
            id="shape_name"
            name="shape_name"
            label="Shape Name*"
            value={addShapForm.values.shape_name}
            onChange={addShapForm.handleChange}
            error={
              addShapForm.touched.shape_name &&
              Boolean(addShapForm.errors.shape_name)
            }
            helperText={
              addShapForm.touched.shape_name && addShapForm.errors.shape_name
            }
            sx={{ mt: 1 }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={onCloseAddShapDialog}
          sx={{ width: 120 }}
        />
        <PrimaryButton
          label="Save"
          onClick={() => addShapForm.handleSubmit()}
          disabled={isLoadingAddEditPolygon}
          isLoading={isLoadingAddEditPolygon}
          sx={{ width: 120 }}
        />
      </DialogActions>
    </Dialog>
  );
}
