import React, { Fragment } from 'react';
import { keysIn } from 'lodash';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HeaderTableCell from '../tabel-component/HeaderTableCell';
import StyledTableCell from '../tabel-component/StyledTableCell';
import TableLoader from '../tabel-component/TableLoader';
import { aixmFeatureKeyMap } from '../../constants/aixmData';
import { serviceValueObject } from '../../constants/aximFeatureData';

export default function HandlingServicesList({
  isLoading,
  category,
  airportDetails,
  aixmFeatureMapData,
  selctedAirport
}) {
  const handlingServicesList = airportDetails[category];
  const keys = Object.keys(handlingServicesList);
  const handlingServicesMap = aixmFeatureMapData['Handling_services_list'];

  return (
    <Fragment key={category}>
      {(isLoading || keys.length === 0) && (
        <TableLoader isLoading={isLoading} data={keys} />
      )}
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {aixmFeatureKeyMap[category]}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keysIn(handlingServicesMap).map((key, i) => {
              const handleServiceKey = handlingServicesMap[key];
              const handleService = handlingServicesList[handleServiceKey];
              const value = serviceValueObject
                .map((v) => {
                  if (handleServiceKey === 'Fuel_and_Oil' && v === 'note') {
                    return `${handleService?.note?.Fuel ? handleService?.note?.Fuel : ''} ${handleService?.note?.Oil ? handleService?.note?.Oil : ''}`;
                  };

                  return handleService[v] ? handleService[v] : undefined;
                })
                .filter((f) => f !== null || f !== '' || f !== ' ' || f !== 'null' || f !== undefined)
                .join(' ');

              return (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell sx={{ width: '20%' }}>
                    {key}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '80%' }}>
                    {!!value ? value : 'NIL'}
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
