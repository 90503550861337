import React from "react";
import { orderBy } from "lodash";
import { TableRow } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import StyledTableCell from "../tabel-component/StyledTableCell";

export default function ObjectsPreviewDataTable({ data }) {
  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>SNO</StyledTableCell>
            <StyledTableCell>UID</StyledTableCell>
            <StyledTableCell>POINT NAME</StyledTableCell>
            <StyledTableCell>LATITUDE</StyledTableCell>
            <StyledTableCell>LONGITUDE</StyledTableCell>
            <StyledTableCell>TOP ELEV. IN m. (EGM 08)</StyledTableCell>
            <StyledTableCell>BASE ELEV. IN m. (EGM 08)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(data, ["SNO"], ["asc"]).map((row) => {
            return (
              <TableRow key={row["SNO"]}>
                <StyledTableCell>{row["SNO"]}</StyledTableCell>
                <StyledTableCell>{row["UID"]}</StyledTableCell>
                <StyledTableCell>{row["POINT NAME"]}</StyledTableCell>
                <StyledTableCell>{row["LATITUDE"]}</StyledTableCell>
                <StyledTableCell>{row["LONGITUDE"]}</StyledTableCell>
                <StyledTableCell>
                  {row["TOP ELEV. IN m. (EGM 08)"]}
                </StyledTableCell>
                <StyledTableCell>
                  {row["BASE ELEV. IN m. (EGM 08)"] || "-"}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
